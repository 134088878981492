/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0004 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M450,158.8L316.4,93.2l27.1-22.3c-64.9-51.4-156.4-51.5-219,21C195.4-3.2,292-30.5,385.3,38.5l25.2-20.7L450,158.8
            L450,158.8z M12.6,344.4v-134h125.8v134H37.7H12.6z M213.9,344.4v-134h125.8v134H226.5H213.9z M0,186v-60.9h138.4V186H25.2L0,186
            L0,186z M213.9,125h138.4v60.9H213.9V125L213.9,125z M163.6,344.4V125h25.2v219.3h-12.6h-12.6V344.4z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0004;