/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order list dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../../../dialog";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import SupplierStore from "../../../../../stores/SupplierStore";
import OrderStatusStore from "../../../../../stores/OrderStatusStore";
import OrderItems from "../orderItems";

class OrderInfoDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { open,
            onClose,
            onCancel,
            order,
            showItems
        } = this.props;

        return (
            <Dialog
                title={order.increment_id}
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Close"
                type="order-list-dialog"
            >
                <React.Fragment>
                    <Divider style={{marginBottom: "10px"}}/>
                    <Typography
                        variant="body1"
                    >
                        {order.hasOwnProperty("vendors") ? order.vendors : SupplierStore.get(order.supplier_id, "name")}
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {"Created: " + order.created_at_date}
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                    >
                        {OrderStatusStore.get(order.status, "name")}
                    </Typography>

                    {showItems && (
                        <React.Fragment>
                            <Typography
                                component="div"
                                variant="caption"
                            >
                                <OrderItems order={order}/>
                            </Typography>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </Dialog>
        );
    }
}

OrderInfoDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    order: PropTypes.any,
    showItems: PropTypes.bool
};

OrderInfoDialog.defaultProps = {
    open: false,
    showItems: false
};

export default OrderInfoDialog;