/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * On/Off Switch component
 * ---------------
 */

/**
 * Import used react components
 */
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const OnOffSwitch = withStyles({
    switchBase: {
        color: red[300],
        "&$checked": {
            color: green[500],
        },
        "&$checked + $track": {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

export default OnOffSwitch;
