function getClassNames () {
    if (arguments.length < 1) {
        return "";
    }

    const sizes = ["xxl", "xl", "l", "m", "s", "xs", "xxs"];
    const settings = arguments[0];
    let result = "";

    sizes.forEach(function (size) {
        if (settings.hasOwnProperty(size)) {
            if (settings[size].visible) {
                result+="ut-" + size + "-" + settings[size].font + "-" + settings[size].align + " ";
                if (settings[size].width !== undefined) {
                    result+="ut-" + size + "-col-" + settings[size].width + " ";
                }
                else {
                    result+="ut-" + size + "-col-12 ";
                }
            }
            else {
                result+="ut-" + size + "-hide ";
            }
        }
    });

    return result;
}

module.exports = getClassNames;