/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Delivery Partner store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class carrierStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the supplier variable
     * @param code
     * @param variable
     * @returns {*}
     */
    get(code, variable) {
        if (this.data.hasOwnProperty(code) && this.data[code].hasOwnProperty(variable)) {
            return this.data[code][variable];
        }
        return code;
    }

    /**
     * Return all data
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Return all data
     * @returns {{}|*}
     */
    getAllById() {
        let carrier_by_id = {};

        for (let key in this.data) {
            carrier_by_id[this.data[key].carrier_id] = this.data[key];
        }

        return carrier_by_id;
    }

    /**
     * Set all suppliers data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const CarrierStore = new carrierStore;

export default CarrierStore;