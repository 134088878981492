import React, { Component } from "react";
import PropTypes from "prop-types";


export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error.message);
        return { hasError: true };
    }


    render() {
        if (this.state.hasError) {
            return <h1>Oops, something went wrong...</h1>;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};