/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Orders component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {Card} from "@material-ui/core";
import Dialog from "../../../../common/dialog";
import getOrdersTable from "./getOrdersTable";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ViewportStore from "../../../../../stores/ViewportStore";

class Orders extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            zebra: ViewportStore.get(),
            show_lines: true,
            order_open: ""
        };


        this.getEAN = this.getEAN.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
    }

    getEAN(eans) {
        if (!eans) {
            return "";
        }
        eans.forEach(function (ean) {
            if (ean.charAt(0) === "2") {
                return ean;
            }
        });

        return eans[0];
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            group,
            tableIndex,
            items,
            getFounded,
            getDifference,
            getRowClassname,
            showDone,
        } = this.props;

        const hasUndone = items.find(itm => {
            if (!itm.founded) return true;
            return itm.founded !== Number(itm.qty);

        });

        if (!hasUndone && !showDone) {
            return (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    height: "50px"
                }}>
                    <Chip
                        style={{backgroundColor: "#8ec08e"}}
                        icon={<DoneIcon/>}
                        label="Minden termeket fellelt"
                    />
                </div>
            );
        }

        if (this.state.zebra) {
            return (
                <div style={{width: "100%"}}>
                    {items.map((item, idx) => (
                        <Card key={idx} elevation={5} style={{
                            margin: ".2rem",
                            display: showOrder(showDone, item) ? "block" : "none"
                        }}>
                            <div
                                onClick={() => {
                                    this.setState({order_open: item.name});
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: ".2rem"
                                }}>
                                <Typography style={{flex: 1, height: "26px", fontSize:"15px"}} className={"cut-text"}>{item.name}</Typography>
                                <InfoOutlined style={{fontSize: 30, marginLeft: ".4rem"}}/>
                            </div>
                            {getItems(item, idx, getDifference, getFounded, tableIndex, getRowClassname)}
                            <Dialog open={item.name === this.state.order_open}
                                    onCancel={() => this.setState({order_open: false})} cancelText={"Close"}>
                                <Typography style={{background: "#00000012", borderRadius: "4px", padding: "1rem"}}
                                            variant="h6">{item.name}</Typography>
                                <div><b>EAN:</b> {this.getEAN(item.ean)}</div>
                                <div><b>SKU: </b> {item.sku}</div>
                                {getItems(item, idx, getDifference, getFounded, tableIndex, getRowClassname)}
                            </Dialog>
                        </Card>
                    ))}
                </div>
            );
        }

        return (
            <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                    <TableRow selected={true}>
                        <TableCell
                            align={"left"}
                        >
                            <Typography color="inherit" variant="caption">EAN</Typography>
                        </TableCell>
                        <TableCell
                            align={"left"}
                        >
                            <Typography color="inherit" variant="caption">BH cikkszám</Typography>
                        </TableCell>
                        <TableCell
                            align={"left"}
                        >
                            <Typography color="inherit" variant="caption">Megnevezés</Typography>
                        </TableCell>
                        <TableCell
                            align={"right"}
                        >
                            <Typography color="inherit" variant="caption">Rendelt</Typography>
                        </TableCell>
                        <TableCell
                            align={"right"}
                        >
                            <Typography color="inherit" variant="caption">Fellelt</Typography>
                        </TableCell>
                        <TableCell
                            align={"right"}
                        >
                            <Typography color="inherit" variant="caption">Eltérés</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {group === false && (
                        <React.Fragment>
                            {getOrdersTable(items, false, showDone, getRowClassname, getDifference, getFounded, tableIndex, this.state.show_lines, this.getEAN)}
                        </React.Fragment>
                    )}
                    {group !== false && (
                        <React.Fragment>
                            {getOrdersTable(items, true, showDone, getRowClassname, getDifference, getFounded, tableIndex, this.state.show_lines, this.getEAN)}
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        );
    }
}

Orders.propTypes = {
    url: PropTypes.any,
    group: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    tableIndex: PropTypes.any,
    items: PropTypes.array,
    getFounded: PropTypes.func,
    getDifference: PropTypes.func,
    getRowClassname: PropTypes.func,
    showDone: PropTypes.any,
    ACL: PropTypes.any
};

Orders.defaultProps = {
    url: false,
    group: true,
    classes: {},
    items: []
};

export default Orders;

const getItems = (item, idx, getDifference, getFounded, tableIndex, getRowClassname) => (
    <div style={{display: "flex", height: "50px"}} className={getRowClassname(item)}>
        {[
            {label: "Rendelt", value: Number(item.qty)},
            {label: "Fellelt", value: Number(getFounded(tableIndex, idx))},
            {label: "Eltérés", value: Number(getDifference(tableIndex, idx))},
        ].map(itm => (
            <div
                key={itm.label}
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    border: "1px dashed gray",
                    padding: ".2rem 1rem",
                    margin: ".2rem"
                }}>
                <Typography variant="caption" style={{lineHeight: "0"}}>{itm.label}</Typography>
                <Typography variant="caption" style={{fontSize: "20px", fontWeight: "bold", lineHeight: "0"}}>{itm.value}</Typography>
            </div>
        ))}
    </div>
);


const showOrder = (showDone, item) => {
    if (showDone) {
        return item.founded && item.founded === +item.qty;
    } else {
        return !(item.founded && item.founded === +item.qty);
    }
};