/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Supplier Visibility dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../../dialog";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Switch from "../../switch/OnOffSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SupplierStore from "../../../../stores/SupplierStore";

class SupplierVisibilityDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            supplier: SupplierStore.getAll(),
        };

        this.checkPermission = this.checkPermission.bind(this);
        this.checkDisabled = this.checkDisabled.bind(this);
        this.supplierStatusUpdate = this.supplierStatusUpdate.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        SupplierStore.addChangeListener("change", this.supplierStatusUpdate);
    }

    componentWillUnmount() {
        SupplierStore.removeChangeListener("change", this.supplierStatusUpdate);
    }

    supplierStatusUpdate() {
        this.setState({supplier: SupplierStore.getAll()});
    }

    checkPermission(supplier) {
        if (this.props.data.hasOwnProperty("all") && this.props.data.all) {
            return true;
        }
        if(this.props.data.hasOwnProperty(supplier)) {
            return this.props.data[supplier];
        }
        return false;
    }

    checkDisabled() {
        if (this.props.data.hasOwnProperty("all") && this.props.data.all) {
            return true;
        }
        return false;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onCancel,
            onChange,
        } = this.props;

        return (
            <Dialog
                title={"Edit supplier visibility"}
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Close"
                okText="Save"
                maxWidth="md"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={true}>
                            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                <TableRow selected={true}>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Supplier</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none", textAlign: "center"}}
                                    >
                                        <Typography color="inherit">Visibility</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover selected={false}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                    >
                                        All
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{textAlign: "center"}}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name={"all"}
                                                    checked={this.checkPermission("all")}
                                                    onChange={onChange}
                                                    value={this.checkPermission("all")}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                {Object.keys(this.state.supplier).map((key, i) => (
                                    <TableRow hover key={i} selected={false}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {this.state.supplier[key].name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{textAlign: "center"}}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name={this.state.supplier[key].supplier_id}
                                                        checked={this.checkPermission(this.state.supplier[key].supplier_id.toString())}
                                                        onChange={onChange}
                                                        disabled={this.checkDisabled()}
                                                        value={this.checkPermission(this.state.supplier[key].supplier_id.toString())}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

SupplierVisibilityDialog.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.any,
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
};

SupplierVisibilityDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default SupplierVisibilityDialog;