/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import PrintIcon from "@material-ui/icons/Print";
import Settings from "./settings";
import SpeedDial from "../../common/speedDial";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import notificationService from "../../../services/Notification";
import Slide from "@material-ui/core/Slide";
import FullscreenLoaderService from "../../../services/FullscreenLoaderService";
import TheTable from "./TheTable";
import userDataStore from "../../../stores/UserDataStore";
import Pager from "./TheTable/pager";
import SearchBar from "./TheTable/serachBar";
import SearchIcon from "@material-ui/icons/Search";
import RoleService from "../../../services/Role";
import StatusSelectorDialog from "../statusSelectorDialog";
import {checkDownloadables} from "../utils/downloadFile";

class UniversalTable extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.page_size = 25;
        this.search_filter = [];
        this.search_order_by = {type: "", value: "asc"};
        this.new_search = false;
        this.refreshTimeout = false;

        this.loading = false;
        this.state = {
            status_selector_open: false,
            status_selector_loading: false,
            selected_status: "",
            filter_open: false,
            data: {},
            user: userDataStore.getData("user"),
            header: [],
            general: {},
            filter: [],
            header_sort: false,
            show_settings: false,
            settings_changed: false,
            settings_loaded: false,
            search_input: "",
            search_type: "increment_id",
            show_loader: true,
            page: 1
        };

        /**
         * Bind functions to this
         */
        this.fetchData = this.fetchData.bind(this);
        this.listSuccess = this.listSuccess.bind(this);
        this.listError = this.listError.bind(this);
        this.createHeader = this.createHeader.bind(this);
        this.handleShowHideTableSettings = this.handleShowHideTableSettings.bind(this);
        this.handleSaveTableSettings = this.handleSaveTableSettings.bind(this);
        this.saveTableSettingsSuccess = this.saveTableSettingsSuccess.bind(this);
        this.settingsLoadedSuccess = this.settingsLoadedSuccess.bind(this);
        this.settingsLoadedError = this.settingsLoadedError.bind(this);
        this.canStart = this.canStart.bind(this);
        this.addExtraFields = this.addExtraFields.bind(this);
        this.printSuccess = this.printSuccess.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.reload = this.reload.bind(this);
        this.getHeaderDefaultSet = this.getHeaderDefaultSet.bind(this);
        this.isPagePrint = this.isPagePrint.bind(this);
        this.isPageAdd = this.isPageAdd.bind(this);
        this.headerHasColumn = this.headerHasColumn.bind(this);
        this.reloadColumns = this.reloadColumns.bind(this);
        this.setPage = this.setPage.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.userUpdate = this.userUpdate.bind(this);
        this.checkAutoRefresh = this.checkAutoRefresh.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        userDataStore.addChangeListener("userChange", this.userUpdate);

        if (this.props.url) {
            this.fetchData();
        }
        FullscreenLoaderService.showLoader();
    }

    componentWillUnmount() {
        userDataStore.removeChangeListener("userChange", this.userUpdate);
        if (this.refreshTimeout !== false) {
            clearTimeout(this.refreshTimeout);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.search_filter = [];
            this.search_order_by = {type: "", value: "asc"};
            this.page_size = 25;
            this.setState(() =>
                ({show_settings: false, settings_loaded: false, page: 1 }),
                () => this.fetchData()
            );
        }

        if (prevProps.hasOwnProperty("needReload") && prevProps.needReload) {
            this.fetchData();
            this.setState({show_settings: false});
        }

        
    }

    userUpdate() {
        this.setState({user: userDataStore.getData("user")});
    }

    reload() {
        this.fetchData();
    }

    canStart() {
        return this.state.user.hasOwnProperty("role_id");
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state,
            target = e.target.name;

        item[target] = e.target.value;
        this.forceUpdate();
    }

    onSearchSubmit(data, order_by) {
        this.search_filter = data;
        this.search_order_by = order_by;
        this.new_search = true;
        this.setState({filter_open: false, speedDial_open: false});
        this.fetchData();
    }

    /**
     * Handle list success
     * @param result
     */
    listSuccess(result){
        this.loading = false;
        FullscreenLoaderService.hideLoader();

        if (result.hasOwnProperty("page")) {
            this.setState({data: result});
            this.createHeader();
            this.checkAutoRefresh();
        }
        if (this.props.needReload) {
            this.props.onReloaded();
        }
    }

    /**
     * Handle list error
     */
    listError() {
        FullscreenLoaderService.hideLoader();
    }

    /**
     * Fetch data from server
     */
    fetchData(what, data){
        if (!this.canStart()) {
            return;
        }

        RoleService.start();
        FullscreenLoaderService.showLoader();

        let _data = data === undefined ? "" : data;

        this.loading = true;

        if (!this.state.settings_loaded) {
            LoaderService.getData("table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + this.state.user.role_id, "", this.settingsLoadedSuccess, this.settingsLoadedError, false);
            return;
        }

        if (what === undefined || what === "") {
            // if (this.state.data.hasOwnProperty("_links")) {
            //     LoaderService.getData(this.state.data._links.self.href,_data, this.listSuccess);
            //     return;
            // }

            let result = this.props.url.includes("?") ? "&" : "?";
            if (this.new_search){
                result+= "page=1";
                this.new_search = false;
            }
            else {
                result+= "page=" + this.state.page;
            }

            result+= "&page_size=" + this.page_size;

            if (this.search_filter.length) {
                this.search_filter.forEach(function (item) {
                    if (item.type !== "" && item.value !== "") {
                        result+= "&filter[" + item.type + "]=" + item.value;
                    }
                });
            }

            if (this.search_order_by.type !== "") {
                result+= "&sort[" + this.search_order_by.type + "]=" + this.search_order_by.value;
            }


            what = this.props.url + result;
            LoaderService.getData(what, _data, this.listSuccess, this.listError);
            return;
        }

        // page size hack
        if (!what.includes("page_size")) {
            what += "&page_size=" + this.page_size;
        }
        // page size hack - END
        LoaderService.getData(what, _data, this.listSuccess, this.listError);
    }

    settingsLoadedError() {
        this.setState({settings_loaded: true});
        this.fetchData();
    }

    settingsLoadedSuccess(result) {
        if (result.hasOwnProperty("settings")) {
            let settings = JSON.parse(result.settings);
            if (settings.hasOwnProperty("ver")) {
                this.setState({
                    header: settings.header,
                    general: settings.hasOwnProperty("general") ? settings.general: {},
                    filter: settings.hasOwnProperty("filter") ? settings.filter: [],
                });
            }
        }
        this.setState({settings_loaded: true});
        this.fetchData();
    }

    /**
     * Handle if pageSize (visible items count) changed
     * @param pageSize
     * @param pageIndex
     */
    onPageSizeChanged(pageSize, pageIndex) {
        this.pageSize = pageSize;
        this.fetchData("order?page=" + (pageIndex+1) + "&page_size=" + this.pageSize);
    }

    /**
     * Handle if page (for pagination) changed
     * @param pageIndex
     */
    onPageChanged(pageIndex) {
        this.fetchData("order?page=" + (pageIndex+1) + "&page_size=" + this.pageSize);
    }

    /**
     * Add extra fields to the table settings
     * if not exists
     */
    addExtraFields() {
        if (this.props.pageData.layout === "orders") {
            let header = this.state.header,
                sort = this.state.header_sort;

            if (this.props.pageData.order_status_to === "" || this.props.pageData.order_status_to === null) {
                if (header.hasOwnProperty("print")) {
                    header.print.visible = false;
                    this.forceUpdate();
                }
                if (header.hasOwnProperty("save")) {
                    header.save.visible = false;
                    this.forceUpdate();
                }
            }
            else {
                if (!header.hasOwnProperty("print")) {
                    header.print = {
                        key: "print",
                        name: "print",
                        visible: true,
                        desktop: {
                            align: "left",
                            visible: true,
                            auto_width: true,
                            width: 0
                        },
                        tablet: {
                            align: "left",
                            visible: true,
                            auto_width: true,
                            width: 0
                        },
                        mobile: {
                            align: "left",
                            visible: true,
                            auto_width: true,
                            width: 0
                        }
                    };
                    sort.push({id: "print"});
                    this.forceUpdate();
                }

                if (!header.hasOwnProperty("save")) {
                    header.save = {
                        key: "save",
                        name: "save",
                        align: "center",
                        visible: true,
                        desktop: {
                            align: "center",
                            visible: true,
                            auto_width: true,
                            width: 0
                        },
                        tablet: {
                            align: "center",
                            visible: true,
                            auto_width: true,
                            width: 0
                        },
                        mobile: {
                            align: "center",
                            visible: true,
                            auto_width: true,
                            width: 0
                        }
                    };
                    sort.push({id: "save"});
                    this.forceUpdate();
                }
            }
        }
    }

    /**
     * The default header set
     * @param key
     * @returns {{xxs: {visible: boolean, align: string, font: string}, visible: boolean, s: {visible: boolean, align: string, font: string}, xl: {visible: boolean, align: string, font: string}, name: *, xs: {visible: boolean, align: string, font: string}, l: {visible: boolean, align: string, font: string}, m: {visible: boolean, align: string, font: string}, xxl: {visible: boolean, align: string, font: string}, key: *}}
     */
    getHeaderDefaultSet(key) {
        return {
            key: key,
            name: key.replace(/[_]/g, " "),
            visible: true,
            xxl: {
                align: "left",
                visible: true,
                font: "normal",

            },
            xl: {
                align: "left",
                visible: true,
                font: "normal",
            },
            l: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            m: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            s: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            xs: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            },
            xxs: {
                width: 12,
                align: "left",
                visible: true,
                font: "normal",
            }
        };
    }

    /**
     * Search key in header
     * @param key
     * @returns {boolean}
     */
    headerHasColumn(key) {
        let result = false;
        this.state.header.forEach(function (item) {
            if (item.key === key) {
                result = true;
            }
        });
        return result;
    }

    /**
     * Search and add new columns to header settings
     */
    reloadColumns() {
        FullscreenLoaderService.showLoader();
        let header = this.state.header,
            founded = 0;

        for (var key in this.state.data._embedded[this.props.data_key][0]) {
            if ( key !== "_links" &&
                key !== "password"
            )
            {
                if (!this.headerHasColumn(key)) {
                    header.push(this.getHeaderDefaultSet(key));
                    founded++;
                }
            }
        }
        FullscreenLoaderService.hideLoader();
        if (founded > 0) {
            notificationService.showNotification("success", founded + "new column(s) found");
            this.forceUpdate();
        }
        else {
            notificationService.showNotification("info", "No new column found");
        }
    }

    createHeader() {
        if (!this.state.header.length &&
            this.state.data.hasOwnProperty("_embedded") &&
            this.state.data._embedded.hasOwnProperty(this.props.data_key) &&
            this.state.data._embedded[this.props.data_key].length)
        {
            let keys = [];

            keys.push(this.getHeaderDefaultSet("row_controls"));

            for (var key in this.state.data._embedded[this.props.data_key][0]) {
                if ( key !== "_links" &&
                    key !== "password" &&
                    typeof this.state.data._embedded[this.props.data_key][0][key] !== "object"
                )
                {
                    keys.push(this.getHeaderDefaultSet(key));
                }
            }
            this.setState({header: keys, general: {}, filter: []});
        }

        this.setState({show_loader: false});
    }


    handleShowHideTableSettings() {
        FullscreenLoaderService.showLoader();
        let self = this;
        setTimeout(function () {
            self.setState({show_settings: !self.state.show_settings, speedDial_open: false});

        },100);
    }

    handleSaveTableSettings(settings, general, filter) {
        let role_id = this.state.user.role_id;
        FullscreenLoaderService.showLoader();

        LoaderService.postData("table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + role_id, {
            table: this.props.pageData.menu_id,
            role_id: role_id,
            settings: JSON.stringify({
                ver: "0.1.1",
                header: settings,
                general: general,
                filter: filter
            })
        }, this.saveTableSettingsSuccess);
        this.setState({header: settings, general: general, filter: filter, speedDial_open: false});
        this.checkAutoRefresh(general.auto_refresh);
    }

    saveTableSettingsSuccess(result) {
        if (result.hasOwnProperty("table_settings_id")) {
            this.setState({show_settings: false});
            notificationService.showNotification("success", "Table settings saved");
        }
    }

    checkAutoRefresh(val) {
        if (this.state.general.hasOwnProperty("auto_refresh") && this.state.general.auto_refresh !== "0") {
            let self = this;
            if (this.refreshTimeout !== false) {
                clearTimeout(this.refreshTimeout);
            }
            this.refreshTimeout = setTimeout(function () {
                self.fetchData();
            },3600 * Number(val === undefined ? this.state.general.auto_refresh : val));
        }
    }

    /**
     * Print the order list
     */
    handlePrint() {
        this.setState({status_selector_open: true});
    }

    /**
     * Print success
     * @param res
     */
    printSuccess(res) {
        this.setState({status_selector_open: false, status_selector_loading: false});
        checkDownloadables(res);

        if (res.hasOwnProperty("success") && res.success === true) {
            notificationService.showNotification("success", "Print success");
            this.fetchData();
        }
    }

    isPagePrint() {
        if (!this.props.pageData.layout ==="orders") {
            return false;
        }
        if (!this.state.general.hasOwnProperty("page_print")) {
            return false;
        }
        return this.state.general.page_print;
    }

    isPageAdd() {
        if (this.props.pageData.layout === "orders") {
            return false;
        }

        if (!this.props.acl.hasOwnProperty("edit")) {
            return false;
        }

        return this.props.acl.edit;
    }

    setPage(page) {
        this.setState({page}, this.fetchData);
    }

    setPageSize(page_size) {
        this.page_size = page_size;
        this.fetchData();
    }

    toggleFilter() {
        this.setState({filter_open: !this.state.filter_open});
    }

    acceptStatusDialog(selected_status) {
        this.setState({selected_status, status_selector_loading: true});

        LoaderService.postData("sales_order_print",{
            menu_id: this.props.pageData.menu_id,
            order_status_to: selected_status
        }, this.printSuccess, () => this.setState({status_selector_loading: false}));
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            pageData,
            classes,
            isThemeDark,
            onAdd,
            onEdit,
            onDelete,
            data_key,
            url
        } = this.props;

        return (
            <React.Fragment>
                { this.canStart() && (
                    <React.Fragment>
                        <SearchBar
                            classes={classes}
                            header={this.state.header}
                            search=""
                            open={this.state.filter_open}
                            toggleFilter={this.toggleFilter}
                            onSearch={this.onSearchSubmit}
                            pageData={pageData}
                        />
                        <Slide direction="down" in={!this.state.show_settings && this.state.data.hasOwnProperty("_embedded") && this.state.settings_loaded} mountOnEnter unmountOnExit>
                            <Pager
                                isBottom={false}
                                url={url}
                                links={this.state.data._links !== undefined ? this.state.data._links : {}}
                                pageInfo={{
                                    page_count: this.state.data.page_count !== undefined ? this.state.data.page_count : 0,
                                    page_size: this.state.data.page_size !== undefined ? this.state.data.page_size : 0,
                                    total_items: this.state.data.total_items !== undefined ? this.state.data.total_items : 0,
                                    page: this.state.data.page !== undefined ? this.state.data.page : 0
                                }}
                                setPage={this.setPage}
                                setPageSize={this.setPageSize}
                                fetchData={this.fetchData}
                            />
                        </Slide>
                        <Slide direction="up" in={this.state.show_settings} mountOnEnter unmountOnExit>
                            <Settings
                                pageData={pageData}
                                classes={classes}
                                header={this.state.header}
                                general={this.state.general}
                                filter={this.state.filter}
                                handleShowHideTableSettings={this.handleShowHideTableSettings}
                                handleSaveTableSettings={this.handleSaveTableSettings}
                                reloadColumns={this.reloadColumns}
                            />
                        </Slide>
                        <Slide direction="up" in={!this.state.show_settings && this.state.data.hasOwnProperty("_embedded") && this.state.settings_loaded} mountOnEnter unmountOnExit>
                            <TheTable
                                classes={classes}
                                isThemeDark={isThemeDark}
                                acl={acl}
                                pageData={pageData}
                                visible={!this.state.show_settings}
                                reload={this.reload}
                                header={this.state.header}
                                general={this.state.general}
                                data={this.state.data._embedded !== undefined ? this.state.data._embedded[data_key] : {}}
                                onEdit={onEdit}
                                onDelete={onDelete}
                            />
                        </Slide>
                        <Slide direction="up" in={!this.state.show_settings && this.state.data.hasOwnProperty("_embedded") && this.state.settings_loaded} mountOnEnter unmountOnExit>
                            <Pager
                                isBottom={true}
                                url={url}
                                links={this.state.data._links !== undefined ? this.state.data._links : {}}
                                pageInfo={{
                                    page_count: this.state.data.page_count !== undefined ? this.state.data.page_count : 0,
                                    page_size: this.state.data.page_size !== undefined ? this.state.data.page_size : 0,
                                    total_items: this.state.data.total_items !== undefined ? this.state.data.total_items : 0,
                                    page: this.state.data.page !== undefined ? this.state.data.page : 0
                                }}
                                setPage={this.setPage}
                                setPageSize={this.setPageSize}
                                fetchData={this.fetchData}
                            />
                        </Slide>
                        <Slide direction="up" in={!this.state.show_settings} mountOnEnter unmountOnExit>
                            <SpeedDial
                                actions={[
                                    {icon: <SearchIcon />, label: "Search", tooltipTitle: "Search", onClick: () => this.toggleFilter(), condition: true},
                                    {icon: <AddIcon />, label: "Add", tooltipTitle: "Add", onClick: () => onAdd(), condition: this.isPageAdd()},
                                    {icon: <PrintIcon />, label: "Print", tooltipTitle: "Print", onClick: () => this.handlePrint(), condition: this.isPagePrint()},
                                    {icon: <SettingsIcon />, label: "Settings", tooltipTitle: "Settings", onClick: () => this.handleShowHideTableSettings(), condition: acl.hasOwnProperty("admin")},
                                ]}
                            >
                            </SpeedDial>
                        </Slide>
                    </React.Fragment>
                )}
                {this.state.status_selector_open && (
                    <StatusSelectorDialog statuses={this.props.pageData.order_status_to}
                                          isLoading={this.state.status_selector_loading}
                                          onCancel={() => this.setState({status_selector_open: false})}
                                          onChange={selected_status => this.acceptStatusDialog(selected_status)}
                    />
                )}
            </React.Fragment>
        );
    }
}

UniversalTable.propTypes = {
    onReloaded: PropTypes.any,
    needReload: PropTypes.bool,
    url: PropTypes.any,
    pageData: PropTypes.any,
    onAdd: PropTypes.any,
    onEdit: PropTypes.any,
    onDelete: PropTypes.any,
    data_key: PropTypes.string,
    row_key: PropTypes.string,
    location: PropTypes.any,
    classes: PropTypes.any,
    isThemeDark: PropTypes.any,
    acl: PropTypes.any,
    theme: PropTypes.any
};

UniversalTable.defaultProps = {
    url: false,
    needReload: false,
    acl: {},
    classes: {},
    theme: {}
};

export default UniversalTable;
