/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Header component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TheTableHeaderCell from "../TheTableHeaderCell";

class TheTableHead extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            header,
        } = this.props;

        return (
            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                <TableRow selected={true}>
                    {header.map((item, index) => (
                        <React.Fragment key={index}>
                            {(item.key !== "row_controls" || (item.key === "row_controls" && acl.hasOwnProperty("edit") && acl.edit)) && (
                                <TheTableHeaderCell
                                    settings={item}
                                    canEdit={acl.hasOwnProperty("edit") && acl.edit}
                                    item={item.name}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

TheTableHead.propTypes = {
    acl: PropTypes.any,
    header: PropTypes.any,
    classes: PropTypes.any,
};

TheTableHead.defaultProps = {
    header: false,
    classes: {}
};

export default TheTableHead;