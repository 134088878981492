/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CommunicationForm from "../../../common/settings/communicationForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "../../../common/switch/OnOffSwitch";

class EditDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            configuration_dialog_open: false,
        };

        this.configurationDialogOpen = this.configurationDialogOpen.bind(this);
        this.configurationDialogClose = this.configurationDialogClose.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    configurationDialogOpen() {
        this.setState({configuration_dialog_open: true});
    }

    configurationDialogClose() {
        this.setState({configuration_dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            stores,
            classes,
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
            onConfigurationChanged
        } = this.props;

        return (
            <Dialog
                title={edit ? "Edit supplier" : "Add new supplier"}
                open={open}
                onOk={onOk}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
                okText="Save"
                maxWidth="sm"
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.dividerFullWidth}
                            color="textSecondary"
                            display="block"
                            variant="caption"
                            style={{textAlign: "left"}}
                        >
                            Default data
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="name_input"
                                color="secondary"
                            >
                                Name
                            </InputLabel>
                            <Input
                                id="name_input"
                                type={"string"}
                                value={data.name === null || data.name === undefined ? "" : data.name}
                                name="name"
                                color="secondary"
                                autoFocus={true}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="full_name_input"
                                color="secondary"
                            >
                                Full name
                            </InputLabel>
                            <Input
                                id="full_name_input"
                                type={"string"}
                                value={data.full_name === null || data.full_name === undefined ? "" : data.full_name}
                                name="full_name"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="code_input"
                                color="secondary"
                            >
                                Code
                            </InputLabel>
                            <Input
                                id="code_input"
                                type={"string"}
                                value={data.code === null || data.code === undefined ? "" : data.code}
                                name="code"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="address_info_input"
                                color="secondary"
                            >
                                Address info
                            </InputLabel>
                            <Input
                                id="address_info_input"
                                type={"string"}
                                value={data.address_info === null || data.address_info === undefined ? "" : data.address_info}
                                name="address_info"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            style={{marginTop: "10px"}}
                            control={
                                <Switch name="direct_delivery" checked={data.direct_delivery === "0" ? false : true} onChange={onChange} value={data.direct_delivery} />
                            }
                            label="Direct delivery"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.dividerFullWidth}
                            color="textSecondary"
                            display="block"
                            variant="caption"
                            style={{textAlign: "left"}}
                        >
                            Communication
                        </Typography>
                        <Divider />
                    </Grid>
                    <CommunicationForm
                        stores={stores}
                        classes={classes}
                        onChange={onChange}
                        data={data}
                        edit={edit}
                        onConfigurationChanged={onConfigurationChanged}
                    />
                    <Grid item xs={12}>
                        <Typography
                            className={classes.dividerFullWidth}
                            color="textSecondary"
                            display="block"
                            variant="caption"
                            style={{textAlign: "left"}}
                        >
                            Paths and files
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="incoming_path_input"
                                color="secondary"
                            >
                                Incoming path
                            </InputLabel>
                            <Input
                                id="incoming_path_input"
                                type={"string"}
                                value={data.incoming_path === null || data.incoming_path === undefined ? "" : data.incoming_path}
                                name="incoming_path"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="incoming_path_processed_input"
                                color="secondary"
                            >
                                Incoming path processed
                            </InputLabel>
                            <Input
                                id="incoming_path_processed_input"
                                type={"string"}
                                value={data.incoming_path_processed === null || data.incoming_path_processed === undefined ? "" : data.incoming_path_processed}
                                name="incoming_path_processed"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="outgoing_path_input"
                                color="secondary"
                            >
                                Outgoing path
                            </InputLabel>
                            <Input
                                id="outgoing_path_input"
                                type={"string"}
                                value={data.outgoing_path === null || data.outgoing_path === undefined ? "" : data.outgoing_path}
                                name="outgoing_path"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="outgoing_path_processed_input"
                                color="secondary"
                            >
                                Outgoing path processed
                            </InputLabel>
                            <Input
                                id="outgoing_path_processed_input"
                                type={"string"}
                                value={data.outgoing_path_processed === null || data.outgoing_path_processed === undefined ? "" : data.outgoing_path_processed}
                                name="outgoing_path_processed"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="file_name_input"
                                color="secondary"
                            >
                                File name
                            </InputLabel>
                            <Input
                                id="file_name_input"
                                type={"string"}
                                value={data.file_name === null || data.file_name === undefined ? "" : data.file_name}
                                name="file_name"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                id={"file_type_input"}
                                color="secondary"
                            >File type</InputLabel>
                            <Select
                                labelId={"file_type_input"}
                                id={"file_type_input"}
                                name="file_type"
                                style={{textAlign: "left"}}
                                value={data.file_type === null || data.file_type === undefined ? "" : data.file_type}
                                color="secondary"
                                onChange={onChange}
                            >
                                <MenuItem value="csv">CSV</MenuItem>
                                <MenuItem value="edi">EDI</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

EditDialog.propTypes = {
    stores: PropTypes.any,
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onConfigurationChanged: PropTypes.any,
};

EditDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditDialog;