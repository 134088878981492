/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Pager component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import {IconButton} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ViewportStore from "../../../../../stores/ViewportStore";
// import FormControl from "@material-ui/core/FormControl";

class Pager extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 0,
            zebra: ViewportStore.get()
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.getPageCounts = this.getPageCounts.bind(this);
        this.onChange = this.onChange.bind(this);


    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pageInfo.page !== this.state.page) {
            this.setState({page: this.props.pageInfo.page});
        }
        if (prevProps.pageInfo.page_size !== this.state.page_size) {
            this.setState({page_size: this.props.pageInfo.page_size});
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state,
            target = e.target.name;

        switch (target) {
            case "page":
                this.props.setPage(e.target.value);
                break;
            case "page_size":
                this.props.setPageSize(e.target.value);
                break;
            default:
                item[target] = e.target.value;
                this.forceUpdate();
                break;
        }
    }

    /**
     * Change page
     * @param where
     */
    onChangePage(where) {
        const page = Number(this.props.links[where].href.split("=").pop());
        this.props.setPage(page || 1);
    }

    getPageCounts() {
        let result = [];
        let i = 1;
        while (i < this.props.pageInfo.page_count + 1) {
            result.push(
                <MenuItem key={i} value={i}>{i}</MenuItem>
            );
            i++;
        }
        return result;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            links,
            isBottom,
            pageInfo
        } = this.props;


        if (this.state.zebra) {
            return (
                <div
                    style={{marginBottom: "0", padding: ".4rem", display: "flex", alignItems: "center", justifyContent: "center"}}
                >
                    <IconButton
                        style={{padding: "0.2rem"}}
                        disabled={!links.hasOwnProperty("first") || links.first.href === links.self.href.replace("?page=1", "")}
                        onClick={() => {
                            {
                                this.onChangePage("first");
                            }
                        }}
                    >
                        <FirstPageIcon style={{fontSize: "2.4rem"}}/>
                    </IconButton>
                    <IconButton
                        style={{padding: "0.2rem"}}
                        disabled={!links.hasOwnProperty("prev")}
                        onClick={() => {
                            {
                                this.onChangePage("prev");
                            }
                        }}
                    >
                        <NavigateBeforeIcon style={{fontSize: "2.4rem"}}/>
                    </IconButton>

                    <Typography style={{display: "inline"}} variant="h6">
                        {this.state.page + " / " + pageInfo.page_count}
                    </Typography>

                    <IconButton
                        style={{padding: "0.2rem"}}
                        disabled={!links.hasOwnProperty("next")}
                        onClick={() => {
                            {
                                this.onChangePage("next");
                            }
                        }}
                    >
                        <NavigateNextIcon style={{fontSize: "2.4rem"}}/>
                    </IconButton>
                    <IconButton
                        style={{padding: "0.2rem"}}
                        disabled={!links.hasOwnProperty("last") || links.last.href === links.self.href}
                        onClick={() => {
                            {
                                this.onChangePage("last");
                            }
                        }}
                    >
                        <LastPageIcon style={{fontSize: "2.4rem"}}/>
                    </IconButton>
                </div>
            );
        }

        return (
            <Grid
                container
                spacing={1}
                style={{marginBottom: "0"}}
                className={!isBottom ? "pager column-reverse" : "pager "}
            >
                <Grid item xs={12} sm={8} className="pager-settings">
                    <Typography style={{display: "inline"}}>
                        {"Page: "}
                    </Typography>
                    {pageInfo.page !== 0 && (
                        <Select
                            labelId={"page_input"}
                            id={"page_input"}
                            name="page"
                            style={{textAlign: "center"}}
                            value={this.state.page === 0 ? 1 : this.state.page}
                            color="secondary"
                            onChange={this.onChange}
                        >
                            {this.getPageCounts()}
                        </Select>
                    )}
                    <Typography style={{display: "inline"}}>
                        {"/" + pageInfo.page_count}
                        {" | Items: "}
                    </Typography>
                    {pageInfo.page_size !== 0 && (
                        <Select
                            labelId={"page_size"}
                            id={"page_size"}
                            name="page_size"
                            style={{textAlign: "center"}}
                            value={this.state.page_size === 0 ? 25 : this.state.page_size}
                            color="secondary"
                            onChange={this.onChange}
                        >
                            <MenuItem value={5}>{5}</MenuItem>
                            <MenuItem value={10}>{10}</MenuItem>
                            <MenuItem value={25}>{25}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                            <MenuItem value={100}>{100}</MenuItem>
                        </Select>
                    )}
                    <Typography style={{display: "inline"}}>
                        {"/" + pageInfo.total_items}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} className="pager-pager">
                    <IconButton
                        disabled={!links.hasOwnProperty("first") || links.first.href === links.self.href.replace("?page=1", "")}
                        onClick={() => {
                            {
                                this.onChangePage("first");
                            }
                        }}
                    >
                        <FirstPageIcon/>
                    </IconButton>
                    <IconButton
                        disabled={!links.hasOwnProperty("prev")}
                        onClick={() => {
                            {
                                this.onChangePage("prev");
                            }
                        }}
                    >
                        <NavigateBeforeIcon/>
                    </IconButton>
                    <IconButton
                        disabled={!links.hasOwnProperty("next")}
                        onClick={() => {
                            {
                                this.onChangePage("next");
                            }
                        }}
                    >
                        <NavigateNextIcon/>
                    </IconButton>
                    <IconButton
                        disabled={!links.hasOwnProperty("last") || links.last.href === links.self.href}
                        onClick={() => {
                            {
                                this.onChangePage("last");
                            }
                        }}
                    >
                        <LastPageIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

Pager.propTypes = {
    fetchData: PropTypes.func,
    url: PropTypes.string,
    links: PropTypes.any,
    pageInfo: PropTypes.any,
    setPage: PropTypes.func,
    setPageSize: PropTypes.func,
    classes: PropTypes.any,
    isBottom: PropTypes.any
};

Pager.defaultProps = {
    url: "",
    links: {},
    classes: {},
    pageInfo: {page: 0, page_count: 0, page_size: 0, total_items: 0}
};

export default Pager;