/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order billing address component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

class BillingAddress extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { order } = this.props;
        return (
            <Grid item xs={12} sm={6} style={{textAlign:"left"}}>
                <Typography variant="h6" gutterBottom>
                    {"Billing Address"}
                </Typography>
                {(!order.hide_address) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_name}
                    </Typography>
                )}
                {(!order.hide_address && order.billing_company !== "" && order.billing_company !== null) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_company}
                    </Typography>
                )}
                {(!order.hide_address && order.billing_vat_id !== "" && order.billing_vat_id !== null) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_vat_id}
                    </Typography>
                )}
                {(!order.hide_address && order.billing_telephone !== "" && order.billing_telephone !== null) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_telephone}
                    </Typography>
                )}
                {(!order.hide_address) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_country_id}
                    </Typography>
                )}

                {(order.hide_address) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_postcode + " " + order.billing_city}
                    </Typography>
                )}

                {(!order.hide_address) && (
                    <Typography variant="inherit" component="div">
                        {order.billing_postcode + " " + order.billing_city + ", " + order.billing_street}
                    </Typography>
                )}
            </Grid>
        );
    }
}

BillingAddress.propTypes = {
    order: PropTypes.any
};

BillingAddress.defaultProps = {
    order: {}
};

export default BillingAddress;