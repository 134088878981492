/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order save dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React, {Fragment} from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "../../../dialog";
import Typography from "@material-ui/core/Typography";
import OrderStatusStore from "../../../../../stores/OrderStatusStore";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import ShippingMethodStore from "../../../../../stores/ShippingMethodStore";

import {
    withStyles
} from "@material-ui/core/styles";

const StyledTextField = withStyles({
    root: {
        color: 'white',
        "& .Mui-focused": {
            color: 'white'
        },
        "& .MuiFormLabel-root": {
            color: 'white'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: 'white !important'
        },
        "& .MuiInputBase-root": {
            color: 'white'
        }
    }
})(TextField);

class OrderSaveDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            selected_status: "",
            pathname: document.location.pathname,
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        if (this.props.statuses.length !== 0) {
            this.setState({selected_status: this.props.statuses[0]});
        }
    }

    /**
     * Set things when component is update
     */
    componentDidUpdate() {
        // menüváltásnál szükség volt a selected_status frissítésére, de csak akkor
        if (this.state.pathname !== document.location.pathname &&
            this.props.statuses.length !== 0 && this.state.selected_status !== this.props.statuses[0]
        ) {
            this.setState({selected_status: this.props.statuses[0], pathname: document.location.pathname});
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onOk,
            onCancel,
            shouldPrintLabels,
            setPrintLabelCount,
            labelPrintCount,
            storage,
            setStorage,
            showStorage,
            tableSettings,
            allItemsFound,
            otherInfo,
            shippingMethod,
            location,
            itemCount,
            weight,
            postCode
        } = this.props;

        return (
            <Dialog
                extraClasses={"itg-selector-dialog"}
                title="The order is ready to save"
                open={open}
                onClose={window.innerWidth < 599 ? onClose : null}
                onOk={() => onOk(this.state.selected_status)}
                onCancel={onCancel}
                primaryAnimation="button-sonar"
                okText="Save order"
                cancelText="Vissza"
                type="success"
                fullScreen={window.innerWidth < 599}
            >
                <div className={'items'}>
                    {tableSettings && tableSettings.partialSave && !allItemsFound && (
                        <Chip style={{margin: "10px", border: "2px solid white", color: "white"}} variant={"outlined"} color="secondary" label={"Partial save"} />
                    )}

                    {this.props.statuses.length === 1 ? (
                        <Typography>{OrderStatusStore.getLabelById(this.props.statuses[0])}</Typography>
                    ) : (
                        <Fragment>
                            {this.props.statuses.map((key, i) => (
                                <Button key={i}
                                        variant={key === this.state.selected_status ? "contained" : "outlined"}
                                        style={{ borderWidth: '2px', marginTop: i === 0 ? "10px" : "30px" }}
                                        color="default" fullWidth
                                        onClick={() => this.setState({selected_status: key})}>{OrderStatusStore.getLabelById(key)}</Button>
                            ))}
                        </Fragment>
                    )}

                    {shouldPrintLabels && (
                        <StyledTextField
                            style={{margin: ".5rem"}}
                            className={"label-printing-text-field"}
                            inputProps={{min: 0, style: { textAlign: "center" }}}
                            variant={"outlined"}
                            type={"number"}
                            label="Number of labels to print"
                            name={"label_printing"}
                            color="secondary"
                            value={labelPrintCount}
                            onChange={(e) => setPrintLabelCount(+e.target.value)}
                        />
                    )}

                    {this.props.tableSettings && this.props.tableSettings.hasOwnProperty("vendorWarning") && this.props.tableSettings.vendorWarning && otherInfo && (
                        <div style={{backgroundColor: "red", color: "black", padding: "5px", border: "2px solid black", textAlign: "left", fontSize: "12px"}} dangerouslySetInnerHTML={{ __html: otherInfo }}/>
                    )}

                    {this.props.tableSettings && this.props.tableSettings.hasOwnProperty("vendorWarning") && this.props.tableSettings.vendorWarning && showStorage && (
                        <StyledTextField
                            type={"text"}
                            variant={"outlined"}
                            className={"storage-text-field"}
                            label="Stocking point"
                            name={"storage"}
                            color="secondary"
                            autoComplete={"off"}
                            value={storage}
                            onChange={(e) => setStorage(e.target.value)}
                            InputLabelProps={{shrink: true}}
                        />
                    )}

                    {location && (
                        <DialogContentText>
                            <Typography style={{color: 'white'}}>Lokáció: { location.join(', ') }</Typography>
                        </DialogContentText>
                    )}

                    {itemCount && (
                        <DialogContentText>
                            <Typography style={{color: 'white'}}>Tételek száma: { itemCount }</Typography>
                        </DialogContentText>
                    )}

                    {weight && (
                        <DialogContentText>
                            <Typography style={{color: 'white'}}>Súly: { weight } kg</Typography>
                        </DialogContentText>
                    )}

                    {shippingMethod && (
                        <DialogContentText>
                            <Typography style={{color: 'white'}}>{ShippingMethodStore.get(shippingMethod, "label")}</Typography>
                        </DialogContentText>
                    )}

                    {postCode && (
                        <DialogContentText>
                            <Typography style={{color: 'white'}}>{ postCode }</Typography>
                        </DialogContentText>
                    )}
                </div>

            </Dialog>
        );
    }
}

OrderSaveDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onOk: PropTypes.any,
    onCancel: PropTypes.any,
    statuses: PropTypes.array.isRequired,
    shouldPrintLabels: PropTypes.bool,
    setPrintLabelCount: PropTypes.func,
    labelPrintCount: PropTypes.number,
    setStorage: PropTypes.func,
    storage: PropTypes.string,
    location: PropTypes.array,
    itemCount: PropTypes.any,
    showStorage: PropTypes.bool,
    tableSettings: PropTypes.object,
    allItemsFound: PropTypes.bool,
    otherInfo: PropTypes.any,
    shippingMethod: PropTypes.any,
    weight: PropTypes.any,
    postCode: PropTypes.any
};

OrderSaveDialog.defaultProps = {
    open: false,
    location: [],
    itemCount: false,
    weight: false,
    postCode: false
};

export default OrderSaveDialog;
