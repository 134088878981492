/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Package size component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "../../../dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class PackageSizeDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};

    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onOk,
            okDisabled,
            onCancel,
            onChange,
            pack
        } = this.props;

        return (
            <Dialog
                title={"Package " + pack.package_id}
                subTitle={"Set package dimensions"}
                open={open}
                onClose={onClose}
                onOk={() => {onOk(pack);}}
                onCancel={() => {onCancel(pack);}}
                okDisabled={okDisabled}
                cancelText="Cancel"
                okText="Save"
                maxWidth="xs"
            >
                <React.Fragment>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <FormControl>
                                <InputLabel
                                    htmlFor="package_dimension_height"
                                    color="secondary"
                                >
                                    Height
                                </InputLabel>
                                <Input
                                    id="package_dimension_height"
                                    type={"text"}
                                    value={pack.new_dimension !== undefined ? pack.new_dimension.height : ""}
                                    name="height"
                                    color="secondary"
                                    autoFocus={true}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                        >
                                            <Typography
                                                component="span"
                                                variant="caption"
                                            >CM</Typography>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <FormControl>
                                <InputLabel
                                    htmlFor="package_dimension_width"
                                    color="secondary"
                                >
                                    Width
                                </InputLabel>
                                <Input
                                    id="package_dimension_width"
                                    type={"text"}
                                    value={pack.new_dimension !== undefined ? pack.new_dimension.width : ""}
                                    name="width"
                                    color="secondary"
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Typography
                                                component="span"
                                                variant="caption"
                                            >CM</Typography>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <FormControl>
                                <InputLabel
                                    color="secondary"
                                >
                                    Length
                                </InputLabel>
                                <Input
                                    type={"text"}
                                    value={pack.new_dimension !== undefined ? pack.new_dimension.length : ""}
                                    name="length"
                                    color="secondary"
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Typography
                                                component="span"
                                                variant="caption"
                                            >CM</Typography>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Dialog>
        );
    }
}

PackageSizeDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onOk: PropTypes.any,
    okDisabled: PropTypes.bool,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    pack: PropTypes.any,
};

PackageSizeDialog.defaultProps = {
    open: false,
    okDisabled: true,
    classes: {}
};

export default PackageSizeDialog;