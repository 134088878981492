/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Autologin Service component
 * ---------------
 * - auto login functionality
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

/**
 * Import used stores
 */
import SecureDataStore from "../stores/SecureDataStore";
import URLDataStore from "../stores/UrlDataStore";

/**
 * Import used services
 */
import LoaderService from "./Loader";

class AutoLogin extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();

        this.state = {
            autologin: false
        };

        /**
         * Bind functions to this
         */
        this.loginError = this.loginError.bind(this);
    }

    /**
     * Try auto login if user data is exists
     * @param callback
     */
    tryLogin(callback) {
        let data = SecureDataStore.getData("cred");

        if(data !== null) {
            this.state.autologin = true;
            LoaderService.postData(URLDataStore.getData("defaultLogin"), data, callback, this.loginError);
        }
    }

    /**
     * Handle login error
     */
    loginError() {
        this.state.autologin = false;
        this.clearData();
    }

    isAutologin() {
        return this.state.autologin;
    }

    /**
     * Save user data for next auto login
     * @param data
     */
    saveData(data) {
        SecureDataStore.setData("cred", data);
    }

    /**
     * Clear user data
     */
    clearData() {
        SecureDataStore.clearData("cred");
    }
}

const autoLogin = new AutoLogin;

export default autoLogin;