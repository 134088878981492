/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order view component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import StatusHistory from "./statusHistory";
import ShippingAddress from "./shippingAddress";
import BillingAddress from "./billingAddress";
import OrderItems from "./orderItems";
import OrderField from "./orderField";
import Controls from "./controls";
import getClassNames from "../../../universalTable/TheTable/utils/getClassNames";

class OrderView extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    orderFields(order) {
        let data = [],
            id = 0,
            value = "";

        if (this.props.tableSettings.hasOwnProperty("header")) {
            for (let header of this.props.tableSettings.header) {
                if (header.visible) {
                    value = typeof order[header.key] === "undefined" || typeof order[header.key] === "object" ? "" : order[header.key];
                    id++;

                    data.push({
                        id: id,
                        field: header.key,
                        label: header.name,
                        value: value,
                        classNames: getClassNames(header)
                    });
                }
            }

            return data;
        }

        for (let field in order) {
            if (order.hasOwnProperty(field)) {
                value = order[field];
                if (typeof value === "object" && value !== null) {
                    continue;
                }

                id++;
                data.push({
                    id: id,
                    field: field,
                    label: field,
                    value: value,
                    classNames: ""
                });
            }
        }

        return data;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            order,
            tableSettings,
            cancelOrder,
            saveOrder,
            print
        } = this.props;

        return (
            <Paper square={true} elevation={1} style={{padding: "4px"}} className={"common-order-info"}>
                <div style={{background: "#00000012", borderRadius: "4px", padding: "4px", margin: "8px 4px 4px 4px"}}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column"}}>
                        <Typography fontSize={"15px"}><b>{order.increment_id}</b></Typography>
                    </div>
                </div>

                <Grid container spacing={2} style={{textAlign:"left"}}>
                    <Grid item xs={12} md={12}>
                            {this.orderFields(order).map(fieldDef => (
                                (fieldDef.field === "row_controls" && (
                                    <Controls
                                        order={order}
                                        settings={tableSettings.general}
                                        key={"of" + fieldDef.id}
                                        cancelOrder={cancelOrder}
                                        saveOrder={saveOrder}
                                        print={print}
                                    />
                                )) ||
                                (fieldDef.field === "items" && (<OrderItems order={order} key={"of" + fieldDef.id}/>)) ||
                                (fieldDef.field === "status_history" && (<StatusHistory order={order} key={"of" + fieldDef.id}/>)) ||
                                (fieldDef.field === "shipping_address" && (<ShippingAddress order={order} key={"of" + fieldDef.id}/>)) ||
                                (fieldDef.field === "billing_address" && (<BillingAddress order={order} key={"of" + fieldDef.id}/>)) ||
                                (<OrderField
                                    key={"of" + fieldDef.id}
                                    fieldDef={fieldDef}
                                    general={tableSettings.general}
                                    order={order}
                                />)
                                )
                            )}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

OrderView.propTypes = {
    order: PropTypes.any,
    tableSettings: PropTypes.object,
    cancelOrder: PropTypes.func,
    saveOrder: PropTypes.func,
    print: PropTypes.func
};

OrderView.defaultProps = {
    order: {},
    tableSettings: {}
};

export default OrderView;