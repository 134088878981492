/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit status dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../dialog";
import Grid from "@material-ui/core/Grid";

class EditDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
        } = this.props;

        return (
            <Dialog
                title={edit ? "Edit status" : "Add new status"}
                open={open}
                onOk={onOk}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
                okText="Save"
                maxWidth="xs"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="key_input"
                                color="secondary"
                            >
                                Code
                            </InputLabel>
                            <Input
                                id="key_input"
                                type={"string"}
                                value={data.key === null || data.key === undefined ? "" : data.key}
                                name="key"
                                color="secondary"
                                autoFocus={true}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="label_input"
                                color="secondary"
                            >
                                Name
                            </InputLabel>
                            <Input
                                id="label_input"
                                type={"string"}
                                value={data.label === null || data.label === undefined ? "" : data.label}
                                name="label"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

EditDialog.propTypes = {
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
};

EditDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditDialog;