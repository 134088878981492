/**
 * Copyright ©2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Location component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";


/**
 * Import other used components
 */

import PropTypes from "prop-types";
import ViewportStore from "../../../../stores/ViewportStore";
import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";

class Location extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.keys = "";
        this.key_time = 0;

        this.state = {
            zebra: ViewportStore.get(),
            edit_modal_open: false,
            new_location: "",
            damaged_edit_mode: false,
            close_callback: false
        };

        this.showEditModal = this.showEditModal.bind(this);
        this.hideEditModal = this.hideEditModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.checkModalAutoFocus = this.checkModalAutoFocus.bind(this);
        this.getLocationText = this.getLocationText.bind(this);
        this.onKeyDetected = this.onKeyDetected.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
        window.addEventListener("keypress", this.onKeyDetected);
    }

    /**
     * Set things when component is unmounting
     */
    componentWillUnmount() {
        window.removeEventListener("keypress", this.onKeyDetected);
    }

    showEditModal() {
        this.setState({edit_modal_open: true});
        this.checkModalAutoFocus();
    }

    hideEditModal() {
        this.setState({edit_modal_open: false});
        if (this.state.close_callback) {
            this.state.close_callback();
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state;
        item[e.target.name] = e.target.value;
        this.forceUpdate();
    }

    /**
     * Save changes to component state
     * @param e
     */
    onKeyUp(e) {
        if (e.keyCode === 13) {
            this.props.onAppend(this.state.new_location);
            this.setState({new_location: ""});
            this.hideEditModal();
        }
    }

    onKeyDetected(e) {
        const limit = 100;

        if (this.state.edit_modal_open) {
            console.log("LocationBarcodeReader", {disabled: true, activeElement: document.activeElement});
            return;
        }
        if ((e.keyCode >= 32 && e.keyCode <= 255) || e.keyCode === 13) {
            let charCode = e.which || e.keyCode,
                key = String.fromCharCode(charCode),
                time = Date.now();

            if (e.keyCode === 13) {
                key = "enter";
            }

            if (time - this.key_time > limit) {
                this.keys = "";
            } else {
                e.preventDefault();
            }

            if (key === "enter") {
                if (this.keys.length > 0) {
                    this.props.onAppend(this.keys);
                    this.keys = "";
                }
            } else {
                this.keys += key;
                console.log("LocationBarcodeReader", {keys: this.keys, activeElement: document.activeElement});
            }
            this.key_time = time;
        }
    }

    checkModalAutoFocus() {
        setTimeout(() => {
            let textField = document.querySelector("input[name=new_location]");
            if (textField) {
                textField.focus();
            }
        }, 500);
    }

    getLocationText() {
        let loc = this.props.currentLocation ? this.props.currentLocation : "---";
        let otherLoc = [];

        if (this.props.location.length > 0) {
            for (let l of this.props.location) {
                if (l !== this.props.currentLocation) {
                    otherLoc.push(l);
                }
            }

            if (otherLoc.length > 0) {
                loc += " / " + otherLoc.join(", ");
            }
        }

        return loc;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            location,
            onDelete
        } = this.props;

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80px",
                        borderBottom: "1px dashed silver",
                        background: "white"
                    }}
                    onClick={this.showEditModal}
                >
                    Lokáció: {this.getLocationText()}
                </div>

                <Dialog
                    title='Lokáció'
                    open={this.state.edit_modal_open}
                    onClose={this.hideEditModal}
                    onOk={this.hideEditModal}
                    okText='Close'
                >
                    <DialogContentText>
                        {location.map(function (value, index) {
                            return (
                                <React.Fragment key={index}>
                                    <span style={{fontWeight: "bold", width: "100px"}}>{value}</span>
                                    <IconButton
                                        key={"delete_icon_btn"}
                                        color={"primary"}
                                        onClick={() => { onDelete(index); }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                    <br/>
                                </React.Fragment>
                            );
                        })}

                        <div style={{marginTop: "20px", borderTop: "1px dotted silver", padding: "10px"}}>
                            <TextField
                                label="New location"
                                name={"new_location"}
                                color="secondary"
                                fullWidth={true}
                                value={this.state.new_location}
                                onKeyUp={(event) => {this.onKeyUp(event);}}
                                onChange={(event) => {this.onChange(event);}}
                            />
                        </div>
                    </DialogContentText>
                </Dialog>
            </React.Fragment>
        );
    }
}

Location.propTypes = {
    location: PropTypes.any,
    currentLocation: PropTypes.string,
    classes: PropTypes.any,
    onDelete: PropTypes.func,
    onAppend: PropTypes.func
};

Location.defaultProps = {
    location: [],
    currentLocation: "",
    classes: {},
    onDelete: () => {},
    onAppend: () => {},
};

export default Location;