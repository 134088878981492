/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_9 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 92046-74620 166667-166667 166667-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-52229 203538l36933-4601c964 5098 2611 8736 4912 10819 2331 2114 5161 3171 8487 3171 5969 0 10601-2985 13959-8985 2425-4446 4259-13803 5471-28104-4446 4570-8985 7896-13679 10010-4663 2145-10073 3202-16228 3202-11969 0-22073-4259-30311-12746-6869-7102-10862-15759-12001-25972v44038-56417c747-6513 2722-12569 5939-18169 4197-7275 9979-12808 17316-16539s16570-5596 27700-5596c13368 0 24063 2301 32146 6870 8083 4601 14550 11876 19368 21856 4819 9980 7244 23161 7244 39576 0 24094-5067 41721-15233 52944-10135 11192-24187 16788-42187 16788-10632 0-18995-1212-25151-3668-6124-2456-11223-6063-15296-10757-4072-4726-7213-10632-9389-17720zm-2457-53206c-227-2037-341-4136-341-6298 0-2069 114-4096 341-6081m70852 5987c0-7244-1834-12902-5472-17006-3637-4104-8083-6156-13306-6156-4943 0-9016 1865-12280 5565-3265 3700-4881 9265-4881 16694 0 7461 1679 13182 5068 17130 3357 3948 7585 5938 12622 5938 5223 0 9576-1927 13057-5751 3451-3824 5192-9296 5192-16415zm90462-83902c-27288-27288-64987-44166-106628-44166S87327 32750 60039 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166c83283 0 150794-67511 150794-150794 0-41641-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_9;