/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Communication form component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "../../../common/switch/OnOffSwitch";
import Checkbox from "@material-ui/core/Checkbox";
import Grow from "@material-ui/core/Grow";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ConfigDialog from "../../../common/settings/config";
import Button from "@material-ui/core/Button";


class CommunicationForm extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
            is_edit: false,
            configuration_dialog_open: false,
        };

        this.configurationDialogOpen = this.configurationDialogOpen.bind(this);
        this.configurationDialogClose = this.configurationDialogClose.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Show/Hide password
     */
    handleClickShowPassword(){
        this.setState({"showPassword": !this.state.showPassword});
    }

    configurationDialogOpen() {
        this.setState({configuration_dialog_open: true});
    }

    configurationDialogClose() {
        this.setState({configuration_dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            onChange,
            edit,
            data,
            onConfigurationChanged
        } = this.props;

        return (
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth={true}
                    >
                        <InputLabel
                            id={"communication_type_input"}
                            color="secondary"
                        >Communication type</InputLabel>
                        <Select
                            labelId={"communication_type_input"}
                            id={"communication_type_input"}
                            name="communication_type"
                            style={{minWidth: "150px", textAlign: "left"}}
                            value={data.communication_type === null || data.communication_type === undefined ? "" : data.communication_type}
                            color="secondary"
                            onChange={onChange}
                        >
                            <MenuItem value="file">File</MenuItem>
                            <MenuItem value="ftp">FTP</MenuItem>
                            <MenuItem value="sftp">SFTP</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth={true}
                        onClick={() => {this.configurationDialogOpen();}}
                    >
                        Show configuration
                    </Button>
                </Grid>

                <Grow in={data.communication_type !== "file"} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={8}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="remote_server_input"
                                color="secondary"
                            >
                                Remote server url
                            </InputLabel>
                            <Input
                                id="remote_server_input"
                                type={"string"}
                                value={data.remote_server === null || data.remote_server === undefined ? "" : data.remote_server}
                                name="remote_server"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                </Grow>
                <Grow in={data.communication_type !== "file"} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", textAlign: "left"}}
                            control={
                                <Switch name="use_ssl" checked={data.use_ssl === "0" ? false : true} onChange={onChange} value={data.use_ssl} />
                            }
                            label="Use SSL"
                        />
                    </Grid>
                </Grow>
                <Grow in={data.communication_type !== "file" && edit} mountOnEnter unmountOnExit>
                    <Grid item xs={12}>
                        <FormControlLabel
                            style={{marginBottom: "-15px"}}
                            control={
                                <Checkbox
                                    checked={data.change_password}
                                    onChange={onChange}
                                    name="change_password"
                                    value={data.change_password.toString()}
                                    color="primary"
                                />
                            }
                            label="Change user/password"
                        />
                    </Grid>
                </Grow>
                <Grow in={data.communication_type !== "file" && (edit && data.change_password || !edit)} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="user_input"
                                color="secondary"
                            >
                                User
                            </InputLabel>
                            <Input
                                id="user_input"
                                type={"string"}
                                value={data.user === null || data.user === undefined ? "" : data.user}
                                name="user"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                </Grow>
                <Grow in={data.communication_type !== "file" && (edit && data.change_password || !edit)} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="new_password"
                                color="secondary"
                            >
                                {edit ? "Change password" : "Password"}
                            </InputLabel>
                            <Input
                                id="new_password"
                                type={this.state.showPassword ? "text" : "password"}
                                value={data.new_password}
                                name="new_password"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            margin="dense"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword.bind(this)}
                                        >
                                            {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grow>
                <ConfigDialog
                    classes={classes}
                    open={this.state.configuration_dialog_open}
                    edit={this.state.is_edit}
                    data={data.config}
                    onCancel={this.configurationDialogClose}
                    onClose={this.configurationDialogClose}
                    onConfigurationChanged={onConfigurationChanged}
                />
            </React.Fragment>
        );
    }
}

CommunicationForm.propTypes = {
    classes: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onConfigurationChanged: PropTypes.any,
};

CommunicationForm.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default CommunicationForm;