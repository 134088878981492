/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Index component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import React from "react";
import ReactDOM from "react-dom";

/**
 * Import used styles
 */
import "normalize.css";
import "./styles/app.scss";

/**
 * Import used utils
 */
import { eventListenerOptionsSupported } from "./utils";

/**
 * Set the touch and click events
 */
const defaultOptions = {
    passive: true,
    capture: false
};

const supportedPassiveTypes = [
    "scroll", "wheel",
    "touchstart", "touchmove", "touchenter", "touchend", "touchleave",
    "mouseout", "mouseleave", "mouseup", "mousedown", "mousemove", "mouseenter", "mousewheel", "mouseover"
];

/**
 * Get the default passive event option
 * @param passive
 * @param eventName
 * @returns {boolean|*}
 */
const getDefaultPassiveOption = (passive, eventName) => {
    if (passive !== undefined) return passive;

    return supportedPassiveTypes.indexOf(eventName) === -1 ? false : defaultOptions.passive;
};

/**
 * Get the writable event options
 * @param options
 * @returns {*}
 */
const getWritableOptions = (options) => {
    const passiveDescriptor = Object.getOwnPropertyDescriptor(options, "passive");

    return passiveDescriptor && passiveDescriptor.writable !== true && passiveDescriptor.set === undefined
        ? Object.assign({}, options)
        : options;
};

/**
 * Overwrite add event functionality
 * @param superMethod
 */
const overwriteAddEvent = (superMethod) => {
    EventTarget.prototype.addEventListener = function (type, listener, options) {
        const usesListenerOptions = typeof options === "object" && options !== null;
        const useCapture          = usesListenerOptions ? options.capture : options;

        options         = usesListenerOptions ? getWritableOptions(options) : {};
        options.passive = getDefaultPassiveOption(options.passive, type);
        options.capture = useCapture === undefined ? defaultOptions.capture : useCapture;

        superMethod.call(this, type, listener, options);
    };

    EventTarget.prototype.addEventListener._original = superMethod;
};

const supportsPassive = eventListenerOptionsSupported();

if (supportsPassive) {
    const addEvent = EventTarget.prototype.addEventListener;
    overwriteAddEvent(addEvent);
}

/**
 * Import the App component
 */
import App from "./components/App";

/**
 * Render the app dom elements
 */
const startApp = () => {
    // document.addEventListener("touchmove", function (e) { e.preventDefault(); }, false);
    ReactDOM.render(
        <App />
        , document.getElementById("app"));
};

/**
 * Start the app if all content loaded
 */
document.addEventListener("DOMContentLoaded", function(){
    startApp();
}, false);
