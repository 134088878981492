/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import StatusList from "../../../common/settings/statusList";
import ConfigDialog from "../../../common/settings/config";
import CommunicationForm from "../../../common/settings/communicationForm";
import OrderStatusStore from "../../../../stores/OrderStatusStore";


class EditDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            order_status: OrderStatusStore.getAll(),
            dialog_open: false,
            is_edit: false,
            configuration_dialog_open: false,
            is_configuration_edit: false,
            actual_edit: {},
        };
        this.dialogOpen = this.dialogOpen.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.configurationDialogOpen = this.configurationDialogOpen.bind(this);
        this.configurationDialogClose = this.configurationDialogClose.bind(this);
        this.orderStatusUpdate = this.orderStatusUpdate.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        OrderStatusStore.addChangeListener("change", this.orderStatusUpdate);
    }

    componentWillUnmount() {
        OrderStatusStore.removeChangeListener("change", this.orderStatusUpdate);
    }

    orderStatusUpdate() {
        this.setState({order_status: OrderStatusStore.getAll()});
    }

    dialogOpen() {
        this.setState({dialog_open: true});
    }

    dialogClose() {
        this.setState({dialog_open: false});
    }

    configurationDialogOpen() {
        this.setState({configuration_dialog_open: true});
    }

    configurationDialogClose() {
        this.setState({configuration_dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
            onStatusChanged,
            onConfigurationChanged
        } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    title={edit ? "Edit carrier" : "Add new carrier"}
                    open={open}
                    onOk={onOk}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Cancel"
                    okText="Save"
                    maxWidth="md"
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                                style={{textAlign: "left"}}
                            >
                                Default data
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="name_input"
                                    color="secondary"
                                >
                                    Name
                                </InputLabel>
                                <Input
                                    id="name_input"
                                    type={"string"}
                                    value={data.name === null || data.name === undefined ? "" : data.name}
                                    name="name"
                                    color="secondary"
                                    autoFocus={true}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="full_name_input"
                                    color="secondary"
                                >
                                    Full name
                                </InputLabel>
                                <Input
                                    id="full_name_input"
                                    type={"string"}
                                    value={data.full_name === null || data.full_name === undefined ? "" : data.full_name}
                                    name="full_name"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="code_input"
                                    color="secondary"
                                >
                                    Code
                                </InputLabel>
                                <Input
                                    id="code_input"
                                    type={"string"}
                                    value={data.code === null || data.code === undefined ? "" : data.code}
                                    name="code"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="address_info_input"
                                    color="secondary"
                                >
                                    Address info
                                </InputLabel>
                                <Input
                                    id="address_info_input"
                                    type={"string"}
                                    value={data.address_info === null || data.address_info === undefined ? "" : data.address_info}
                                    name="address_info"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                                style={{textAlign: "left"}}
                            >
                                Communication
                            </Typography>
                            <Divider />
                        </Grid>
                        <CommunicationForm
                            classes={classes}
                            onChange={onChange}
                            data={data}
                            edit={edit}
                            onConfigurationChanged={onConfigurationChanged}
                        />
                        <Grid item xs={12}>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                display="block"
                                variant="caption"
                                style={{textAlign: "left"}}
                            >
                                Paths and files
                            </Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="incoming_path_input"
                                    color="secondary"
                                >
                                    Incoming path
                                </InputLabel>
                                <Input
                                    id="incoming_path_input"
                                    type={"string"}
                                    value={data.incoming_path === null || data.incoming_path === undefined ? "" : data.incoming_path}
                                    name="incoming_path"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="incoming_path_processed_input"
                                    color="secondary"
                                >
                                    Incoming path processed
                                </InputLabel>
                                <Input
                                    id="incoming_path_processed_input"
                                    type={"string"}
                                    value={data.incoming_path_processed === null || data.incoming_path_processed === undefined ? "" : data.incoming_path_processed}
                                    name="incoming_path_processed"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="outgoing_path_input"
                                    color="secondary"
                                >
                                    Outgoing path
                                </InputLabel>
                                <Input
                                    id="outgoing_path_input"
                                    type={"string"}
                                    value={data.outgoing_path === null || data.outgoing_path === undefined ? "" : data.outgoing_path}
                                    name="outgoing_path"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="outgoing_path_processed_input"
                                    color="secondary"
                                >
                                    Outgoing path processed
                                </InputLabel>
                                <Input
                                    id="outgoing_path_processed_input"
                                    type={"string"}
                                    value={data.outgoing_path_processed === null || data.outgoing_path_processed === undefined ? "" : data.outgoing_path_processed}
                                    name="outgoing_path_processed"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="file_name_input"
                                    color="secondary"
                                >
                                    File name
                                </InputLabel>
                                <Input
                                    id="file_name_input"
                                    type={"string"}
                                    value={data.file_name === null || data.file_name === undefined ? "" : data.file_name}
                                    name="file_name"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    id={"file_type_input"}
                                    color="secondary"
                                >File type</InputLabel>
                                <Select
                                    labelId={"file_type_input"}
                                    id={"file_type_input"}
                                    name="file_type"
                                    style={{textAlign: "left"}}
                                    value={data.file_type === null || data.file_type === undefined ? "" : data.file_type}
                                    color="secondary"
                                    onChange={onChange}
                                >
                                    <MenuItem value="csv">CSV</MenuItem>
                                    <MenuItem value="edi">EDI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="partner_id_input"
                                    color="secondary"
                                >
                                    Partner id
                                </InputLabel>
                                <Input
                                    id="partner_id_input"
                                    type={"string"}
                                    value={data.partner_id === null || data.partner_id === undefined ? "" : data.partner_id}
                                    name="partner_id"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    id={"complete_status_input"}
                                    color="secondary"
                                >Complete status</InputLabel>
                                <Select
                                    labelId={"complete_status_input"}
                                    id={"complete_status_input"}
                                    name="complete_status"
                                    style={{minWidth: "150px", textAlign: "left"}}
                                    value={data.complete_status === null || data.complete_status === undefined ? "" : data.complete_status}
                                    color="secondary"
                                    onChange={onChange}
                                >
                                    {Object.keys(this.state.order_status).map((key, i) => (
                                        <MenuItem key={i} value={this.state.order_status[key].status_id}>{this.state.order_status[key].label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.dialogOpen();}}
                            >
                                Show status list
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    id={"status_list_selected_input"}
                                    color="secondary"
                                >Selected status</InputLabel>
                                <Select
                                    labelId={"status_list_selected_input"}
                                    id={"status_list_selected_input"}
                                    name="status_list_selected"
                                    style={{minWidth: "150px", textAlign: "left"}}
                                    value={data.status_list_selected === null || data.status_list_selected === undefined ? "" : data.status_list_selected}
                                    color="secondary"
                                    onChange={onChange}
                                >
                                    {data.status_list !== undefined && (Object.keys(data.status_list).map((key, i) => (
                                        <MenuItem key={i} value={key}>{data.status_list[key]}</MenuItem>
                                    )))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Dialog>
                <StatusList
                    classes={classes}
                    open={this.state.dialog_open}
                    edit={this.state.is_edit}
                    data={data.status_list}
                    onCancel={this.dialogClose}
                    onClose={this.dialogClose}
                    onStatusChanged={onStatusChanged}
                />
                <ConfigDialog
                    classes={classes}
                    open={this.state.configuration_dialog_open}
                    edit={this.state.is_edit}
                    data={data.suppliers}
                    onCancel={this.configurationDialogClose}
                    onClose={this.configurationDialogClose}
                    onConfigurationChanged={onConfigurationChanged}
                />
            </React.Fragment>
        );
    }
}

EditDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onStatusChanged: PropTypes.any,
    onConfigurationChanged: PropTypes.any,
};

EditDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {},
};

export default EditDialog;