/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * HideOnScroll component
 * ---------------
 */

/**
 * Import used react components
 */
import useScrollTrigger from "@material-ui/core/useScrollTrigger/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import React from "react";
import PropTypes from "prop-types";

function HideOnScroll(props) {
    const { children, window, threshold } = props;
    // Note that you normally won"t need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined, threshold: threshold });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element,
    threshold: PropTypes.any,
    window: PropTypes.func
};

export default HideOnScroll;