/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Viewport store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class viewportStore extends EventEmitter {
    constructor() {
        super();
        const {matches} = window.matchMedia("(max-width: 400px)");
        this.data = !!matches;
        window.addEventListener("resize", () => {
            const {matches} = window.matchMedia("(max-width: 400px)");
            this.set(!!matches);
        });
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the supplier variable
     * @param supplier_id
     * @param variable
     * @returns {*}
     */
    get() {
        return this.data;
    }


    /**
     * Set all suppliers data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const ViewportStore = new viewportStore;

export default ViewportStore;