/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Printer type store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class printerTypeStore extends EventEmitter {
    constructor() {
        super();
        this.data = {
            "0": "Desktop",
            "1": "Label"
        };
    }

    /**
     * Return the order status variable
     * @param status_id
     * @param variable
     * @returns {*}
     */
    get(status_id, variable) {
        if (this.data.hasOwnProperty(status_id) && this.data[status_id].hasOwnProperty(variable)) {
            return this.data[status_id][variable];
        }
        return status_id;
    }

    /**
     * Return all order status
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Set all order status data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const PrinterTypeStore = new printerTypeStore;

export default PrinterTypeStore;