/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */

import {Table} from "@material-ui/core";
import TheTableHead from "./TheTableHead";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TheTableCell from "./TheTableCell";
import TableRow from "@material-ui/core/TableRow";
import FullscreenLoaderService from "../../../../services/FullscreenLoaderService";
import CarrierStore from "../../../../stores/CarrierStore";
import RoleStore from "../../../../stores/RoleStore";
import OrderStatusStore from "../../../../stores/OrderStatusStore";
import PaymentMethodStore from "../../../../stores/PaymentMethodStore";
import ShippingMethodStore from "../../../../stores/ShippingMethodStore";
import SupplierStore from "../../../../stores/SupplierStore";
import PrinterTypeStore from "../../../../stores/PrinterTypeStore";
import WebshopStore from "../../../../stores/WebshopStore";
import PieTimer from "../../pieTimer";
import OrderInfoDialog from "../../orderInfoDialog";
import loaderService from "../../../../services/Loader";

class TheTable extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.piePosition= {x: 0, y: 0};
        this.pieId = 0;

        this.state = {
            order_info_dialog: false,
            order_info: false,
            order_info_status: false,
            buttonPressPie: false,
            carrier: CarrierStore.getAllById(),
            role: RoleStore.getAll(),
            order_status: OrderStatusStore.getAll(),
            payment_method: PaymentMethodStore.getAll(),
            shipping_method: ShippingMethodStore.getAll(),
            supplier: SupplierStore.getAll(),
            printer_type: PrinterTypeStore.getAll(),
            webshop: WebshopStore.getAll(),
            disableClick: false
        };

        this.carrierUpdate = this.carrierUpdate.bind(this);
        this.roleUpdate = this.roleUpdate.bind(this);
        this.orderStatusUpdate = this.orderStatusUpdate.bind(this);
        this.paymentMethodStatusUpdate = this.paymentMethodStatusUpdate.bind(this);
        this.shippingMethodStatusUpdate = this.shippingMethodStatusUpdate.bind(this);
        this.supplierStatusUpdate = this.supplierStatusUpdate.bind(this);
        this.webshopStatusUpdate = this.webshopStatusUpdate.bind(this);

        this.handleButtonPress = this.handleButtonPress.bind(this);
        this.handleButtonRelease = this.handleButtonRelease.bind(this);
        this.clearPressEvent = this.clearPressEvent.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.orderInfoStatusListSuccess = this.orderInfoStatusListSuccess.bind(this);
        this.orderInfoSuccess = this.orderInfoSuccess.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        CarrierStore.addChangeListener("change", this.carrierUpdate);
        RoleStore.addChangeListener("change", this.roleUpdate);
        OrderStatusStore.addChangeListener("change", this.orderStatusUpdate);
        PaymentMethodStore.addChangeListener("change", this.paymentMethodStatusUpdate);
        ShippingMethodStore.addChangeListener("change", this.shippingMethodStatusUpdate);
        SupplierStore.addChangeListener("change", this.supplierStatusUpdate);
        WebshopStore.addChangeListener("change", this.webshopStatusUpdate);
        FullscreenLoaderService.hideLoader();
    }

    componentWillUnmount() {
        CarrierStore.removeChangeListener("change", this.carrierUpdate);
        RoleStore.removeChangeListener("change", this.roleUpdate);
        OrderStatusStore.removeChangeListener("change", this.orderStatusUpdate);
        PaymentMethodStore.removeChangeListener("change", this.paymentMethodStatusUpdate);
        ShippingMethodStore.removeChangeListener("change", this.shippingMethodStatusUpdate);
        SupplierStore.removeChangeListener("change", this.supplierStatusUpdate);
        WebshopStore.removeChangeListener("change", this.webshopStatusUpdate);
    }

    absorbEvent_(event) {
        let e = event || window.event;
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        e.cancelBubble = true;
        e.returnValue = false;
        return false;
    }

    setDisableClick(status) {
        this.setState({ 'disableClick': status });
    }

    handleButtonPress (e, row) {
        if (this.props.pageData.layout !== "orders" || this.state.disableClick) {
            return;
        }
        let self = this,
            x = e.clientX,
            y = e.clientY;

        if (x === undefined) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }

        // console.log(row);

        //TODO: absorb browser menu on mobile
        //
        // node.ontouchstart = this.absorbEvent_;
        // node.ontouchmove = this.absorbEvent_;
        // node.ontouchend = this.absorbEvent_;
        // node.ontouchcancel = this.absorbEvent_;

        this.piePosition = {x: x, y: y};
        this.pieId = row.entity_id;
        this.buttonPressTimer = setTimeout(() => self.setButtonPressPie(), 500);
        return false;
    }


    setButtonPressPie() {
        this.setState({buttonPressPie: true});
    }

    handleButtonRelease () {
        clearTimeout(this.buttonPressTimer);
    }

    clearPressEvent() {
        this.setState({buttonPressPie: false});
    }

    carrierUpdate() {
        this.setState({carrier: CarrierStore.getAllById()});
    }

    roleUpdate() {
        this.setState({role: RoleStore.getAll()});
    }

    orderStatusUpdate() {
        this.setState({order_status: OrderStatusStore.getAll()});
    }

    paymentMethodStatusUpdate() {
        this.setState({payment_method: PaymentMethodStore.getAll()});
    }

    shippingMethodStatusUpdate() {
        this.setState({shipping_method: ShippingMethodStore.getAll()});
    }

    supplierStatusUpdate() {
        this.setState({supplier: SupplierStore.getAll()});
    }

    webshopStatusUpdate() {
        this.setState({webshop: WebshopStore.getAll()});
    }

    closeOrderInfo() {
        this.setState({order_info_dialog: false});
    }

    openOrderInfo(reload_all_data) {
        FullscreenLoaderService.showLoader();
        this.setState({buttonPressPie: false});

        loaderService.getData("sales_order_available_status", this.pieId, this.orderInfoStatusListSuccess);
        if (reload_all_data !== undefined) {
            this.props.reload();
        }
    }

    orderInfoStatusListSuccess(res) {
        if (res.hasOwnProperty("0")) {
            this.setState({order_info_status: res["0"]});
        }
        loaderService.getData("sales_order", this.pieId, this.orderInfoSuccess);
    }

    orderInfoSuccess(res) {
        FullscreenLoaderService.hideLoader();
        this.setState({order_info: res, order_info_dialog: true});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            visible,
            classes,
            isThemeDark,
            pageData,
            reload,
            header,
            general,
            data,
            onEdit,
            onDelete
        } = this.props;
        // let header = this.state.header;

        return (
            <React.Fragment>
                <PieTimer
                    position={this.piePosition}
                    visible={this.state.buttonPressPie}
                    clearEvent={this.clearPressEvent}
                    onFire={this.openOrderInfo}
                    isThemeDark={isThemeDark}
                />
                {visible &&(
                    <React.Fragment>
                        <Paper square={true} elevation={1}>
                            <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                                <TheTableHead
                                    acl={acl}
                                    classes={classes}
                                    header={header}
                                    general={general}
                                />
                                <TableBody>
                                    {data.map((row, row_index) => (
                                        <TableRow
                                            key={row_index}
                                            selected={false}
                                            className="ut-xxs-row ut-xs-row ut-s-row ut-m-row "
                                            onTouchStart={(e) => {this.handleButtonPress(e, row);}}
                                            onTouchEnd={this.handleButtonRelease}
                                            onMouseDown={(e) => {this.handleButtonPress(e, row);}}
                                            onMouseUp={this.handleButtonRelease}
                                            // onMouseLeave={this.handleButtonRelease}
                                        >
                                            {header.map((settings, settings_index) => (
                                                <React.Fragment key={settings_index}>
                                                    {(settings.key !== "row_controls" || (settings.key === "row_controls" && acl.hasOwnProperty("edit") && acl.edit)) && (
                                                        <TheTableCell
                                                            acl={acl}
                                                            settings={settings}
                                                            setDisableClick={this.setDisableClick.bind(this)}
                                                            stores={{
                                                                carrier: this.state.carrier,
                                                                role: this.state.role,
                                                                order_status: this.state.order_status,
                                                                payment_method: this.state.payment_method,
                                                                shipping_method: this.state.shipping_method,
                                                                supplier: this.state.supplier,
                                                                printer_type: this.state.printer_type,
                                                                webshop: this.state.webshop
                                                            }}
                                                            general={general}
                                                            row={row}
                                                            row_index={row_index}
                                                            item={row[settings.key]}
                                                            pageData={pageData}
                                                            onEdit={onEdit}
                                                            onDelete={onDelete}
                                                            reload={reload}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </React.Fragment>
                )}
                <OrderInfoDialog
                    classes={classes}
                    general={general}
                    open={this.state.order_info_dialog}
                    onClose={this.closeOrderInfo}
                    onCancel={this.closeOrderInfo}
                    order={this.state.order_info}
                    orderStatusList={this.state.order_info_status}
                    stores={{
                        order_status: this.state.order_status,
                    }}
                    reload={this.openOrderInfo}
                />
            </React.Fragment>
        );
    }
}

TheTable.propTypes = {
    acl: PropTypes.any,
    pageData: PropTypes.any,
    onEdit: PropTypes.any,
    onDelete: PropTypes.any,
    header: PropTypes.any,
    general: PropTypes.any,
    data: PropTypes.any,
    classes: PropTypes.any,
    isThemeDark: PropTypes.any,
    disableClick: PropTypes.bool,
    reload: PropTypes.func,
    visible: PropTypes.bool,
};

TheTable.defaultProps = {
    acl: {},
    header: false,
    general: false,
    classes: {},
};

export default TheTable;
