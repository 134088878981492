/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Notification component
 * ---------------
 * - Show/Hide notifications
 */

 /**
  * Import external modules
  */
 import {SnackbarProvider, withSnackbar} from "notistack";

 
/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";


/**
 * Import used services
 */
import notificationService from "../services/Notification";

/**
 * Maximum nr of notifications to display at once
 */
const MAX_STACK_SIZE = 3;

class Notificator extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = notificationService.getState();
    }

    /**
     * Set things when component is mounting
     * Subscribe to NotificationService's change event to fire notistack's enqueue method
     */
    componentDidMount() {
        notificationService.on("change", () => {
            const {message, variant} = notificationService.getState();

            this.props.enqueueSnackbar(message, {
                variant, 
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
            }});

            let _self = this;
            setTimeout(() => {
                _self.props.closeSnackbar();
            }, 3000);
        });
    }


    /**
     * DOM elements are rendered declaratively by notistack
     * @returns {*}
     */
    render() {
        return null;
    }
}


Notificator.propTypes = {
    enqueueSnackbar: PropTypes.func
};

const NotificatorWithSnackbar = withSnackbar(Notificator);

const ProvidedNotificator = () => (
    <SnackbarProvider maxSnack={MAX_STACK_SIZE}>
        <NotificatorWithSnackbar/>
    </SnackbarProvider>
);


export default ProvidedNotificator;