/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0004 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M448.8,190.8c-5.4-16.1-16.7-29.1-31.8-36.7c-6.9-3.4-15.2-0.7-18.6,6.2c-3.4,6.9-0.6,15.2,6.2,18.6
            c8.5,4.3,14.9,11.6,17.9,20.7c3,9.1,2.3,18.8-1.9,27.3c-3.1,6.1-8.3,10.7-14.8,12.9c-19.4-71-94.2-106.6-183.7-106.6
            c-29.8,0-57.9,4.1-83,12c-24.9-11.3-53.7-13.6-80.8-5c-6.7,2.1-9.8,9.9-6.4,16.1l16.7,30.5c-18.7,19.4-30.9,44-34.2,74l-15.7,2.6
            C7.8,265.1,0,274.4,0,285.2v37.9c0,6.3,2.7,12.3,7.4,16.5l24.5,22l0,0c15.1,14.3,34.7,26,56.9,35.2V444c0,6.1,5,11.1,11.1,11.1h57.9
            c5.1,0,9.5-3.5,10.8-8.4l6.9-27.8c15.5,1.9,31.2,2.9,46.5,2.9c16,0,31.5-1.3,46.3-3.5l7.1,28.4c1.2,4.9,5.7,8.4,10.8,8.4h57.9
            c6.1,0,11.1-5,11.1-11.1v-58.1c34.3-24.1,55.5-60.2,55.5-108.4c0-3.5-0.3-6.9-0.6-10.3c1.4-0.3,2.8-0.6,4.2-1
            c13.6-4.5,24.6-14.1,31-26.9C452.9,224.2,454.2,206.9,448.8,190.8z M94.4,266.4c-9.2,0-16.7-7.5-16.7-16.7c0-9.2,7.5-16.7,16.7-16.7
            s16.7,7.5,16.7,16.7C111,259,103.6,266.4,94.4,266.4z M296.6,191.2c-1.9,5.9-7.3,9.6-13.2,9.6c-1.4,0-2.9-0.2-4.3-0.7
            c-20.4-6.6-44.6-9.1-70.1-7.2c-11.7,0.9-23.1,2.6-33.8,5.2c-7.5,1.8-14.9-2.8-16.7-10.2c-1.8-7.5,2.8-14.9,10.2-16.7
            c12.1-2.9,25-4.9,38.2-5.9c29.5-2.2,56.6,0.6,80.8,8.5C295,176.1,298.9,183.9,296.6,191.2z M277.5,50c0,27.6-22.4,50-50,50
            c-27.6,0-50-22.4-50-50c0-27.6,22.4-50,50-50C255.2,0,277.5,22.4,277.5,50z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0004;