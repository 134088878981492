/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order not editable dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Dialog from "../../../dialog";
import OrderStatusStore from "../../../../../stores/OrderStatusStore";

class OrderNotEditableDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onOk,
            message,
            status,
        } = this.props;
        let statusLabel = "";
        if (status) {
            statusLabel = OrderStatusStore.get(status, "name");
        }
        return (
            <Dialog
                title="The order is not editable"
                open={open}
                onClose={onClose}
                onOk={onOk}
                primaryAnimation="button-sonar"
                okText="I understand"
                type="error"
            >
                <DialogContentText>
                    <NotInterestedIcon
                        className="blink"
                        style={{fontSize: "60px"}}
                    />
                </DialogContentText>
                <DialogContentText>
                    {message}
                </DialogContentText>
                {status && (
                    <DialogContentText style={{fontSize: "calc(100% / " + Math.max(20, statusLabel.length) + " * 40)", fontWeight: "bold", color: "#fff"}}>
                        {statusLabel}
                    </DialogContentText>
                )}
            </Dialog>
        );
    }
}

OrderNotEditableDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onOk: PropTypes.any,
    message: PropTypes.any,
    status: PropTypes.any,
};

OrderNotEditableDialog.defaultProps = {
    open: false,
    message: "",
    status: ""
};

export default OrderNotEditableDialog;