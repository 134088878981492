/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Replacement component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import UniversalTable from "../../universalTable";
import EditDialog from "./editDialog";

class Replacement extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            dialog_open: false,
            actual_edit: {},
            need_reload: false
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.reloaded = this.reloaded.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Clone object
     * @param src
     * @returns {any}
     */
    cloneObject(src) {
        return Object.assign({}, src);
    }

    /**
     * Edit item
     * @param item
     * @param index
     */
    onEdit(item, index) {
        let data = this.cloneObject(item);
        delete data._links;

        try {
            data.items = JSON.parse(data.items);
        } catch (e) {
            console.log(e);
        }

        this.setState({actual_edit: data, actual_index: index, dialog_open: true});
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.actual_edit,
            target = e.target.name,
            value = e.target.value;

        item[target] = value;
        this.forceUpdate();
    }

    /**
     * Close the edit dialog
     */
    onCancel() {
        this.setState({
            actual_edit: {},
            actual_index: 0,
            dialog_open: false,
            need_reload: false
        });
    }

    /**
     * Save menu data
     */
    onSave() {
        let data = this.cloneObject(this.state.actual_edit);
        delete data.id;
        LoaderService.patchData("replacement/" + this.state.actual_edit.id, data, this.saveSuccess);
    }

    /**
     * Save success
     */
    saveSuccess() {
        this.setState({need_reload: true});
        this.onCancel();
    }

    /**
     * Switch the need reload flag back
     */
    reloaded() {
        this.setState({need_reload:false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            classes,
            pageData
        } = this.props;
        return (
            <React.Fragment>
                <div className={classes.tableRoot}>
                    <UniversalTable
                        needReload={this.state.need_reload}
                        acl={acl}
                        pageData={pageData}
                        url={"replacement?menu_id=" + pageData.menu_id}
                        data_key="replacement"
                        row_key="id"
                        canEdit={false}
                        onAdd={this.onEdit}
                        onEdit={this.onEdit}
                        onReloaded={this.reloaded}
                    />
                </div>
                <EditDialog
                    open={this.state.dialog_open}
                    edit={true}
                    data={this.state.actual_edit}
                    onOk={this.onSave}
                    onCancel={this.onCancel}
                    onClose={this.onCancel}
                    onChange={this.onChange}
                />
            </React.Fragment>
        );
    }
}

Replacement.propTypes = {
    acl: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    pageData: PropTypes.any
};

Replacement.defaultProps = {
    url: false,
    classes: {}
};

export default Replacement;