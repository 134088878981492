/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Menu Visibility dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../../dialog";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Switch from "../../switch/OnOffSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoaderService from "../../../../services/Loader";

class MenuVisibilityDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };

        this.checkPermission = this.checkPermission.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataSuccess = this.getDataSuccess.bind(this);
        this.checkIsGroup = this.checkIsGroup.bind(this);
        this.checkGlobalEnabled = this.checkGlobalEnabled.bind(this);
        this.checkDisabled = this.checkDisabled.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.getData();
    }

    checkIsGroup(item) {
        return item.resource === "menu_group";
    }

    getData() {
        LoaderService.getData("menu_tree?is_admin=1", "", this.getDataSuccess);
    }

    getDataSuccess(result) {
        if(result.hasOwnProperty("_embedded")) {
            let self = this,
                data = [];

            result._embedded.forEach(function (main) {
                let items = [];
                main.depth = 0;
                main.is_group = self.checkIsGroup(main);

                if (main.hasOwnProperty("items")){
                    items = JSON.parse(JSON.stringify(main.items));
                    delete main.items;
                }

                data.push(main);

                if (items.length) {
                    items.forEach(function (item) {
                        item.depth = 1;
                        item.is_group = self.checkIsGroup(item);
                        data.push(item);
                    });
                }
            });
            this.setState({data: data});
        }
    }

    checkDisabled(type, menu_id) {
        if (menu_id === "all") {
            switch (type) {
            case "view":
                return false;

            case "edit":
                if (!this.props.data.hasOwnProperty("all")  ||
                    (this.props.data.hasOwnProperty("all") &&
                        !this.props.data.all.hasOwnProperty("view") ||
                        (this.props.data.all.hasOwnProperty("view") && !this.props.data.all.view)
                    )
                ) {
                    return true;
                }
                return false;

            case "delete":
                if (!this.props.data.hasOwnProperty("all")  ||
                    (this.props.data.hasOwnProperty("all") &&
                        !this.props.data.all.hasOwnProperty("view") ||
                        (this.props.data.all.hasOwnProperty("view") && !this.props.data.all.view) ||
                        !this.props.data.all.hasOwnProperty("edit") ||
                        (this.props.data.all.hasOwnProperty("edit") && !this.props.data.all.edit)
                    )
                ) {
                    return true;
                }
                return false;
            }
        }

        switch (type) {
        case "view":
            return false;
        case "edit":
            if (!this.props.data.hasOwnProperty(menu_id)) {
                return true;
            }
            if (
                !this.props.data[menu_id].hasOwnProperty("view") ||
                (this.props.data[menu_id].hasOwnProperty("view") && !this.props.data[menu_id].view)
            ){
                return true;
            }
            return false;
        case "delete":
            if (!this.props.data.hasOwnProperty(menu_id)) {
                return true;
            }
            if (
                !this.props.data[menu_id].hasOwnProperty("view") ||
                (this.props.data[menu_id].hasOwnProperty("view") && !this.props.data[menu_id].view) ||
                !this.props.data[menu_id].hasOwnProperty("edit") ||
                (this.props.data[menu_id].hasOwnProperty("edit") && !this.props.data[menu_id].edit)
            ){
                return true;
            }
            return false;
        }
    }

    checkGlobalEnabled(type) {
        if ((!this.props.data.hasOwnProperty("all"))) {
            return false;
        }

        switch (type) {
        case "view":
            if (
                !this.props.data.all.hasOwnProperty("view")
            ) {
                return false;
            }
            return this.props.data.all.view;

        case "edit":
            if (
                !this.props.data.all.hasOwnProperty("view") ||
                (this.props.data.all.hasOwnProperty("view") && !this.props.data.all.view) ||
                !this.props.data.all.hasOwnProperty("edit")
            ) {
                return false;
            }
            return this.props.data.all.edit;

        case "delete":
            if (
                !this.props.data.all.hasOwnProperty("view") ||
                (this.props.data.all.hasOwnProperty("view") && !this.props.data.all.view) ||
                !this.props.data.all.hasOwnProperty("edit") ||
                (this.props.data.all.hasOwnProperty("edit") && !this.props.data.all.edit) ||
                !this.props.data.all.hasOwnProperty("delete")
            ) {
                return false;
            }
            return this.props.data.all.delete;
        }
    }

    checkPermission(type, menu_id) {
        if (this.checkGlobalEnabled(type)) {
            return true;
        }

        if (!this.props.data.hasOwnProperty(menu_id)) {
            return false;
        }
        switch (type) {
        case "view":
            if ( !this.props.data[menu_id].hasOwnProperty("view")){
                return false;
            }
            return this.props.data[menu_id][type];
        case "edit":
            if (
                !this.props.data[menu_id].hasOwnProperty("view") ||
                (this.props.data[menu_id].hasOwnProperty("view") && !this.props.data[menu_id].view) ||
                !this.props.data[menu_id].hasOwnProperty("edit")
            ){
                return false;
            }
            return this.props.data[menu_id][type];
        case "delete":
            if (
                !this.props.data[menu_id].hasOwnProperty("view") ||
                (this.props.data[menu_id].hasOwnProperty("view") && !this.props.data[menu_id].view) ||
                !this.props.data[menu_id].hasOwnProperty("edit") ||
                (this.props.data[menu_id].hasOwnProperty("edit") && !this.props.data[menu_id].edit) ||
                !this.props.data[menu_id].hasOwnProperty("delete")
            ){
                return false;
            }
            return this.props.data[menu_id][type];
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onCancel,
            onChange
        } = this.props;

        return (
            <Dialog
                title={"Edit menu visibility"}
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Close"
                okText="Save"
                maxWidth="md"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={true}>
                            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                <TableRow selected={true}>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Menu</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none", textAlign: "center"}}
                                    >
                                        <Typography color="inherit">View</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none", textAlign: "center"}}
                                    >
                                        <Typography color="inherit">Edit</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none", textAlign: "center"}}
                                    >
                                        <Typography color="inherit">Delete</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover selected={false}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                    >
                                        All
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{textAlign: "center"}}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name={"view_all"}
                                                    checked={this.checkPermission("view", "all")}
                                                    onChange={onChange}
                                                    disabled={this.checkDisabled("view", "all")}
                                                    value={this.checkPermission("view", "all")}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{textAlign: "center"}}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name={"edit_all"}
                                                    checked={this.checkPermission("edit", "all")}
                                                    onChange={onChange}
                                                    disabled={this.checkDisabled("edit", "all")}
                                                    value={this.checkPermission("edit", "all")}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{textAlign: "center"}}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name={"delete_all"}
                                                    checked={this.checkPermission("delete", "all")}
                                                    onChange={onChange}
                                                    disabled={this.checkDisabled("delete", "all")}
                                                    value={this.checkPermission("delete", "all")}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                {this.state.data.map((row, index) => (
                                    <TableRow hover key={index} selected={false}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{ paddingLeft: (row.depth+1) * 20 }}
                                        >
                                            {row.title}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{textAlign: "center"}}
                                        >
                                            {!row.is_group && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name={"view_" + row.id}
                                                            checked={this.checkPermission("view", row.id.toString())}
                                                            onChange={onChange}
                                                            disabled={this.checkDisabled("view", row.id.toString())}
                                                            value={this.checkPermission("view", row.id.toString())}
                                                        />
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{textAlign: "center"}}
                                        >
                                            {!row.is_group && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name={"edit_" + row.id}
                                                            checked={this.checkPermission("edit", row.id.toString())}
                                                            onChange={onChange}
                                                            disabled={this.checkDisabled("edit", row.id.toString())}
                                                            value={this.checkPermission("edit", row.id.toString())}
                                                        />
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{textAlign: "center"}}
                                        >
                                            {!row.is_group && (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name={"delete_" + row.id}
                                                            checked={this.checkPermission("delete", row.id.toString())}
                                                            onChange={onChange}
                                                            disabled={this.checkDisabled("delete", row.id.toString())}
                                                            value={this.checkPermission("delete", row.id.toString())}
                                                        />
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

MenuVisibilityDialog.propTypes = {
    classes: PropTypes.any,
    data: PropTypes.any,
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
};

MenuVisibilityDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default MenuVisibilityDialog;