/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order list dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../dialog";
import Print from "@material-ui/icons/Print";

// import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// import SupplierStore from "../../../stores/SupplierStore";
import OrderStatusStore from "../../../stores/OrderStatusStore";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FullscreenLoaderService from "../../../services/FullscreenLoaderService";
import loaderService from "../../../services/Loader";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ChangeAllStatusDialog from "./changeAllStatusDialog";
import {checkDownloadables, downloadFile} from "../utils/downloadFile";
import LoaderService from "../../../services/Loader";
import notificationService from "../../../services/Notification";

class OrderInfoDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            show_dialog: false,
            comment: "",
            status: 0
        };

        this.onChange = this.onChange.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.getStatusText = this.getStatusText.bind(this);
        this.saveCommentSuccess = this.saveCommentSuccess.bind(this);
        this.saveCommentStart = this.saveCommentStart.bind(this);
        this.saveCommentOne = this.saveCommentOne.bind(this);
        this.saveCommentAll = this.saveCommentAll.bind(this);
        this.saveCommentAllSuccess = this.saveCommentAllSuccess.bind(this);
        this.onPrintLabel = this.onPrintLabel.bind(this);
        this.onPrintSuccess = this.onPrintSuccess.bind(this);
        this.onPrintPackingSlip = this.onPrintPackingSlip.bind(this);
        this.onPrintPackingSlipSuccess = this.onPrintPackingSlipSuccess.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.order.hasOwnProperty("status") && nextProps.order.status !== this.props.order.status) {
            this.setState({status: nextProps.order.status});
        }
        return true;
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state,
            target = e.target.name,
            value = e.target.value;

        item[target] = value;
        this.forceUpdate();
    }

    saveComment() {
        let order = this.props.order,
            ignore_merge = OrderStatusStore.getById(this.state.status).ignore_merge === "1";

        if (order.package_id !== "" && order.related_ids.length > 0 && !ignore_merge) {
            this.setState({show_dialog: true});
        }
        else {
            this.saveCommentStart(false);
        }
    }

    saveCommentAll() {
        this.setState({show_dialog: false});
        this.saveCommentStart(true);
    }

    saveCommentOne() {
        this.setState({show_dialog: false});
        this.saveCommentStart(false);
    }

    saveCommentAllSuccess() {
    }

    saveCommentStart(all) {
        if (all) {
            let self = this;
            this.props.order.related_ids.forEach(function (item) {
                loaderService.postData("sales_order_status_history/" + item, {parent_id: item, comment: self.state.comment, status: self.state.status}, self.saveCommentAllSuccess);
            });
        }
        FullscreenLoaderService.showLoader();
        loaderService.postData("sales_order_status_history/" + this.props.order.entity_id, {parent_id: this.props.order.entity_id, comment: this.state.comment, status: this.state.status}, this.saveCommentSuccess);
    }

    saveCommentSuccess() {
        this.setState({ comment: ""});
        FullscreenLoaderService.hideLoader();
        this.props.reload(true);
    }

    getStatusText(item) {
        return this.props.stores.order_status[item] !== undefined ? this.props.stores.order_status[item]["label"] : item;
    }
    
    onPrintLabel() {
        loaderService.postData("sales_order_print_label", {increment_id: this.props.order.increment_id}, this.onPrintSuccess);
    }

    onPrintSuccess(res) {
        if (res.download) {
            checkDownloadables(res);
        }
    }

    onPrintPackingSlip() {
        LoaderService.postData("packing_slip", {
            "order_id": [
                this.props.order.entity_id
            ],
        }, this.onPrintPackingSlipSuccess);
    }

    onPrintPackingSlipSuccess(res) {
        checkDownloadables(res);

        if (res.hasOwnProperty("success") && res.success === true) {
            notificationService.showNotification("success", "Print success");
            this.props.reload();
        }
    }


    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            general,
            open,
            onClose,
            onCancel,
            order,
            orderStatusList
        } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    title={"Order info (" + order.increment_id + ")" }
                    open={open}
                    onOk={order.hasOwnProperty("download")
                        ? () => downloadFile(order.download.content_base64, order.download.filename)
                        : undefined}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Close"
                    okText="Download label"
                    type="order-info-dialog"
                    maxWidth={"xl"}
                    fullWidth={true}
                    secondaryText={"Label"}
                    onSecondaryAction={this.onPrintLabel}
                    secondaryActionIcon={<Print/>}
                    tertiaryText={"Packing Slip"}
                    onTertiaryAction={this.onPrintPackingSlip}
                    tertiaryActionIcon={<Print />}
                >
                    <React.Fragment>
                        {(order !== undefined && order !== null && order !== false) && (orderStatusList !== undefined && orderStatusList !== null && orderStatusList !== false) && (
                            <Grid
                                container
                                spacing={2}
                                style={{textAlign:"left"}}
                            >
                                <Grid item xs={12} md={8}>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid item xs={12} sm={6} style={{textAlign:"left"}}>
                                            <Typography variant="h6" gutterBottom>
                                                {"Billing Address"}
                                            </Typography>
                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_name}
                                                </Typography>
                                            )}
                                            {(!order.hide_address && order.billing_company !== "" && order.billing_company !== null) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_company}
                                                </Typography>
                                            )}
                                            {(!order.hide_address && order.billing_vat_id !== "" && order.billing_vat_id !== null) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_vat_id}
                                                </Typography>
                                            )}
                                            {(!order.hide_address && order.billing_telephone !== "" && order.billing_telephone !== null) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_telephone}
                                                </Typography>
                                            )}
                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_country_id}
                                                </Typography>
                                            )}

                                            {(order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_postcode + " " + order.billing_city}
                                                </Typography>
                                            )}

                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.billing_postcode + " " + order.billing_city + ", " + order.billing_street}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={6} style={{textAlign:"left"}}>
                                            <Typography variant="h6" gutterBottom>
                                                {"Shipping Address"}
                                            </Typography>
                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.shipping_name}
                                                </Typography>
                                            )}
                                            {(!order.hide_address && order.shipping_telephone !== "" && order.shipping_telephone !== null) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.shipping_telephone}
                                                </Typography>
                                            )}
                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.shipping_country_id}
                                                </Typography>
                                            )}

                                            {(!order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.shipping_postcode + " " + order.shipping_city + ", " + order.shipping_street}
                                                </Typography>
                                            )}

                                            {(order.hide_address) && (
                                                <Typography variant="inherit" component="div">
                                                    {order.shipping_postcode + " " + order.shipping_city}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography variant="h6" gutterBottom style={{marginTop: "1.5rem"}}>
                                            {"Order details"}
                                        </Typography>
                                        <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                                            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                                <TableRow selected={true}>
                                                    <TableCell
                                                        align={"left"}
                                                    >
                                                        <Typography color="inherit" variant="caption">Megnevezés</Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        align={"left"}
                                                    >
                                                        <Typography color="inherit" variant="caption">BH cikkszám</Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        align={"right"}
                                                    >
                                                        <Typography color="inherit" variant="caption">Menny. db</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {order.items.map((item, item_index) => (
                                                    <TableRow
                                                        key={"items_" + item_index}
                                                        hover
                                                        selected={false}
                                                        className={"ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                                                    >
                                                        <TableCell
                                                            component="td"
                                                            scope="row"
                                                            className="ut-xxs-col-12 ut-xs-col-12 ut-s-col-12 ut-m-col-12"
                                                        >
                                                            <Typography
                                                                className={"tdBefore"}
                                                                color="textSecondary"
                                                                display="block"
                                                                variant="caption"
                                                                style={{textAlign: "left"}}
                                                            >
                                                                Megnevezés
                                                            </Typography>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell
                                                            component="td"
                                                            scope="row"
                                                            className="ut-xxs-col-6 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left"
                                                        >
                                                            <Typography
                                                                className={"tdBefore"}
                                                                color="textSecondary"
                                                                display="block"
                                                                variant="caption"
                                                                style={{textAlign: "left"}}
                                                            >
                                                                BH cikkszám
                                                            </Typography>
                                                            {item.sku}
                                                        </TableCell>
                                                        <TableCell
                                                            component="td"
                                                            scope="row"
                                                            style={{textAlign: "right"}}
                                                            className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right"
                                                        >
                                                            <Typography
                                                                className={"tdBefore"}
                                                                color="textSecondary"
                                                                display="block"
                                                                variant="caption"
                                                                style={{textAlign: "right"}}
                                                            >
                                                                Mennyiség
                                                            </Typography>
                                                            {Number(item.qty)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{alignItems: "flex-end"}}
                                    >
                                        {(general.hasOwnProperty("edit_comment") && general.edit_comment === true) && (
                                            <Grid item xs={12} sm={6} md={12}>
                                                <TextField
                                                    label="Note (for merchant)"
                                                    name={"comment"}
                                                    color="secondary"
                                                    value={this.state.comment}
                                                    multiline
                                                    rows="4"
                                                    fullWidth={true}
                                                    onChange={(event) => {this.onChange(event);}}
                                                />
                                            </Grid>
                                        )}
                                        {(general.hasOwnProperty("change_status") && general.change_status === true) && (
                                            <Grid item xs={12} sm={6} md={12}>
                                                <FormControl
                                                    fullWidth={true}
                                                >
                                                    <InputLabel
                                                        id={"set_state_label"}
                                                        color="secondary"
                                                    >Set state</InputLabel>
                                                    <Select
                                                        labelId={"set_state_label"}
                                                        id={"set_state_component"}
                                                        name="status"
                                                        style={{minWidth: "150px", textAlign: "left"}}
                                                        value={this.state.status}
                                                        color="secondary"
                                                        onChange={(event) => {this.onChange(event);}}
                                                    >
                                                        <MenuItem key={0} value={0} style={{display: "none"}} />
                                                        {Object.keys(orderStatusList).map((key, index) => (
                                                            <MenuItem key={index + 1} value={key}>{orderStatusList[key]}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                        {((general.hasOwnProperty("change_status") && general.change_status === true) || (general.hasOwnProperty("edit_comment") && general.edit_comment === true)) && (
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    fullWidth={false}
                                                    onClick={() => {this.saveComment();}}
                                                    disabled={this.state.comment === "" && this.state.status === this.props.order.status}
                                                >
                                                    {"Save"}
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                {"Status history"}
                                            </Typography>
                                            {order.status_history.map((status_item, status_item_index) => (
                                                <Card
                                                    key={status_item_index}
                                                    className={classes.orderInfoCard}
                                                    variant="outlined"
                                                    style={{margin: "10px 0"}}
                                                >
                                                    <CardContent >
                                                        <Typography variant="inherit" component="div">
                                                            {status_item.created_at}
                                                        </Typography>
                                                        <Typography variant="inherit" component="div">
                                                            {this.getStatusText(status_item.status)}
                                                        </Typography>
                                                        <Typography variant="inherit" component="div" color="textSecondary">
                                                            <span
                                                                dangerouslySetInnerHTML={{ __html: status_item.comment }}>
                                                            </span>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                </Dialog>
                <ChangeAllStatusDialog
                    open={this.state.show_dialog}
                    onOk={this.saveCommentAll}
                    onCancel={this.saveCommentOne}
                />
            </React.Fragment>
        );
    }
}

OrderInfoDialog.propTypes = {
    classes: PropTypes.any,
    general: PropTypes.any,
    stores: PropTypes.any,
    reload: PropTypes.func,
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    order: PropTypes.any,
    orderStatusList: PropTypes.any
};

OrderInfoDialog.defaultProps = {
    open: false
};

export default OrderInfoDialog;