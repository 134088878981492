/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order Status store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class orderStatusStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the order status variable
     * @param status_id
     * @param variable
     * @returns {*}
     */
    get(status_ids, variable) {
        if (!status_ids) return "";
        if (["number", "string"].includes(typeof status_ids)) {
            return this.getLabelById(status_ids);
        }

        return status_ids.filter(id => !!this.data[id]).map(id => {
            return this.data[id][variable];
        }).join(", ");
    }

    getById(status_id) {
        return this.data[status_id];
    }

    getLabelById(status_id) {
        return this.data[status_id] && this.data[status_id].label;
    }

    /**
     * Return all order status
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Set all order status data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const OrderStatusStore = new orderStatusStore;

export default OrderStatusStore;