/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Change all order status Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import Dialog from "../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

class ChangeAllStatusDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onCancel,
            onOk,
        } = this.props;

        return (
            <Dialog
                title="There are also orders related to the order"
                open={open}
                onClose={onCancel}
                onCancel={onCancel}
                onOk={onOk}
                okText="Yes, change all"
                cancelText="No, just this"
            >
                <DialogContentText>
                    Would you also like to change the status of related orders?
                </DialogContentText>
            </Dialog>
        );
    }
}

ChangeAllStatusDialog.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

ChangeAllStatusDialog.defaultProps = {
    open: false,
};

export default ChangeAllStatusDialog;