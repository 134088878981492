/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_2 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 46022-18655 87689-48816 117851-30161 30161-71828 48816-117851 48816-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm56177 235170H110490c1286-11072 5207-21517 11731-31272 6524-9786 18757-21329 36698-34628 10978-8155 18004-14334 21109-18569 3074-4234 4611-8249 4611-12044 0-4109-1506-7622-4548-10539-3043-2917-6869-4360-11480-4360-4768 0-8689 1506-11731 4517-3043 3043-5081 8343-6116 15997l-37451-3043c1474-10570 4172-18788 8093-24717 3920-5928 9441-10445 16561-13613 7152-3168 17032-4736 29641-4736 13174 0 23399 1506 30739 4517 7308 2980 13080 7591 17251 13801 4203 6242 6305 13205 6305 20921 0 8187-2415 16028-7214 23525-4799 7465-13550 15683-26254 24622-7528 5207-12578 8877-15119 10947-2541 2102-5520 4799-8970 8187h58498v30488zm50452-175132c-27288-27288-64987-44166-106628-44166S87328 32750 60040 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166 79340-16878 106628-44166 44166-64987 44166-106628-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_2;