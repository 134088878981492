/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_1 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 46022-18655 87689-48816 117851-30161 30161-71828 48816-117851 48816-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm38398 98487v136359h-37648v-89220c-6087 4620-11988 8335-17700 11176-5713 2841-12862 5588-21446 8179v-30343c12674-4120 22539-9053 29532-14797 7024-5776 12518-12893 16483-21353h30780zm68231-38449c-27288-27288-64987-44166-106628-44166S87328 32750 60040 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166 79340-16878 106628-44166 44166-64987 44166-106628-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_1;