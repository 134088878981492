/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order list dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../../../dialog";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import SupplierStore from "../../../../../stores/SupplierStore";
import OrderInfoDialog from "../orderInfoDialog";
// import MenuItem from "@material-ui/core/MenuItem";

class OrderListDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            order_list_dialog: false,
            order: {}
        };

        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    closeOrderInfo() {
        this.setState({order_list_dialog: false});
    }

    openOrderInfo(order) {
        this.setState({order: order, order_list_dialog: true});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { open,
            onClose,
            onCancel,
            onSelect,
            orders
        } = this.props;

        return (
            <React.Fragment>
                <OrderInfoDialog
                    open={this.state.order_list_dialog}
                    onClose={this.closeOrderInfo}
                    onCancel={this.closeOrderInfo}
                    order={this.state.order}
                />
                <Dialog
                    title="Select Order"
                    open={open && !this.state.order_list_dialog}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Cancel"
                    type="order-list-dialog"
                >
                    <List
                        dense={false}
                    >
                        {orders.map((order, index) => (
                            <React.Fragment
                                key={index}
                            >
                                <ListItem
                                    // button
                                >
                                    <ListItemIcon>
                                        <IconButton
                                            edge="start"
                                            aria-label="info"
                                            onClick={() => {{this.openOrderInfo(order);}}}
                                        >
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText style={{minWidth: "150px"}}>
                                        <React.Fragment>
                                            <Typography
                                                variant="body1"
                                            >
                                                {order.increment_id}
                                            </Typography>
                                            <Typography
                                                component="div"
                                                variant="caption"
                                            >
                                                {/*{SupplierStore.get(order.supplier_id, "name") + " (" + order.total_item_count + "db)"}*/}
                                                {SupplierStore.get(order.supplier_id, "name")}
                                            </Typography>
                                        </React.Fragment>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="select"
                                            onClick={() => {{onSelect(order.increment_id);}}}
                                        >
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Dialog>
            </React.Fragment>
        );
    }
}

OrderListDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onSelect: PropTypes.any,
    orders: PropTypes.any
};

OrderListDialog.defaultProps = {
    open: false,
    orders: []
};

export default OrderListDialog;