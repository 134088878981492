/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order field component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CarrierStore from "../../../../../../stores/CarrierStore";
import RoleStore from "../../../../../../stores/RoleStore";
import OrderStatusStore from "../../../../../../stores/OrderStatusStore";
import PaymentMethodStore from "../../../../../../stores/PaymentMethodStore";
import ShippingMethodStore from "../../../../../../stores/ShippingMethodStore";
import SupplierStore from "../../../../../../stores/SupplierStore";
import PrinterTypeStore from "../../../../../../stores/PrinterTypeStore";
import WebshopStore from "../../../../../../stores/WebshopStore";

class OrderField extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            stores: {
                carrier: CarrierStore.getAllById(),
                role: RoleStore.getAll(),
                order_status: OrderStatusStore.getAll(),
                payment_method: PaymentMethodStore.getAll(),
                shipping_method: ShippingMethodStore.getAll(),
                supplier: SupplierStore.getAll(),
                printer_type: PrinterTypeStore.getAll(),
                webshop: WebshopStore.getAll()
            }
        };

        this.getItem = this.getItem.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Return the col value
     * @param key
     * @param item
     * @returns {string | *|string|*[]}
     */
    getItem(key, item) {
        const stores = this.state.stores;
        let supplier = "";

        switch (key) {
            case "row_controls":
                return this.getRowControls();
            case "carrier_id":
                return stores.carrier[item] !== undefined ? stores.carrier[item]["name"] : item;
            case "role_id":
                return stores.role[item] !== undefined ? stores.role[item]["role_name"] : item;
            case "status":
            case "order_status":
            case "complete_status":
                return stores.order_status[item] !== undefined ? stores.order_status[item]["label"] : item;
            case "payment_method":
                return stores.payment_method[item] !== undefined ? stores.payment_method[item]["label"] : item;
            case "shipping_method":
            case "carrier_type":
                return stores.shipping_method[item] !== undefined ? stores.shipping_method[item]["label"] : item;
            case "supplier_id":
                supplier = stores.supplier[item] !== undefined ? stores.supplier[item]["name"] : item;
                if (this.props.order.hasOwnProperty("suppliers")) {
                    if (supplier === null) {
                        supplier = this.props.order["suppliers"];
                    } else {
                        supplier = this.props.order["suppliers"] + " &nbsp; " + supplier;
                    }
                }

                return (
                    <React.Fragment>
                        <span dangerouslySetInnerHTML={{ __html: supplier }}>
                        </span>
                    </React.Fragment>
                );
            case "webshop_status":
                return this.props.order.hasOwnProperty("webshop_status_label")
                    ? this.props.order["webshop_status_label"]
                    : item;
            case "webshop_id":
                return stores.webshop[item] !== undefined ? stores.webshop[item]["name"] : item;
            case "printer_type":
                return stores.printer_type[item] !== undefined ? stores.printer_type[item] : item;
            case "role_type":
                return item === "G" ? "Group" : "User";
            case "direct_delivery":
            case "notify_webshop":
            case "notify_carrier":
            case "notify_supplier":
            case "permission_view":
            case "permission_edit":
            case "enabled":
            case "ignore_merge":
            case "show_printer_dialog":
                return item === "1" ? <SuccessIcon style={{color: "#4caf50"}}/> :
                    <CancelIcon style={{color: "#e57373"}}/>;
            default:
                return item;
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            fieldDef,
            general
        } = this.props;

        return (
            <Typography
                key={"of" + fieldDef.id}
                className={fieldDef.classNames}
            >
                {(general.col_name_show) && (
                    <Typography
                        className={"tdBefore cut-text"}
                        color="textSecondary"
                        display={general.col_name_follow ? "inline" : "block"}
                        variant="caption"
                    >{fieldDef.label}&nbsp;
                    </Typography>
                )}
                <Typography color="inherit" display="inline" variant="caption">
                    {this.getItem(fieldDef.field, fieldDef.value)}
                </Typography>
            </Typography>
        );
    }
}

OrderField.propTypes = {
    fieldDef: PropTypes.any,
    general: PropTypes.any,
    order: PropTypes.object
};

OrderField.defaultProps = {
    fieldDef: {},
    general: {
        col_name_show: true,
        col_name_follow: true
    },
    order: {}
};

export default OrderField;