/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Utils component
 * -----------------------------------------------
 * - handle events and set to passive if possible
 */

export const eventListenerOptionsSupported = () => {
    let supported = false;

    try {
        const opts = Object.defineProperty({}, "passive", {
            get() {
                supported = true;
            }
        });

        window.addEventListener("test", null, opts);
        window.removeEventListener("test", null, opts);
    } catch (e) {
        console.log(e);
    }

    return supported;
};


/**
 * Order_status_from and order_status_to fields are now arrays of number
 * Older fields from db need to be mapped to new data model
 */
export const mapOrderStatus = (data) => {
    return data.map(item => {
        item = transformStatus(item);
        if (item.items) {
            item.items = item.items.map((item) => transformStatus(item));
        }
        return item;
    });
};


/**
 * Helper method:
 * Order status to array
 * Order status items to number
 * @param item
 * @return {*}
 */
const transformStatus = (item) => {
    item.order_status_to = item.order_status_to ?  JSON.parse(item.order_status_to) : [];
    item.order_status_from = item.order_status_from ? JSON.parse(item.order_status_from) : [];
    item.order_status_from = Array.isArray(item.order_status_from) ? item.order_status_from : [item.order_status_from];
    item.order_status_to = Array.isArray(item.order_status_to) ? item.order_status_to : [item.order_status_to];
    item.order_status_to = item.order_status_to.map(Number);
    item.order_status_from = item.order_status_from.map(Number);
    return item;
};