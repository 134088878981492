/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_6 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 46022-18655 87689-48816 117851-30161 30161-71828 48816-117851 48816-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm52009 130276l-36778 4489c-959-5108-2569-8699-4829-10773-2291-2105-5077-3158-8390-3158-5975 0-10619 3003-13931 9040-2415 4365-4210 13652-5356 27893 4427-4489 8947-7802 13591-9938 4674-2136 10030-3219 16129-3219 11857 0 21887 4241 30060 12693 8204 8483 12290 19225 12290 32227 0 8761-2074 16779-6222 24054-4148 7244-9876 12755-17213 16470-7368 3715-16562 5572-27645 5572-13312 0-23961-2260-32011-6811-8049-4520-14488-11764-19287-21701-4798-9938-7213-23095-7213-39502 0-23993 5046-41546 15169-52721 10092-11145 24085-16717 42010-16717 10587 0 18946 1207 25076 3653s11238 6037 15293 10711c4056 4706 7151 10619 9256 17739zm-68014 59254c0 7213 1826 12848 5448 16934 3653 4087 8080 6130 13343 6130 4860 0 8916-1857 12197-5542 3251-3684 4891-9194 4891-16531 0-7492-1702-13219-5077-17150-3406-3932-7616-5913-12631-5913-5139 0-9473 1919-12940 5727-3498 3808-5232 9256-5232 16346zM273295 60039c-27288-27288-64987-44166-106628-44166S87327 32751 60039 60039s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166 79340-16878 106628-44166 44166-64987 44166-106628-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_6;