/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_0 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 92046-74620 166667-166667 166667-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-54358 166109c0-25274 4553-42960 13659-53057s22982-15146 41597-15146c8951 0 16323 1115 22053 3314 5761 2199 10438 5080 14062 8611 3655 3531 6504 7248 8580 11150 2106 3903 3779 8456 5049 13659 2478 9911 3717 20256 3717 31035 0 24128-4088 41783-12265 52965-8146 11181-22208 16787-42186 16787-11181 0-20226-1796-27133-5358s-12544-8796-16973-15704c-3190-4894-5699-11584-7496-20070-1765-8518-2664-17903-2664-28186zm36611 62c0 16911 1487 28496 4491 34690 3004 6195 7341 9292 13040 9292 3748 0 7000-1301 9756-3934 2757-2633 4770-6814 6071-12482 1301-5699 1952-14557 1952-26575 0-17655-1487-29518-4491-35620-3004-6070-7496-9106-13473-9106-6133 0-10531 3097-13257 9292s-4089 17686-4089 34443zM273296 60038c-27288-27288-64987-44166-106628-44166S87328 32750 60040 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166c83283 0 150794-67511 150794-150794 0-41641-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_0;