/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * User Groups component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import UniversalTable from "../../common/universalTable";
import EditDialog from "./editDialog";
import DeleteDialog from "../../common/deleteDialog";
import notificationService from "../../../services/Notification";

class UserGroups extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            delete_item_id: false,
            dialog_open: false,
            is_edit: false,
            actual_edit: {},
            need_reload: false
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.reloaded = this.reloaded.bind(this);
        this.onSupplierVisibilityChanged = this.onSupplierVisibilityChanged.bind(this);
        this.onShippingMethodVisibilityChanged = this.onShippingMethodVisibilityChanged.bind(this);
        this.onMenuVisibilityChanged = this.onMenuVisibilityChanged.bind(this);

        this.openDelete = this.openDelete.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.onDeleteOk = this.onDeleteOk.bind(this);
        this.deleteSuccess = this.deleteSuccess.bind(this);
        this.onLabelPrintChanged = this.onLabelPrintChanged.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Clone object
     * @param src
     * @returns {any}
     */
    cloneObject(src) {
        return Object.assign({}, src);
    }

    /**
     * Edit item
     * @param item
     * @param index
     */
    onEdit(item, index) {
        if (item !== undefined) {
            let data = this.cloneObject(item);
            data.settings = JSON.parse(data.settings);
            delete data._links;
            this.setState({actual_edit: data, actual_index: index, dialog_open: true, is_edit: true});
        }
        else {
            this.setState({
                actual_edit: {
                    "parent_id": "0",
                    "role_type": "",
                    "user_id": "0",
                    "role_name": "",
                    "menus": null,
                    "suppliers": null,
                    "shipping_methods": null,
                },
                actual_index: index,
                dialog_open: true,
                is_edit: false
            });
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.actual_edit,
            target = e.target.name;

        item[target] = e.target.value;
        this.forceUpdate();
    }

    /**
     * Supplier visibility switch state change
     * @param e
     */
    onSupplierVisibilityChanged(e) {
        let item = this.state.actual_edit,
            key = e.target.name,
            suppliers = item.suppliers !== null ? JSON.parse(item.suppliers) : {};

        suppliers[key] = e.target.value === "false" ? true :false;

        item.suppliers = JSON.stringify(suppliers);
        this.setState({actual_edit: item});
    }


    /**
     * Label print option to usergroup toggle
     */
    onLabelPrintChanged() {
        let item = this.state.actual_edit;
        if (item.settings) {
            item.settings.label_printing = !item.settings.label_printing;
        } else {
            item.settings = {label_printing: true};
        }

        this.setState({actual_edit: item });
    }

    /**
     * Shipping method visibility switch state change
     * @param e
     */
    onShippingMethodVisibilityChanged(e) {
        let item = this.state.actual_edit,
            key = e.target.name,
            shipping_methods = item.shipping_methods !== null ? JSON.parse(item.shipping_methods) : {};

        shipping_methods[key] = e.target.value === "false" ? true :false;

        item.shipping_methods = JSON.stringify(shipping_methods);
        this.setState({actual_edit: item});
    }

    /**
     * Menu visibility switch state change
     * @param e
     */
    onMenuVisibilityChanged(e) {
        let item = this.state.actual_edit,
            key = e.target.name.split("_")[1],
            type = e.target.name.split("_")[0],
            menus = item.menus !== null ? JSON.parse(item.menus) : {};

        if(!menus.hasOwnProperty(key)) {
            menus[key] = {};
        }

        menus[key][type] = e.target.value === "false" ? true :false;

        item.menus = JSON.stringify(menus);
        this.setState({actual_edit: item});
    }


    /**
     * Close the edit dialog
     */
    onCancel() {
        this.setState({actual_edit: {}, actual_index: 0, dialog_open: false, is_edit: false, need_reload: false});
    }

    /**
     * Save menu data
     */
    onSave() {
        if (this.state.is_edit) {
            let data = this.cloneObject(this.state.actual_edit);
            delete data.role_id;
            delete data.setting;
            data.settings = JSON.stringify(data.settings);
            LoaderService.patchData("role/" + this.state.actual_edit.role_id, data, this.saveSuccess);
        }
        else {
            LoaderService.postData("role", this.state.actual_edit, this.saveSuccess);
        }
    }

    /**
     * Save success
     */
    saveSuccess() {
        this.setState({need_reload: true});
        this.onCancel();
    }

    /**
     * Switch the need reload flag back
     */
    reloaded() {
        this.setState({need_reload:false});
    }

    openDelete(data) {
        this.setState({delete_item_id: data.role_id});
    }

    onDeleteCancel() {
        this.setState({delete_item_id: false});
    }

    onDeleteOk() {
        LoaderService.deleteData("role/" + this.state.delete_item_id, {}, this.deleteSuccess);
    }

    deleteSuccess() {
        notificationService.showNotification("success", "Item successfully deleted");
        this.setState({delete_item_id: false, need_reload: true});
        this.onCancel();
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            classes,
            stores,
            pageData
        } = this.props;
        return (
            <React.Fragment>
                <div className={classes.tableRoot}>
                    <UniversalTable
                        needReload={this.state.need_reload}
                        acl={acl}
                        pageData={pageData}
                        url="role"
                        data_key="oauth_role"
                        row_key="role_id"
                        onAdd={this.onEdit}
                        onEdit={this.onEdit}
                        onDelete={this.openDelete}
                        onReloaded={this.reloaded}
                    />
                </div>
                <EditDialog
                    onLabelPrintChanged={this.onLabelPrintChanged}
                    classes={classes}
                    stores={stores}
                    open={this.state.dialog_open}
                    edit={this.state.is_edit}
                    data={this.state.actual_edit}
                    onOk={this.onSave}
                    onCancel={this.onCancel}
                    onClose={this.onCancel}
                    onChange={this.onChange}
                    onSupplierVisibilityChanged={this.onSupplierVisibilityChanged}
                    onShippingMethodVisibilityChanged={this.onShippingMethodVisibilityChanged}
                    onMenuVisibilityChanged={this.onMenuVisibilityChanged}
                />
                <DeleteDialog
                    open={this.state.delete_item_id !== false}
                    onCancel={this.onDeleteCancel}
                    onOk={this.onDeleteOk}
                />
            </React.Fragment>
        );
    }
}

UserGroups.propTypes = {
    acl: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    stores: PropTypes.any,
    pageData: PropTypes.any
};

UserGroups.defaultProps = {
    url: false,
    classes: {}
};

export default UserGroups;