/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Loader animation component
 * ---------------
 * - show loader animation on load
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * Import used services
 */
import LoaderService from "../services/Loader";

class LoaderAnim extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            showLoader: LoaderService.getState()
        };
    }

    componentDidMount() {
        LoaderService.on("change", () => {
            this.setState({
                showLoader: LoaderService.getState(),
            });
        });
    }

    render() {
        // const { classes} = this.props;

        return <div className={"progressbar"} style={{width: "0"}}>
            {this.state.showLoader === "send" && (
                <div className={"progressbar-send-holder"}>
                    <LinearProgress color="secondary"/>
                </div>
            )}
            {this.state.showLoader === "get" && (
                <div className={"progressbar-get-holder"}>
                    <LinearProgress color="secondary" variant="query"/>
                </div>
            )}
        </div>;
    }
}

// Accepts either a single field, or an array of fields
// Note: Reject a Form with no children
LoaderAnim.propTypes = {
    classes: PropTypes.any
};

LoaderAnim.defaultProps = {
    classes: {}
};

export default LoaderAnim;