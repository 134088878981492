/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * User Data store component
 * -----------------------------------------------
 * - store data from user
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class UserDataStore extends EventEmitter {
    constructor() {
        super();
        this.reset();
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    reset() {
        this.userdata = {
            scope: "",
            email: "",
            password: "",
            refresh_token: "",
            token: "",
            user: {},
            acl: {}
        };
        this.emit("change");
        this.emit("scopeChange");
        this.emit("emailChange");
        this.emit("passwordChange");
        this.emit("refresh_tokenChange");
        this.emit("tokenChange");
        this.emit("userChange");
        this.emit("aclChange");
    }

    getData(variable) {
        return this.userdata[variable];
    }

    setData(variable, data) {
        this.userdata[variable] = data;
        this.emit(variable + "Change");
        this.emit("change");
    }
}

const userDataStore = new UserDataStore;

export default userDataStore;