/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Ordering Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {Card, IconButton} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";

class Filtering extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            filter: [{
                field: this.props.pageData.layout === "orders" ? "increment_id" : "",
                op: "eq",
                value: ""
            }],
        };

        this.getSearchSelectElements = this.getSearchSelectElements.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.setState({search_text: this.props.search, filter: this.props.filter});
    }

    componentDidUpdate(prevProps) {

        if (!this.state.filter && this.props.filter) {
            this.setState({filter: this.props.filter});
        }

        if (prevProps.pageData.menu_id !== this.props.pageData.menu_id) {
            this.setState({
                filter: this.props.filter ? this.props.filter : [],
            });
        }
    }

    /**
     * Return elements for the search filter
     * @returns {[]}
     */
    getSearchSelectElements() {
        let header = this.props.header,
            result = [];

        header.forEach(function (item, index) {
            if (item.key !== "row_controls" && item.visible) {
                result.push(
                    <MenuItem key={index} value={item.key}>{item.name}</MenuItem>
                );
            }
        });

        return result;
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.filter,
            index = e.target.name.split("_")[1],
            target = e.target.name.split("_")[0];

        item[index][target] = e.target.value;
        this.forceUpdate();
        this.props.onChangeFilter(this.state.filter);
    }

    addFilterRow() {
        let filter = this.state.filter;
        filter.push({
            field: "",
            op: "eq",
            value: ""
        });
        this.forceUpdate();
    }

    removeFilterRow(index) {
        let filter = this.state.filter;
        delete filter[index];
        this.forceUpdate();
        this.props.onChangeFilter(this.state.filter);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            header,
            visible
        } = this.props;

        return (
            <Paper square={true} elevation={4} style={{
                maxHeight: "314px",
                width: "100%",
                overflow: "hidden",
                overflowY: "scroll"
            }}>
                {header && visible && this.state.filter && (
                    <div style={{
                        alignItems: "left"
                    }}>
                    <div style={{
                        alignItems: "left", width: "50%", display: "block", float: "left"
                    }}>
                        {this.state.filter.map((item, index) => (
                            <Card key={index} style={{width: "100%", padding: ".4rem", display: "flex", marginBottom: ".2rem", boxShadow: "none"}}>
                                <Select
                                    name={"field_" + index}
                                    style={{minWidth: "150px", width: "150px", textAlign: "left", border: "none"}}
                                    className="search-select"
                                    value={item.field}
                                    color="secondary"
                                    placeholder="in"
                                    onChange={this.onChange}
                                >
                                    {this.getSearchSelectElements()}
                                </Select>
                                <Select
                                    name={"op_" + index}
                                    style={{minWidth: "150px", width: "150px", textAlign: "left"}}
                                    className="op-select"
                                    value={item.op}
                                    color="secondary"
                                    placeholder="in"
                                    onChange={this.onChange}
                                >
                                    <MenuItem key={0} value="eq">equal</MenuItem>
                                    <MenuItem key={1} value="like">like</MenuItem>
                                    <MenuItem key={2} value="in">in</MenuItem>
                                    <MenuItem key={3} value="neq">not equal</MenuItem>
                                    <MenuItem key={4} value="nlike">not like</MenuItem>
                                    <MenuItem key={5} value="nin">not in</MenuItem>
                                    <MenuItem key={6} value="from">from</MenuItem>
                                    <MenuItem key={7} value="to">to</MenuItem>
                                    <MenuItem key={8} value="between">between</MenuItem>
                                    <MenuItem key={9} value="gt">greater than</MenuItem>
                                    <MenuItem key={10} value="lt">less than</MenuItem>
                                </Select>
                                <Input
                                    type={"text"}
                                    style={{width: "300px"}}
                                    placeholder={""}
                                    value={item.value}
                                    name={"value_" + index}
                                    color="secondary"
                                    autoFocus={true}
                                    inputProps={{"aria-label": "search"}}
                                    onChange={this.onChange}
                                />

                                <div style={ { display: "flex", alignItems: "center"  }}
                                     onClick={() => {{this.removeFilterRow(index);}}}
                                > <IconButton
                                    type="button"
                                    // className={classes.searchIconButton}
                                    aria-label="search"
                                >
                                    <RemoveIcon/>
                                </IconButton></div>
                            </Card>
                        ))}
                        <div style={ { alignItems: "center", padding: "5px"  }}
                             onClick={() => {{this.addFilterRow();}}}
                        > Új Szűrő <IconButton
                            type="button"
                            // className={classes.searchIconButton}
                            aria-label="search"
                        >
                            <AddIcon/>
                        </IconButton></div>
                    </div>
                    <div style={ { alignItems: "center", width: "50%", display: "block", float: "right" }}>
                        <ul>
                            <li><strong>equal</strong>: pontos egyezés (pl. &quot;Szállítási mód&quot; <i>equal</i> &quot;home_delivery&quot;)</li>
                            <li><strong>like</strong>: tartalmaz (pl. &quot;Szállítási mód&quot; <i>like</i> &quot;delivery&quot;)</li>
                            <li><strong>in</strong>: egyike (pl. &quot;Szállítási mód&quot; <i>in</i> &quot;home_delivery,default_irsz&quot;, így mindkét szállítási mód listázásra kerül)</li>
                            <li><strong>not equal</strong>: nem egyenlő</li>
                            <li><strong>not like</strong>: nem tartalmaz</li>
                            <li><strong>not in</strong>: nem egyike</li>
                            <li><strong>from</strong>: -tól (pl. &quot;Végösszeg&quot; <i>from</i> &quot;10000&quot;, a 10 000 Ft és az fölötti rendeléseket listázza)</li>
                            <li><strong>to</strong>: -ig (pl. &quot;Végösszeg&quot; <i>to</i> &quot;10000&quot;, a 10 000 Ft és az alatti rendeléseket listázza)</li>
                            <li><strong>between</strong>: között (pl. &quot;Végösszeg&quot; <i>between</i> &quot;100-1000&quot;, a 100 Ft és 1 000 Ft közötti rendeléseket listázza, beleértve a 10 000 és 100 000 Ft-os értékeket is)</li>
                            <li><strong>greater than</strong>: nagyobb, mint (pl. &quot;Végösszeg&quot; <i>greater than</i> &quot;10000&quot;, a 10 000 Ft-nál drágább rendeléseket listázza)</li>
                            <li><strong>less than</strong>: kisebb, mint (pl. &quot;Végösszeg&quot; <i>less than</i> &quot;10000&quot;, a 10 000 Ft-nál olcsóbb rendeléseket listázza)</li>
                        </ul>
                        A feltételek <strong>AND</strong> kapcsolatban állnak egymással.
                        Kerüljük a felesleges szóközöket, a listákat, és tól-ig értékeket egybe írjuk szóközök nélkül.
                        Pl: &quot;home_delivery,default_irsz&quot; vagy &quot;1000-100000&quot;.
                    </div>
                    </div>
                    )}
            </Paper>
        );
    }
}

Filtering.propTypes = {
    header: PropTypes.any,
    filter: PropTypes.any,
    visible: PropTypes.bool,
    handleFilterChanged: PropTypes.func,
    onChangeFilter: PropTypes.func,
    location: PropTypes.any,
    classes: PropTypes.any,
    pageData: PropTypes.any,
    onSearch: PropTypes.func,
    search: PropTypes.string
};

Filtering.defaultProps = {
    header: false,
    header_sort: false,
    classes: {}
};

export default Filtering;