/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Founded component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

// import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {Typography} from "@material-ui/core";
import Dialog from "../../dialog";
import TextField from "@material-ui/core/TextField";

class Founded extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            edit: false
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {founded_item, increaseToFounded, decreaseToFounded, getRowClassname, foundedChanged} = this.props;

        const quantItems = [
            {label: "Rendelt db", value: Number(founded_item.item.qty)},
            {label: "Fellelt db", value: Number(founded_item.item.founded)},
            {label: "Eltérés db", value: Number(founded_item.item.qty) - Number(founded_item.item.founded)},
        ];
        return (
            <Paper
                square={true}
                elevation={1}
            >
                <div className={getRowClassname(founded_item.item)}
                     onClick={() => this.setState({edit: true})}
                     style={{display: "flex", height: "40px"}}>
                    {quantItems.map(item => <div key={item.label} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flex: 1,
                        borderTop: "1px dashed",
                        borderLeft: "1px dashed",
                        borderBottom: "1px dashed",
                        borderRight: item.label === "Eltérés db" ? "1px dashed" : 0
                    }}>
                        <Typography variant="caption" style={{lineHeight: "0"}}>{item.label}</Typography>
                        <Typography variant="caption" style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            lineHeight: "0"
                        }}>{item.value}</Typography>
                    </div>)}
                </div>
                <Dialog open={this.state.edit} okText={"OK"}
                        onOk={() => this.setState({edit: false})}>
                    {itemSetter(founded_item, foundedChanged, decreaseToFounded, increaseToFounded)}
                </Dialog>
            </Paper>
        );
    }
}

Founded.propTypes = {
    classes: PropTypes.any,
    founded_item: PropTypes.any,
    foundedChanged: PropTypes.any,
    increaseToFounded: PropTypes.any,
    decreaseToFounded: PropTypes.any,
    getRowClassname: PropTypes.any,
    setScannerActive: PropTypes.func,
};

Founded.defaultProps = {
    classes: {},
    founded_item: {}
};

export default Founded;


const itemSetter = (founded_item, foundedChanged, decreaseToFounded, increaseToFounded) => (
    <FormControl style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexDirection: "row"
    }}>
        <div
            style={{
                width: "100px",
                height: "100px",
                margin: ".5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                background: "rgba(0,0,0,0.12)"
            }}
            onClick={() => {
                decreaseToFounded(founded_item);
            }}
        >
            <RemoveCircleIcon color={"secondary"} fontSize={"large"} size={"medium"}/>
        </div>
        <TextField
            name="founded_nr"
            id="founded_nr"
            style={{minHeight: "50px", fontSize: "30px"}}
            inputProps={{min: 0, style: {textAlign: "center", fontSize: "30px"}}}
            variant={"outlined"}
            type={"number"}
            color="secondary"
            value={Number(founded_item.item.founded)}
            onChange={(e) => foundedChanged(founded_item, e.target.value)}/>
        <div
            style={{
                width: "100px",
                height: "100px",
                margin: ".5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                background: "rgba(0,0,0,0.12)"
            }}
            onClick={() => {
                increaseToFounded(founded_item);
            }}
        >
            <AddCircleIcon color={"secondary"} fontSize={"large"} size={"medium"}/>
        </div>

    </FormControl>
);