/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Loader Service component
 * ---------------
 * - Show/Hide loader animation on loading
 * - Show error messages if not turned off
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

/**
 * Import used libraries
 */
import $ from "jquery";

/**
 * Import used stores
 */
import UserDataStore from "../stores/UserDataStore";
// import URLDataStore from "../stores/UrlDataStore";

/**
 * Import used services
 */
import notificationService from "../services/Notification";
import host from "../host";

class LoaderService extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();
        this.state = {
            showLoader: false
        };

        this.getErrorResponse = this.getErrorResponse.bind(this);
    }

    /**
     * Return the loader state
     * @returns {boolean}
     */
    getState() {
        return this.state.showLoader;
    }

    /**
     * Show the loader animation
     */
    showLoader(type) {

        let anim = "send";

        if (type === "GET") {
            anim = "get";
        }
        this.state.showLoader = anim;
        this.emit("change");
    }

    /**
     * Hide the loader animation
     */
    hideLoader() {
        this.state.showLoader = false;
        this.emit("change");
    }

    /**
     * Check is cordova app
     * @returns {boolean}
     */
    isCordova() {
        return document.location.protocol === "file:";
    }

    /**
     * Delete data
     * @param pathname
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    deleteData(pathname, data, callback, errorCallback, showError) {
        this.loadData(pathname, "DELETE", data, callback, errorCallback, showError);
    }

    /**
     * Put data
     * @param pathname
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    putData(pathname, data, callback, errorCallback, showError) {
        this.loadData(pathname, "PUT", data, callback, errorCallback, showError);
    }

    /**
     * Patch data
     * @param pathname
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    patchData(pathname, data, callback, errorCallback, showError) {
        this.loadData(pathname, "PATCH", data, callback, errorCallback, showError);
    }

    /**
     * Post data
     * @param pathname
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    postData(pathname, data, callback, errorCallback, showError) {
        this.loadData(pathname, "POST", data, callback, errorCallback, showError);
    }

    /**
     * Get data
     * @param pathname
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    getData(pathname, data, callback, errorCallback, showError) {
        pathname =  pathname + "/" + data;
        if (data === "") {
            pathname = pathname.slice(0, -1);
        }
        this.loadData(pathname, "GET", "", callback, errorCallback, showError);
    }

    getErrorResponse(res, msg) {
        if (!res.hasOwnProperty("responseJSON")) {
            return msg;
        }
        if (!res.responseJSON.hasOwnProperty("detail")) {
            return msg;
        }

        return res.responseJSON.detail;
    }

    /**
     * Load data
     * @param pathname
     * @param type
     * @param data
     * @param callback
     * @param errorCallback
     * @param showError
     */
    loadData(pathname, type, data, callback, errorCallback, showError) {
        let self = this,
            token = UserDataStore.getData("token"),
            // baseURL = URLDataStore.getData("defaultURI"),
            baseURL = host.URI,
            url = baseURL + pathname,
            headers = { "Accept": "application/json"};

        if (showError === undefined) {
            showError = true;
        }

        if (self.isCordova()) {
            url = "https:" + url;
        }

        if (pathname.includes("http://") || pathname.includes("https://")) {
            url = pathname;
        }

        if (token !== "") {
            headers["Authorization"] = "Bearer " + token;
        }

        this.showLoader(type);
        $.support.cors = true;

        $.ajax({
            url: url,
            data: JSON.stringify(data),
            type: type,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            headers: headers,
            xhrFields: {
                withCredentials: false
            },
            statusCode: {
                401: function(res) {
                    UserDataStore.reset();
                    let result = {status: "", message: self.getErrorResponse(res, "Unauthorised (401)"), statusCode: 401};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }

                    notificationService.showNotification("error", result.status + " " + result.message);
                },
                404: function(res) {
                    // No content found (404)
                    // This code will be executed if the server returns a 404 response
                    let result = {status: "", message: self.getErrorResponse(res, "No content found (404)"), statusCode: 404};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }

                    notificationService.showNotification("error", result.status + " " + result.message);
                },
                405: function(res) {
                    // Method not allowed (405)
                    // This code will be executed if the server returns a 405 response
                    let result = {status: "", message: self.getErrorResponse(res, "Method not allowed (405)"), statusCode: 405};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }
                    notificationService.showNotification("error", result.status + " " + result.message);
                },
                422: function(res) {
                    let result = {status: "", message: self.getErrorResponse(res, "Unprocessable Entity (422)"), statusCode: 422};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }

                    notificationService.showNotification("error", result.status + " " + result.message);
                },
                503: function(res) {
                    // Service Unavailable (503)
                    // This code will be executed if the server returns a 503 response
                    let result = {status: "", message: self.getErrorResponse(res, "Service Unavailable (503)"), statusCode: 503};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }
                    notificationService.showNotification("error", result.status + " " + result.message);
                },
                500: function(_result) {
                    // Service Unavailable (503)
                    // This code will be executed if the server returns a 503 response
                    let result = {status: "", message: self.getErrorResponse(_result, "Internal Server error (500)"), statusCode: 500};

                    if (errorCallback) {
                        errorCallback(result);
                        if (!showError) {
                            return;
                        }
                    }
                    notificationService.showNotification("error", result.status + " " + result.message);
                }
            },
            success: function (result) {
                self.hideLoader();
                if (result === undefined) {
                    if (callback) {
                        callback();
                    }
                    return;
                }
                if (result.hasOwnProperty("status") && result.status === 204) {
                    notificationService.showNotification("error", result.status + " " + result.message);
                }

                if (result.hasOwnProperty("status") && result.status === 401) {
                    notificationService.showNotification("error", result.status + " " + result.message);
                }

                if (callback) {
                    if (type === "GET" && result.hasOwnProperty("data")) {
                        callback(result.data);
                    }
                    else {
                        callback(result);
                    }
                }
                else {
                    notificationService.showNotification("success", result.data || "Successful operation");
                }
            },
            error: function (error) {
                self.hideLoader();
                if (error.hasOwnProperty("responseJSON")) {
                    if (errorCallback) {
                        errorCallback(error.responseJSON);
                        if (!showError) {
                            return;
                        }
                        if (error.responseJSON.title && error.responseJSON.detail) {
                            if (error.responseJSON.detail === "Order or product not found") return;
                            notificationService.showNotification("error", error.responseJSON.title + ": " + error.responseJSON.detail);
                        } else {
                            notificationService.showNotification("error", error.responseJSON.status + " " + error.responseJSON.message);
                        }
                    }
                }
                else {
                    console.log(error);
                }
            }
        });
    }
}

const loaderService = new LoaderService;

export default loaderService;