/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0008 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M135.6,232.5C60.7,232.5,0,293.2,0,368.1s60.7,135.6,135.6,135.6c74.9,0,135.6-60.7,135.6-135.6S210.5,232.5,135.6,232.5z
            M111.7,328c8.9,0,16.1,7.2,16.1,16.1s-7.2,16.1-16.1,16.1h-3.2v19.4h3.2c8.9,0,16.1,7.2,16.1,16.1s-7.2,16.1-16.1,16.1h-2.9
            c1.6,10.9,10.9,19.4,22.3,19.4c12.5,0,22.6-10.1,22.6-22.6c0-8.9,7.2-16.1,16.1-16.1s16.1,7.2,16.1,16.1c0,30.3-24.6,54.9-54.9,54.9
            c-29.2,0-52.9-22.9-54.6-51.7h-3.6c-8.9,0-16.1-7.2-16.1-16.1s7.2-16.1,16.1-16.1h3.2v-19.4h-3.2c-8.9,0-16.1-7.2-16.1-16.1
            S64,328,72.9,328h3.6c1.7-28.7,25.4-51.7,54.6-51.7c30.3,0,54.9,24.6,54.9,54.9c0,8.9-7.2,16.1-16.1,16.1s-16.1-7.2-16.1-16.1
            c0-12.5-10.1-22.6-22.6-22.6c-11.4,0-20.7,8.4-22.3,19.4H111.7z M66.8,214.3c0.8,0.1,1.6,0.2,2.4,0.2c7.9,0,14.8-5.8,16-13.8
            c5.6-38.1,27.4-71.1,59.6-91.1l5.3,11.7c3.5,7.8,14.4,8.1,18.4,0.6l22.9-42.3c3.2-6-0.1-13.5-6.8-15l-46.9-10.6
            c-8.3-1.9-15.2,6.6-11.6,14.3l5.3,11.7C89.2,104.9,60.3,147,53.2,196C51.9,204.8,58,213,66.8,214.3z M355.2,0
            c-74.9,0-135.6,60.7-135.6,135.6s60.7,135.6,135.6,135.6c74.9,0,135.6-60.7,135.6-135.6S430.1,0,355.2,0z M346.3,109.6l29.8,15.3
            c16.9,8.7,25.9,26.6,22.9,45.6c-2.4,15.2-12.2,27.2-25.8,32.6v5.4c0,8.9-7.2,16.1-16.1,16.1s-16.1-7.2-16.1-16.1v-4.3
            c-14.4-4.3-26.6-14.7-32.9-29.2c-3.6-8.2,0.2-17.7,8.4-21.2c8.2-3.5,17.7,0.2,21.2,8.4c3.4,7.8,11.5,12.5,19.9,11.8l1.5-0.2
            c6.2-1,7.7-6.2,8.1-8.4c0.3-1.9,0.8-8.4-5.8-11.8l-29.8-15.3c-17.1-8.8-26.2-27.5-22.7-46.5c3.2-17.2,15.6-29.9,32-33.7v-4.5
            c0-8.9,7.2-16.1,16.1-16.1s16.1,7.2,16.1,16.1v8c11.5,5.2,21,14.4,26.3,26.6c3.6,8.2-0.2,17.7-8.4,21.2c-8.2,3.6-17.7-0.2-21.2-8.4
            c-3.4-7.8-11.5-12.6-19.9-11.8c-7.2,0.8-9,6.2-9.4,8.5C340.2,99.7,339.6,106.1,346.3,109.6z M430.4,295.9c-8.9-1.4-17,4.8-18.3,13.6
            c-5.6,38-28,71.1-59.9,91l-6.6-13.4c-3.8-7.6-14.6-7.7-18.5-0.1l-21.6,42.9c-3.1,6.1,0.5,13.4,7.3,14.7l47.2,9.1
            c8.4,1.6,15-7,11.2-14.7l-4.7-9.5c41.4-24.7,70.4-66.9,77.5-115.4C445.4,305.3,439.3,297.1,430.4,295.9z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0008;