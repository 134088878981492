/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Printer store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class printerStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the printer variable
     * @param printer_id
     * @param variable
     * @returns {*}
     */
    get(printer_id, variable) {
        if (this.data.hasOwnProperty(printer_id) && this.data[printer_id].hasOwnProperty(variable)) {
            return this.data[printer_id][variable];
        }
        console.log({printer_id: printer_id, data: this.data});
        return printer_id;
    }

    /**
     * Return the printer variable
     * @param value
     * @param field
     * @param variable
     * @returns {*}
     */
    getBy(value, field, variable) {
        let result = "";

        for (let id in this.data) {
            if (this.data[id][field] === value) {
                result = this.data[id][variable];
            }
        }

        return result;
    }

    /**
     * Return all data
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Set all suppliers data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const PrinterStore = new printerStore;

export default PrinterStore;