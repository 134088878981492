/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Slide in menu component
 * -----------------------------------------------
 * - show/hide slide in menu
 */


/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import {CssBaseline} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import MenuItem from "./menuItem";
import MenuIcon from "./menuIcon";

// const icon ="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Réteg_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 566.9 566.9' style='enable-background:new 0 0 0 0;' xml:space='preserve'%3E%3Cpath d='M278.7,95.6l-139.4,52.3l2.3-35C59.7,124.9-3.1,191.4,6.7,286.7C-13.8,169.8,32.6,80.8,146.8,60.2l2.2-32.6L278.7,95.6 L278.7,95.6z M260,450.6l111.7,88.8l8.6-31.7c116.3-0.8,181.9-80.1,184.7-198.6c-9.1,95.4-81.5,150.2-164.4,148.1l9.2-34L260,450.6 L260,450.6z M124.6,393.2v-134h125.8v134H149.7H124.6z M325.9,393.2v-134h125.8v134H338.5H325.9z M112,234.8v-60.9h138.4v60.9H137.2 L112,234.8L112,234.8z M325.9,173.8h138.4v60.9H325.9V173.8L325.9,173.8z M275.6,393.2V173.8h25.2v219.3h-12.6h-12.6V393.2z'/%3E%3C/svg%3E%0A";

class MainMenu extends React.Component {

    constructor(props) {
        super( props );

        this.state = {
            menu_open: ""
        };

        this.handleMenuOpen = this.handleMenuOpen.bind(this);
    }

    componentDidMount() {
    }

    /**
     * Open/close menu groups
     * @param menu_id
     */
    handleMenuOpen(menu_id) {
        this.setState({menu_open: this.state.menu_open === menu_id ? "" : menu_id});
    }

    render() {
        const {
            classes,
            location: { pathname },
            mainMenuItems,
            handleDrawerToggle
        } = this.props;

        return <div className="drawer-menu">
            <CssBaseline/>
            <List>
                {mainMenuItems.map((row, index) => (
                    <React.Fragment key={index}>
                        {!row.hasOwnProperty("items") && (
                            <MenuItem
                                item={row}
                                pathname={pathname}
                                classes={classes}
                                handleDrawerToggle={handleDrawerToggle}
                            />
                        )}
                        {row.hasOwnProperty("items") && (
                            <React.Fragment>
                                <Divider />
                                <ListItem
                                    button
                                    onClick={() => {{this.handleMenuOpen(row.id);}}}
                                >
                                    <ListItemIcon>
                                        <MenuIcon
                                            classes={classes}
                                            icon={row.icon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText className={row.title.length > 18 ? "dropdown-scroll" : ""} primary={row.title} />
                                    {this.state.menu_open === row.id ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.menu_open === row.id} timeout="auto" unmountOnExit>
                                    {row.items.map((item, item_index) => (
                                        <MenuItem
                                            handleDrawerToggle={handleDrawerToggle}
                                            key={item_index}
                                            item={item}
                                            pathname={pathname}
                                            classes={classes}
                                        />
                                    ))}
                                </Collapse>
                                <Divider />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </div>;
    }
}

MainMenu.propTypes = {
    handleDrawerToggle: PropTypes.func,
    history: PropTypes.func,
    classes: PropTypes.any,
    location: PropTypes.any,
    mainMenuItems: PropTypes.array
};

MainMenu.defaultProps = {
    classes: {},
    location: {},
    mainMenuItems: []
};

export default MainMenu;