/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import Dialog from "../dialog";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import OrderStatusStore from "../../../stores/OrderStatusStore";
import DoneAllIcon from "@material-ui/icons/DoneAll";

class StatusSelectorDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected_status: 0
        };
    }

    okClicked() {
        if (this.state.selected_status) {
            this.props.onChange(this.state.selected_status);
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        return (
            <Dialog
                extraClasses={"itg-selector-dialog"}
                type={"success"}
                open={true}
                title={"Státuszváltás"}
                disabled={this.props.isLoading}
                onOk={this.okClicked.bind(this)}
                onCancel={this.props.onCancel}
                onClose={window.innerWidth < 599 ? this.props.onCancel : null}
                primaryAnimation="button-sonar"
                okText="Save order"
                cancelText="Vissza"
                fullScreen={window.innerWidth < 599}
            >
                <div className={'items'}>
                    <DoneAllIcon className={'top-icon'} />
                    {this.props.statuses.map((key, i) => (
                        <Button style={{marginTop: i===0 ? "10px" : "20px", borderWidth: "2px"}} key={i} color="default" fullWidth
                                variant={key === this.state.selected_status ? "contained" : "outlined"}
                                onClick={() => this.setState({ 'selected_status': key })}>{OrderStatusStore.getLabelById(key)}</Button>
                    ))}
                </div>
            </Dialog>

        );
    }
}

StatusSelectorDialog.propTypes = {
    statuses: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
};

StatusSelectorDialog.defaultProps = {
    isLoading: false
};

export default StatusSelectorDialog;
