/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order info component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

// import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import ShippingMethodStore from "../../../../../stores/ShippingMethodStore";

class OrderInfo extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { order } = this.props;
        const currentTotalPackageWeight = order.packages.reduce((acc, pack) => acc + Number(pack.weight), 0);
        const remainingPackageWeight = Math.round((order.weight - currentTotalPackageWeight) * 1000) / 1000;
        const orderWeight = Math.round(order.weight * 1000) / 1000;
        return (
            <Paper
                square={true}
                elevation={1}
                style={{padding: "4px"}}
                className={"common-order-info"}
            >
                <Grid container spacing={1} alignItems="center">
                    <Grid item className="ut-xxs-col-5 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right ut-l-col-6 ut-l-right ut-xl-col-6 ut-xl-right ut-xxl-col-6 ut-xxl-right">
                        <Typography variant="caption">Total order weight:</Typography>
                    </Grid>
                    <Grid item className={"ut-xxs-col-7 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left ut-l-col-6 ut-l-left ut-xl-col-6 ut-xl-left ut-xxl-col-6 ut-xxl-left"}>
                        <Typography variant="caption">
                            {remainingPackageWeight + " Kg / " + orderWeight + " Kg"}
                        </Typography>
                    </Grid>

                    <Grid item className="ut-xxs-col-5 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right ut-l-col-6 ut-l-right ut-xl-col-6 ut-xl-right ut-xxl-col-6 ut-xxl-right">
                        <Typography variant="caption">Payment method:</Typography>
                    </Grid>
                    <Grid item className={"ut-xxs-col-7 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left ut-l-col-6 ut-l-left ut-xl-col-6 ut-xl-left ut-xxl-col-6 ut-xxl-left"}>
                        <Typography variant="caption">{order.payment_method}</Typography>
                    </Grid>

                    <Grid item className="ut-xxs-col-5 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right ut-l-col-6 ut-l-right ut-xl-col-6 ut-xl-right ut-xxl-col-6 ut-xxl-right">
                        <Typography variant="caption">Shipping method:</Typography>
                    </Grid>
                    <Grid item className={"ut-xxs-col-7 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left ut-l-col-6 ut-l-left ut-xl-col-6 ut-xl-left ut-xxl-col-6 ut-xxl-left"}>
                        <Typography variant="caption">{ShippingMethodStore.get(order.shipping_method, "label") + " " + Math.round(order.shipping_amount) + " Ft"}</Typography>
                    </Grid>

                    <Grid item className="ut-xxs-col-5 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right ut-l-col-6 ut-l-right ut-xl-col-6 ut-xl-right ut-xxl-col-6 ut-xxl-right">
                        <Typography variant="caption">Order summary:</Typography>
                    </Grid>
                    <Grid item className={"ut-xxs-col-7 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left ut-l-col-6 ut-l-left ut-xl-col-6 ut-xl-left ut-xxl-col-6 ut-xxl-left"}>
                        <Typography variant="caption">{Math.round(order.grand_total) + " Ft"}</Typography>
                    </Grid>

                </Grid>
            </Paper>
        );
    }
}

OrderInfo.propTypes = {
    order: PropTypes.any,
    classes: PropTypes.any,
};

OrderInfo.defaultProps = {
    order: {},
    classes: {}
};

export default OrderInfo;