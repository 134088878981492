/**
 * Copyright ©2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Consolidation item component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import SettingsIcon from "@material-ui/icons/Settings";
import ShippingMethodStore from "../../../../stores/ShippingMethodStore";
import ItemInfo from "../common/itemInfo";
import CodeInputDialog from "../common/codeInputDialog";
import NotificationService from "../../../../services/Notification";
import SettingsDataStore from "../../../../stores/SettingsDataStore";
import OrderSaveDialog from "../common/orderSaveDialog";
import ItemNotNeedDialog from "../common/itemNotNeedDialog";
import OrderInfoDialog from "../common/orderInfoDialog";
import PleaseScan from "../common/pleaseScan";
import OrderSelectTable from "../common/orderSelectTable";
import BarcodeReader from "../common/barcodeReader";
import FocusLock from "react-focus-lock";
import OrderNotEditableDialog from "../common/orderNotEditableDialog";
import Settings from "./settings";
import SpeedDial from "../../speedDial";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {checkDownloadables} from "../../utils/downloadFile";
import RoleStore from "../../../../stores/RoleStore";
import userDataStore from "../../../../stores/UserDataStore";
import Button from "@material-ui/core/Button";
import Location from "./Location";
import Founded from "./Founded";
import ItemSaveDialog from "../common/itemSaveDialog";

class ConsolidationItem extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.loading = false;
        this.keys = "";
        this.key_time = 0;
        this.rootRef = React.createRef();
        this.menu_id = "";
        this.locationComponent = null;

        this.state = {
            scannerActive: true,
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            total_count: 0,
            founded_count: 0,
            enter_code_dialogue: false,
            speedDial_open: false,
            orders: [],
            storages: [],
            location: [],
            current_location: "",
            data: false,
            actual_founded: false,
            alert_open: false,
            warning_open: false,
            warning_message: "",
            can_save: false,
            save_dialog: false,
            save_item_dialog: false,
            save_item_type: "",
            settings: {},
            tableSettings: {
                partialSave: false,
                saveType: ""
            },
            editable: true,
            notEditableDialog: false,
            message: "",
            error_message: false,
            labelPrintCount: 1,
            shouldPrintLabel: false,
            myRole: userDataStore.getData("user") && userDataStore.getData("user").role_id,
            settingsOpen: false,
            status: "",
            storage: "",
            active_product: null,
            vendorCount: 0,
            location_edit_modal: false,
            previousData: false
        };
        this._can_save = false;

        this.fetchData = this.fetchData.bind(this);
        this.handleClickOrderCode = this.handleClickOrderCode.bind(this);
        this.ordersSuccess = this.ordersSuccess.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.warningClose = this.warningClose.bind(this);
        this.newOrderAlertClose = this.newOrderAlertClose.bind(this);
        this.newOrderAlertOk = this.newOrderAlertOk.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkReadyToSave = this.checkReadyToSave.bind(this);
        this.onClickBackButton = this.onClickBackButton.bind(this);
        this.onClickSaveButton = this.onClickSaveButton.bind(this);
        this.onClickDamagedButton = this.onClickDamagedButton.bind(this);
        this.toggleShowEnterCode = this.toggleShowEnterCode.bind(this);
        this.closeCodeDialogue = this.closeCodeDialogue.bind(this);
        this.preventClose = this.preventClose.bind(this);
        this.prepareSaveObject = this.prepareSaveObject.bind(this);
        this.saveOrderOk = this.saveOrderOk.bind(this);
        this.saveItemOk = this.saveItemOk.bind(this);
        this.saveOrderSuccess = this.saveOrderSuccess.bind(this);
        this.notNeedOk = this.notNeedOk.bind(this);
        this.orderListCancel = this.orderListCancel.bind(this);
        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
        this.reset = this.reset.bind(this);
        this.cancelSaveOrder = this.cancelSaveOrder.bind(this);
        this.cancelSave = this.cancelSave.bind(this);
        this.searchBarcode = this.searchBarcode.bind(this);
        this.notEditableDialogOk = this.notEditableDialogOk.bind(this);
        this.setScannerActive = this.setScannerActive.bind(this);
        this.ordersError = this.ordersError.bind(this);
        this.loadTableSettings = this.loadTableSettings.bind(this);
        this.loadTableSettingsSuccess = this.loadTableSettingsSuccess.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.foundedChanged = this.foundedChanged.bind(this);
        this.increaseToFounded = this.increaseToFounded.bind(this);
        this.decreaseToFounded = this.decreaseToFounded.bind(this);
        this.deleteLocation = this.deleteLocation.bind(this);
        this.addLocation = this.addLocation.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        let settings = SettingsDataStore.getData(this.props.pageData.layout);

        this.loadTableSettings();

        if (settings !== undefined) {
            this.setState({settings: settings});
        }

        const labelSettings = JSON.parse(RoleStore.get(this.state.myRole, "settings"));
        this.setState({shouldPrintLabel: labelSettings ? labelSettings.label_printing : false});
        RoleStore.addChangeListener("change", this.onRoleChange);
    }

    componentDidUpdate() {
        this.checkTableSettings(this.props.pageData);
    }

    componentWillUnmount() {
        RoleStore.removeAllListeners();
    }

    onRoleChange() {
        const settings = JSON.parse(RoleStore.get(this.state.myRole, "settings"));
        this.setState({shouldPrintLabel: settings ? settings.label_printing : false});
    }

    loadTableSettings() {
        LoaderService.getData(
            "table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + this.state.myRole,
            "",
            this.loadTableSettingsSuccess,
            this.notFoundTableSettings,
            false
        );
    }

    reset() {
        this.setState({
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            total_count: 0,
            founded_count: 0,
            enter_code_dialogue: false,
            speedDial_open: false,
            orders: [],
            data: false,
            actual_founded: false,
            alert_open: false,
            warning_open: false,
            warning_message: "",
            can_save: false,
            save_dialog: false,
            save_item_dialog: false,
            save_item_type: "",
            previous_edit: false,
            just_item_info: false,
            notEditableDialog: false,
            editable: true,
            message: "",
            status: "",
            lastScanned: "",
            location_edit_model: false,
            current_location: ""
        });
    }

    /**
     * Show/Hide enter code dialogue
     */
    toggleShowEnterCode() {
        this.setState({enter_code_dialogue: !this.state.enter_code_dialogue});
    }

    closeCodeDialogue() {
        if (this.state.enter_code_dialogue) {
            this.setState({enter_code_dialogue: false});
        }
    }

    loadTableSettingsSuccess(res) {
        if (res.settings) {
            const settings = JSON.parse(res.settings);
            this.setState({tableSettings: settings.settings});
        }
    }

    notFoundTableSettings(res) {
        console.log(res);
    }

    /**
     * Check order is ready to save
     */
    checkReadyToSave() {
        let self = this,
            total_count = 0,
            founded_count = 0;

        self._can_save = true;
        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item) {
                if (item.founded !== Number(item.qty)) {
                    self._can_save = false;
                }
                total_count += Number(item.qty);
                if (item.hasOwnProperty("founded")) {
                    founded_count += Number(item.founded);
                }
            });
        });

        this.setState({
            can_save: this._can_save,
            save_dialog: this._can_save,
            total_count: total_count,
            founded_count: founded_count
        });

        if (this._can_save) {
            this.checkModalAutoFocus();
        }

        return self._can_save;
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    newOrder() {
        if (this.state.data !== false) {
            this.setState({alert_open: true, speedDial_open: false});
            return false;
        }

        this.reset();
    }

    onClickSaveButton() {
        if (!this.state.current_location) {
            this.locationComponent.showEditModal();
            return;
        }

        if (!this.checkReadyToSave()) {
            if (this.props.showSaveItemDialog) {
                this.setState({save_item_dialog: true, save_item_type: ""});
            } else {
                this.setState({save_item_type: ""});
                this.saveItemOk("");
            }
            this.checkModalAutoFocus();
        }
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    onClickBackButton() {
        this.reset();

        if (this.state.previousData) {
            let prevData = this.state.previousData;
            this.setOrder(prevData.order);
            this.setState({
                just_item_info: prevData.just_item_info,
                previous_edit:  true
            });
        }
    }

    onClickDamagedButton() {
        if (!this.checkReadyToSave()) {
            let order = this.state.data;

            if (!this.locationComponent.state.damaged_edit_mode && !this.state.current_location) {
                order.location = this.state.location;

                this.setState({location: order.damaged_location});
                this.locationComponent.setState({damaged_edit_mode: true, close_callback: this.onClickDamagedButton});
                this.locationComponent.showEditModal();
            } else {
                this.locationComponent.setState({damaged_edit_mode: false, close_callback: false});

                if (this.props.showSaveItemDialog) {
                    this.setState({save_item_dialog: true, save_item_type: "damaged"});
                } else {
                    this.setState({save_item_type: "damaged"});
                    this.saveItemOk("");
                }
                this.checkModalAutoFocus();
            }
        }
    }

    handleClickOrderCode(order_code) {
        if (order_code === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({lastScanned: order_code});
        this.closeCodeDialogue();
        this.fetchData("consolidation_item/" + this.props.pageData.menu_id + "/" + order_code, this.ordersSuccess, (e) => this.ordersError(e));
    }

    ordersError(e) {
        this.setState({error_message: e.message});
    }

    loadOrder(id) {
        for (let order of this.state.orders) {
            if (order.increment_id === id) {
                this.setOrder(order);
                this.setState({order_list_dialog: false});
                return;
            }
        }

        NotificationService.showNotification("error", "The order not found " + id);
    }

    ordersSuccess(result) {
        this.loading = false;
        let just_item_info = false;
        window.consolidationItem = this; // @TODO ki kell majd szedni

        let order = null;

        if (result.hasOwnProperty("_embedded")) {
            if (result._embedded.hasOwnProperty("orders")) {
                if (result._embedded.hasOwnProperty("product")) {
                    this.setState({active_product: result._embedded.product});
                    just_item_info = {item: result._embedded.product};
                }

                if (result._embedded.orders.length === 1 && result._embedded.orders[0].hasOwnProperty("increment_id")) {
                    order = result._embedded.orders[0];
                }

                this.setState({
                    orders: result._embedded.orders,
                    order_list_dialog: order === null,
                    just_item_info: just_item_info
                });
            }

            if (order && order.hasOwnProperty("suppliers")) {
                this.setOrder(order);
            }
        }
    }

    setOrder(order) {
        let newState = {
            data: order,
            orders: [],
            notEditableDialog: !order.editable,
            editable: order.editable,
            message: order.message,
            status: order.status,
            storage: order.storage,
            storages: order.storages,
            location: order.location,
            current_location: "",
            vendorCount: order.vendor_count,
            actual_founded: null
        };

        if (order.editable) {
            order.suppliers.forEach(sup => {
                sup.items.forEach(itm => {
                    if (itm.hasOwnProperty("current") && itm.current) {
                        newState.actual_founded = itm;
                        newState.actual_founded.founded = itm.current_founded;
                        newState.actual_founded.qty = itm.not_founded_qty;
                    }
                });
            });
        } else {
            newState.data = false;
        }

        this.setState(newState);

        if (order.editable && order.other_info && this.state.tableSettings &&
            this.state.tableSettings.hasOwnProperty("vendorWarning") &&
            this.state.tableSettings.vendorWarning
        ) {
            this.setState({warning_open: true, warning_message: order.other_info});
        }
    }

    fetchData(what, callback, errorCallback) {
        this.loading = true;
        LoaderService.getData(what, "", callback, errorCallback);
    }

    newOrderAlertClose() {
        this.setState({alert_open: false});
    }

    warningClose() {
        this.setState({warning_open: false, warning_message: ""});
    }

    newOrderAlertOk() {
        this.reset();
    }

    preventClose(e) {
        e.preventDefault();
    }

    prepareSaveObject(status) {
        let _data = Object.assign(this.state.data, {status});
        if (this.state.labelPrintCount) {
            _data.print_label = this.state.labelPrintCount;
        }
        if (this.state.storage) {
            _data.storage = this.state.storage;
        }

        _data.location = this.state.location;
        _data.save_item_type = this.state.save_item_type;
        _data.suppliers.forEach(sup => {
            sup.items.forEach(itm => {
                if (!itm.hasOwnProperty("founded")) {
                    itm.founded = 0;
                }
                if (!itm.hasOwnProperty("damaged")) {
                    itm.founded = 0;
                }

                if (itm.hasOwnProperty("current") && itm.current) {
                    itm.location = this.state.current_location;
                }
            });
        });

        if (this.state.tableSettings && this.state.tableSettings.partialSave && !this.state.can_save) {
            _data.partial_save =  this.state.tableSettings.saveType;
        } else {
            _data.partial_save =  false;
        }

        if (!_data.hasOwnProperty('uid')) {
            _data.uid = Math.random().toString(36).substring(2);
        }

        return _data;
    }

    saveOrderOk(status) {
        let _itemData = this.prepareSaveObject(status);
        LoaderService.postData("consolidation_item/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id, _itemData, false);

        let actual_founded = this.state.actual_founded;
        if (Number(actual_founded.qty) < Number(actual_founded.original.qty)) {
            actual_founded.qty = Number(actual_founded.original.qty);
            actual_founded.founded += actual_founded.original.founded;
        }

        let _data = this.prepareSaveObject(status);
        LoaderService.postData("consolidation/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id, _data, this.saveOrderSuccess);
    }

    saveItemOk(status) {
        let _data = this.prepareSaveObject(status);
        let justItemInfo = this.state.just_item_info;
        let actualFounded = this.state.actual_founded;
        this.setState({
            previousData: {
                order: Object.assign({}, _data),
                just_item_info: Object.assign({}, justItemInfo),
                actual_founded: Object.assign({}, actualFounded)
            },
        });
        LoaderService.postData("consolidation_item/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id, _data, this.saveOrderSuccess);
    }

    saveOrderSuccess(result) {
        if (result.hasOwnProperty("_embedded")) {
            result._embedded.forEach(res => {
                checkDownloadables(res);
            });
        }

        if (result.hasOwnProperty("_embedded")) {
            this.reset();
        }

        this.setState({labelPrintCount: 1, storage: "", vendorCount: 0});
    }

    cancelSaveOrder() {
        this.setState({save_dialog: false});
    }

    cancelSave() {
        this.setState({save_item_dialog: false});
    }

    notNeedOk() {
        this.setState({is_too_mouch: false});
    }

    notEditableDialogOk() {
        this.setState({notEditableDialog: false});
    }

    orderListCancel() {
        this.setState({order_list_dialog: false});
    }

    closeOrderInfo() {
        this.setState({order_info_dialog: false});
    }

    openOrderInfo() {
        this.setState({order_info_dialog: true});
    }

    searchBarcode(keys) {
        if (keys !== undefined) {
            this.handleClickOrderCode(keys);
        }
    }

    setScannerActive(val) {
        this.setState({scannerActive: val});
    }

    checkTableSettings(pageData) {
        if (pageData.menu_id !== this.menu_id) {
            this.menu_id = pageData.menu_id;
            this.loadTableSettings();
        }
    }

    checkModalAutoFocus() {
        let _self = this;

        setTimeout(() => {
            let okButton = document.querySelector(".button-sonar");
            if (okButton) {
                okButton.focus();
            }

            if (!_self.state.storage) {
                let storageTextField = document.querySelector(".storage-text-field input");
                if (storageTextField) {
                    storageTextField.focus();
                }
            }
        }, 1000);
    }

    /**
     * Increase item founded count
     * @param founded_item
     */
    increaseToFounded(founded_item) {
        if (Number(founded_item.qty) === Number(founded_item.founded)) {
            this.setState({is_too_mouch: true});
            return false;
        }
        founded_item.founded++;
        this.setState({actual_founded: founded_item});
    }

    /**
     * Increase item founded count
     * the 0 is the limit of the minimum
     * @param founded_item
     */
    decreaseToFounded(founded_item) {
        if (founded_item.founded > 0) {
            founded_item.founded--;
            this.setState({actual_founded: founded_item});
        }
    }

    /**
     * Set item founded count by arbitrary value
     * the 0 is the limit of the minimum
     * item.qty is the limit for maximum
     * @param founded_item
     * @param value
     */
    foundedChanged(founded_item, value) {
        if (Number(value) > Number(founded_item.qty)) {
            this.setState({is_too_mouch: true});
        } else if (Number(value) >= 0) {
            founded_item.founded = Number(value);
            this.setState({actual_founded: founded_item});
        }
    }

    deleteLocation(index) {
        let location = this.state.location;
        location.splice(index, 1);
        this.setState({location: location});
    }

    addLocation(newLocation) {
        let location = this.state.location;
        if (location.indexOf(newLocation) > -1) {
            this.setState({current_location: newLocation});
            return false;
        }

        location.push(newLocation);
        this.setState({location: location, current_location: newLocation});
        return true;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            pageData,
            classes,
            acl
        } = this.props;

        return (
          <div className={classes.tableRoot} ref={this.rootRef}>
            <FocusLock>
              <BarcodeReader
                onEnter={this.searchBarcode}
                preventDefault={false}
                disabled={
                  !this.state.scannerActive ||
                  this.state.notEditableDialog ||
                  this.state.save_dialog ||
                  this.state.save_item_dialog ||
                  this.state.order_info_dialog ||
                  this.state.alert_open ||
                  this.state.order_list_dialog ||
                  this.state.is_too_mouch
                }
              />

            {this.state.data && (
                <div style={{background: "#00000012", padding: "4px", textAlign: "center", fontSize: "15px"}}
                     onClick={this.openOrderInfo}>
                    <b>{this.state.data.increment_id}</b>
                </div>
            )}

            {this.state.just_item_info && (
                <ItemInfo founded_item={this.state.just_item_info} layout_type="card" />
            )}

            {this.state.data && (
                <React.Fragment>
                    <Location location={this.state.location}
                              currentLocation={this.state.current_location}
                              onDelete={this.deleteLocation}
                              onAppend={this.addLocation}
                              ref={instance => { this.locationComponent = instance; }}
                    />

                    <Founded founded_item={this.state.actual_founded}
                             foundedChanged={this.foundedChanged}
                             increaseToFounded={this.increaseToFounded}
                             decreaseToFounded={this.decreaseToFounded}/>
                </React.Fragment>
            )}

            {!this.state.data && !this.state.order_list_dialog && (
                <PleaseScan title={pageData.title} onClick={this.toggleShowEnterCode} />
            )}

            <React.Fragment>
                <div style={{marginTop: "6px", padding: "4px", textAlign: "center"}}>
                    {this.state.previousData && (!this.state.data.hasOwnProperty('uid') ||
                        this.state.data.uid !== this.state.previousData.order.uid) && (
                        <Button variant="contained"
                                color="primary"
                                style={{backgroundColor: "#CC0000", width: "25%", marginRight: "10px"}}
                                onClick={this.onClickBackButton}>Vissza</Button>
                    )}

                    {!this.state.previousData || (this.state.data.hasOwnProperty('uid') &&
                        this.state.data.uid === this.state.previousData.order.uid) && (
                        <Button variant="contained"
                                color="primary"
                                disabled={true}
                                style={{width: "25%", marginRight: "10px"}}
                                onClick={this.onClickBackButton}>Vissza</Button>
                    )}

                    {this.state.data && (
                        <React.Fragment>
                            <Button variant="contained"
                                    color="secondary"
                                    style={{backgroundColor: "#BDB76B", width: "25%", marginRight: "10px"}}
                                    onClick={this.onClickDamagedButton}>Sérült</Button>

                            <Button variant="contained"
                                    color="secondary"
                                    style={{backgroundColor: "#009900", width: "40%"}}
                                    onClick={this.onClickSaveButton}>Behelyez</Button>
                        </React.Fragment>
                        )}
                </div>
            </React.Fragment>

            {this.state.order_list_dialog && (
                <OrderSelectTable onSelect={this.loadOrder} orders={this.state.orders}/>
            )}

            <SpeedDial actions={[
                {
                  icon: <SearchIcon />,
                  label: 'Search',
                  condition: true,
                  onClick: () => this.toggleShowEnterCode(),
                  className: !this.state.data && !this.state.enter_code_dialogue ? 'highlight' : '',
                },
                {
                  icon: <InfoIcon />,
                  label: 'Details',
                  condition: this.state.data,
                  onClick: () => this.openOrderInfo(),
                },
                {
                  icon: <AddCircleOutlineIcon />,
                  label: 'New',
                  tooltipTitle: 'New',
                  condition: this.state.data,
                  onClick: () => this.newOrder(),
                },
                {
                  icon: <SettingsIcon />,
                  label: 'Settings',
                  tooltipTitle: 'Settings',
                  condition: acl.hasOwnProperty('admin'),
                  onClick: () => this.setState({ settingsOpen: true }),
                },
              ]}
              isHighlit={(!this.state.data && !this.state.enter_code_dialogue) || this.state.can_save}>
            </SpeedDial>

            <Dialog title='Are you sure to load new product?'
                    open={this.state.alert_open}
                    onClose={this.newOrderAlertClose}
                    onCancel={this.newOrderAlertClose}
                    onOk={this.newOrderAlertOk}
                    okText='Agree'
                    cancelText='Disagree'>
                <DialogContentText>
                  All not saved data for this order wil be lost
                </DialogContentText>
            </Dialog>

              <Dialog
                open={this.state.warning_open}
                onClose={this.warningClose}
                onOk={this.warningClose}
                okText='Ok'
                type='success'>
                <DialogContentText>
                  <div dangerouslySetInnerHTML={{__html: this.state.warning_message}}
                       style={{
                          backgroundColor: 'red',
                          color: 'white',
                          padding: '5px',
                          border: '2px solid white',
                          textAlign: 'left',
                          fontSize: '12px',
                          borderRadius: '5px'
                        }}></div>
                  {this.state.data.shipping_method && (
                    <Typography style={{color: "white"}}>
                      <br/><br/>{ShippingMethodStore.get(this.state.data.shipping_method, 'label')}
                    </Typography>
                  )}
                </DialogContentText>
              </Dialog>

              <OrderInfoDialog
                open={this.state.order_info_dialog}
                onClose={this.closeOrderInfo}
                onCancel={this.closeOrderInfo}
                order={this.state.data}
              />

              <OrderSaveDialog
                allItemsFound={this.state.can_save}
                tableSettings={this.state.tableSettings}
                labelPrintCount={this.state.labelPrintCount}
                storage={this.state.storage}
                location={this.state.location}
                itemCount={this.state.data.item_qty}
                weight={this.state.data.weight}
                postCode={this.state.data.shipping_postcode}
                showStorage={this.state.vendorCount > 1}
                shouldPrintLabels={this.state.shouldPrintLabel}
                setPrintLabelCount={(labelPrintCount) =>
                  this.setState({ labelPrintCount })
                }
                shippingMethod={this.state.data.shipping_method}
                setStorage={(storage) => this.setState({ storage })}
                statuses={this.props.pageData.order_status_to}
                otherInfo={this.state.data.other_info}
                open={this.state.save_dialog}
                onClose={this.preventClose}
                onOk={this.saveOrderOk}
                onCancel={this.cancelSaveOrder}
              />

            <ItemSaveDialog
                location={this.state.location}
                open={this.state.save_item_dialog}
                onClose={this.preventClose}
                onOk={this.saveItemOk}
                onCancel={this.cancelSave}
             />

              <ItemNotNeedDialog
                open={this.state.is_too_mouch}
                onClose={this.preventClose}
                onOk={this.notNeedOk}
              />

              <OrderNotEditableDialog
                open={this.state.notEditableDialog}
                onClose={this.preventClose}
                onOk={this.notEditableDialogOk}
                message={this.state.message}
                status={this.state.status}
              />

              {!this.state.alert_open && !this.state.error_message && (
                <CodeInputDialog
                  open={this.state.enter_code_dialogue}
                  onClose={this.toggleShowEnterCode}
                  onCancel={this.toggleShowEnterCode}
                  hasOrder={true}
                  searchOrder={this.handleClickOrderCode}
                  searchItem={this.handleClickOrderCode}
                />
              )}

              <Dialog
                type={'error'}
                open={!!this.state.error_message}
                okText={'I understand'}
                cancelText={'I understand'}
                onCancel={() => this.setState({ error_message: false })}
                onClose={() => this.setState({ error_message: false })}
              >
                <DialogContentText>
                  <NotInterestedIcon
                    className='blink'
                    style={{ fontSize: '60px' }}
                  />
                </DialogContentText>
                <DialogContentText>
                  {this.state.error_message}
                </DialogContentText>
              </Dialog>

              <Dialog
                open={this.state.settingsOpen}
                title={'Consolidation settings'}
                onClose={() => this.setState({ settingsOpen: false })}
              >
                <Settings
                  settings={this.state.tableSettings}
                  setSettings={(tableSettings) =>
                    this.setState({ tableSettings })
                  }
                  menu_id={this.props.pageData.menu_id}
                  onClose={() => {
                    this.setState({ settingsOpen: false });
                    this.loadTableSettings();
                  }}
                />
              </Dialog>

            </FocusLock>
          </div>
        );
    }
}

ConsolidationItem.propTypes = {
    pageData: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    acl: PropTypes.any,
    showSaveItemDialog: PropTypes.bool
};

ConsolidationItem.defaultProps = {
    url: false,
    classes: {},
    acl: {},
    showSaveItemDialog: true
};

export default ConsolidationItem;
