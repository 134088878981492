/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Ordering Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef";
import {IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class Ordering extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            drag: ""
        };
        this.onMenuSortEnd = this.onMenuSortEnd.bind(this);
        this.getItemClass = this.getItemClass.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    onMenuSortEnd(result) {
        const items = reorder(
            this.props.header,
            result.source.index,
            result.destination.index
        );
        this.props.handleOrderChanged(items);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        this.setState({drag: "" });
        this.onMenuSortEnd(result);
    }

    onDragStart(item) {
        this.setState({drag: item.draggableId });
    }


    getItemClass(item, id) {
        if (!item.visible) {
            return "hidden";
        }
        return this.state.drag === id ? "MuiPaper-elevation12" : "c";
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            header,
            visible
        } = this.props;

        return (
            <Paper square={true} elevation={4} style={{
                maxHeight: "314px",
                width: "100%",
                overflow: "hidden",
                overflowY: "scroll"
            }}>
                {header && visible && (
                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    {/*<List style={getListStyle(snapshot.isDraggingOver)}>*/}
                                    <List>
                                        {Object.keys(header).map((key, index) => (
                                            <Draggable key={key} draggableId={key} index={index}>
                                                {(provided) => (
                                                    <ListItem
                                                        className={this.getItemClass(header[key], key)}
                                                        ContainerComponent="li"
                                                        selected={snapshot.isDragging}
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ListItemIcon>
                                                            <DragIndicatorIcon />
                                                        </ListItemIcon>
                                                        {header[key].name}
                                                        <ListItemSecondaryAction style={{opacity: "0"}}>
                                                            <IconButton>x</IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </List>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </Paper>
        );
    }
}

Ordering.propTypes = {
    header: PropTypes.any,
    visible: PropTypes.bool,
    handleOrderChanged: PropTypes.func,
    location: PropTypes.any,
    classes: PropTypes.any
};

Ordering.defaultProps = {
    header: false,
    header_sort: false,
    classes: {}
};

export default Ordering;