/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Orders component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

// import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";


import UniversalTable from "../../universalTable";


class Users extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            pageData,
            isLoggedIn,
            classes,
            isThemeDark
        } = this.props;

        return (
            <div className={classes.tableRoot}>
                { isLoggedIn  && (
                    <UniversalTable isThemeDark={isThemeDark} acl={acl} canEdit={false} pageData={pageData} url={"sales_order?menu_id=" + pageData.menu_id} data_key={"sales_order"} row_key={"entity_id"}/>
                )}
            </div>
        );
    }
}

Users.propTypes = {
    isLoggedIn: PropTypes.any,
    isThemeDark: PropTypes.any,
    pageData: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    acl: PropTypes.any
};

Users.defaultProps = {
    isLoggedIn: false,
    url: false,
    classes: {}
};

export default Users;