/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0001 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <g>
                <path d="M524.8,71.3L190.6,0.6c-14.1-3-28.2,6.1-31.1,20.3l-6.3,40.8l385.6,81.6l6.3-40.8C548,88.3,538.9,74.3,524.8,71.3z"/>
                <path d="M384.3,151.9c-3.9-14.1-18.6-22.4-32.6-18.5L288.2,151l-149.5-31.6l-16.4,77.7L19.4,225.6C5.4,229.5-2.9,244.2,1,258.2
                l57.9,208.7c3.9,14.1,18.6,22.4,32.6,18.5l332.2-92.2c14.1-3.9,22.4-18.6,18.5-32.6l-9.9-35.8l37.3,7.9c14.1,3,28.2-6.1,31.1-20.3
                L524.2,201L390,172.6L384.3,151.9z M422.6,257.9l8.2-38.8c1.2-5.8,7-9.6,12.8-8.3l38.8,8.2c5.8,1.2,9.6,7,8.3,12.8l-8.2,38.8
                c-1.2,5.8-7,9.6-12.8,8.3l-38.8-8.2C425.1,269.5,421.4,263.8,422.6,257.9z M25.2,246.4l91.9-25.5L334,160.7l23.3-6.5
                c0.4-0.1,0.9-0.2,1.3-0.2c1.9,0,4.1,1.2,4.8,3.6l2.7,9.9l8.6,30.9L33.1,293.3l-11.3-40.8C21.1,249.9,22.6,247.1,25.2,246.4z
                M421.3,366.3c0.4,1.6,0,2.9-0.5,3.7c-0.5,0.8-1.3,1.9-2.9,2.3L85.7,464.6c-0.4,0.1-0.9,0.2-1.3,0.2c-1.9,0-4.1-1.2-4.8-3.6
                L45.9,339.4l341.6-94.8l20.8,75.1L421.3,366.3z"/>
                <path d="M139.4,374.7c-1.6-5.7-7.6-9.1-13.3-7.5l-38.8,10.8c-5.7,1.6-9.1,7.6-7.5,13.3L90.6,430c1.6,5.7,7.6,9.1,13.3,7.5
                l38.8-10.8c5.7-1.6,9.1-7.6,7.5-13.3L139.4,374.7z"/>
            </g>
        </SvgIcon>
    );
}

export default PaymentIcon_0001;