/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * User Role store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class userRoleStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the variable
     * @param code
     * @param variable
     * @returns {*}
     */
    get(code, variable) {
        if (this.data.hasOwnProperty(code) && this.data[code].hasOwnProperty(variable)) {
            return this.data[code][variable];
        }
        return code;
    }

    /**
     * Set all data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const UserRoleStore = new userRoleStore;

export default UserRoleStore;