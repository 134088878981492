/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Fullscreen Loader animation component
 * ---------------
 * - show fullscreen loader animation on load
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Import used services
 */
import FullscreenLoaderService from "../services/FullscreenLoaderService";

class FullscreenLoader extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            showLoader: FullscreenLoaderService.getState()
        };
    }

    componentDidMount() {
        FullscreenLoaderService.on("change", () => {
            this.setState({
                showLoader: FullscreenLoaderService.getState(),
            });
        });
    }

    render() {
        // const { classes} = this.props;

        return <React.Fragment>
            <Fade in={this.state.showLoader}>
                <div className={"fullscreen-loader"}>
                    <CircularProgress disableShrink />
                </div>
            </Fade>
        </React.Fragment>;
    }
}

// Accepts either a single field, or an array of fields
// Note: Reject a Form with no children
FullscreenLoader.propTypes = {
    classes: PropTypes.any
};

FullscreenLoader.defaultProps = {
    classes: {}
};

export default FullscreenLoader;