/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ShopIcon_0004 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M414.1,132.2h-44l-28.6-85.7c-1-2.8-3.6-4.8-6.6-4.8H152.3c-3,0-5.6,1.9-6.6,4.8l-28.6,85.7h-44l33-98.9
            C112.7,13.4,131.3,0,152.3,0h182.6c21,0,39.6,13.4,46.2,33.3L414.1,132.2z M487.2,181v69.6c0,7.7-6.2,13.9-13.9,13.9h-23.9
            l-28.2,185.2c-2.1,13.6-13.8,23.7-27.5,23.7H93.5c-13.8,0-25.5-10-27.5-23.7L37.8,264.5H13.9c-7.7,0-13.9-6.2-13.9-13.9V181
            c0-7.7,6.2-13.9,13.9-13.9h459.4C481,167,487.2,173.3,487.2,181z M167,306.2c0-11.5-9.3-20.9-20.9-20.9c-11.5,0-20.9,9.4-20.9,20.9
            v90.5c0,11.5,9.3,20.9,20.9,20.9c11.5,0,20.9-9.4,20.9-20.9V306.2z M264.5,306.2c0-11.5-9.3-20.9-20.9-20.9
            c-11.5,0-20.9,9.4-20.9,20.9v90.5c0,11.5,9.3,20.9,20.9,20.9c11.5,0,20.9-9.4,20.9-20.9V306.2z M361.9,306.2
            c0-11.5-9.3-20.9-20.9-20.9s-20.9,9.4-20.9,20.9v90.5c0,11.5,9.3,20.9,20.9,20.9s20.9-9.4,20.9-20.9V306.2z"/>
        </SvgIcon>
    );
}

export default ShopIcon_0004;