/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Select carrier component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import CustomSelect from "../customSelect";

class SelectCarrier extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            carriers,
            selectedCarrier,
            changeCarrier
        } = this.props;

        return (
            <Paper
                square={true}
                elevation={1}
                style={{padding: "4px"}}
            >
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} className="text-center">
                        <FormControl>
                            <InputLabel id={"select_carrier"} color="secondary">{'Kiszállító választás'}</InputLabel>

                            <CustomSelect
                                labelId={"select_carrier"}
                                id={"select_carrier"}
                                name="unit"
                                label={'Kiszállító választás'}
                                style={{minWidth: "150px"}}
                                value={selectedCarrier === null ? "" : selectedCarrier}
                                color="secondary"
                                onClose={null}
                                onChange={changeCarrier}>
                                {carriers.map((carrier, index) => (
                                    <MenuItem key={index} value={carrier.carrier_id}>{carrier.name}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

SelectCarrier.propTypes = {
    carriers: PropTypes.any,
    selectedCarrier: PropTypes.any,
    changeCarrier: PropTypes.func,
    classes: PropTypes.any,
};

SelectCarrier.defaultProps = {
    carriers: [],
    selectedCarrier: 0,
    classes: {}
};

export default SelectCarrier;
