/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0003 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M0,158.8l39.4-141l25.2,20.7c93.3-69,189.9-41.8,260.9,53.3c-62.7-72.5-154.1-72.3-219-21l27.1,22.3L0,158.8L0,158.8z
            M112.2,344.4v-134H238v134H137.3H112.2z M313.5,344.4v-134h125.8v134H326.1H313.5z M99.6,186v-60.9H238V186H124.8L99.6,186
            L99.6,186z M313.5,125h138.4v60.9H313.5V125L313.5,125z M263.2,344.4V125h25.2v219.3h-12.6h-12.6V344.4z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0003;