/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Notification Service component
 * ---------------
 * - Show/Hide notifications
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class NotificationService extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();
        this.state = {
            showNotification: false,
            message: "",
        };
    }

    /**
     * Return the notification state
     * @returns {{showNotification: boolean, text: string, title: string}|*}
     */
    getState() {
        return this.state;
    }

    /**
     * Hide notification
     */
    hideNotification() {
        this.state.showNotification = false;
        this.emit("change");
    }

    /**
     * Show notification
     * @param variant
     * @param message
     */
    showNotification(variant, message) {
        this.state = {
            showNotification: true,
            variant: variant,
            message: message
        };
        this.emit("change");
    }
}

const notificationService = new NotificationService;

export default notificationService;