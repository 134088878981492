/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Name and Visibility Settings Row component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";

/**
 * Import other used components
 */
import OnOffSwitch from "../../../switch/OnOffSwitch";

class Row extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            visible: "unset",
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        let result = false;

        if (this.state.name !== nextProps.row.name) {
            this.setState({name: nextProps.row.name});
            result = true;
        }

        if (this.state.visible !== nextProps.row.visible) {
            this.setState({visible: nextProps.row.visible});
            result = true;
        }

        return result;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            row,
            rowKey,
            cahngeCulomnName,
            handleClickCulomnVisibilitySwitch,
        } = this.props;

        return (
            <TableRow
                hover
                selected={false}
            >
                <TableCell align={"left"}>
                    {row.key}
                </TableCell>
                <TableCell align={"left"}>
                    <TextField
                        label="Enter column name"
                        name={row.key + "-column-name"}
                        color="secondary"
                        value={row.name}
                        onChange={(event) => {cahngeCulomnName(event, rowKey);}}
                    />
                </TableCell>
                <TableCell align={"center"}>
                    <OnOffSwitch
                        value="true"
                        color="primary"
                        checked={row.visible}
                        inputProps={{ "aria-label": "Column visibility switch" }}
                        onClick={() => {handleClickCulomnVisibilitySwitch(rowKey);}}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

Row.propTypes = {
    row: PropTypes.any,
    rowKey: PropTypes.any,
    cahngeCulomnName: PropTypes.func,
    handleClickCulomnVisibilitySwitch: PropTypes.func,
    classes: PropTypes.any
};

Row.defaultProps = {
    header: false,
    classes: {}
};

export default Row;