/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ShopIcon_0005 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M464.5,435.5h-29V206.6c-1.4,0.1-2.7,0.2-4.1,0.2H428c-24.8,0-47-11.4-61.7-29.2c-14.7,17.8-36.9,29.2-61.7,29.2h-3.4
            c-24.8,0-47-11.4-61.7-29.2c-14.7,17.8-36.9,29.2-61.7,29.2h-3.4c-24.8,0-47-11.4-61.7-29.2c-14.7,17.8-36.9,29.2-61.7,29.2h-3.4
            c-1.4,0-2.8-0.1-4.1-0.2v228.8h-29C6.5,435.5,0,442,0,450v50.8c0,4,3.3,7.3,7.3,7.3h464.5c4,0,7.3-3.2,7.3-7.3V450
            C479,442,472.5,435.5,464.5,435.5z M232.2,413.7c0,4-3.3,7.3-7.3,7.3H94.4c-4,0-7.3-3.2-7.3-7.3V268.5c0-4,3.3-7.3,7.3-7.3H225
            c4,0,7.3,3.3,7.3,7.3V413.7z M391.9,457.2c0,4-3.3,7.3-7.3,7.3h-87.1c-4,0-7.3-3.2-7.3-7.3V268.5c0-4,3.2-7.3,7.3-7.3h87.1
            c4,0,7.3,3.3,7.3,7.3V457.2z M348.4,352h-14.5c-10,0-18.1,8.1-18.1,18.1c0,10,8.1,18.1,18.1,18.1h14.5c10,0,18.1-8.1,18.1-18.1
            C366.5,360.1,358.4,352,348.4,352z M474.9,105.2V75.1c0-5-1.3-10-3.8-14.4l-26.3-46.1C439.6,5.6,430,0,419.6,0H59.4
            C49,0,39.3,5.6,34.2,14.7L7.9,60.8c-2.5,4.4-3.8,9.3-3.8,14.4v41.9h0v9.9c0,24,19.5,43.5,43.5,43.5h3.4c24,0,43.5-19.5,43.5-43.5
            v-25.4c0-10,8.1-18.1,18.1-18.1s18.1,8.1,18.1,18.1V127c0,24,19.5,43.5,43.5,43.5h3.4c24,0,43.5-19.5,43.5-43.5v-21.8H221v-3.6
            c0-10,8.1-18.1,18.1-18.1c10,0,18.1,8.1,18.1,18.1v15.5h0.4v9.9c0,24,19.5,43.5,43.5,43.5h3.4c24,0,43.5-19.5,43.5-43.5v-25.4
            c0-10,8.1-18.1,18.1-18.1s18.1,8.1,18.1,18.1V127c0,24,19.5,43.5,43.5,43.5h3.4c24,0,43.5-19.5,43.5-43.5L474.9,105.2L474.9,105.2z"
            />
        </SvgIcon>
    );
}

export default ShopIcon_0005;