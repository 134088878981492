/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_3 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 46022-18655 87689-48816 117851-30161 30161-71828 48816-117851 48816-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-17930 139574l-34931-6225c2911-11117 8454-19633 16722-25579 8237-5915 19912-8888 34993-8888 17341 0 29852 3221 37594 9693 7711 6472 11582 14617 11582 24402 0 5729-1580 10931-4707 15576-3128 4645-7835 8733-14152 12232 5141 1270 9073 2756 11767 4459 4428 2725 7835 6317 10281 10746 2446 4459 3685 9755 3685 15917 0 7742-2013 15174-6070 22296-4057 7091-9879 12573-17496 16413-7618 3840-17620 5760-30039 5760-12077 0-21615-1424-28583-4273-6998-2849-12728-6999-17249-12480-4490-5481-7959-12387-10374-20686l36975-4924c1456 7463 3685 12665 6751 15545 3035 2880 6906 4335 11644 4335 4924 0 9043-1827 12356-5450 3282-3623 4924-8454 4924-14524 0-6163-1579-10931-4738-14338-3159-3375-7463-5078-12883-5078-2880 0-6874 712-11922 2168l1920-26415c2013 310 3592 465 4738 465 4769 0 8764-1548 11953-4614 3190-3066 4800-6689 4800-10900 0-4057-1207-7278-3592-9662-2415-2415-5729-3623-9909-3623-4335 0-7835 1301-10560 3933-2694 2602-4521 7185-5481 13719zm124559-79536c-27288-27288-64987-44166-106628-44166S87328 32750 60040 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166 79340-16878 106628-44166 44166-64987 44166-106628-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_3;