/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Fullscreen Loader Service component
 * ---------------
 * - Show/Hide loader animation on loading
 * - Show error messages if not turned off
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class fullscreenLoaderService extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();
        this.state = {
            showLoader: true
        };
    }

    /**
     * Return the loader state
     * @returns {boolean}
     */
    getState() {
        return this.state.showLoader;
    }

    /**
     * Show the loader animation
     */
    showLoader() {
        this.state.showLoader = true;
        this.emit("change");
    }

    /**
     * Hide the loader animation
     */
    hideLoader() {
        this.state.showLoader = false;
        this.emit("change");
    }
}

const FullscreenLoaderService = new fullscreenLoaderService;

export default FullscreenLoaderService;