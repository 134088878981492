/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Responsive Settings Row component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
// import shallowCompare from "react-addons-shallow-compare";
// import propsCompare from "../../../utils/propsCompare";
// import deepCompare from "../../../utils/deepCompare";

/**
 * Import other used components
 */
import OnOffSwitch from "../../../switch/OnOffSwitch";
import {Typography, withStyles} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";

const PrettoSlider = withStyles({
    valueLabel: {
        color: "#888888"
    }
})(Slider);

class Row extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            width: "unset",
            visible: "unset",
            align: "unset",
            font: "unset",
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        let result = false;

        if (this.state.visible !== nextProps.row[this.props.size].visible) {
            this.setState({visible: nextProps.row[this.props.size].visible});
            result = true;
        }

        if (this.state.align !== nextProps.row[this.props.size].align) {
            this.setState({align: nextProps.row[this.props.size].align});
            result = true;
        }

        if (this.state.width !== nextProps.row[this.props.size].width) {
            this.setState({width: nextProps.row[this.props.size].width});
            result = true;
        }

        if (this.state.font !== nextProps.row[this.props.size].font) {
            this.setState({font: nextProps.row[this.props.size].font});
            result = true;
        }
        return result;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            row,
            rowKey,
            size,
            handleClickCulomnVisibilitySwitch,
            handleClickCulomnAligment
        } = this.props;

        return (
            <TableRow
                hover
                selected={false}
            >
                <TableCell align={"left"}>
                    {row.name}
                </TableCell>
                <TableCell align={"center"}>
                    <ToggleButtonGroup
                        size="small"
                        name={row.key + "-column-font"}
                        value={row[size].font}
                        exclusive
                        onChange={(event, newFont) => {handleClickCulomnAligment(event, newFont, rowKey, size, "font");}}
                    >
                        <ToggleButton
                            key={1}
                            value="normal"
                            disabled={!row[size].visible}
                        >
                            <Typography className="table-font-normal" style={{width: "24px"}}>
                                B
                            </Typography>
                        </ToggleButton>,
                        <ToggleButton
                            key={2}
                            value="big"
                            disabled={!row[size].visible}

                        >
                            <Typography className="table-font-big" style={{width: "24px"}}>
                                B
                            </Typography>
                        </ToggleButton>,
                        <ToggleButton
                            key={3}
                            value="small"
                            disabled={!row[size].visible}
                        >
                            <Typography className="table-font-small" style={{width: "24px"}}>
                                B
                            </Typography>
                        </ToggleButton>,
                    </ToggleButtonGroup>
                </TableCell>
                {(size === "xxs" || size === "xs" || size === "s" || size === "m") &&(
                    <TableCell align={"center"}>
                        <PrettoSlider
                            // disabled={header[item.id].desktop.auto_width}
                            valueLabelDisplay="auto"
                            aria-label="discrete-slider"
                            color={"secondary"}
                            name={row.key + "-column-width"}
                            value={row[size].width}
                            // onChange={(event, value) => {this.handleChangeCulomnWidthSwitch(event, value, item.id);}}
                            // onChange={this.handleChangeCulomnWidthSwitch(event, value, item.id)}
                            onChange={(event, value) => {handleClickCulomnAligment(event, value, rowKey, size, "width");}}
                            disabled={!row[size].visible}
                            marks={true}
                            // defaultValue={12}
                            steps={12}
                            min={1}
                            max={12}
                            style={{width: "100%"}}/>
                    </TableCell>
                )}
                <TableCell align={"center"}>
                    <ToggleButtonGroup
                        size="small"
                        name={row.key + "-column-aligment"}
                        value={row[size].align}
                        exclusive
                        onChange={(event, newAlign) => {handleClickCulomnAligment(event, newAlign, rowKey, size, "align");}}
                    >
                        <ToggleButton
                            key={1}
                            value="left"
                            disabled={!row[size].visible}
                        >
                            <FormatAlignLeftIcon />
                        </ToggleButton>,
                        <ToggleButton
                            key={2}
                            value="center"
                            disabled={!row[size].visible}
                        >
                            <FormatAlignCenterIcon />
                        </ToggleButton>,
                        <ToggleButton
                            key={3}
                            value="right"
                            disabled={!row[size].visible}
                        >
                            <FormatAlignRightIcon />
                        </ToggleButton>,
                    </ToggleButtonGroup>
                </TableCell>
                <TableCell align={"center"}>
                    <OnOffSwitch
                        value="true"
                        color="primary"
                        checked={row[size].visible}
                        inputProps={{ "aria-label": "Column visibility switch" }}
                        onClick={() => {handleClickCulomnVisibilitySwitch(rowKey, size);}}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

Row.propTypes = {
    row: PropTypes.any,
    rowKey: PropTypes.any,
    handleClickCulomnVisibilitySwitch: PropTypes.func,
    handleClickCulomnAligment: PropTypes.func,
    size: PropTypes.any,
    classes: PropTypes.any
};

Row.defaultProps = {
    header: false,
    classes: {}
};

export default Row;