/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import RoleStore from "../../../../stores/RoleStore";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grow from "@material-ui/core/Grow";

class EditMenuDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            data: RoleStore.getAll(),
            showPassword: false
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        RoleStore.on("change", () => {
            this.setState({data: RoleStore.getAll()});
        });
    }

    /**
     * Show/Hide password
     */
    handleClickShowPassword(){
        this.setState({"showPassword": !this.state.showPassword});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
        } = this.props;

        return (
            <Dialog
                title={edit ? "Edit user" : "Add new user"}
                open={open}
                onOk={onOk}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
                okText="Save"
                maxWidth="xs"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="name_input"
                                color="secondary"
                            >
                                Name
                            </InputLabel>
                            <Input
                                id="name_input"
                                type={"string"}
                                value={data.username === null || data.username === undefined ? "" : data.username}
                                name="username"
                                color="secondary"
                                autoFocus={true}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="first_name_input"
                                color="secondary"
                            >
                                First name
                            </InputLabel>
                            <Input
                                id="first_name_input"
                                type={"string"}
                                value={data.first_name === null || data.first_name === undefined ? "" : data.first_name}
                                name="first_name"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="last_name_input"
                                color="secondary"
                            >
                                Last name
                            </InputLabel>
                            <Input
                                id="last_name_input"
                                type={"string"}
                                value={data.last_name === null || data.last_name === undefined ? "" : data.last_name}
                                name="last_name"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                id={"role_input"}
                                color="secondary"
                            >Role</InputLabel>
                            <Select
                                labelId={"role_input"}
                                id={"role_input"}
                                name="role_id"
                                style={{minWidth: "150px", textAlign: "left"}}
                                value={data.role_id === null || data.role_id === undefined ? "" : data.role_id}
                                color="secondary"
                                onChange={onChange}
                            >
                                {Object.keys(this.state.data).map((key, i) => (
                                    <MenuItem key={i} value={this.state.data[key].role_id}>{this.state.data[key].role_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {edit && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.change_password}
                                        onChange={onChange}
                                        name="change_password"
                                        value={data.change_password}
                                        color="primary"
                                    />
                                }
                                label="Change password"
                            />
                        </Grid>
                    )}
                    <Grow in={edit && data.change_password || !edit} mountOnEnter unmountOnExit>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="new_password"
                                    color="secondary"
                                >
                                    {edit ? "Change password" : "Password"}
                                </InputLabel>
                                <Input
                                    id="new_password"
                                    type={this.state.showPassword ? "text" : "password"}
                                    value={data.new_password}
                                    name="new_password"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                margin="dense"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword.bind(this)}
                                            >
                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grow>
                    <Grid item xs={12} style={{marginTop: "10px"}} />
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="label_printer_input"
                                color="secondary"
                            >
                                Label printer
                            </InputLabel>
                            <Input
                                id="label_printer_input"
                                type={"string"}
                                value={data.label_printer === null || data.label_printer === undefined ? "" : data.label_printer}
                                name="label_printer"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel
                                htmlFor="document_printer_input"
                                color="secondary"
                            >
                                Document printer
                            </InputLabel>
                            <Input
                                id="document_printer_input"
                                type={"string"}
                                value={data.document_printer === null || data.document_printer === undefined ? "" : data.document_printer}
                                name="document_printer"
                                color="secondary"
                                autoFocus={false}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.show_printer_dialog}
                                    onChange={onChange}
                                    name="show_printer_dialog"
                                    value={data.show_printer_dialog}
                                    color="primary"
                                />
                            }
                            label="Show printer dialog"
                        />
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

EditMenuDialog.propTypes = {
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
};

EditMenuDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditMenuDialog;