/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ShopIcon_0001 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M330.1,496.7c0.4,6.8-5,12.6-11.8,12.6H11.8c-6.8,0-12.3-5.8-11.8-12.6l3.8-58.8c0.2-3.1,2.8-5.5,5.9-5.5h310.6
            c3.1,0,5.7,2.4,5.9,5.5L330.1,496.7z M322.9,384.3c0.4,6.8-5,12.5-11.8,12.5H19c-6.8,0-12.2-5.7-11.8-12.5l15-254.7
            c0.4-6.3,5.6-11.1,11.8-11.1h41C77,47.4,112.6,0,165,0c52.4,0,88,47.4,89.9,118.5h41c6.3,0,11.5,4.9,11.8,11.1L322.9,384.3z
            M108.8,189.5c0-13.1-10.6-23.7-23.7-23.7s-23.7,10.6-23.7,23.7c0,13.1,10.6,23.7,23.7,23.7S108.8,202.6,108.8,189.5z M219.5,118.5
            c-1.3-39.5-16.1-82.9-54.5-82.9c-38.4,0-53.1,43.4-54.5,82.9H219.5z M268.7,195.4c0-13.1-10.6-23.7-23.7-23.7s-23.7,10.6-23.7,23.7
            s10.6,23.7,23.7,23.7S268.7,208.5,268.7,195.4z"/>
        </SvgIcon>
    );
}

export default ShopIcon_0001;