/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Rounded Input component
 * ---------------
 */

/**
 * Import used react components
 */
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles } from "@material-ui/core/styles";

const RoundedIput = withStyles({
    root: {
        borderRadius: "20px !important"
    }
})(OutlinedInput);

export default RoundedIput;
