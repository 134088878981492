/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order layout General Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import Grid from "@material-ui/core/Grid";
import OnOffSwitch from "../../../../switch/OnOffSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";

class OrderViewGeneralSettings extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
        this.getItem = this.getItem.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    getItem(item){
        return this.props.general.hasOwnProperty(item) ? this.props.general[item] : false;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            pageData,
            setGeneralSwitch
        } = this.props;

        return (
            <Paper square={true} elevation={1} style={{marginBottom: "10px", paddingBottom: "15px"}}>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("row_save")}
                                    color="primary"
                                    name="row_save"
                                    checked={this.getItem("row_save").toString() === "true"}
                                    inputProps={{ "aria-label": "Save switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Save"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("row_print")}
                                    color="primary"
                                    name="row_print"
                                    checked={this.getItem("row_print").toString() === "true"}
                                    inputProps={{ "aria-label": "Print switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Print"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("col_name_show")}
                                    color="primary"
                                    name="col_name_show"
                                    checked={this.getItem("col_name_show").toString() === "true"}
                                    inputProps={{ "aria-label": "Col name show switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Show col names"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("col_name_follow")}
                                    color="primary"
                                    name="col_name_follow"
                                    checked={this.getItem("col_name_follow").toString() === "true"}
                                    inputProps={{ "aria-label": "Col name follow switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Col name follow"
                            labelPlacement="end"
                        />
                    </Grid>
                    {pageData.layout === "order" && false && (
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                style={{marginTop: "10px", marginLeft: "5px"}}
                                control={
                                    <OnOffSwitch
                                        value={this.getItem("edit_comment")}
                                        color="primary"
                                        name="edit_comment"
                                        checked={this.getItem("edit_comment").toString() === "true"}
                                        inputProps={{ "aria-label": "Edit comment switch" }}
                                        onClick={(e) => {setGeneralSwitch(e);}}
                                    />
                                }
                                label="Write comment"
                                labelPlacement="end"
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        );
    }
}

OrderViewGeneralSettings.propTypes = {
    header: PropTypes.any,
    general: PropTypes.any,
    visible: PropTypes.bool,
    setGeneralSwitch: PropTypes.func,
    onChangeGeneral: PropTypes.func,
    classes: PropTypes.any,
    pageData: PropTypes.any
};

OrderViewGeneralSettings.defaultProps = {
    header: {},
    general: {},
    classes: {}
};

export default OrderViewGeneralSettings;