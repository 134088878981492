/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Change password dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";

class ChangePasswordDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            data,
        } = this.props;

        return (
            <Dialog
                title={"Change password"}
                open={open}
                onOk={onOk}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
                okText="Save"
                maxWidth="xs"
                primaryAnimation="button-sonar">
                <Grid
                    container
                    spacing={1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                htmlFor="current_password_input"
                                color="secondary">
                                Current password
                            </InputLabel>
                            <Input
                                id="current_password_input"
                                type={"password"}
                                value={data.current_password}
                                name="current_password"
                                color="secondary"
                                autoFocus={true}
                                autoComplete={"off"}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                htmlFor="new_password_input"
                                color="secondary">
                                New password
                            </InputLabel>
                            <Input
                                id="new_password_input"
                                type={"password"}
                                value={data.new_password}
                                name="new_password"
                                color="secondary"
                                autoComplete={"off"}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                htmlFor="new_password_check_input"
                                color="secondary">
                                New password confirmation
                            </InputLabel>
                            <Input
                                id="new_password_check_input"
                                type={"password"}
                                value={data.new_password_check}
                                name="new_password_check"
                                color="secondary"
                                autoComplete={"off"}
                                onChange={onChange}
                            />
                            <div style={{color: "red"}}>&nbsp;
                                {data.new_password_validation}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

ChangePasswordDialog.propTypes = {
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
};

ChangePasswordDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default ChangePasswordDialog;