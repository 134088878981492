/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Settings Data store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class SettingsDataStore extends EventEmitter {
    constructor() {
        super();
        this.reset();
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    reset() {
        this.settings = {
            general: {
                page_reload_dialog: true,
                left_hand: false,
                force_dark_mode: false
            },
            dialog: {
                align_title: "center", // flex-start, center, flex-end
                align_content: "center", // flex-start, center, flex-end
                align_actions: "center" // flex-start, center, flex-end
            },
            consolidation: {
                scroll_to_top_on_scan: true,
                show_product_on_order_list: true,
                auto_load_order: true,
                show_order_list_dialog: false,
                header: {
                    show_order_details: false,
                    show_code_input: false,
                }
            },
            packaging: {
                scroll_to_top_on_scan: true,
                show_product_on_order_list: true,
                auto_load_order: true,
                show_order_list_dialog: false,
                group_by_supplier: false,
                header: {
                    show_order_details: false,
                    show_code_input: false
                }
            },
            control: {
                scroll_to_top_on_scan: true,
                show_product_on_order_list: true,
                auto_load_order: true,
                show_order_list_dialog: false,
                header: {
                    show_order_details: false,
                    show_code_input: false
                }
            },
             toolbarOptions: [ // Configuration for React-Quill Rich Text Editor
                ["bold", "italic", "underline", "strike"],        // toggled buttons
                [{"list": "ordered"}, {"list": "bullet"}],
                [{"script": "sub"}, {"script": "super"}],      // superscript/subscript
                [{"indent": "-1"}, {"indent": "+1"}],          // outdent/indent
                [{"direction": "rtl"}],                         // text direction
                [{"size": ["small", false, "large", "huge"]}],  // custom dropdown
                [{"color": []}, {"background": []}],          // dropdown with defaults from theme
                ["clean"]                                         // remove formatting button
            ]
        };
        this.emit("change");
    }

    getData(variable) {
        return this.settings[variable];
    }

    setData(variable, data) {
        this.settings[variable] = data;
        this.emit("change");
    }
}

const settingsDataStore = new SettingsDataStore;

export default settingsDataStore;