/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_5 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 46022-18655 87689-48816 117851-30161 30161-71828 48816-117851 48816-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-39113 98163h89494v30147h-60611l-3224 20320c4172-1959 8311-3444 12419-4424s8153-1485 12167-1485c13557 0 24585 4108 33023 12324 8437 8185 12672 18518 12672 31000 0 8754-2180 17159-6510 25249-4361 8090-10523 14252-18518 18518s-18234 6383-30685 6383c-8943 0-16622-853-23006-2559-6383-1675-11819-4235-16274-7584-4487-3381-8090-7174-10871-11440s-5088-9575-6952-15958l38111-4172c917 6131 3097 10808 6478 13999 3413 3192 7458 4772 12135 4772 5278 0 9607-1991 13020-5973 3444-3981 5151-9922 5151-17823 0-8090-1738-13999-5182-17760-3444-3760-8058-5656-13778-5656-3634 0-7173 885-10555 2686-2528 1296-5309 3634-8311 7047l-32138-4646 11945-72967zm145742-38125c-27288-27288-64987-44166-106628-44166S87328 32750 60040 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166 79340-16878 106628-44166 44166-64987 44166-106628-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_5;