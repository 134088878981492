/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Not found page component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import LoaderService from "../../../services/Loader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
// import clsx from "clsx";
// import Collapse from "@material-ui/core/Collapse";
// import CardActions from "@material-ui/core/CardActions";
// import IconButton from "@material-ui/core/IconButton";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Divider from "@material-ui/core/Divider";
// import AppsIcon from "@material-ui/icons/Apps";
import {withRouter} from "react-router-dom";
import ViewportStore from "../../../stores/ViewportStore";

class DashboardPage extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            zebra: ViewportStore.get(),
            data: [],
            expanded: {}
        };

        this.fetchData = this.fetchData.bind(this);
        this.loadSuccess = this.loadSuccess.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.isExpanded = this.isExpanded.bind(this);


    }

    componentDidMount() {
        this.fetchData();
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
    }

    fetchData() {
        LoaderService.getData("dashboard", "", this.loadSuccess);
    }

    loadSuccess(response) {
        let expanded = {};

        if (response.hasOwnProperty("_embedded") && response._embedded.hasOwnProperty("dashboard")) {
            response._embedded.dashboard.forEach(function (group, group_index) {
                if (!expanded.hasOwnProperty(group_index)) {
                    expanded[group_index] = {};
                }
                for (let key in group) {
                    if (!expanded[group_index].hasOwnProperty(key)) {
                        expanded[group_index][key] = 0;
                    }
                }
            });
            this.setState({data: response._embedded.dashboard, expanded: expanded});
        }
    }

    handleExpandClick(group_index, index, val) {
        let expanded = this.state.expanded;

        expanded[group_index][index] = expanded[group_index][index] === val ? 0 : val;
        this.forceUpdate();
    }

    isExpanded(group_index, index, item_index) {
        return this.state.expanded[group_index][index] === item_index + 1;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            classes,
        } = this.props;

        return (
            <div className={classes.root}>
                {this.state.data.map((group, group_index) => (
                    <Grid key={group_index}
                          container
                          // spacing={}
                          style={{justifyContent: "center", alignItems: "baseline"}}
                    >
                        {Object.keys(group).map((key, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} className="text-center">
                                    <Typography variant="h6" gutterBottom>
                                        {group[key].title}
                                    </Typography>
                                </Grid>
                                <div className={this.state.zebra ? classes.dashboardCards : classes.dashboardCardsDesktop}>
                                {group[key].items.map((item, item_index) => (
                                        <Card
                                            style={{cursor: "pointer"}}
                                            onClick={() => this.props.history.push(item.menu_id)}
                                            className={classes.dashboardCard + " dashboard-card"} variant="outlined" key={item_index}>
                                            <CardContent>
                                                <Typography variant="h3" align="center">
                                                    {item.count}
                                                </Typography>
                                                <Typography style={{marginRight: "5px"}} align="center">
                                                    {item.label}
                                                </Typography>
                                            </CardContent>
                                            {/*<Divider/>*/}
                                            {/*<CardActions disableSpacing>*/}
                                            {/*    <Typography variant="caption" style={{marginRight: "5px"}}>*/}
                                            {/*        {"Quick action"}*/}
                                            {/*    </Typography>*/}
                                            {/*    <IconButton*/}
                                            {/*        className={clsx(classes.dashboardExpand, {*/}
                                            {/*            [classes.dashboardExpandOpen]: this.isExpanded(group_index, index, item_index),*/}
                                            {/*        })}*/}
                                            {/*        onClick={() => {this.handleExpandClick(group_index, index, item_index + 1);}}*/}
                                            {/*        aria-expanded={this.isExpanded(group_index, index, item_index)}*/}
                                            {/*        aria-label="show more"*/}
                                            {/*    >*/}
                                            {/*        <ExpandMoreIcon />*/}
                                            {/*    </IconButton>*/}
                                            {/*</CardActions>*/}
                                            {/*<Collapse in={this.isExpanded(group_index, index, item_index)} timeout="auto">*/}
                                            {/*    <List>*/}
                                            {/*        <ListItem button >*/}
                                            {/*            <ListItemIcon><AppsIcon /></ListItemIcon>*/}
                                            {/*            <ListItemText primary="Menu item" />*/}
                                            {/*        </ListItem>*/}
                                            {/*        <ListItem button >*/}
                                            {/*            <ListItemIcon><AppsIcon /></ListItemIcon>*/}
                                            {/*            <ListItemText primary="Menu item" />*/}
                                            {/*        </ListItem>*/}
                                            {/*        <ListItem button >*/}
                                            {/*            <ListItemIcon><AppsIcon /></ListItemIcon>*/}
                                            {/*            <ListItemText primary="Menu item" />*/}
                                            {/*        </ListItem>*/}
                                            {/*        <ListItem button >*/}
                                            {/*            <ListItemIcon><AppsIcon /></ListItemIcon>*/}
                                            {/*            <ListItemText primary="Menu item" />*/}
                                            {/*        </ListItem>*/}
                                            {/*    </List>*/}
                                            {/*</Collapse>*/}
                                        </Card>
                                ))}
                                </div>
                            </React.Fragment>
                        ))}
                    </Grid>
                ))}
            </div>
        );
    }
}

DashboardPage.propTypes = {
    location: PropTypes.any,
    classes: PropTypes.any,
    history: PropTypes.any
};

DashboardPage.defaultProps = {
    classes: {}
};

export default withRouter(DashboardPage);