/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0008 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M526.9,125.9v274.6c0,21.8-17.7,39.5-39.5,39.5H249.3c33.8-25.5,55.8-65.8,55.8-111.3
            c0-76.9-62.5-139.4-139.4-139.4c-50.8,0-95.1,27.4-119.5,68V125.9c0-10.6,5.4-26.1,12-34.4l63.4-79.6c6-7.5,15.1-11.9,24.7-11.9
            h280.4c9.6,0,18.7,4.4,24.7,11.9l63.4,79.6C521.5,99.8,526.9,115.3,526.9,125.9z M281.4,328.7c0,63.9-51.8,115.7-115.7,115.7
            c-16.2,0-31.7-3.4-45.7-9.4l-71.4,77.9c-5.5,6-13.1,9.1-20.6,9.1c-6.7,0-13.5-2.4-18.9-7.3c-11.4-10.4-12.2-28.1-1.7-39.5l68-74.3
            c-15.9-19.8-25.4-44.9-25.4-72.2c0-63.9,51.8-115.7,115.7-115.7C229.6,213,281.4,264.8,281.4,328.7z M241.9,328.7
            c0-42.1-34.1-76.2-76.2-76.2c-42.1,0-76.2,34.1-76.2,76.2s34.1,76.2,76.2,76.2C207.8,404.9,241.9,370.8,241.9,328.7z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0008;