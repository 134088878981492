/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0003 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M289.6,294.4c0,48,39.1,87.1,87.1,87.1h89.5V452c0,15.6-12.6,28.3-28.3,28.3H56.5C25.3,480.3,0,455,0,423.8V56.5
            C0,25.3,25.3,0,56.5,0h339c15.6,0,28.3,12.6,28.3,28.3v28.3h-332c-11.7,0-21.2,9.5-21.2,21.2c0,11.7,9.5,21.2,21.2,21.2h346.1
            c15.6,0,28.3,12.6,28.3,28.3v80.1h-89.5C328.7,207.3,289.6,246.4,289.6,294.4z M480.3,249.7v89.5c0,3.9-3.2,7.1-7.1,7.1h-96.5
            c-28.6,0-51.8-23.2-51.8-51.8c0-28.6,23.2-51.8,51.8-51.8h96.5C477.1,242.6,480.3,245.8,480.3,249.7z M409.7,294.4
            c0-11.7-9.5-21.2-21.2-21.2c-11.7,0-21.2,9.5-21.2,21.2c0,11.7,9.5,21.2,21.2,21.2C400.2,315.6,409.7,306.1,409.7,294.4z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0003;