/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Main application component
 * -----------------------------------------------
 * - redirect user to the wanted page
 * - call the layout component
 */

import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import createBrowserHistory from "react-router-dom";

// import { NotFound } from "./pages/List";
import Layout from "./layout";
// import MenuDataStore from "../stores/MenuDataStore";


// import UserDataStore from "../stores/UserDataStore";
// import MenuSettings from "./pages/menu";

// import { SnackbarProvider, useSnackbar } from 'notistack';

// let history = useHistory();
// import PageHandler from "../services/PageHandler";


// import {browserHistory} from "react-router";

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    getComponent() {
    }

    render() {
        return <div>
            <Router>
                <Layout/>
            </Router>
        </div>;
    }
}