/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0005 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M272.4,174.6c-38.6,0-69.9,27.9-69.9,83.8s31.3,83.8,69.9,83.8c38.6,0,69.9-27.9,69.9-83.8S311,174.6,272.4,174.6z
            M125.7,230.8c-15.3,0-27.7,12.4-27.7,27.7c0,15.3,12.4,27.7,27.7,27.7c15.3,0,27.7-12.4,27.7-27.7
            C153.4,243.2,141,230.8,125.7,230.8z M419.2,230.8c-15.3,0-27.7,12.4-27.7,27.7c0,15.3,12.4,27.7,27.7,27.7
            c15.3,0,27.7-12.4,27.7-27.7C446.8,243.2,434.4,230.8,419.2,230.8z M469.4,0H78.2c-11.6,0-21,9.4-21,21c0,11.6,9.4,21,21,21h391.2
            c11.6,0,21-9.4,21-21C490.4,9.4,481,0,469.4,0z M538.6,87.3c-1.1-1.4-2.4-2.7-3.7-3.8c-4.9-4.1-11-6.6-17.9-6.6h-60v0H88v0h-60
            C12.5,76.8,0,89.3,0,104.8v307.4c0,15.4,12.5,27.9,27.9,27.9h489c15.4,0,27.9-12.5,27.9-27.9V104.8
            C544.9,98.1,542.5,92.1,538.6,87.3z M496,328.3c-34.7,0-62.9,28.1-62.9,62.9v0H111.8v0c0-34.7-28.1-62.9-62.9-62.9V188.6
            c34.7,0,62.9-28.1,62.9-62.9h321.4c0,34.7,28.1,62.9,62.9,62.9V328.3z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0005;