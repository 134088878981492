/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import Input from "@material-ui/core/Input";
import Dialog from "../../../dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Typography} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import TableBody from "@material-ui/core/TableBody";

class EditDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};

        window.replacementDialog = this;
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            data,
        } = this.props;

        return (
            <Dialog
                title={"View / change status"}
                open={open}
                onOk={onOk}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
                okText="Save"
                maxWidth="xs"
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <div>
                            ID: {data.id}
                        </div>
                        <div>
                            Lokáció: {data.location}
                        </div>
                    </Grid>

                    {data && data.items && (
                        <Grid item xs={12}>
                            <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                                <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                    <TableRow selected={true}>
                                        <TableCell align={"left"}>
                                            <Typography color="inherit" variant="caption">Megnevezés</Typography>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <Typography color="inherit" variant="caption">BH cikkszám</Typography>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <Typography color="inherit" variant="caption">Menny. db</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {data.items.map((item, item_index) => (
                                        <TableRow
                                            key={"items_" + item_index}
                                            hover
                                            selected={false}
                                            className={"ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                                        >
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                className="ut-xxs-col-12 ut-xs-col-12 ut-s-col-12 ut-m-col-12"
                                            >
                                                <Typography
                                                    className={"tdBefore"}
                                                    color="textSecondary"
                                                    display="block"
                                                    variant="caption"
                                                    style={{textAlign: "left"}}
                                                >
                                                    Megnevezés
                                                </Typography>
                                                {item.name}
                                            </TableCell>
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                className="ut-xxs-col-6 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left"
                                            >
                                                <Typography
                                                    className={"tdBefore"}
                                                    color="textSecondary"
                                                    display="block"
                                                    variant="caption"
                                                    style={{textAlign: "left"}}
                                                >
                                                    BH cikkszám
                                                </Typography>
                                                {item.sku}
                                            </TableCell>
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                style={{textAlign: "right"}}
                                                className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right"
                                            >
                                                <Typography
                                                    className={"tdBefore"}
                                                    color="textSecondary"
                                                    display="block"
                                                    variant="caption"
                                                    style={{textAlign: "right"}}
                                                >
                                                    Mennyiség
                                                </Typography>
                                                {Number(item.qty)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>

                        </Grid>
                    )}

                    {data && data.history && (
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography variant="h6" gutterBottom>
                                {"Status history"}
                            </Typography>
                            {data.history.map((status_item, status_item_index) => (
                                    <div style={{display: "block", clear: "both", textAlign: "left"}} key={status_item_index}>
                                        <Typography variant="inherit" component="div">
                                            {status_item.created_at}
                                        </Typography>
                                        <Typography variant="inherit" component="div">
                                            {status_item.status}
                                        </Typography>
                                        <Typography variant="inherit" component="div" color="textSecondary">
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: status_item.comment }}>
                                                    </span>
                                        </Typography>
                                    </div>
                            ))}
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={12}>
                        <TextField
                            label="Note"
                            name={"comment"}
                            color="secondary"
                            value={this.state.comment}
                            multiline
                            rows="4"
                            fullWidth={true}
                            onChange={(event) => {onChange(event);}}
                        />
                    </Grid>

                    {data && data.status && data.available_statuses && (
                        <Grid item xs={12}>
                        <FormControl
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="label_input" color="secondary">Státusz</InputLabel>
                            <Select
                                labelId={"set_state_label"}
                                id={"set_state_component"}
                                name="status"
                                style={{minWidth: "150px", textAlign: "left"}}
                                value={data.status}
                                color="secondary"
                                onChange={(event) => {onChange(event);}}
                            >
                                <MenuItem key={0} value={0} style={{display: "none"}} />
                                {Object.keys(data.available_statuses).map((key, index) => (
                                    <MenuItem key={index + 1} value={data.available_statuses[key]}>{data.available_statuses[key]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    )}
                </Grid>
            </Dialog>
        );
    }
}

EditDialog.propTypes = {
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
};

EditDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditDialog;