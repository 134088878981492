/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_7 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 92046-74620 166667-166667 166667-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-55676 98054h111351v25746c-9679 8720-17760 18175-24276 28365-7858 12361-14055 26097-18622 41269-3641 11787-6069 25746-7315 41844h-37948c3003-22392 7698-41174 14119-56346 6420-15173 16578-31400 30473-48680h-67782V98054zm162304-38016c-27288-27288-64987-44166-106628-44166S87327 32750 60039 60038s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166c83283 0 150794-67511 150794-150794 0-41641-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_7;