/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0007 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M453.2,345.2L340,434.7c-14.2,11.2-31.8,17.2-49.8,17.1l-29.8-0.3l-107.8-0.9c-2,0-3.9-0.9-5.1-2.5L58.4,335.4
            c-2.3-2.9-1.8-7,1.1-9.3l45.9-36c14-11,31.2-16.9,48.9-16.9l8.7,0l23.9,0l42.5,0l62.2-0.1c18.2,0,33,14.8,33.1,33
            c0,18.2-14.8,33-33,33.1l-56.1,0.1c-9.1,0-16.5,7.4-16.5,16.5c0,9.1,7.4,16.5,16.5,16.5l67-0.1c5.9,0,11.6-2,16.3-5.6l93.5-73.3
            c14.3-11.2,35.1-8.8,46.4,5.5C469.9,313.1,467.5,333.9,453.2,345.2z M119.5,465.9c2.3,2.9,1.8,7-1.1,9.3l-20.7,16.4
            c-2.9,2.3-7,1.8-9.3-1.1l-87-110c-2.3-2.9-1.8-7,1.1-9.3l20.7-16.4c2.9-2.3,7-1.8,9.3,1.1L119.5,465.9z M402.7,116.3
            c0,64.2-52.1,116.3-116.3,116.3c-64.2,0-116.3-52.1-116.3-116.3C170.1,52.1,222.2,0,286.4,0C350.6,0,402.7,52.1,402.7,116.3z
            M275.8,85.4c0.4-2.1,1.7-5.6,6.7-6.1c6.7-0.7,13.2,3.2,16,9.5c3.6,8.4,13.3,12.2,21.7,8.5c8.4-3.6,12.2-13.4,8.6-21.7
            c-4.8-10.9-13.2-19.3-23.6-24.3v-6.1c0-9.1-7.4-16.5-16.5-16.5s-16.5,7.4-16.5,16.5v2.5c-14.9,4-25.9,15.8-28.8,31.6
            c-3.4,18,5.2,35.7,21.4,44l26.9,13.8c4.7,2.4,4.4,7.2,4.2,8.5c-0.4,2.4-1.7,5.3-6.8,6.1c-6.8,0.6-13.2-3.2-16-9.5
            c-3.6-8.4-13.3-12.2-21.7-8.6c-8.4,3.6-12.2,13.4-8.6,21.7c5.7,13,16.5,22.5,29.4,26.8v2.5c0,9.1,7.4,16.5,16.5,16.5
            s16.5-7.4,16.5-16.5v-3.6c12.2-5.3,21-16.4,23.2-30.3c2.9-17.9-5.6-34.9-21.7-43.1L279.9,94C275.2,91.5,275.5,87.1,275.8,85.4z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0007;