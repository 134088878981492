/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0006 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M423.6,431.1l-1.2-0.2c0.1-6.3,0.2-13.1,0.2-21c0-187.9-99.4-248.6-165.7-273.4h-71c-61.6,24.9-161.1,73.1-161.1,273.4
            c0,7.9,0.1,14.7,0.3,21l-1.3,0.2C10.1,433.2,0,445,0,458.9c0,14.5,11,26.6,25.5,28l54.6,2.9c36.2,12,87.1,19.5,143.7,19.5
            s107.5-7.5,143.7-19.5l54.6-2.9c14.4-1.4,25.5-13.5,25.5-28C447.4,445,437.3,433.2,423.6,431.1z M214.7,289l32.3,16.6
            c17.6,9,26.9,27.6,23.8,47.3c-2.6,16.2-13.3,28.9-28,34.3v7.2c0,8.6-7,15.5-15.5,15.5c-8.6,0-15.5-7-15.5-15.5v-6.2
            c-15.6-4.2-29-15.3-35.7-30.8c-3.4-7.9,0.2-17,8.1-20.4c7.9-3.4,17,0.2,20.4,8.1c4,9.3,13.4,15,23.5,14l1.5-0.2
            c8.2-1.3,10.1-7.9,10.6-10.7c0.8-4.9-0.7-11.4-7.3-14.8l-32.3-16.6c-17.8-9.1-27.2-28.5-23.5-48.3c3.4-18.4,16.9-31.7,34.7-35.2
            v-6.5c0-8.6,7-15.5,15.5-15.5c8.6,0,15.5,7,15.5,15.5v10c12.6,5.3,23,15.2,28.7,28.2c3.4,7.9-0.2,17-8.1,20.4
            c-7.9,3.4-17-0.2-20.4-8.1c-4-9.3-13.5-15-23.5-14c-9.4,1-11.5,8.5-11.9,10.8C207.1,276.5,206.3,284.6,214.7,289z M259.5,105.3h-82
            c-4.5-4.4-53.2-80.7-17-102.6c10.9-6.6,29.2-0.2,44.7,14.6c7.5,7.2,19.1,7.2,26.6,0c15.5-14.8,33.9-21.5,44.7-14.6
            C310.7,24.6,264,100.9,259.5,105.3z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0006;