/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Column Responsive Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

/**
 * Import other used components
 */
import Row from "./Row";

class ResponsiveSettings extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            header,
            visible,
            handleClickCulomnVisibilitySwitch,
            handleClickCulomnAligment,
            size
        } = this.props;

        return (
            <React.Fragment>
                {header && visible && (
                    <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                        <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                            <TableRow>
                                <TableCell align={"left"}>
                                    Name
                                </TableCell>
                                <TableCell align={"center"}>
                                    Style
                                </TableCell>
                                {(size === "xxs" || size === "xs" || size === "s" || size === "m") &&(
                                    <TableCell align={"center"}>
                                        Width
                                    </TableCell>
                                )}
                                <TableCell align={"center"}>
                                    Alignment
                                </TableCell>
                                <TableCell align={"center"}>
                                    Visible
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(header).map((key, i) => (
                                <React.Fragment key={i}>
                                    {header[key].visible &&(
                                        <Row
                                            row={header[key]}
                                            rowKey={key}
                                            size={size}
                                            handleClickCulomnVisibilitySwitch={handleClickCulomnVisibilitySwitch}
                                            handleClickCulomnAligment={handleClickCulomnAligment}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </React.Fragment>
        );
    }
}

ResponsiveSettings.propTypes = {
    header: PropTypes.any,
    visible: PropTypes.bool,
    handleClickCulomnVisibilitySwitch: PropTypes.func,
    handleClickCulomnAligment: PropTypes.func,
    size: PropTypes.any,
    classes: PropTypes.any
};

ResponsiveSettings.defaultProps = {
    header: false,
    classes: {}
};

export default ResponsiveSettings;