/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Device and platform information store component
 * -----------------------------------------------
 * - detect is cordova or not
 * - device platform
 * - device UUID
 * - device model
 * - device version (full version not only the major)
 * - is virtual device or not
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

let deviceUUID = "";

class DeviceInformationStore extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();

        /**
         * Set state for cordova platforms
         */
        if (window.hasOwnProperty("cordova")) {
            this.isCordova = true;
            this.platform = "device";
            this.uuid = "sadq34w23zh32j4i879uih23432423"; //TODO: generate unique device UUID;

            // var self = this;
            if (typeof device === "undefined") {
                var device = window.device;
            }

            // alert(device.uuid);
        }

        /**
         * Set state for web
         */
        else {
            this.isCordova = false;
            this.platform = "web";
            this.uuid = "H4k4p3sz1m4k1H4k4p3sz1"; //TODO: generate unique browser UUID;
        }

        deviceUUID = this.uuid;
        /**
         * Bind functions to this
         */
        this.successUUID = this.successUUID.bind(this);
        this.failUUID = this.failUUID.bind(this);
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    successUUID(res){
        deviceUUID = res;
    }

    failUUID(res){
        deviceUUID = "sadq34w23zh32j4i879uih23432423"; // prevent error if no UUID
        console.log("UUID generation fail " + res); //TODO: handle UUID generation error
    }

    /**
     * Get data from store
     * @param name
     * @returns {*}
     */
    getInfo(name){
        if (name === "uuid") {
            return deviceUUID;
        }
        return this[name];
    }
}

const deviceInformationStore = new DeviceInformationStore;

export default deviceInformationStore;