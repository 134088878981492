/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import Dialog from "../dialog";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";


class AddPackageNumber extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            packageCount: 0,
            open: true
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        e && e.preventDefault();
        this.props.addPackageCount(this.state.packageCount);
        this.setState({open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        return (
            <Dialog
                open={this.state.open}
                title={"Add packages to order"}
                onOk={this.onSubmit}
                onCancel={this.props.newOrder}
                onClose={this.props.newOrder}
                okText="Add"
                cancelText="Cancel"
            >
                    <form onSubmit={this.onSubmit}>
                        <TextField
                            autoFocus
                            type={"number"}
                            label="Number of packages"
                            name={"packageCount"}
                            color="secondary"
                            value={this.state.packageCount}
                            fullWidth={true}
                            onChange={(event) => {
                                this.setState({packageCount: Number(event.target.value)});
                            }}
                        />
                    </form>
            </Dialog>

        );
    }
}

AddPackageNumber.propTypes = {
    addPackageCount: PropTypes.func.isRequired,
    newOrder: PropTypes.func.isRequired
};

AddPackageNumber.defaultProps = {};

export default AddPackageNumber;