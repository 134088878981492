/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Select icon dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "../../dialog";
import IconDataStore from "../../../../stores/IconDataStore";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class SelectIconDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            icons: IconDataStore.getAll(),
        };

        this.getIcon = this.getIcon.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    getIcon(icon) {
        let _icon = icon;
        return <_icon/>;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onCancel,
            onChange,
            data
        } = this.props;

        let icons = this.state.icons;

        return (
            <Dialog
                title={"Select icon"}
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Close"
                okText="Save"
                maxWidth="xs"
            >
                <Grid
                    container
                    spacing={1}
                >
                    {Object.keys(icons).map((key, index) => (
                        <Grid item key={index} xs={6} className="icon-preview">
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {onChange(key);}}
                                disabled={data.icon === key}
                            >
                                {this.getIcon(icons[key])}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Dialog>
        );
    }
}

SelectIconDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    data: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
};

SelectIconDialog.defaultProps = {
    open: false,
    false: false,
    classes: {}
};

export default SelectIconDialog;