import React from "react";
import Switch from "../../../switch/OnOffSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import LoaderService from "../../../../../services/Loader";
import userDataStore from "../../../../../stores/UserDataStore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

const Settings = ({menu_id, onClose, settings, setSettings}) => {

    const role_id = userDataStore.getData("user").role_id;

    console.log({settings: settings});

    const saveSettings = () => {
        LoaderService.postData("table_settings/table/" + menu_id + "/role_id/" + role_id, {
            table: menu_id,
            role_id: role_id,
            settings: JSON.stringify({
                settings
            })
        }, () => {
            onClose();
        });
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {settings && <FormControlLabel
                control={
                    <Switch
                        name="vendor_warning"
                        checked={settings.vendorWarning}
                        onChange={() => setSettings({
                            itemConsolidation: settings.itemConsolidation,
                            vendorWarning: !settings.vendorWarning,
                            partialSave: settings.partialSave,
                            saveType: settings.saveType
                        })}/>
                }
                label="Enable vendor warning"
            />}
            {settings && <FormControlLabel
                control={
                    <Switch
                        name="item_consolidation"
                        checked={settings.itemConsolidation}
                        onChange={() => setSettings({
                            itemConsolidation: !settings.itemConsolidation,
                            vendorWarning: settings.vendorWarning,
                            partialSave: settings.partialSave,
                            saveType: settings.saveType
                        })}/>
                }
                label="Load item consolidation"
            />}
            {settings && <FormControlLabel
                control={
                    <Switch
                        name="label_printing"
                        checked={settings.partialSave}
                        onChange={() => setSettings({
                            itemConsolidation: settings.itemConsolidation,
                            vendorWarning: settings.vendorWarning,
                            partialSave: !settings.partialSave,
                            saveType: settings.saveType
                        })}/>
                }
                label="Enable partial consolidation"
            />}
            {settings && settings.partialSave && (
                <FormControl component="fieldset">
                    <RadioGroup name="save_type" value={settings.saveType} onChange={(e) => setSettings({
                        itemConsolidation: settings.itemConsolidation,
                        vendorWarning: settings.vendorWarning,
                        partialSave: settings.partialSave,
                        saveType: e.target.value
                    })}>
                        <FormControlLabel value="save_only" control={<Radio/>} label="Save only"/>
                        <FormControlLabel value="order_modification" control={<Radio/>} label="Order modification"/>
                    </RadioGroup>
                </FormControl>
            )}
            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                <Button variant={"contained"} onClick={saveSettings} color="primary" style={{margin: ".4rem"}}>Save</Button>
                <Button variant={"contained"} onClick={onClose} color="secondary" style={{margin: ".4rem"}}>Cancel</Button>
            </div>
        </div>
    );
};

export default Settings;

Settings.propTypes = {
    menu_id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired
};
