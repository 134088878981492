/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order layout Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import SpeedDial from "../../../speedDial";
import NameAndVisibility from "../../../universalTable/settings/nameAndVisibility";
import ResponsiveSettings from "../../../universalTable/settings/responsiveSettings";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import FullscreenLoaderService from "../../../../../services/FullscreenLoaderService";
import Ordering from "../../../universalTable/settings/ordering";
import {Typography} from "@material-ui/core";
import GeneralSettings from "./general";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

class OrderViewSettings extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            header: false,
            general: false,
            settings_changed: false,
            drag: "",
            active_tab: 0,
            activeStep: 0
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleClickCulomnVisibilitySwitch = this.handleClickCulomnVisibilitySwitch.bind(this);
        this.handleCahngeCulomnName = this.handleCahngeCulomnName.bind(this);
        this.handleClickCulomnAligment = this.handleClickCulomnAligment.bind(this);
        this.handleOrderChanged = this.handleOrderChanged.bind(this);
        this.getSizeName = this.getSizeName.bind(this);
        this.setGeneralSwitch = this.setGeneralSwitch.bind(this);
        this.handleSaveTableSettings = this.handleSaveTableSettings.bind(this);
        this.onChangeGeneral = this.onChangeGeneral.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.setState({
            header: JSON.parse(JSON.stringify(this.props.header)),
            general: JSON.parse(JSON.stringify(this.props.general))
        });
        FullscreenLoaderService.hideLoader();
    }

    handleTabChange(newValue) {
        if (this.state.active_tab === newValue) {
            this.setState({active_tab: false});
            return;
        }
        this.setState({active_tab: newValue});
    }

    handleClickCulomnVisibilitySwitch(id, size) {
        let _header = this.state.header;
        if (size === undefined) {
            _header[id].visible = !_header[id].visible;
        }
        else {
            _header[id][size].visible = !_header[id][size].visible;
        }
        this.forceUpdate();
    }

    handleCahngeCulomnName(event, id) {
        let _header = this.state.header;
        _header[id].name = event.target.value;
        this.forceUpdate();
    }

    handleClickCulomnAligment(event, newValue, id, size, key) {
        let _header = this.state.header;
        _header[id][size][key] = newValue;
        this.forceUpdate();
    }

    handleOrderChanged(new_order) {
        this.setState({header: new_order});
    }

    getSizeName(size) {
        let result = "Screen size ";
        if (size === "xl" || size === "m" || size === "xxs") {
            return result + size.toUpperCase() + " (normal)";
        }

        return result + size.toUpperCase();
    }

    onChangeGeneral(e) {
        if (!this.state.general) return;
        let settings = this.state.general,
            target = e.target.name;

        settings[target] = e.target.value;
        this.forceUpdate();
    }

    setGeneralSwitch(e) {
        let settings = this.state.general,
            target = e.target.name;

        if (!settings.hasOwnProperty(target)) {
            settings[target] = true;
        }
        else {
            settings[target] = !settings[target];
        }

        this.forceUpdate();
    }

    handleSaveTableSettings() {
        this.props.handleSaveTableSettings(this.state.header, this.state.general);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            pageData,
            reloadColumns,
            handleShowHideTableSettings,
        } = this.props;

        const sizes = ["xxl", "xl", "l", "m", "s", "xs", "xxs"];

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    General settings
                </Typography>
                <GeneralSettings
                    header={this.state.header}
                    general={this.state.general}
                    setGeneralSwitch={this.setGeneralSwitch}
                    onChangeGeneral={this.onChangeGeneral}
                    pageData={pageData}
                />
                <Typography variant="h6" gutterBottom>
                    Visibility an ordering
                </Typography>
                <ExpansionPanel
                    key={1}
                    defaultExpanded
                    expanded={this.state.active_tab === 1}
                >
                    <ExpansionPanelSummary
                        expandIcon={
                            <React.Fragment>
                                <ExpandMoreIcon />
                            </React.Fragment>
                        }
                        aria-controls="nameandvisibilit-content"
                        onClick={() => {this.handleTabChange(1);}}
                    >
                        Name an visibility
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        className={classes.details}
                    >
                        <NameAndVisibility
                            header={this.state.header}
                            visible={this.state.active_tab === 1}
                            cahngeCulomnName={this.handleCahngeCulomnName}
                            handleClickCulomnVisibilitySwitch={this.handleClickCulomnVisibilitySwitch}
                            handleClickCulomnAligment={this.handleClickCulomnAligment}
                        />
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {reloadColumns();}}
                        >
                            {"Reload columns"}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <ExpansionPanel
                    key={2}
                    defaultExpanded
                    expanded={this.state.active_tab === 2}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="ordering-content"
                        onClick={() => {this.handleTabChange(2);}}
                    >
                        Ordering
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        className={classes.details}
                    >
                        <Ordering
                            header={this.state.header}
                            visible={this.state.active_tab === 2}
                            cahngeCulomnName={this.handleCahngeCulomnName}
                            handleOrderChanged={this.handleOrderChanged}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                {sizes.map((item, index) => (
                    <React.Fragment key={index}>
                        {item === "xxl" && (
                            <Typography variant="h6" gutterBottom style={{marginTop: "10px"}}>
                                Desktop
                            </Typography>
                        )}
                        {item === "l" && (
                            <Typography variant="h6" gutterBottom style={{marginTop: "10px"}}>
                                Tablet
                            </Typography>
                        )}
                        {item === "xs" && (
                            <Typography variant="h6" gutterBottom style={{marginTop: "10px"}}>
                                Mobile
                            </Typography>
                        )}
                        <ExpansionPanel
                            defaultExpanded
                            expanded={this.state.active_tab === item}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={item + "-content"}
                                onClick={() => {this.handleTabChange(item);}}
                            >
                                {this.getSizeName(item)}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                                className={classes.details}
                                style={{width: "100%"}}
                            >
                                <ResponsiveSettings
                                    header={this.state.header}
                                    visible={this.state.active_tab === item}
                                    size={item}
                                    handleClickCulomnVisibilitySwitch={this.handleClickCulomnVisibilitySwitch}
                                    handleClickCulomnAligment={this.handleClickCulomnAligment}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </React.Fragment>
                ))}
                <SpeedDial>
                    <SpeedDialAction
                        icon={<SaveIcon />}
                        tooltipTitle={"Save"}
                        tooltipOpen
                        onClick={() => {this.handleSaveTableSettings();}}
                    />
                    <SpeedDialAction
                        icon={<CloseIcon />}
                        tooltipTitle={"Close"}
                        tooltipOpen
                        onClick={() => {handleShowHideTableSettings();}}
                    />
                </SpeedDial>
            </React.Fragment>
        );
    }
}

OrderViewSettings.propTypes = {
    url: PropTypes.any,
    acl: PropTypes.any,
    header: PropTypes.any,
    general: PropTypes.any,
    handleShowHideTableSettings: PropTypes.func,
    handleSaveTableSettings: PropTypes.func,
    reloadColumns: PropTypes.func,
    classes: PropTypes.any,
    pageData: PropTypes.any,
};

OrderViewSettings.defaultProps = {
    url: false,
    acl: {},
    header: false,
    classes: {}
};

export default OrderViewSettings;