/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Header component
 * -----------------------------------------------
 * - app top bar functionality
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import UserMenuButton from "./user/menu/button";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import HideOnScroll from "../common/hideOnScroll";
import userDataStore from "../../stores/UserDataStore";
import ChangePasswordDialog from "../pages/users/passwordDialog";
import SelectPrinterDialog from "../pages/printers/selectDialog";
import LoaderService from "../../services/Loader";
import PrinterService from "../../services/Printer";
import PrinterStore from "../../stores/PrinterStore";

class Header extends React.Component {

    constructor(props) {
        super( props );

        this.state = {
            anchorEl: null,
            user: userDataStore.getData("user"),
            open: false,
            actualPage: {id:""},
            user_changed: false,
            password_dialog_open: false,
            password_dialog_data: {
                current_password: "",
                new_password: "",
                new_password_check: "",
                new_password_validation: "",
            },
            printer_dialog_open: false,
            printer_dialog_data: {
                document_printer: "",
                label_printer: "",
                document_printer_name: "",
                label_printer_name: "",
            },
        };

        this.userUpdate = this.userUpdate.bind(this);
        this.handleChangePasswordMenu = this.handleChangePasswordMenu.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onCancelPassword = this.onCancelPassword.bind(this);
        this.onSavePassword = this.onSavePassword.bind(this);
        this.onSavePasswordSuccess = this.onSavePasswordSuccess.bind(this);
        this.initPrinters = this.initPrinters.bind(this);
        this.onChangePrinter = this.onChangePrinter.bind(this);
        this.onCancelPrinter = this.onCancelPrinter.bind(this);
        this.onSavePrinter = this.onSavePrinter.bind(this);
        this.onSavePrinterSuccess = this.onSavePrinterSuccess.bind(this);

        LoaderService.getData("printer", "", this.initPrinters);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        userDataStore.addChangeListener("userChange", this.userUpdate);
    }

    componentWillUnmount() {
        userDataStore.removeChangeListener("userChange", this.userUpdate);
    }

    userUpdate() {
        this.setState({user: userDataStore.getData("user"), user_updated: true});
    }

    shouldComponentUpdate(nextProps, nextState) {
        let result = false;

        if (nextState.user_updated) {
            this.setState({user_updated:false});
            result = true;
        }

        if (this.state.actualPage.id !== nextProps.actualPage.id) {
            this.setState({actualPage: nextProps.actualPage});
            result = true;
        }

        if (this.state.open !== nextProps.open) {
            this.setState({open: nextProps.open});
            result = true;
        }

        if (nextState.anchorEl !== null && this.state.anchorEl === null) {
            this.setState({anchorEl: nextState.anchorEl});
            result = true;
        }
        else if (nextState.anchorEl === null && this.state.anchorEl !== null) {
            this.setState({anchorEl: nextState.anchorEl});
            result = true;
        }

        return result;
    }

    /**
     * Init printers
     * @param result
     */
    initPrinters(result) {
        PrinterService.success(result);

        if (!this.state.user.hasOwnProperty("show_printer_dialog") || this.state.user.show_printer_dialog !== "1") {
            return;
        }

        let documentPrinterCode = PrinterStore.get(this.state.user.document_printer, "barcode"),
            labelPrinterCode = PrinterStore.get(this.state.user.label_printer, "barcode"),
            documentPrinterName = PrinterStore.get(this.state.user.document_printer, "name"),
            labelPrinterName = PrinterStore.get(this.state.user.label_printer, "name");

        let printerDialogData = {
            document_printer: documentPrinterCode,
            label_printer: labelPrinterCode,
            document_printer_name: documentPrinterName,
            label_printer_name: labelPrinterName,
        };

        this.setState({printer_dialog_data: printerDialogData, printer_dialog_open: true});
        this.forceUpdate();

        let labelPrinterInputField = document.getElementById("label_printer_input");
        let documentPrinterInputField = document.getElementById("document_printer_input");

        if (labelPrinterInputField) {
            labelPrinterInputField.focus();
            labelPrinterInputField.setSelectionRange(0, labelPrinterInputField.value.length);
            labelPrinterInputField.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    documentPrinterInputField.focus();
                    documentPrinterInputField.setSelectionRange(0, documentPrinterInputField.value.length);
                }
            });
        }

        if (documentPrinterInputField) {
            documentPrinterInputField.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    labelPrinterInputField.focus();
                    labelPrinterInputField.setSelectionRange(0, labelPrinterInputField.value.length);
                    let okButton = document.querySelector(".button-sonar");
                    if (okButton) {
                        okButton.focus();
                    }
                }
            });
        }
    }

    handleUserMenu(event) {
        this.setState({anchorEl:event.currentTarget});
    }

    handleColseUserMenu(){
        this.setState({anchorEl:null});
    }

    handleChangePasswordMenu() {
        this.setState({password_dialog_open: true, anchorEl:null});
    }

    handleLogout(){
        userDataStore.reset();
    }

    /**
     * Cancel printer
     */
    onCancelPrinter() {
        this.setState({printer_dialog_open: false});
        this.forceUpdate();
    }

    /**
     * Save printer
     */
    onSavePrinter() {
        let inputData = this.state.printer_dialog_data;

        if (inputData.label_printer_name === "" || inputData.document_printer_name === "") {
            return;
        }

        let patchData = {
            label_printer: PrinterStore.getBy(inputData.label_printer, "barcode", "printer_id"),
            document_printer: PrinterStore.getBy(inputData.document_printer, "barcode", "printer_id")
        };

        LoaderService.patchData("user/" + this.state.user.user_id, patchData, this.onSavePrinterSuccess);
        this.onCancelPrinter();
    }

    /**
     * Save printer ajax success callback
     * @param userData
     */
    onSavePrinterSuccess(userData) {
        this.setState({printer_dialog_open: false});
        let currentUser = userDataStore.getData("user");
        currentUser.document_printer = userData.document_printer;
        currentUser.label_printer = userData.label_printer;

        this.forceUpdate();
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChangePrinter(e) {
        let item = this.state.printer_dialog_data,
            target = e.target.name,
            value = e.target.value;

        item[target] = value;
        item[target + "_name"] = PrinterStore.getBy(value, "barcode", "name");

        this.forceUpdate();
    }

    /**
     * Close password change dialog
     */
    onCancelPassword() {
        this.setState({
            password_dialog_open: false,
            password_dialog_data: {
                current_password: "",
                new_password: "",
                new_password_check: "",
                new_password_validation: "",
            }
        });

        this.forceUpdate();
    }

    /**
     * Check password string
     * @param password
     * @return {string}
     */
    checkPassword(password) {
        if (password.length < 8) {
            return "Password must be at least 8 characters long!";
        }

        if (password === password.toLowerCase() || password === password.toUpperCase() || !password.match(/\d+/)) {
            return "The password contains uppercase and lowercase characters and numbers!";
        }

        return "";
    }

    /**
     * Validate and save password
     */
    onSavePassword() {
        let item = this.state.password_dialog_data,
            checkMessage = "";

        if (item.current_password === "") {
            item.new_password_validation = "The current password field cannot be empty!";
            this.forceUpdate();
            return;
        }

        if (item.new_password === "") {
            item.new_password_validation = "The new password field cannot be empty!";
            this.forceUpdate();
            return;
        }

        if (item.new_password !== item.new_password_check) {
            item.new_password_validation = "Password confirmation doesn't match entered password.";
            this.forceUpdate();
            return;
        }

        checkMessage = this.checkPassword(item.new_password);
        if (checkMessage) {
            item.new_password_validation = checkMessage;
            this.forceUpdate();
            return;
        }

        let patchData = {
            current_password: item.current_password,
            new_password: item.new_password
        };

        LoaderService.patchData("password/" + this.state.user.user_id, patchData, this.onSavePasswordSuccess);

        this.onCancelPassword();
    }

    /**
     * Save password ajax success callback
     */
    onSavePasswordSuccess() {
        this.setState({
            password_dialog_open: false,
            password_dialog_data: {
                current_password: "",
                new_password: "",
                new_password_check: "",
                new_password_validation: "",
            }
        });
        this.forceUpdate();
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChangePassword(e) {
        let item = this.state.password_dialog_data,
            target = e.target.name,
            value = e.target.value,
            checkMessage = "";

        item[target] = value;

        if (item.new_password) {
            if (item.new_password_check && item.new_password !== item.new_password_check) {
                checkMessage = "Password confirmation doesn't match entered password.";
            } else {
                checkMessage = this.checkPassword(item.new_password);
            }

            item.new_password_validation = checkMessage;
        }

        this.forceUpdate();
    }

    render() {
        const {
            classes,
            handleClick,
            open
        } = this.props;


        const userMenuOpen = Boolean(this.state.anchorEl);

        return<HideOnScroll window={document.window} threshold={64}>
            <div style={{width: "0"}}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleClick}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6"  className={classes.title + " header-title"} noWrap>
                            {this.props.actualPage.title !== "Loading" ? this.props.actualPage.title : ""}
                        </Typography>
                        {this.state.user.hasOwnProperty("first_name") && (
                            <div>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleUserMenu.bind(this)}
                                    color="inherit"
                                >
                                    <Avatar aria-label="recipe" className={classes.avatar} style={{textTransform: "capitalize"}}>
                                        {this.state.user.first_name.charAt(0)}
                                    </Avatar>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={userMenuOpen}
                                    onClose={this.handleColseUserMenu.bind(this)}
                                >
                                    <Card className={classes.headerUserCard}>
                                        <CardHeader
                                            avatar={
                                                <Avatar aria-label="recipe" className={classes.avatar}>
                                                    {this.state.user.first_name.charAt(0)}
                                                </Avatar>
                                            }
                                            action={
                                                <IconButton aria-label="settings" onClick={this.handleColseUserMenu.bind(this)}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            }
                                            title={this.state.user.username}
                                            subheader={this.state.user.first_name + " " + this.state.user.last_name}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Divider />
                                    <UserMenuButton url={"myaccount"} callback={this.handleColseUserMenu.bind(this)}>My account</UserMenuButton>
                                    <UserMenuButton url={"/"} callback={this.handleChangePasswordMenu.bind(this)}>Change password</UserMenuButton>
                                    <UserMenuButton url={"/"} callback={this.handleLogout.bind(this)}>Log Out</UserMenuButton>
                                </Menu>
                            </div>
                        )}
                        {(
                            <SelectPrinterDialog
                                open={this.state.printer_dialog_open}
                                data={this.state.printer_dialog_data}
                                onOk={this.onSavePrinter}
                                onCancel={this.onCancelPrinter}
                                onClose={this.onCancelPrinter}
                                onChange={this.onChangePrinter}
                            />
                        )}
                        {(
                            <ChangePasswordDialog
                                open={this.state.password_dialog_open}
                                data={this.state.password_dialog_data}
                                onOk={this.onSavePassword}
                                onCancel={this.onCancelPassword}
                                onClose={this.onCancelPassword}
                                onChange={this.onChangePassword}
                            />
                        )}
                    </Toolbar>
                </AppBar>;
            </div>
        </HideOnScroll>;
    }
}

// Accepts either a single field, or an array of fields
// Note: Reject a Form with no children
Header.propTypes = {
    actualPage: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    handleClick: PropTypes.func,
    open: PropTypes.bool
};

Header.defaultProps = {
    classes: {},
    open: false
};

export default Header;