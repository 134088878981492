/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Rounded TextField component
 * ---------------
 */

/**
 * Import used react components
 */
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const RoundedTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
            "& fieldset": {
                borderRadius: "20px",
            },
        },
    },
})(TextField);

export default RoundedTextField;
