/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Input store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class webshopStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the webshop variable
     * @param webshop_id
     * @param variable
     * @returns {*}
     */
    get(webshop_id, variable) {
        if (this.data.hasOwnProperty(webshop_id) && this.data[webshop_id].hasOwnProperty(variable)) {
            return this.data[webshop_id][variable];
        }
        return webshop_id;
    }

    /**
     * Return all data
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Set all suppliers data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const WebshopStore = new webshopStore;

export default WebshopStore;