/**
 * Copyright ©2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order select table component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import OrderInfoDialog from "../orderInfoDialog";
import {Paper} from "@material-ui/core";
import ShippingMethodStore from "../../../../../stores/ShippingMethodStore";

class OrderSelectTable extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            order_list_dialog: false,
            order: {}
        };

        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    closeOrderInfo() {
        this.setState({order_list_dialog: false});
    }

    openOrderInfo(order) {
        this.setState({order: order, order_list_dialog: true});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            onSelect,
            orders
        } = this.props;

        return (
            <Paper>
                <OrderInfoDialog
                    open={this.state.order_list_dialog}
                    onClose={this.closeOrderInfo}
                    onCancel={this.closeOrderInfo}
                    order={this.state.order}
                    showItems={true}
                />
                <List dense={false}>
                    {orders.map((order, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemIcon>
                                    <IconButton
                                        edge="start"
                                        aria-label="info"
                                        onClick={() => {{this.openOrderInfo(order);}}}
                                    >
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText style={{minWidth: "150px"}}>
                                    <React.Fragment>
                                        <Typography variant="body1" style={{float: "left"}}>
                                            {order.increment_id}
                                        </Typography>
                                        <Typography variant={"body1"} style={{float: "right"}}>
                                            {order.created_at_date}
                                        </Typography>
                                        <Typography variant={"body1"} style={{clear: "both"}}>
                                            {order.vendors}
                                        </Typography>
                                        <Typography variant="body1">
                                            {ShippingMethodStore.get(order.shipping_method, "label")}
                                        </Typography>
                                        <Typography variant="body1">
                                            {order.item_founded} / {order.item_qty}
                                        </Typography>
                                    </React.Fragment>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="select"
                                        onClick={() => {{onSelect(order.increment_id);}}}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        );
    }
}

OrderSelectTable.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onSelect: PropTypes.any,
    orders: PropTypes.any
};

OrderSelectTable.defaultProps = {
    open: false,
    orders: []
};

export default OrderSelectTable;