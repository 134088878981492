/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Icon Data store component
 * -----------------------------------------------
 * - store "burned in" url-s
 */

/**
 * Import used react components
 */
import EventEmitter from "events";
import PackageIcon_0001 from "../components/common/icons/package/PackageIcon_0001";
import PackageIcon_0002 from "../components/common/icons/package/PackageIcon_0002";
import PackageIcon_0003 from "../components/common/icons/package/PackageIcon_0003";
import PackageIcon_0004 from "../components/common/icons/package/PackageIcon_0004";
import PackageIcon_0005 from "../components/common/icons/package/PackageIcon_0005";
import PackageIcon_0006 from "../components/common/icons/package/PackageIcon_0006";
import PackageIcon_0007 from "../components/common/icons/package/PackageIcon_0007";
import PackageIcon_0008 from "../components/common/icons/package/PackageIcon_0008";
import PackageIcon_0009 from "../components/common/icons/package/PackageIcon_0009";
import PaymentIcon_0001 from "../components/common/icons/payment/PaymentIcon_0001";
import PaymentIcon_0002 from "../components/common/icons/payment/PaymentIcon_0002";
import PaymentIcon_0003 from "../components/common/icons/payment/PaymentIcon_0003";
import PaymentIcon_0004 from "../components/common/icons/payment/PaymentIcon_0004";
import PaymentIcon_0005 from "../components/common/icons/payment/PaymentIcon_0005";
import PaymentIcon_0006 from "../components/common/icons/payment/PaymentIcon_0006";
import PaymentIcon_0007 from "../components/common/icons/payment/PaymentIcon_0007";
import PaymentIcon_0008 from "../components/common/icons/payment/PaymentIcon_0008";
import ScanIcon_0001 from "../components/common/icons/scan/ScanIcon_0001";
import SettingsIcon_0001 from "../components/common/icons/settings/SettingsIcon_0001";
import ShopIcon_0001 from "../components/common/icons/shop/ShopIcon_0001";
import ShopIcon_0002 from "../components/common/icons/shop/ShopIcon_0001";
import ShopIcon_0003 from "../components/common/icons/shop/ShopIcon_0003";
import ShopIcon_0004 from "../components/common/icons/shop/ShopIcon_0004";
import ShopIcon_0005 from "../components/common/icons/shop/ShopIcon_0005";
import ShopIcon_0006 from "../components/common/icons/shop/ShopIcon_0006";
import NumberIcon_0 from "../components/common/icons/number/NumberIcon_0";
import NumberIcon_1 from "../components/common/icons/number/NumberIcon_1";
import NumberIcon_2 from "../components/common/icons/number/NumberIcon_2";
import NumberIcon_3 from "../components/common/icons/number/NumberIcon_3";
import NumberIcon_4 from "../components/common/icons/number/NumberIcon_4";
import NumberIcon_5 from "../components/common/icons/number/NumberIcon_5";
import NumberIcon_6 from "../components/common/icons/number/NumberIcon_6";
import NumberIcon_7 from "../components/common/icons/number/NumberIcon_7";
import NumberIcon_8 from "../components/common/icons/number/NumberIcon_8";
import NumberIcon_9 from "../components/common/icons/number/NumberIcon_9";

class iconDataStore extends EventEmitter {
    constructor() {
        super();
        this.data = {
            PackageIcon_0001: PackageIcon_0001,
            PackageIcon_0002: PackageIcon_0002,
            PackageIcon_0003: PackageIcon_0003,
            PackageIcon_0004: PackageIcon_0004,
            PackageIcon_0005: PackageIcon_0005,
            PackageIcon_0006: PackageIcon_0006,
            PackageIcon_0007: PackageIcon_0007,
            PackageIcon_0008: PackageIcon_0008,
            PackageIcon_0009: PackageIcon_0009,
            PaymentIcon_0001: PaymentIcon_0001,
            PaymentIcon_0002: PaymentIcon_0002,
            PaymentIcon_0003: PaymentIcon_0003,
            PaymentIcon_0004: PaymentIcon_0004,
            PaymentIcon_0005: PaymentIcon_0005,
            PaymentIcon_0006: PaymentIcon_0006,
            PaymentIcon_0007: PaymentIcon_0007,
            PaymentIcon_0008: PaymentIcon_0008,
            ScanIcon_0001: ScanIcon_0001,
            SettingsIcon_0001: SettingsIcon_0001,
            ShopIcon_0001: ShopIcon_0001,
            ShopIcon_0002: ShopIcon_0002,
            ShopIcon_0003: ShopIcon_0003,
            ShopIcon_0004: ShopIcon_0004,
            ShopIcon_0005: ShopIcon_0005,
            ShopIcon_0006: ShopIcon_0006,
            NumberIcon_0: NumberIcon_0,
            NumberIcon_1: NumberIcon_1,
            NumberIcon_2: NumberIcon_2,
            NumberIcon_3: NumberIcon_3,
            NumberIcon_4: NumberIcon_4,
            NumberIcon_5: NumberIcon_5,
            NumberIcon_6: NumberIcon_6,
            NumberIcon_7: NumberIcon_7,
            NumberIcon_8: NumberIcon_8,
            NumberIcon_9: NumberIcon_9
        };
    }

    getAll() {
        return this.data;
    }

    get(resource) {
        // return this.data["ShopIcon_0002"]; //TODO this icon has an error
        return resource === undefined || resource === "" || resource === null ? ShopIcon_0006 : this.data[resource];
    }
}

const IconDataStore = new iconDataStore;

export default IconDataStore;