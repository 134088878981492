/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order status history component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import loaderService from "../../../../../../services/Loader";
import OrderStatusStore from "../../../../../../stores/OrderStatusStore";

class StatusHistory extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            order_info_status: {}
        };

        this.getStatusText = this.getStatusText.bind(this);
        this.orderInfoStatusListSuccess = this.orderInfoStatusListSuccess.bind(this);

        loaderService.getData("sales_order_available_status", props.order.entity_id, this.orderInfoStatusListSuccess);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    orderInfoStatusListSuccess(res) {
        if (res.hasOwnProperty("0")) {
            this.setState({order_info_status: res["0"]});
        }
    }

    getStatusText(item) {
        return OrderStatusStore.get(item, "label");
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { order } = this.props;
        // @TODO későbbi továbbfejlesztéshez ezt itt hagyom
        const general = {};
        const orderStatusList = {};

        return (
            <Grid item xs={12} md={4} >
                <Grid
                    container
                    spacing={2}
                    style={{alignItems: "flex-end"}}
                >
                    {(general.hasOwnProperty("edit_comment") && general.edit_comment === true) && (
                        <Grid item xs={12} sm={6} md={12}>
                            <TextField
                                label="Note (for merchant)"
                                name={"comment"}
                                color="secondary"
                                value={this.state.comment}
                                multiline
                                rows="4"
                                fullWidth={true}
                                onChange={(event) => {this.onChange(event);}}
                            />
                        </Grid>
                    )}
                    {(general.hasOwnProperty("change_status") && general.change_status === true) && (
                        <Grid item xs={12} sm={6} md={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    id={"set_state_label"}
                                    color="secondary"
                                >Set state</InputLabel>
                                <Select
                                    labelId={"set_state_label"}
                                    id={"set_state_component"}
                                    name="status"
                                    style={{minWidth: "150px", textAlign: "left"}}
                                    value={this.state.status}
                                    color="secondary"
                                    onChange={(event) => {this.onChange(event);}}
                                >
                                    <MenuItem key={0} value={0} style={{display: "none"}} />
                                    {Object.keys(orderStatusList).map((key, index) => (
                                        <MenuItem key={index + 1} value={key}>{orderStatusList[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {((general.hasOwnProperty("change_status") && general.change_status === true) || (general.hasOwnProperty("edit_comment") && general.edit_comment === true)) && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={false}
                                onClick={() => {this.saveComment();}}
                                disabled={this.state.comment === "" && this.state.status === this.props.order.status}
                            >
                                {"Save"}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {"Status history"}
                        </Typography>
                        {order.status_history.map((status_item, status_item_index) => (
                            <Card
                                key={status_item_index}
                                variant="outlined"
                                style={{margin: "10px 0"}}
                            >
                                <CardContent >
                                    <Typography variant="inherit" component="div">
                                        {status_item.created_at}
                                    </Typography>
                                    <Typography variant="inherit" component="div">
                                        {this.getStatusText(status_item.status)}
                                    </Typography>
                                    <Typography variant="inherit" component="div" color="textSecondary">
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: status_item.comment }}>
                                                    </span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

StatusHistory.propTypes = {
    order: PropTypes.any
};

StatusHistory.defaultProps = {
    order: {}
};

export default StatusHistory;