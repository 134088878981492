/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Suppliers component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Orders from "../orders";
import SupplierStore from "../../../../../stores/SupplierStore";

class Suppliers extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            show_lines: true
        };

        this.blurElements = this.blurElements.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    blurElements() {
        setTimeout(function () {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            window.focus();
        }, 100);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            classes,
            suppliers,
            group,
            title,
            // titleDone,
            getFounded,
            getDifference,
            getRowClassname,
            show_done
        } = this.props;

        return (
            <React.Fragment>
                {group === false && (
                    <ExpansionPanel
                        defaultExpanded
                        onChange={this.blurElements}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="orders-content"
                            id="orders-expansion-header"
                        >
                            <Typography
                                align="center"
                                variant="h6"
                            >
                                {title}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            className={classes.details}
                        >
                            <Orders showDone={show_done} group={group} tableIndex={0} items={suppliers}
                                    getRowClassname={getRowClassname} getFounded={getFounded}
                                    getDifference={getDifference}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                {group !== false && (
                    <React.Fragment>
                        {suppliers.map((supplier, index) => (
                            <ExpansionPanel
                                style={{background: "#00000012", padding: 0, overflow: "scroll"}}
                                defaultExpanded
                                key={index}
                                onChange={this.blurElements}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="orders-content"
                                    id="orders-expansion-header"
                                    style={{height: "24px"}}
                                >
                                    <Typography
                                        style={{width: "100%", fontSize: "20px"}}
                                        align="center"
                                    >
                                        <b>{SupplierStore.get(supplier.supplier_id, "name")}</b>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    style={{padding: 0, overflow: "hidden"}}
                                    className={classes.details}
                                >
                                    <Orders showDone={show_done} tableIndex={index} items={supplier.items}
                                            getRowClassname={getRowClassname} getFounded={getFounded}
                                            getDifference={getDifference}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}

                    </React.Fragment>
                )}



                {/*<ExpansionPanel*/}
                {/*    defaultExpanded*/}
                {/*    onChange={this.blurElements}*/}
                {/*>*/}
                {/*    <ExpansionPanelSummary*/}
                {/*        expandIcon={<ExpandMoreIcon/>}*/}
                {/*        aria-controls="orders-done-content"*/}
                {/*        id="orders-done-expansion-header"*/}
                {/*    >*/}
                {/*        <Typography*/}
                {/*            align="center"*/}
                {/*            variant="h6"*/}
                {/*        >*/}
                {/*            {titleDone}*/}
                {/*        </Typography>*/}
                {/*    </ExpansionPanelSummary>*/}
                {/*    <ExpansionPanelDetails*/}
                {/*        className={classes.details}*/}
                {/*    >*/}
                {/*        <Orders showDone={true} group={false} tableIndex={0} items={suppliers}*/}
                {/*                getRowClassname={getRowClassname} getFounded={getFounded}*/}
                {/*                getDifference={getDifference}/>*/}
                {/*    </ExpansionPanelDetails>*/}
                {/*</ExpansionPanel>*/}
            </React.Fragment>
        );
    }
}

Suppliers.propTypes = {
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    suppliers: PropTypes.array,
    getFounded: PropTypes.func,
    getDifference: PropTypes.func,
    getRowClassname: PropTypes.func,
    group: PropTypes.any,
    title: PropTypes.any,
    titleDone: PropTypes.any,
    ACL: PropTypes.any,
    show_done: PropTypes.bool,
    closeDone: PropTypes.func
};

Suppliers.defaultProps = {
    url: false,
    group: true,
    title: "",
    classes: {},
    suppliers: []
};

export default Suppliers;