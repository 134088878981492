import React from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

const UserMenuButton = (props) => {
    let history = useHistory(),
        url = props.url,
        callback = props.callback;

    if (url) {
        if (url === "/") { url = ""; }
        return <MenuItem onClick={() => {history.push("/" + url); {callback && (callback());}}}>{props.children}</MenuItem>;
    }
    return <MenuItem onClick={() => {{callback && (callback());}}}>{props.children}</MenuItem>;

};

UserMenuButton.propTypes = {
    url: PropTypes.any,
    children: PropTypes.any,
    callback: PropTypes.any
};

UserMenuButton.defaultProps = {
    url: false,
    callback: false
};

export default UserMenuButton;