/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Component Data store component
 * -----------------------------------------------
 * - store "burned in" url-s
 */

/**
 * Import used react components
 */
import EventEmitter from "events";
import Consolidation from "../components/common/dynamicPages/consolidation";
import Packaging from "../components/common/dynamicPages/packaging";
import Control from "../components/common/dynamicPages/control";
import Order from "../components/common/dynamicPages/order";
import Orders from "../components/common/dynamicPages/orders";
import Suppliers from "../components/pages/suppliers";
import Webshops from "../components/pages/webshops";
import Carriers from "../components/pages/carriers";
import ShippingMethods from "../components/pages/shippingMethods";
import PaymentMethods from "../components/pages/paymentMethods";
import OrderStatuses from "../components/pages/orderStatuses";
import Users from "../components/pages/users";
import UserGroups from "../components/pages/userGroups";
import Printers from "../components/pages/printers";
import MenuSettings from "../components/pages/menu";
import NotFoundPage from "../components/pages/notFound";
import LoadingPage from "../components/pages/loading";
import DashboardPage from "../components/pages/dashboard";
import ConsolidationItem from "../components/common/dynamicPages/consolidationItem";
import Replacement from "../components/common/dynamicPages/replacement";

class layoutDataStore extends EventEmitter {
    constructor() {
        super();
        this.data = {
            dashboard: {title: "Dashboard", component: DashboardPage, type: "static"},
            consolidation: {title: "Consolidation", component: Consolidation, type: "dynamic"},
            consolidation_item: {title: "Consolidation Item", component: ConsolidationItem, type: "dynamic"},
            replacement: {title: "Replacement", component: Replacement, type: "dynamic"},
            packaging: {title: "Packaging", component: Packaging, type: "dynamic"},
            control: {title: "Control", component: Control, type: "dynamic"},
            order: {title: "Order", component: Order, type: "dynamic"},
            orders: {title: "Orders", component: Orders, type: "dynamic"},
            suppliers: {title: "Suppliers", component: Suppliers, type: "static"},
            webshops: {title: "Webshops", component: Webshops, type: "static"},
            carriers: {title: "Carriers", component: Carriers, type: "static"},
            shipping_methods: {title: "Shipping methods", component: ShippingMethods, type: "static"},
            payment_methods: {title: "Payment methods", component: PaymentMethods, type: "static"},
            order_statuses: {title: "Order statuses", component: OrderStatuses, type: "static"},
            users: {title: "Users", component: Users, type: "static"},
            user_groups: {title: "User groups", component: UserGroups, type: "static"},
            menu: {title: "Menu settings", component: MenuSettings, type: "static"},
            printers: {title: "Printers", component: Printers, type: "static"}
        };
    }

    getAll() {
        return this.data;
    }

    getDefault() {
        return {title: "Page not found", resource: "not_found", component: NotFoundPage, type: "static"};
    }

    getLoading() {
        return {title: "Loading", resource: "loading", component: LoadingPage, type: "static"};
    }

    get(resource) {
        if (this.data.hasOwnProperty(resource)) {
            return this.data[resource];
        }
        return {title: "Page not found", resource: "not_found", component: NotFoundPage, type: "static"};
    }

    setData(variable, data) {
        this.data[variable] = data;
        this.emit("change");
    }
}

const LayoutDataStore = new layoutDataStore;

export default LayoutDataStore;