/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Printer Service component
 * ---------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

/**
 * Import used stores
 */
import PrinterStore from "../stores/PrinterStore";

/**
 * Import used services
 */
import LoaderService from "./Loader";

class Printer extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();

        this.timer = false;
        this.state = {};

        /**
         * Bind functions to this
         */
        this.success = this.success.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {

    }

    /**
     * Set things when component is unmounting
     */
    componentWillUnmount(){
        this.stop();
    }

    /**
     * Start service
     */
    start() {
        if (this.timer !== false) { return; }

        this.fetchData();
    }

    /**
     * Stop the service
     */
    stop() {
        clearInterval(this.timer);
        this.timer = false;
    }

    /**
     * Fetch the data
     */
    fetchData() {
        LoaderService.getData("printer", "", this.success);
    }

    /**
     * Handle success data
     * @param result
     */
    success(result) {
        if (result.hasOwnProperty("_embedded") && result._embedded.hasOwnProperty("printer")) {
            let items = {};

            result._embedded.printer.forEach(function (item) {
                items[item.printer_id] = item;
            });
            PrinterStore.set(items);
        }
    }
}

const PrinterService = new Printer;

export default PrinterService;