/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Menu Icon component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import Icon from "@material-ui/core/Icon";
import IconDataStore from "../../../../stores/IconDataStore";
// import ShopIcon_0001 from "../../common/icons/shop/ShopIcon_0001";

class MenuIcon extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            icon: "unset",
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        let result = false;

        if (this.state.icon !== nextProps.icon) {
            this.setState({icon: nextProps.icon});
            result = true;
        }

        return result;
    }

    getIcon(icon) {
        let _icon = IconDataStore.get(icon);
        return <_icon/>;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            icon,
        } = this.props;

        return (
            <Icon classes={{root: classes.iconRoot}}>
                {this.getIcon(icon)}
            </Icon>
        );
    }
}

MenuIcon.propTypes = {
    icon: PropTypes.any,
    classes: PropTypes.any
};

MenuIcon.defaultProps = {
    classes: {}
};

export default MenuIcon;