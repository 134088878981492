/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grow from "@material-ui/core/Grow";
import LayoutDataStore from "../../../../stores/LayoutDataStore";
import Button from "@material-ui/core/Button";
import SelectIconDialog from "../../../common/settings/selectIconDialog";
import OrderStatusStore from "../../../../stores/OrderStatusStore";

class EditMenuDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            order_status: OrderStatusStore.getAll(),
            layouts: LayoutDataStore.getAll(),
            dialog_open: false
        };

        this.statusAvailable = this.statusAvailable.bind(this);
        this.selectIconDialogOpen = this.selectIconDialogOpen.bind(this);
        this.selectIconDialogClose = this.selectIconDialogClose.bind(this);
        this.orderStatusUpdate = this.orderStatusUpdate.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        OrderStatusStore.addChangeListener("change", this.orderStatusUpdate);
    }

    componentWillUnmount() {
        OrderStatusStore.removeChangeListener("change", this.orderStatusUpdate);
    }

    orderStatusUpdate() {
        this.setState({order_status: OrderStatusStore.getAll()});
    }

    statusAvailable(item) {
        switch (item.layout) {
        case "consolidation":
        case "consolidation_item":
        case "packaging":
        case "control":
        case "orders":
        case "order":
            return true;
        default:
            return false;
        }
    }

    selectIconDialogOpen() {
        this.setState({dialog_open: true});
    }

    selectIconDialogClose() {
        this.setState({dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
            onIconSelected
        } = this.props;

        let layouts = this.state.layouts;

        if (JSON.stringify(data)=== "{}") {
            return null;
        }

        return (
            <React.Fragment>
                <Dialog
                    title={edit ? "Edit menu item" : "Add new menu item"}
                    open={open}
                    onOk={onOk}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Cancel"
                    okText="Save"
                    maxWidth="xs"
                >
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="menu_title_input"
                                    color="secondary"
                                >
                                    Title
                                </InputLabel>
                                <Input
                                    id="menu_title_input"
                                    type={"string"}
                                    value={data.title === null || data.title === undefined ? "" : data.title}
                                    name="title"
                                    color="secondary"
                                    autoFocus={true}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.selectIconDialogOpen();}}
                            >
                                Select icon
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="menu_name_input"
                                    color="secondary"
                                >
                                    Menu id
                                </InputLabel>
                                <Input
                                    id="menu_name_input"
                                    type={"string"}
                                    value={data.menu_id === null || data.menu_id === undefined ? "" : data.menu_id}
                                    name="menu_id"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.is_group}
                                        onChange={onChange}
                                        name="is_group"
                                        value={data.is_group ? "true" : "false"}
                                        color="primary"
                                    />
                                }
                                label="Menu group"
                            />
                        </Grid>
                        <Grow
                            in={!data.is_group}
                            mountOnEnter unmountOnExit
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        htmlFor="menu_url_input"
                                        color="secondary"
                                    >
                                        Resource
                                    </InputLabel>
                                    <Input
                                        id="menu_url_input"
                                        type={"text"}
                                        value={data.resource === null || data.resource === undefined || data.resource === "menu_group" ? "" : data.resource}
                                        name="resource"
                                        color="secondary"
                                        autoFocus={false}
                                        onChange={onChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grow>
                        <Grow
                            in={!data.is_group}
                            mountOnEnter unmountOnExit
                        >
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"menu_select_component"}
                                        color="secondary"
                                    >Select layout</InputLabel>
                                    <Select
                                        labelId={"menu_select_component"}
                                        id={"menu_select_component"}
                                        name="layout"
                                        style={{minWidth: "150px", textAlign: "left"}}
                                        value={data.layout === null || data.layout === undefined || data.layout === "menu_group" ? [] : data.layout}
                                        color="secondary"
                                        onChange={onChange}
                                    >
                                        {Object.keys(layouts).map((key, i) => (
                                            <MenuItem key={i} value={key}>{layouts[key].title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grow>
                        <Grow
                            in={!data.is_group && this.statusAvailable(data)}
                            mountOnEnter unmountOnExit
                        >
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"menu_from_input"}
                                        color="secondary"
                                    >Status from</InputLabel>
                                    <Select
                                        style={{minWidth: "150px", textAlign: "left", minHeight: "30px"}}
                                        name={"order_status_from"}
                                        multiple
                                        value={data.order_status_from  || []}
                                        onChange={this.props.onChange}
                                        input={<Input />}
                                        renderValue={(selected) => selected.map(item =>
                                            item ? (
                                                <div key={item} style={{display: "block", marginTop: "4px"}}>
                                                    <Chip label={this.state.order_status[item] && this.state.order_status[item].label}/>
                                                </div>) : "")}
                                    >
                                        {Object.keys(this.state.order_status).map((key, i) => (
                                            <MenuItem key={i} value={+this.state.order_status[key].status_id}>
                                                <Checkbox checked={data.order_status_from && data.order_status_from.includes(+this.state.order_status[key].status_id)} />
                                                {this.state.order_status[key] && this.state.order_status[key].label}
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grow>
                        <Grow
                            in={!data.is_group && this.statusAvailable(data)}
                            mountOnEnter unmountOnExit
                        >
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"menu_to_input"}
                                        color="secondary"
                                    >Status to</InputLabel>
                                    <Select
                                        style={{minWidth: "150px", textAlign: "left", minHeight: "30px"}}
                                        name={"order_status_to"}
                                        id={"menu_to_input"}
                                        multiple
                                        value={data.order_status_to || []}
                                        onChange={this.props.onChange}
                                        input={<Input />}
                                        renderValue={(selected) => selected.filter(item => item).map(item => item
                                            && <div key={item} style={{display: "block", marginTop: "4px"}}>
                                                <Chip label={this.state.order_status[item] && this.state.order_status[item].label}/>
                                        </div>)}
                                    >
                                        {Object.keys(this.state.order_status).map((key, i) => (
                                            <MenuItem key={i} value={+this.state.order_status[key].status_id}>
                                                <Checkbox checked={data.order_status_to && data.order_status_to.includes(+this.state.order_status[key].status_id)} />
                                                {this.state.order_status[key].label}
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grow>
                    </Grid>
                </Dialog>
                <SelectIconDialog
                    classes={classes}
                    data={data}
                    open={this.state.dialog_open}
                    onCancel={this.selectIconDialogClose}
                    onClose={this.selectIconDialogClose}
                    onChange={onIconSelected}
                />
            </React.Fragment>
        );
    }
}

EditMenuDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onIconSelected: PropTypes.any
};

EditMenuDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditMenuDialog;