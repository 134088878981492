/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0001 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M276.7,68l-139.4,52.3l2.3-35C57.7,97.3-5.1,163.8,4.7,259.1C-15.8,142.2,30.6,53.2,144.8,32.6L147,0L276.7,68L276.7,68z
            M258,423l111.7,88.8l8.6-31.7C494.6,479.3,560.2,400,563,281.5c-9.1,95.4-81.5,150.2-164.4,148.1l9.2-34L258,423L258,423z
            M122.6,365.6v-134h125.8v134H147.7H122.6z M323.9,365.6v-134h125.8v134H336.5H323.9z M110,207.2v-60.9h138.4v60.9H135.2L110,207.2
            L110,207.2z M323.9,146.2h138.4v60.9H323.9V146.2L323.9,146.2z M273.6,365.6V146.2h25.2v219.3h-12.6h-12.6V365.6z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0001;