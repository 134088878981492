/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Menu Item component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItemButton from "./MenuItemButton";
import MenuIcon from "../menuIcon";

class MenuItem extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            pathname: "unset",
            resource: "unset",
            menu_id: "unset",
            icon: "unset",
            title: "unset",
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        let result = false;

        if (this.state.pathname !== nextProps.pathname) {
            this.setState({pathname: nextProps.pathname});
            result = true;
        }

        if (this.state.resource !== nextProps.item.resource) {
            this.setState({resource: nextProps.item.resource});
            result = true;
        }

        if (this.state.menu_id !== nextProps.item.menu_id) {
            this.setState({menu_id: nextProps.item.menu_id});
            result = true;
        }

        if (this.state.icon !== nextProps.item.icon) {
            this.setState({icon: nextProps.item.icon});
            result = true;
        }

        if (this.state.title !== nextProps.item.title) {
            this.setState({title: nextProps.item.title});
            result = true;
        }

        return result;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            pathname,
            item,
            handleDrawerToggle
        } = this.props;

        return (
            <MenuItemButton
                url={item.resource}
                callback={handleDrawerToggle}
                id={item.menu_id}
                pathname={pathname}
            >
                <ListItemIcon>
                    <MenuIcon
                        classes={classes}
                        icon={item.icon}
                    />
                </ListItemIcon>
                <ListItemText className={item.title.length > 19 ? "scroll" : ""} primary={item.title} />
            </MenuItemButton>
        );
    }
}

MenuItem.propTypes = {
    handleDrawerToggle: PropTypes.func,
    item: PropTypes.any,
    pathname: PropTypes.any,
    classes: PropTypes.any
};

MenuItem.defaultProps = {
    classes: {}
};

export default MenuItem;