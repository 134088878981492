/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Column Responsive Settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";

/**
 * Import other used components
 */
import Grid from "@material-ui/core/Grid";
import OnOffSwitch from "../../../switch/OnOffSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsDataStore from "../../../../../stores/SettingsDataStore";

class GeneralSettings extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
        };

        this.getItem = this.getItem.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    getItem(item){
        if (!this.props.general.hasOwnProperty(item)) {
            return false;
        }

        return this.props.general[item];
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            pageData,
            setGeneralSwitch,
            onChangeGeneral
        } = this.props;

        return (
            <Paper square={true} elevation={1} style={{marginBottom: "10px", paddingBottom: "15px"}}>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("row_print")}
                                    color="primary"
                                    name="row_print"
                                    checked={this.getItem("row_print").toString() === "true" ? true : false}
                                    inputProps={{ "aria-label": "Row print switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Row print"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("row_save")}
                                    color="primary"
                                    name="row_save"
                                    checked={this.getItem("row_save").toString() === "true" ? true : false}
                                    inputProps={{ "aria-label": "Row save switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Row save"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("page_print")}
                                    color="primary"
                                    name="page_print"
                                    checked={this.getItem("page_print").toString() === "true" ? true : false}
                                    inputProps={{ "aria-label": "Page print switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Page print"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("col_name_show")}
                                    color="primary"
                                    name="col_name_show"
                                    checked={this.getItem("col_name_show").toString() === "true" ? true : false}
                                    inputProps={{ "aria-label": "Col name show switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Show col names"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            style={{marginTop: "10px", marginLeft: "5px"}}
                            control={
                                <OnOffSwitch
                                    value={this.getItem("col_name_follow")}
                                    color="primary"
                                    name="col_name_follow"
                                    checked={this.getItem("col_name_follow").toString() === "true" ? true : false}
                                    inputProps={{ "aria-label": "Col name follow switch" }}
                                    onClick={(e) => {setGeneralSwitch(e);}}
                                />
                            }
                            label="Col name follow"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl
                            fullWidth={false}
                            style={{width: "150px", marginLeft: "15px", marginRight: "15px"}}
                        >
                            <InputLabel
                                id={"auto_refresh_input"}
                                color="secondary"
                            >Auto refresh</InputLabel>
                            <Select
                                labelId={"auto_refresh_input"}
                                id={"auto_refresh_input"}
                                name="auto_refresh"
                                style={{textAlign: "left"}}
                                value={this.getItem("auto_refresh") === false ? "0" : this.getItem("auto_refresh")}
                                color="secondary"
                                onChange={onChangeGeneral}
                            >
                                <MenuItem value="0">disabled</MenuItem>
                                <MenuItem value="10">10 sec</MenuItem>
                                <MenuItem value="30">30 sec</MenuItem>
                                <MenuItem value="60">1 min</MenuItem>
                                <MenuItem value="120">2 min</MenuItem>
                                <MenuItem value="240">4 min</MenuItem>
                                <MenuItem value="600">10 min</MenuItem>
                                <MenuItem value="900">15 min</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {pageData.layout === "orders" && (
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                style={{marginTop: "10px", marginLeft: "5px"}}
                                control={
                                    <OnOffSwitch
                                        value={this.getItem("edit_comment")}
                                        color="primary"
                                        name="edit_comment"
                                        checked={this.getItem("edit_comment").toString() === "true" ? true : false}
                                        inputProps={{ "aria-label": "Edit comment switch" }}
                                        onClick={(e) => {setGeneralSwitch(e);}}
                                    />
                                }
                                label="Write comment"
                                labelPlacement="end"
                            />
                        </Grid>
                    )}
                    {pageData.layout === "orders" && (
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                style={{marginTop: "10px", marginLeft: "5px"}}
                                control={
                                    <OnOffSwitch
                                        value={this.getItem("change_status")}
                                        color="primary"
                                        name="change_status"
                                        checked={this.getItem("change_status").toString() === "true" ? true : false}
                                        inputProps={{ "aria-label": "Change status switch" }}
                                        onClick={(e) => {setGeneralSwitch(e);}}
                                    />
                                }
                                label="Change Status"
                                labelPlacement="end"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <div style={{padding: "1rem"}}>
                            <Typography variant={"subtitle1"}>PDF Header From:</Typography>
                            <ReactQuill
                                options={SettingsDataStore.getData("toolbarOptions")}
                                value={this.getItem("pdf_header_from") || ""}
                                name={"pdf_header_from"}
                                onChange={value => onChangeGeneral({target: {name: "pdf_header_from", value}})}
                            />
                            <Typography variant={"subtitle2"}>Variables: &#123;documentId&#125;, &#123;date&#125;</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{padding: "1rem"}}>
                            <Typography variant={"subtitle1"}>PDF Header To:</Typography>
                            <ReactQuill
                                options={SettingsDataStore.getData("toolbarOptions")}
                                value={this.getItem("pdf_header_to") || ""}
                                name={"pdf_header_from"}
                                onChange={value => onChangeGeneral({target: {name: "pdf_header_to", value}})}
                            />
                            <Typography variant={"subtitle2"}>Variables: &#123;documentId&#125;, &#123;date&#125;</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

GeneralSettings.propTypes = {
    header: PropTypes.any,
    general: PropTypes.any,
    visible: PropTypes.bool,
    setGeneralSwitch: PropTypes.func,
    onChangeGeneral: PropTypes.func,
    classes: PropTypes.any,
    pageData: PropTypes.any
};

GeneralSettings.defaultProps = {
    header: {},
    general: {},
    classes: {}
};

export default GeneralSettings;