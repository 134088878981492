/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0006 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M409.3,61.1L156.7,179.9v76.3l-44.1-19.6v-76.3L365.3,41.5L280.4,3.8c-11.4-5.1-29.9-5.1-41.3,0L30.2,96.7
            C13.5,104.1,0,125,0,143.3L0.5,361c0,18.4,13.9,39.4,30.9,46.6l208.4,89.1c11,4.7,29,4.7,40,0l208.4-89.1
            c16.9-7.2,30.8-28.2,30.9-46.6l0.5-217.8c0-18.2-13.5-39.2-30.2-46.6L409.3,61.1z M472.3,235.4l-12.2,5.7l-0.2,86
            c0,3.8-3.3,8.2-7.4,10l-18.3,7.9c-4,1.7-7.3,0.1-7.3-3.6l-0.1-84.9L415,262c-5.6,2.6-9.1-1.5-6.3-7.3L437,195c2.9-6,10-9.5,12.9-6.2
            l29,33.1C481.8,225.2,478.1,232.7,472.3,235.4z M352.8,373.6c0,3.6-3,7.8-6.8,9.5l-17,7.3c-3.7,1.6-6.8,0-6.8-3.5l-1-81.3l-11,5.1
            c-5.2,2.4-8.5-1.5-5.9-7.1l25.7-56.8c2.6-5.7,9.2-8.9,11.9-5.8l27.4,32.1c2.7,3.2-0.6,10.4-6,12.9l-11.3,5.3L352.8,373.6z
            M492.5,350c0,1.9-1.7,4.1-3.8,5l-193.9,81.4c-1.8,0.8-3.3,0-3.3-1.8l-0.1-9.5c0-1.7,1.4-3.8,3.2-4.6l194.2-82.7
            c2.1-0.9,3.8-0.1,3.8,1.8L492.5,350z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0006;