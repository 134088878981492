/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit status list dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import SpeedDial from "../../speedDial";
import Dialog from "../../dialog";
import EditDialog from "./EditDialog";


class ConfigDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
            dialog_open: false,
            is_edit: false,
            actual_edit: {key: "", label: ""},
            original_key: false
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Edit item
     * @param key
     */
    onEdit(key) {
        if (key !== undefined) {
            this.setState({original_key: key, actual_edit: {key: key,  label: this.props.data[key]}, dialog_open: true, is_edit: true});
        }
        else {
            this.setState({original_key: false, actual_edit: {key: "", label: ""}, dialog_open: true, is_edit: false});
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.actual_edit,
            target = e.target.name,
            value = e.target.value;

        item[target] = value;
        this.forceUpdate();
    }

    onCancel() {
        this.setState({original_key: false, actual_edit: {key: "", label: ""}, dialog_open: false, is_edit: false});
    }

    onSave() {
        this.props.onConfigurationChanged({original_key: this.state.original_key, key: this.state.actual_edit.key, label: this.state.actual_edit.label});
        this.onCancel();
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onOk,
            onClose,
            onCancel,
            data,
        } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    title={"Config"}
                    open={open}
                    onOk={onOk}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Close"
                    okText="Save"
                    maxWidth="md"
                >
                    <Paper square={true} elevation={1}>
                        <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={true}>
                            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                <TableRow selected={true}>
                                    <TableCell style={{width: "80px"}} />
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Key</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Value</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data !== undefined && (Object.keys(data).map((key, i) => (
                                    <TableRow hover key={i} selected={false}>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {{this.onEdit(key);}}}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {key}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {data[key]}
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <SpeedDial
                        onClick={() => {{this.onEdit();}}}
                    />
                </Dialog>
                <EditDialog
                    open={this.state.dialog_open}
                    edit={this.state.is_edit}
                    data={this.state.actual_edit}
                    onOk={this.onSave}
                    onCancel={this.onCancel}
                    onClose={this.onCancel}
                    onChange={this.onChange}
                />
            </React.Fragment>
        );
    }
}

ConfigDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onConfigurationChanged: PropTypes.any,
};

ConfigDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {},
};

export default ConfigDialog;