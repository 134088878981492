/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0009 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M270.5,116.5v223.7l178.1-33.8v-109c0-4.6,4-8.1,8.5-7.6l21.9,3.4c3.9,0.4,6.8,3.7,6.8,7.6v126.6
            c0,3.6-2.5,6.7-6,7.5l-194.9,41.9c-9.4,2-19.2,2-28.6,0.1L63.4,336.3c-3.5-0.7-6-3.7-6.1-7.3L54,200.4c-0.1-4,2.9-7.5,7-7.8
            l163.8-14.4c2.8-0.2,5.3-2.1,6.4-4.7l24.7-59.9C259,105.8,270.5,108.1,270.5,116.5z M52.1,43.2L0.7,153.1
            c-2.5,5.4,1.8,11.4,7.7,10.8l193.1-19.5c2.7-0.3,5.1-2,6.2-4.5L269.8,0L57.7,39C55.2,39.4,53.2,41,52.1,43.2z M269.8,0l65.7,144.3
            c1.1,2.5,3.6,4.2,6.3,4.4l192.7,15.5c5.8,0.5,10-5.5,7.6-10.8L491.9,43.3c-1-2.3-3.1-3.9-5.6-4.3L269.8,0z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0009;