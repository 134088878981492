import React from "react";
import Grow from "@material-ui/core/Grow";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";


const OrdersTable = (items, isGroup,showDone, getRowClassname, getDifference, getFounded, tableIndex, show_lines, getEAN ) => {
    if (isGroup) {
        return (<React.Fragment>
            {items.map((item, index) => (
                <React.Fragment key={item.item_id}>
                    {(showDone && getRowClassname(item) === " green" || !showDone && getRowClassname(item) !== " green") && (
                        <Grow
                            in={show_lines}
                            {...(show_lines ? {timeout: 100 * index} : {})}>
                            <TableRow
                                hover
                                selected={false}
                                className={getRowClassname(item) + " ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                            >
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    className="ut-xxs-col-6 ut-xs-col-6 ut-s-col-6 ut-m-col-6"
                                >
                                    {/*<Typography*/}
                                    {/*    className={"tdBefore"}*/}
                                    {/*    color="textSecondary"*/}
                                    {/*    display="block"*/}
                                    {/*    variant="caption"*/}
                                    {/*    style={{textAlign: "left"}}*/}
                                    {/*>*/}
                                    {/*    EAN*/}
                                    {/*</Typography>*/}
                                    <Typography
                                        color="inherit"
                                        variant="inherit"
                                    >{getEAN(item.ean)} </Typography>
                                </TableCell>
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right"
                                >
                                    {/*<Typography*/}
                                    {/*    className={"tdBefore"}*/}
                                    {/*    color="textSecondary"*/}
                                    {/*    display="block"*/}
                                    {/*    variant="caption"*/}
                                    {/*    style={{textAlign: "left"}}*/}
                                    {/*>*/}
                                    {/*    BH cikkszám*/}
                                    {/*</Typography>*/}
                                    {item.sku}
                                </TableCell>
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    className="ut-xxs-col-6 ut-xs-col-6 ut-s-col-6 ut-m-col-6 clip-text"
                                >
                                    {/*<Typography*/}
                                    {/*    className={"tdBefore"}*/}
                                    {/*    color="textSecondary"*/}
                                    {/*    display="block"*/}
                                    {/*    variant="caption"*/}
                                    {/*    style={{textAlign: "left"}}*/}
                                    {/*>*/}
                                    {/*    Megnevezés*/}
                                    {/*</Typography>*/}
                                    <span style={{
                                        // whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>
                                                {item.name}
                                            </span>
                                </TableCell>
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    align={"right"}
                                    className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                >
                                    <Typography
                                        className={"tdBefore small"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "left"}}
                                    >
                                        Rendelt
                                    </Typography>
                                    {Number(item.qty)}
                                </TableCell>
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    align={"right"}
                                    className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                >
                                    <Typography
                                        className={"tdBefore small"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "left"}}
                                    >
                                        Fellelt
                                    </Typography>
                                    {Number(getFounded(tableIndex, index))}
                                </TableCell>
                                <TableCell
                                    // align={header[item.id].align}
                                    component="td"
                                    scope="row"
                                    align={"right"}
                                    className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                >
                                    <Typography
                                        className={"tdBefore small"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "left"}}
                                    >
                                        Eltérés
                                    </Typography>
                                    {Number(getDifference(tableIndex, index))}
                                </TableCell>
                            </TableRow>
                        </Grow>
                    )}
                </React.Fragment>
            ))}
        </React.Fragment>);
    }

    return <React.Fragment>
        {items.map((supplier, supplier_index) => (
            <React.Fragment key={"supplier-" + supplier_index}>
                {supplier.items.map((item, index) => (
                    <React.Fragment key={item.item_id}>
                        {(showDone && getRowClassname(item) === " green" || !showDone && getRowClassname(item) !== " green") && (
                            <Grow
                                in={show_lines}
                                {...(show_lines ? {timeout: 100 * index} : {})}>
                                <TableRow
                                    hover
                                    selected={false}
                                    className={getRowClassname(item) + " ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                                >
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        className="ut-xxs-col-6 ut-xs-col-6 ut-s-col-6 ut-m-col-6"
                                    >
                                        {/*<Typography*/}
                                        {/*    className={"tdBefore"}*/}
                                        {/*    color="textSecondary"*/}
                                        {/*    display="block"*/}
                                        {/*    variant="caption"*/}
                                        {/*    style={{textAlign: "left"}}*/}
                                        {/*>*/}
                                        {/*    EAN*/}
                                        {/*</Typography>*/}
                                        <Typography
                                            color="inherit"
                                            variant="inherit"
                                        >{getEAN(item.ean)} </Typography>
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right"
                                    >
                                        {/*<Typography*/}
                                        {/*    className={"tdBefore"}*/}
                                        {/*    color="textSecondary"*/}
                                        {/*    display="block"*/}
                                        {/*    variant="caption"*/}
                                        {/*    style={{textAlign: "left"}}*/}
                                        {/*>*/}
                                        {/*    BH cikkszám*/}
                                        {/*</Typography>*/}
                                        {item.sku}
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        className="ut-xxs-col-6 ut-xs-col-6 ut-s-col-6 ut-m-col-6 clip-text"
                                    >
                                        {/*<Typography*/}
                                        {/*    className={"tdBefore"}*/}
                                        {/*    color="textSecondary"*/}
                                        {/*    display="block"*/}
                                        {/*    variant="caption"*/}
                                        {/*    style={{textAlign: "left"}}*/}
                                        {/*>*/}
                                        {/*    Megnevezés*/}
                                        {/*</Typography>*/}
                                        {item.name}
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        align={"right"}
                                        className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                    >
                                        <Typography
                                            className={"tdBefore small"}
                                            color="textSecondary"
                                            display="block"
                                            variant="caption"
                                            style={{textAlign: "left"}}
                                        >
                                            Rendelt
                                        </Typography>
                                        {Number(item.qty)}
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        align={"right"}
                                        className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                    >
                                        <Typography
                                            className={"tdBefore small"}
                                            color="textSecondary"
                                            display="block"
                                            variant="caption"
                                            style={{textAlign: "left"}}
                                        >
                                            Fellelt
                                        </Typography>
                                        {Number(getFounded(tableIndex, index))}
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        align={"right"}
                                        className="ut-xxs-col-2 ut-xxs-center ut-xs-col-2 ut-xs-center ut-s-col-2 ut-s-center ut-m-col-2 ut-m-center"
                                    >
                                        <Typography
                                            className={"tdBefore small"}
                                            color="textSecondary"
                                            display="block"
                                            variant="caption"
                                            style={{textAlign: "left"}}
                                        >
                                            Eltérés
                                        </Typography>
                                        {Number(getDifference(tableIndex, index))}
                                    </TableCell>
                                </TableRow>
                            </Grow>
                        )}
                    </React.Fragment>
                ))}
            </React.Fragment>
        ))}
    </React.Fragment>;
};

export default OrdersTable;