/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Item save dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "../../../dialog";
import Typography from "@material-ui/core/Typography";

class ItemSaveDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            selected_status: "",
            pathname: document.location.pathname,
        };
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {

    }

    /**
     * Set things when component is update
     */
    componentDidUpdate() {

    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onOk,
            onCancel,
            location
        } = this.props;

        return (
            <Dialog
                title="The item is ready to save"
                open={open}
                onClose={onClose}
                onOk={() => onOk(this.state.selected_status)}
                onCancel={onCancel}
                primaryAnimation="button-sonar"
                okText="Save item"
                cancelText="Cancel"
                type="success"
            >
                {location && (
                    <DialogContentText>
                        <Typography style={{color: 'white'}}>Lokáció: {location.join(", ")}</Typography>
                    </DialogContentText>
                )}
            </Dialog>
        );
    }
}

ItemSaveDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onOk: PropTypes.any,
    onCancel: PropTypes.any,
    location: PropTypes.array
};

ItemSaveDialog.defaultProps = {
    open: false,
    location: []
};

export default ItemSaveDialog;