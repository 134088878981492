/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0005 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M2.8,89c-9.6-29.2,6.3-60.8,35.5-70.4s60.8,6.3,70.4,35.5l109.5,332c-10.2-2.7-21.1-3.2-31.9-1.2L80.3,63.5
            C75.8,49.9,61.2,42.6,47.7,47c-13.5,4.5-20.9,19.1-16.4,32.6c2.6,7.8-1.7,16.3-9.5,18.9C13.9,101.1,5.4,96.9,2.8,89z M434.4,349.5
            l-179.5,59.2c6.4,7.2,11.4,15.8,14.6,25.5c0.1,0.3,0.2,0.7,0.3,1l174-57.4c7.8-2.6,12.1-11,9.5-18.9
            C450.7,351.2,442.3,346.9,434.4,349.5z M181.1,401.5c-30.8,10.2-47.6,43.4-37.4,74.2c10.2,30.8,43.4,47.6,74.2,37.4
            c30.8-10.2,47.6-43.4,37.4-74.2C245.2,408.1,212,391.4,181.1,401.5z M445.3,318.4l-191.8,63.3c-3.9,1.3-8.2-0.8-9.4-4.8l-60.9-184.7
            c-1.3-3.9,0.8-8.2,4.8-9.4l191.8-63.3c3.9-1.3,8.2,0.8,9.4,4.8L450,309C451.3,312.9,449.2,317.1,445.3,318.4z M363.7,231.6
            l-65.3-35.9l-31.1,67.7l20.6,9.5l13.4-29.3l24.3,73.6l23.2-7.7l-24.3-73.6l28.2,15.5L363.7,231.6z M306.7,121.4l-39-118.1
            c-0.8-2.5-3.5-3.9-6-3l-33.4,11l-0.2,0.3l-0.3-0.2l-17.6,5.8l14.5,44l-19.9,6.6l-14.5-44l-17.6,5.8l-0.1,0.3l-0.3-0.1l-33.2,11
            c-2.5,0.8-3.9,3.5-3,6l39,118.1c0.8,2.5,3.5,3.9,6,3l122.6-40.5C306.2,126.6,307.5,123.9,306.7,121.4z"/>
        </SvgIcon>
    );
}

export default PackageIcon_0005;