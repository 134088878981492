/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Application layout component
 * -----------------------------------------------
 * - generate layout theme
 * - show default components
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import {withStyles, createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import { compose } from "recompose";


import CssBaseline from "@material-ui/core/CssBaseline";

import { amber, green } from "@material-ui/core/colors";
import Loader from "../Loader";
import Login from "../login";
import Notificator from "../Notification";
import MenuDataStore from "../../stores/MenuDataStore";
import LayoutDataStore from "../../stores/LayoutDataStore";
import FullscreenLoader from "../FullscreenLoader";
import FullscreenLoaderService from "../../services/FullscreenLoaderService";
import MenuDrawer from "../menuDrawer";
import userDataStore from "../../stores/UserDataStore";
import SettingsDataStore from "../../stores/SettingsDataStore";
import {ErrorBoundary} from "../pages/error";


const {matches} = window.matchMedia("(max-width: 400px)");
const drawerWidth = matches ? window.innerWidth : 300;

let isDark = false;

function getTheme() {
    if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
        isDark = false;
        return false;
    }
    else {
        isDark = true;
        return true;
    }
}

function getScrollbarColor() {
    return isDark ? "0,0,0" : "255,255,255";
}

const styles = theme => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
            height: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(" + getScrollbarColor() + ",0.00)"
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(" + getScrollbarColor() + ",.1)",
            outline: "1px solid slategrey"
        }
    },
    orderInfoCard: {
        margin: "10px",
    },
    menu_logo_button: {
        backgroundColor: "unset !important"
    },
    root: {
        display: "flex",
        flexGrow: 1
    },
    dashboardCards: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
    },
    dashboardCardsDesktop: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr"
    },
    dashboardCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 .4rem",
        height: "8rem",
        margin: ".2rem"
    },
    dashboardExpand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    dashboardExpandOpen: {
        transform: "rotate(180deg)",
    },
    dividerFullWidth: {
        margin: "5px 0 0 ${theme.spacing(2)}px",
    },
    dividerInset: {
        margin: "5px 0 0 ${theme.spacing(9)}px",
    },
    searchRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: "fit-content",
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchIconButton: {
        padding: 10,
    },
    searchDivider: {
        height: 28,
        margin: 4,
        display: "inline-block"
    },
    menuNestedElement: {
        paddingLeft: theme.spacing(4),
    },
    paperRoot: {
        padding: theme.spacing(3, 2),
    },
    headerUserCard: {
        maxWidth: 345,
        boxShadow: "unset"
    },
    progressBarRoot: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        "& .MuiSvgIcon-root": {
            fontSize: "2rem"
        }
    },
    title: {
        flexGrow: 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        overflow: "auto",
        padding: "0 24px"

    },
    imageIcon: {
        height: "100%"
    },
    iconRoot: {
        textAlign: "center"
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    notificationIcon: {
        fontSize: 20,
    },
    notificationIconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    notificationMessage: {
        display: "flex",
        alignItems: "center",
    },
    tableRoot: {
        flexGrow: 1,
        minWidth: "inherit",
        [theme.breakpoints.up("md")]: {
            minWidth: "fit-content",
        },
    },
    tablePaper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
});

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: {},
            adminOpen: false,
            open: MenuDataStore.getMenuState(),
            theme1: {
                palette: {
                    type: "light",
                    primary: {main: "#CC0000"},
                    secondary: {main: "#333333"},
                },
            },
            theme2: {
                palette: {
                    type: "dark",
                    primary: {main: "#CC0000"},
                    secondary: {main: "#ffffff"},
                },
            },
            isThemeLight: getTheme(),
            mainMenuItems: MenuDataStore.get(),
            user_data: userDataStore.getData("user"),
            path: "",
            acl: userDataStore.getData("acl")
        };

        this.getAcl = this.getAcl.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.menuStoreUpdate = this.menuStoreUpdate.bind(this);
        this.userUpdate = this.userUpdate.bind(this);
        this.aclUpdate = this.aclUpdate.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
    }

    componentDidMount() {
        let settings = SettingsDataStore.getData("general");

        if (settings !== undefined) {
            this.setState({settings: settings});
            if (settings.page_reload_dialog) {
                window.onbeforeunload = this.beforeUnload;
            }
        }

        
        MenuDataStore.addChangeListener("change", this.menuStoreUpdate);
        userDataStore.addChangeListener("userChange", this.userUpdate);
        userDataStore.addChangeListener("aclChange", this.aclUpdate);
        FullscreenLoaderService.hideLoader();

    }

    componentWillUnmount() {
        MenuDataStore.removeChangeListener("change", this.menuStoreUpdate);
        userDataStore.removeChangeListener("userChange", this.userUpdate);
        userDataStore.removeChangeListener("aclChange", this.aclUpdate);
    }

    static getDerivedStateFromProps(props, state) {
        const {location: {pathname}} = props;
        const path = pathname.substr(1);
        if (state.path !== path) {
           return {path};
        } 
        return null;
    }


    beforeUnload() {
        console.log(this.state.settings);
        return false;
    }

    userUpdate() {
        this.setState({user_data: userDataStore.getData("user")});
    }

    aclUpdate() {
        this.setState({acl: userDataStore.getData("acl")});
    }

    menuStoreUpdate() {
        this.setState({mainMenuItems: MenuDataStore.get()});
    }

    changeTheme() {
        this.setState( {isThemeLight: !this.state.isThemeLight});
        this.setState( {isThemeLight: !this.state.isThemeLight});
    }

    /**
     *  Return the actual component
     *  depend on path
     **/
    getComponent(path) {
        let menu_items = this.state.mainMenuItems,
            founded = false;

        if(!menu_items.length) {
            return {title: "Loading", menu_id: "loading", layout: "loading", component: LayoutDataStore.getLoading().component};
        }

        if (path === "") {
            return {title: "Dashboard", menu_id: "dashboard", layout: "dashboard", component: LayoutDataStore.get("dashboard").component};
        }

        menu_items.forEach(function (item) {
            if (item.resource === path) {
                item.component = LayoutDataStore.get(item.layout).component;
                founded = item;
                return true;
            }
            if (item.hasOwnProperty("items")) {
                item.items.forEach(function (sub_item) {
                    if (sub_item.resource === path) {
                        sub_item.component = LayoutDataStore.get(sub_item.layout).component;
                        founded = sub_item;
                        return true;
                    }
                });
            }
        });

        return founded ? founded : {title: "Page not found", menu_id: "not_found", layout: "not_found", component: LayoutDataStore.getDefault().component};
    }

    getAcl(id) {
        let result = {};

        if (this.state.acl.hasOwnProperty("menu")) {
            if (this.state.acl.menu.hasOwnProperty(id)) {
                result = this.state.acl.menu[id];
            }

            else if (this.state.acl.menu.hasOwnProperty("all")) {
                result = this.state.acl.menu.all;
            }
        }

        if (this.state.user_data.hasOwnProperty("role_id") && this.state.user_data.role_id === "1") {
            result.admin = true;
        }

        return result;
    }


    render() {
        const { classes, location } = this.props;
    
        let actualPage = this.getComponent(this.state.path),
            ActualComponent = actualPage.component;

        return <div className={classes.root}>
            <MuiThemeProvider
                theme={this.state.isThemeLight ? createMuiTheme(this.state.theme1) : createMuiTheme(this.state.theme2)}
            >
                <CssBaseline />
                <Loader/>
                <FullscreenLoader/>
                <Login themeColor={`${this.state.isThemeLight ? "light" : "dark"}`}/>
                <Notificator/>
                {this.state.user_data.hasOwnProperty("role_id") && (
                    <MenuDrawer
                        classes={classes}
                        location={location}
                        actualPage={actualPage}
                        changeTheme={this.changeTheme}
                        isThemeLight={this.state.isThemeLight}
                    />
                )}
                <main className={`${classes.content} ${this.state.isThemeLight ? " light" : " dark"}`}>
                    <div className={classes.toolbar} />
                    {this.state.user_data.hasOwnProperty("role_id") && (
                        <ErrorBoundary>
                            <ActualComponent
                                location={location}
                                classes={classes}
                                acl={this.getAcl(actualPage.id)}
                                isThemeDark={!this.state.isThemeLight}
                                isLoggedIn={this.state.user_data.hasOwnProperty("role_id")}
                                user={this.state.user_data}
                                pageData={actualPage}
                            />
                        </ErrorBoundary>
                    )}
                </main>
            </MuiThemeProvider>
        </div>;
    }
}

Layout.propTypes = {
    children: PropTypes.shape({
        type: PropTypes.any
    }),
    classes: PropTypes.any,
    location: PropTypes.any,
};

Layout.defaultProps = {
    classes: {},
    location: {}
};

export default compose(
    withRouter,
    withStyles(styles)
)(Layout);