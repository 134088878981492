/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PackageIcon_0007 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M532.8,87l-59.9-75.2C467.3,4.8,458.3,0,449.3,0H142.5c-9,0-17.5,4.8-23.1,11.8L58.2,86.6
            c-6.2,7.8-13.1,22.6-13.1,33.6v54.6c9.4-7.4,13.4-13,21.7-15.8c0.5-6.2,3.2-12.3,6.5-17.8c5.6-9.5,15-16.3,25.7-19.1
            c3.8-1,7.6-1.5,11.1-1.5c14.2,0,27.1,7.2,34.6,18.5c2.2-0.4,4.6-0.6,6.8-0.6c7,0,13.5,1.9,19.3,5.2l32.9-8.6
            c4.1-1.1,8.4-1.6,12.6-1.6c22.7,0,42.6,15.4,48.4,37.4l64.4,247.7c3.4,12.9,1.5,26.4-5.3,37.9c-6.8,11.5-17.6,21.4-30.5,24.8
            l-28.8,9.2h241.6c20.4,0,39-20,39-40.4V119.2C545.1,109.3,539,94.8,532.8,87z M79.4,84.9L99,66h134.1l13.2-28.3h-130L130.9,22
            c2.8-3.5,7-3.1,11.6-3.1h306.8c4.5,0,8.7-0.4,11.6,3.1l14.5,15.8H343l7.4,28.3h142.3l19.7,18.9H79.4z M91.3,167.2l-0.1-0.3
            c-2.7-10.3,3.5-20.8,13.8-23.5l0.4-0.1c10.3-2.7,20.8,3.5,23.5,13.8l0.1,0.3c1.2,4.4,5.7,7.1,10.1,5.9l8.3-2.2
            c8.8-2.3,17.9,3,20.2,11.8l0.1,0.3c2.3,8.8-3,17.9-11.8,20.2l-70.3,18.3c-8.8,2.3-17.9-3-20.2-11.8l-0.1-0.3
            c-2.3-8.8,3-17.9,11.8-20.2l8.3-2.2C89.8,176.2,92.5,171.7,91.3,167.2z M211.7,409c0.8,3-1,6-4,6.8l-102.3,26.6c-3,0.8-6-1-6.8-4
            l-1.4-5.4c-0.8-3,1-6,4-6.8l102.3-26.6c3-0.8,6,1,6.8,4L211.7,409z M224.2,302.2l1.4,5.4c0.8,3-1,6-4,6.8l-140,36.4
            c-3,0.8-6-1-6.8-4l-1.4-5.4c-0.8-3,1-6,4-6.8l140-36.4C220.4,297.5,223.4,299.3,224.2,302.2z M209,265.9L69,302.4c-3,0.8-6-1-6.8-4
            l-1.4-5.4c-0.8-3,1-6,4-6.8l140-36.4c3-0.8,6,1,6.8,4l1.4,5.4C213.7,262.1,212,265.2,209,265.9z M205.9,364.5L92.8,393.9
            c-3,0.8-6-1-6.8-4l-1.4-5.4c-0.8-3,1-6,4-6.8l113.1-29.4c3-0.8,6,1,6.8,4l1.4,5.4C210.6,360.7,208.8,363.7,205.9,364.5z
            M307.7,424.2l-64.4-247.7c-3.9-14.9-19.1-23.8-33.9-19.9l-32.8,8.5c0.8,1.6,1.4,3.3,1.9,5.1c3.9,15.1-5,30.2-19.7,34l-69.3,18
            c-14.1,3.7-29.4-3.6-34.1-17.4c-0.9-2.6-1.4-5.2-1.5-7.8l-32.9,8.6C6,209.5-3,224.7,0.9,239.6l64.4,247.7
            c3.9,14.9,19.1,23.8,33.9,19.9l188.5-49C302.6,454.3,311.5,439.1,307.7,424.2z M92.3,480.3l-56-215.4l188.5-49l56,215.4L92.3,480.3z
            "/>
        </SvgIcon>
    );
}

export default PackageIcon_0007;