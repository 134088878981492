/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Consolidation component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import InfoIcon from "@material-ui/icons/Info";


/**
 * Import other used components
 */

import Dialog from "../../../dialog";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import Grow from "@material-ui/core/Grow";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

/**
 * Import used stores
 */
// import OrderStatusStore from "../../../../../stores/OrderStatusStore";

class Consolidation extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            code: "",
            infoOpen: false
        };

        this.orderCodeRef = React.createRef();

        this.onOrderSubmit = this.onOrderSubmit.bind(this);
        this.onItemSubmit = this.onItemSubmit.bind(this);
        this.detectKey = this.detectKey.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        // window.addEventListener("keydown", this.detectKey);
    }

    componentWillUnmount() {
        // window.removeEventListener("keydown", this.detectKey);
    }

    detectKey(e) {
        if (e.keyCode >= 48 && e.keyCode <= 57)
            if (this.props.status === undefined) {
                this.orderCodeRef.current.focus();
            }
    }


    onOrderSubmit(e) {
        e.preventDefault();
        this.props.handleClickOrderCode(this.state.code);
    }

    onItemSubmit(e) {
        e.preventDefault();
        this.props.searchItem(this.state.code);
    }

    handleChange(e) {
        this.setState({code: e.target.value});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {

        const {
            status,
            created,
            vendors,
            settings,
            webshopStatus
            // show,
            // just_item_info,
            // showItemInfo
        } = this.props;

        if (status) {
            return (
                <div style={{width: "50%", background: "#00000012", borderRadius: "4px", padding: "4px", margin: "8px 4px 4px 4px"}}>
                    <div
                        onClick={() => this.setState({infoOpen: true})}
                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column"}}>
                        <Typography fontSize={"15px"}><b>{this.props.increment_id}</b></Typography>
                        <InfoIcon />
                    </div>
                    <Dialog
                        open={this.state.infoOpen}
                        onClose={() => this.setState({infoOpen: false})}
                        onCancel={() => this.setState({infoOpen: false})}
                        cancelText={"Close"}
                    >
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            style={{margin: ".2rem", background: "#00000012"}}
                        >
                            {(settings.show_code_input) && (
                                <Grid
                                    item
                                    xs={(settings.show_order_details && status !== undefined) ? 6 : 12}
                                    className={"text-center"}
                                >
                                    <Collapse in={status === undefined} mountOnEnter unmountOnExit>
                                        <form
                                            onSubmit={(e) => {
                                                this.onOrderSubmit(e);
                                            }}
                                        >
                                            <FormControl
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <InputLabel
                                                    htmlFor="order_code_"
                                                    color="secondary"
                                                >
                                                    Order Code
                                                </InputLabel>
                                                <Input
                                                    ref={this.orderCodeRef}
                                                    id="order_code"
                                                    type={"text"}
                                                    value={this.state.code}
                                                    name="order_code"
                                                    color="secondary"
                                                    // autoFocus={true}
                                                    onChange={this.handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                type="submit"
                                                                aria-label="Send order code"
                                                                // onClick={() => {handleClickOrderCode();}}
                                                                onClick={(e) => {
                                                                    this.onOrderSubmit(e);
                                                                }}
                                                            >
                                                                <SearchIcon/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </form>
                                    </Collapse>
                                    <Collapse in={status !== undefined} mountOnEnter unmountOnExit>
                                        <form
                                            onSubmit={(e) => {
                                                this.onItemSubmit(e);
                                            }}
                                        >
                                            <FormControl
                                                className={settings.show_order_details ? "float-right" : ""}
                                            >
                                                <InputLabel
                                                    htmlFor="item_code"
                                                    color="secondary"
                                                >
                                                    Item barcode
                                                </InputLabel>
                                                <Input
                                                    id="item_code"
                                                    type={"text"}
                                                    value={this.state.code}
                                                    name="item_code"
                                                    color="secondary"
                                                    autoFocus={true}
                                                    onChange={this.handleChange}
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                        >
                                                            <IconButton
                                                                aria-label="Send item barcode"
                                                                onClick={() => {
                                                                    this.onItemSubmit();
                                                                }}
                                                            >
                                                                <SearchIcon/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </form>
                                    </Collapse>
                                </Grid>
                            )}

                            {(status !== undefined) && (
                                <Grow in={(status !== undefined)} mountOnEnter unmountOnExit>
                                    <Grid
                                        item
                                        xs={settings.show_code_input ? 6 : 12}
                                        style={{paddingRight: "24px"}}
                                    >
                                        <Typography variant="caption">Date: </Typography><Typography variant="caption"
                                                                                                     className="bold">{created}</Typography><br/>
                                        <Typography variant="caption">Magento status: </Typography><Typography
                                        variant="caption"
                                        className="bold">{webshopStatus}</Typography><br/>
                                        <Typography variant="caption">Vendor: </Typography><Typography variant="caption"
                                                                                                       className="bold">{vendors}</Typography>
                                    </Grid>
                                </Grow>
                            )}
                        </Grid>
                    </Dialog>
                </div>
            );
        } else {
            return null;
        }
    }
}

Consolidation.propTypes = {
    increment_id: PropTypes.string,
    classes: PropTypes.any,
    handleClickOrderCode: PropTypes.func,
    searchItem: PropTypes.func,
    status: PropTypes.any,
    webshopStatus: PropTypes.string,
    vendors: PropTypes.any,
    created: PropTypes.any,
    settings: PropTypes.any,
    show: PropTypes.bool,
    showItemInfo: PropTypes.any,
    just_item_info: PropTypes.any,
};

Consolidation.defaultProps = {
    show: false,
    settings: {},
    classes: {}
};

export default Consolidation;