/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import {green, red} from "@material-ui/core/colors";
import {Typography} from "@material-ui/core";

import SettingsDataStore from "../../../stores/SettingsDataStore";
import ViewportStore from "../../../stores/ViewportStore";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/* This is a dialog window that can be used to display information to the user */
class DialogWindow extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            zebra: ViewportStore.get(),
            type: {
                success: {
                    style: {
                        backgroundColor: green[800],
                        color: 'white'
                    }
                },
                error: {
                    style: {
                        backgroundColor: red[900]
                    }
                }
            }
        };

        this.getButtonAnimation = this.getButtonAnimation.bind(this);
        this.setZebra = this.setZebra.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.setState(SettingsDataStore.getData("dialog"));
        ViewportStore.addChangeListener("change", this.setZebra);
    }

    componentWillUnmount() {
        ViewportStore.removeAllListeners();
    }

    setZebra() {
       this.setState({zebra: ViewportStore.get()});
    }

    getButtonAnimation(animation) {
        return animation ? "rounded " + animation : "rounded";
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            title,
            subTitle,
            children,
            onCancel,
            onOk,
            onClose,
            open,
            okText,
            cancelText,
            fullWidth,
            fullScreen,
            maxWidth,
            type,
            primaryAnimation,
            secondaryAnimation,
            okDisabled,
            secondaryText,
            onSecondaryAction,
            secondaryActionIcon,
            onTertiaryAction,
            tertiaryActionIcon,
            tertiaryText,
            extraClasses
        } = this.props;

        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                PaperProps={type ? this.state.type[type] : {}}
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={fullWidth}
                fullScreen={fullScreen}
                maxWidth={maxWidth}
                className={extraClasses + (type ? " " + type : "")}
            >
                <DialogTitle style={{textAlign: this.state.align_title}}>{title}</DialogTitle>
                {subTitle && <Typography align={"center"} variant={"subtitle1"}>{subTitle}</Typography>}
                <DialogContent style={{textAlign: this.state.align_content}}>
                    {children}
                </DialogContent>
                <DialogActions style={{justifyContent: this.state.align_actions}}>
                    {onCancel !== undefined && (
                        <Button
                            variant="contained"
                            disabled={this.props.disabled}
                            color={(type === "success" || type === "error") ? "secondary" : "primary"}
                            className={this.getButtonAnimation(secondaryAnimation)}
                            onClick={onCancel}
                        >
                            {cancelText}
                        </Button>
                    )}
                    {onOk !== undefined && (
                        <Button
                            variant="contained"
                            disabled={this.props.disabled || okDisabled}
                            color={(type === "success" || type === "error") ? "secondary" : "primary"}
                            className={this.getButtonAnimation(primaryAnimation)}
                            onClick={onOk}
                        >
                            {okText}
                        </Button>
                    )}
                    {secondaryText && (
                        <Button
                            variant="contained"
                            color={"secondary"}
                            disabled={this.props.disabled}
                            onClick={onSecondaryAction}
                            startIcon={secondaryActionIcon}
                        >
                            {secondaryText}
                        </Button>
                    )}
                    {tertiaryText && (
                        <Button
                            variant="contained"
                            color={"secondary"}
                            disabled={this.props.disabled}
                            onClick={onTertiaryAction}
                            startIcon={tertiaryActionIcon}
                        >
                            {tertiaryText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

DialogWindow.propTypes = {
    title: PropTypes.any,
    subTitle: PropTypes.string,
    children: PropTypes.any,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
    cancelText: PropTypes.string,
    okText: PropTypes.string,
    secondaryText: PropTypes.string,
    onSecondaryAction: PropTypes.func,
    secondaryActionIcon: PropTypes.any,
    open: PropTypes.bool,
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    okDisabled: PropTypes.bool,
    maxWidth: PropTypes.any,
    noDecoration: PropTypes.any,
    type: PropTypes.any,
    primaryAnimation: PropTypes.any,
    secondaryAnimation: PropTypes.any,
    tertiaryText: PropTypes.string,
    onTertiaryAction: PropTypes.func,
    tertiaryActionIcon: PropTypes.any,
    extraClasses: PropTypes.string,
    disabled: PropTypes.bool
};

DialogWindow.defaultProps = {
    title: "",
    cancelText: "Disagree",
    okText: "Agree",
    open: false,
    fullWidth: false,
    fullScreen: false,
    okDisabled: false,
    maxWidth: "xl",
    noDecoration: false,
    type: false,
    primaryAnimation: false,
    secondaryAnimation: false,
    extraClasses: '',
    disabled: false
};

export default DialogWindow;
