/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * SpeedDial component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDial from "@material-ui/lab/SpeedDial";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SpeedDialCustomComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.handleSpeedDialClose = this.handleSpeedDialClose.bind(this);
        this.handleSpeedDialOpen = this.handleSpeedDialOpen.bind(this);

    }


    /***
     * Opent the speed dial buttons
     */
    handleSpeedDialOpen(e, reason) {
        this.setState({isOpen: reason === "toggle"});
    }

    /**
     * Close the sepped dial buttons
     */
    handleSpeedDialClose() {
        this.setState({isOpen: false});
    }

    render() {
        const filteredActions = this.props.actions && this.props.actions.filter(action => action.condition);
        const onlyOneAction = filteredActions && filteredActions.length === 1;

        return <SpeedDial
            ariaLabel="Extra functions"
            hidden={false}
            icon={<SpeedDialIcon/>}
            className={clsx({"highlight": this.props.isHighlit && this.state.isOpen}, "speedDial")}
            onClose={this.handleSpeedDialClose}
            onOpen={this.handleSpeedDialOpen}
            open={this.state.isOpen}
            onClick={this.props.onClick ? this.props.onClick : (onlyOneAction ? filteredActions[0].onClick : null)}
            TransitionComponent={Transition}
            direction={"up"}
        >
            {this.props.children}
            {this.props.actions && this.props.actions.filter(action => action.condition).map((action) => (
                <SpeedDialAction
                    key={action.label}
                    icon={action.icon}
                    tooltipTitle={action.label}
                    className={action.className}
                    tooltipOpen
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>;
    }
}


SpeedDialCustomComponent.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.any
    ]),
    isHighlit: PropTypes.bool,
    mainClick: PropTypes.func,
    actions: PropTypes.any
};

SpeedDialCustomComponent.defaultProps = {
    children: null,
    onClick: null,
    mainClick: () => {
    }
};


const SD = withStyles({
    root: {
        position: "fixed",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: "60px",
            right: "50px",
        },
        "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: "60px",
            left: "50px",
        }
    }
})(SpeedDialCustomComponent);

export default SD;
