/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RoleStore from "../../../../stores/RoleStore";
import Button from "@material-ui/core/Button";
import SupplierVisibilityDialog from "../../../common/settings/supplierVisibilityDialog";
import MenuVisibilityDialog from "../../../common/settings/MenuVisibilityDialog";
import ShippingMethodVisibilityDialog from "../../../common/settings/shippingMethodVisibilityDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "../../../common/switch/OnOffSwitch";

class EditMenuDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            data: RoleStore.getAll(),
            supplier_visibility_dialog_open: false,
            shipping_method_visibility_dialog_open: false,
            menu_visibility_dialog_open: false
        };

        this.supplierVisibilityDialogOpen = this.supplierVisibilityDialogOpen.bind(this);
        this.supplierVisibilityDialogClose = this.supplierVisibilityDialogClose.bind(this);
        this.shippingMethodVisibilityDialogOpen = this.shippingMethodVisibilityDialogOpen.bind(this);
        this.shippingMethodVisibilityDialogClose = this.shippingMethodVisibilityDialogClose.bind(this);
        this.menuVisibilityDialogOpen = this.menuVisibilityDialogOpen.bind(this);
        this.menuVisibilityDialogClose = this.menuVisibilityDialogClose.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.setState({data: RoleStore.getAll()});
    }

    supplierVisibilityDialogOpen() {
        this.setState({supplier_visibility_dialog_open: true});
    }

    supplierVisibilityDialogClose() {
        this.setState({supplier_visibility_dialog_open: false});
    }

    shippingMethodVisibilityDialogOpen() {
        this.setState({shipping_method_visibility_dialog_open: true});
    }

    shippingMethodVisibilityDialogClose() {
        this.setState({shipping_method_visibility_dialog_open: false});
    }

    menuVisibilityDialogOpen() {
        this.setState({menu_visibility_dialog_open: true});
    }

    menuVisibilityDialogClose() {
        this.setState({menu_visibility_dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
            onSupplierVisibilityChanged,
            onShippingMethodVisibilityChanged,
            onMenuVisibilityChanged,
            onLabelPrintChanged
        } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    title={edit ? "Edit user group" : "Add new user group"}
                    open={open}
                    onOk={onOk}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Cancel"
                    okText="Save"
                    maxWidth="xs"
                >
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="name_input"
                                    color="secondary"
                                >
                                    Name
                                </InputLabel>
                                <Input
                                    id="name_input"
                                    type={"string"}
                                    value={data.role_name === null || data.role_name === undefined ? "" : data.role_name}
                                    name="role_name"
                                    color="secondary"
                                    autoFocus={true}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        {!edit && (
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"role_type_input"}
                                        color="secondary"
                                    >Role type</InputLabel>
                                    <Select
                                        labelId={"role_type_input"}
                                        id={"role_type_input"}
                                        name="role_type"
                                        style={{minWidth: "150px", textAlign: "left"}}
                                        value={data.role_type === null || data.role_type === undefined ? "" : data.role_type}
                                        color="secondary"
                                        onChange={onChange}
                                    >
                                        <MenuItem value="U">User</MenuItem>
                                        <MenuItem value="G">Group</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {data.role_type === "U" && (
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth={true}
                                >
                                    <InputLabel
                                        id={"parent_input"}
                                        color="secondary"
                                    >Parent</InputLabel>
                                    <Select
                                        labelId={"parent_input"}
                                        id={"parent_input"}
                                        name="parent_id"
                                        style={{minWidth: "150px", textAlign: "left"}}
                                        value={data.parent_id === null || data.parent_id === undefined ? "" : data.parent_id}
                                        color="secondary"
                                        onChange={onChange}
                                    >
                                        <MenuItem value={0}>Not selected</MenuItem>
                                        {Object.keys(this.state.data).map((key, i) => (
                                            <MenuItem key={i} value={this.state.data[key].role_id}>{this.state.data[key].role_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.supplierVisibilityDialogOpen();}}
                            >
                                Edit supplier visibility
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.menuVisibilityDialogOpen();}}
                            >
                                Edit menu visibility
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.shippingMethodVisibilityDialogOpen();}}
                            >
                                Edit shipping method visibility
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="label_printing"
                                        checked={(data && data.settings && data.settings) ? !!data.settings.label_printing : false}
                                        onChange={onLabelPrintChanged} />
                                }
                                label="Label printing"
                            />
                        </Grid>
                    </Grid>
                </Dialog>
                <SupplierVisibilityDialog
                    classes={classes}
                    open={this.state.supplier_visibility_dialog_open}
                    data={!data.hasOwnProperty("suppliers") || data.suppliers === null ? {} : JSON.parse(data.suppliers)}
                    onCancel={this.supplierVisibilityDialogClose}
                    onClose={this.supplierVisibilityDialogClose}
                    onChange={onSupplierVisibilityChanged}
                />
                <ShippingMethodVisibilityDialog
                    classes={classes}
                    open={this.state.shipping_method_visibility_dialog_open}
                    data={!data.hasOwnProperty("shipping_methods") || data.shipping_methods === null ? {} : JSON.parse(data.shipping_methods)}
                    onCancel={this.shippingMethodVisibilityDialogClose}
                    onClose={this.shippingMethodVisibilityDialogClose}
                    onChange={onShippingMethodVisibilityChanged}
                />
                <MenuVisibilityDialog
                    classes={classes}
                    open={this.state.menu_visibility_dialog_open}
                    data={!data.hasOwnProperty("menus") || data.menus === null ? {} : JSON.parse(data.menus)}
                    onCancel={this.menuVisibilityDialogClose}
                    onClose={this.menuVisibilityDialogClose}
                    onChange={onMenuVisibilityChanged}
                />
            </React.Fragment>
        );
    }
}

EditMenuDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onSupplierVisibilityChanged: PropTypes.any,
    onShippingMethodVisibilityChanged: PropTypes.any,
    onMenuVisibilityChanged: PropTypes.any,
    onLabelPrintChanged: PropTypes.func
};

EditMenuDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditMenuDialog;