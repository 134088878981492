/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
// import { green, red } from "@material-ui/core/colors";

class PieTimer extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.buttonPressTimer = false;

        this.handleButtonRelease = this.handleButtonRelease.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.visible) {
            this.buttonPressTimer = setTimeout(() => {this.props.onFire();}, 0);
        }
        return true;
    }

    handleButtonRelease () {
        clearTimeout(this.buttonPressTimer);
        this.setState({buttonPressPie: false});
        this.props.clearEvent();
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            visible,
            isThemeDark,
            position
        } = this.props;

        return (
            <React.Fragment>
                {visible && (
                    <div
                        className={isThemeDark ? "pieWrapper dark" : "pieWrapper"}
                        style={{top: position.y, left: position.x}}
                        // onTouchStart={this.handleButtonPress}
                         onTouchEnd={this.handleButtonRelease}
                         // onMouseDown={this.handleButtonPress}
                         onMouseUp={this.handleButtonRelease}
                         onMouseLeave={this.handleButtonRelease}
                        >
                        <div className="pie spinner"/>
                        <div className="pie filler"/>
                        <div className="mask"/>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

PieTimer.propTypes = {
    isThemeDark: PropTypes.any,
    position: PropTypes.any,
    visible: PropTypes.any,
    clearEvent: PropTypes.func,
    onFire: PropTypes.func,
};

// PieTimer.defaultProps = {
// };

export default PieTimer;