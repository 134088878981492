/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Shipping Methods component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import UniversalTable from "../../common/universalTable";
import EditDialog from "./editDialog";
import notificationService from "../../../services/Notification";
import DeleteDialog from "../../common/deleteDialog";

class ShippingMethods extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            delete_item_id: false,
            dialog_open: false,
            is_edit: false,
            actual_edit: {},
            need_reload: false
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.reloaded = this.reloaded.bind(this);

        this.openDelete = this.openDelete.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.onDeleteOk = this.onDeleteOk.bind(this);
        this.deleteSuccess = this.deleteSuccess.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Clone object
     * @param src
     * @returns {any}
     */
    cloneObject(src) {
        return Object.assign({}, src);
    }

    /**
     * Edit item
     * @param item
     * @param index
     */
    onEdit(item, index) {
        if (item !== undefined) {
            let data = this.cloneObject(item);
            delete data._links;
            this.setState({actual_edit: data, actual_index: index, dialog_open: true, is_edit: true});
        }
        else {
            this.setState({
                actual_edit: {
                    code: "",
                    label: "",
                },
                actual_index: index,
                dialog_open: true,
                is_edit: false
            });
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.actual_edit,
            target = e.target.name,
            value = e.target.value;

        item[target] = value;
        this.forceUpdate();
    }

    /**
     * Close the edit dialog
     */
    onCancel() {
        this.setState({
            actual_edit: {
                code: "",
                label: "",
            },
            actual_index: 0,
            dialog_open: false,
            is_edit: false,
            need_reload: false
        });
    }

    /**
     * Save menu data
     */
    onSave() {
        let data = this.cloneObject(this.state.actual_edit);
        delete data.shipping_method_id;

        if (this.state.is_edit) {
            LoaderService.patchData("shipping_method/" + this.state.actual_edit.shipping_method_id, data, this.saveSuccess);
        }
        else {
            LoaderService.postData("shipping_method", data, this.saveSuccess);
        }
    }

    /**
     * Save success
     */
    saveSuccess() {
        this.setState({need_reload: true});
        this.onCancel();
    }

    /**
     * Switch the need reload flag back
     */
    reloaded() {
        this.setState({need_reload:false});
    }

    openDelete(data) {
        this.setState({delete_item_id: data.shipping_method_id});
    }

    onDeleteCancel() {
        this.setState({delete_item_id: false});
    }

    onDeleteOk() {
        LoaderService.deleteData("shipping_method/" + this.state.delete_item_id, {}, this.deleteSuccess);
    }

    deleteSuccess() {
        notificationService.showNotification("success", "Item successfully deleted");
        this.setState({delete_item_id: false, need_reload: true});
        this.onCancel();
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            acl,
            classes,
            pageData
        } = this.props;
        return (
            <React.Fragment>
                <div className={classes.tableRoot}>
                    <UniversalTable
                        needReload={this.state.need_reload}
                        acl={acl}
                        pageData={pageData}
                        url="shipping_method"
                        data_key="shipping_method"
                        row_key="shipping_method_id"
                        onAdd={this.onEdit}
                        onEdit={this.onEdit}
                        onDelete={this.openDelete}
                        onReloaded={this.reloaded}
                    />
                </div>
                <EditDialog
                    open={this.state.dialog_open}
                    edit={this.state.is_edit}
                    data={this.state.actual_edit}
                    onOk={this.onSave}
                    onCancel={this.onCancel}
                    onClose={this.onCancel}
                    onChange={this.onChange}
                />
                <DeleteDialog
                    open={this.state.delete_item_id !== false}
                    onCancel={this.onDeleteCancel}
                    onOk={this.onDeleteOk}
                />
            </React.Fragment>
        );
    }
}

ShippingMethods.propTypes = {
    acl: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    pageData: PropTypes.any
};

ShippingMethods.defaultProps = {
    url: false,
    classes: {}
};

export default ShippingMethods;