/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order info component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "../../../dialog";

class CodeInputDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            code: ""
        };

        this.handleHitEnter = this.handleHitEnter.bind(this);
        this.onOrderSubmit = this.onOrderSubmit.bind(this);
        this.onItemSubmit = this.onItemSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleHitEnter, true);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        document.addEventListener("keydown", this.handleHitEnter, true);
    }

    handleHitEnter(e) {
        if (this.props.open) {
            const ENTER_KEY_CODE = 13;
            if ((e.target.name === "order_code" || e.target.name === "item_code") &&
                (e.key === "Enter" || e.keyCode === ENTER_KEY_CODE)) {
                e.stopPropagation();
                if (e.target.name === "order_code") {
                    this.onOrderSubmit(e);
                }
                else {
                    this.onItemSubmit(e);
                }
            }
        }
    }

    onOrderSubmit(e) {
        e.preventDefault();
        this.props.searchOrder(this.state.code);
        this.setState({code: ""});
    }

    onItemSubmit(e) {
        e.preventDefault();
        this.props.searchItem(this.state.code);
        this.setState({code: ""});
    }

    handleChange(e) {
        this.setState({code: e.target.value});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { open,
            onClose,
            onCancel,
            hasOrder,
        } = this.props;

        return (
            <Dialog
                title={!hasOrder ? "Search Order / Item" : "Search Item"}
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                cancelText="Cancel"
            >

                {!hasOrder && (
                    <FormControl noValidate autoComplete="chrome-off">
                        <InputLabel
                            htmlFor="order_code_dialog_input"
                            color="secondary"
                        >
                            Order Code
                        </InputLabel>
                        <Input
                            autoComplete={"off"}
                            id="order_code_dialog_input"
                            type={"text"}
                            value={this.state.code}
                            name="order_code"
                            color="secondary"
                            autoFocus={true}
                            onChange={this.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        type="submit"
                                        aria-label="Send order code"
                                        onClick={(e) => {this.onOrderSubmit(e);}}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )}
                {hasOrder && (
                    <FormControl noValidate autoComplete="off">
                        <InputLabel
                            htmlFor="item_code_dialog_input"
                            color="secondary"
                        >
                            Item barcode
                        </InputLabel>
                        <Input
                            id="item_code_dialog_input"
                            type={"text"}
                            value={this.state.code}
                            name="item_code"
                            color="secondary"
                            autoFocus={true}
                            onChange={this.handleChange}
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                >
                                    <IconButton
                                        aria-label="Send item barcode"
                                        onClick={(e) => {this.onItemSubmit(e);}}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )}
            </Dialog>
        );
    }
}

CodeInputDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    hasOrder: PropTypes.any,
    searchOrder: PropTypes.any,
    searchItem: PropTypes.any,
};

CodeInputDialog.defaultProps = {
    open: false,
    hasOrder: false,
    classes: {}
};

export default CodeInputDialog;