/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Payment Method store component
 * -----------------------------------------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class paymentMethodStore extends EventEmitter {
    constructor() {
        super();
        this.data = {};
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    /**
     * Return the variable
     * @param code
     * @param variable
     * @returns {*}
     */
    get(code, variable) {
        if (this.data.hasOwnProperty(code) && this.data[code].hasOwnProperty(variable)) {
            return this.data[code][variable];
        }
        return code;
    }

    /**
     * Return all data
     * @returns {{}|*}
     */
    getAll() {
        return this.data;
    }

    /**
     * Set all data
     * @param data
     */
    set(data) {
        this.data = data;
        this.emit("change");
    }
}

const PaymentMethodStore = new paymentMethodStore;

export default PaymentMethodStore;