/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order items component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

class OrderItems extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const { order } = this.props;
        return (
            <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                    <TableRow selected={true}>
                        <TableCell align={"left"}>
                            <Typography color="inherit" variant="caption">Megnevezés</Typography>
                        </TableCell>
                        <TableCell align={"left"}>
                            <Typography color="inherit" variant="caption">Supplier</Typography>
                        </TableCell>
                        <TableCell align={"left"}>
                            <Typography color="inherit" variant="caption">BH cikkszám</Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                            <Typography color="inherit" variant="caption">Menny. db</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {order.items.map((item, item_index) => (
                        <TableRow
                            key={"items_" + item_index}
                            hover
                            selected={false}
                            className={"ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                        >
                            <TableCell
                                component="td"
                                scope="row"
                                className="ut-xxs-col-12 ut-xs-col-12 ut-s-col-12 ut-m-col-12"
                            >
                                {this.props.showLabels && (
                                    <Typography
                                        className={"tdBefore"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "left"}}
                                    >
                                        Megnevezés
                                    </Typography>
                                )}
                                {item.name}
                            </TableCell>
                            {item.hasOwnProperty('supplier_name') && (
                                <TableCell
                                    component="td"
                                    scope="row"
                                    className="ut-xxs-col-6 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left"
                                >
                                    {this.props.showLabels && (
                                        <Typography
                                            className={"tdBefore"}
                                            color="textSecondary"
                                            display="block"
                                            variant="caption"
                                            style={{textAlign: "right"}}
                                        >
                                            Supplier
                                        </Typography>
                                    )}
                                    {item.supplier_name}
                                </TableCell>
                            )}
                            <TableCell
                                component="td"
                                scope="row"
                                className="ut-xxs-col-6 ut-xxs-left ut-xs-col-6 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-6 ut-m-left"
                            >
                                {this.props.showLabels && (
                                    <Typography
                                        className={"tdBefore"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "left"}}
                                    >
                                        BH cikkszám
                                    </Typography>
                                )}
                                {item.sku}
                            </TableCell>
                            <TableCell
                                component="td"
                                scope="row"
                                style={{textAlign: "right"}}
                                className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-6 ut-m-right"
                            >
                                {this.props.showLabels && (
                                    <Typography
                                        className={"tdBefore"}
                                        color="textSecondary"
                                        display="block"
                                        variant="caption"
                                        style={{textAlign: "right"}}
                                    >
                                        Mennyiség
                                    </Typography>
                                )}
                                {item.hasOwnProperty('original') && item.original.hasOwnProperty('founded') && (
                                    <React.Fragment>
                                        {Number(item.original.founded)}/
                                    </React.Fragment>
                                )}
                                {Number(item.qty)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

OrderItems.propTypes = {
    order: PropTypes.any,
    showLabels: PropTypes.bool
};

OrderItems.defaultProps = {
    order: {},
    showLabels: false
};

export default OrderItems;