/**
 * Donwload file supplied in base64 to OS.
 * @param content_base64
 * @param fileName
 * @param content_type
 */
export const downloadFile = (content_base64, fileName, content_type) => {
    const linkSource = `data:${content_type};base64,${content_base64}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};


/**
 * When printer is set to local, we do not print but save file to OS instead.
 * Method checks downloadable items in response and sends them to downloadFile utililty.
 * @param res: response object from print requests
 */
export const checkDownloadables = res => {
    const downloadableItems = ["download", "download_label", "download_pdf"];

    downloadableItems.forEach(item => {
        if (res.hasOwnProperty(item)) {
            const {content_base64, filename, content_type} = res[item];
            downloadFile(content_base64, filename, content_type);
        }
    });
};
