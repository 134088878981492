function getHeaderClassNames () {
    if (arguments.length < 1) {
        return "";
    }

    const sizes = ["xxl", "xl", "l", "m", "s", "xs", "xxs"];
    const settings = arguments[0];
    let result = "";

    sizes.forEach(function (size) {
        if (settings.hasOwnProperty(size)) {
            if (settings[size].visible) {
                result+="ut-" + size + "-normal-" + settings[size].align + " ";
            }
            else {
                result+="ut-" + size + "-hide ";
            }
        }
    });

    return result;
}

module.exports = getHeaderClassNames;