/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Delete Dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import Dialog from "../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

class DeleteDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onCancel,
            onOk,
        } = this.props;

        return (
            <Dialog
                title="Are you sure you want to delete it?"
                open={open}
                onClose={onCancel}
                onCancel={onCancel}
                onOk={onOk}
                okText="Delete"
                cancelText="Cancel"
            >
                <DialogContentText>
                    The deleted data will be lost
                </DialogContentText>
            </Dialog>
        );
    }
}

DeleteDialog.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

DeleteDialog.defaultProps = {
    open: false,
};

export default DeleteDialog;