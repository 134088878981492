/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Menu Data store component
 * -----------------------------------------------
 * - store the menu elements
 * - store the main menu open/close status
 */

/**
 * Import used react components
 */
import EventEmitter from "events";
import {mapOrderStatus} from "../utils";

class menuDataStore extends EventEmitter {
    constructor() {
        super();

        this.menuOpen = false;
        this.menu = [];
        this.actual = {
            menu_id: "",
            title: "",
            layout: "",
            resource: "",
            icon: "",
            order_status_from: null,
            order_status_to: null,
        };
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    setActual(data) {
        this.actual = data;
        this.emit("pageChange");
    }

    clearActual() {
        this.actual = {
            menu_id: "",
            title: "",
            layout: "",
            resource: "",
            icon: "",
            order_status_from: null,
            order_status_to: null,
        };
        this.emit("pageChange");
    }

    getActual() {
        return this.actual;
    }

    set(data) {
        this.menu = mapOrderStatus(data);
        this.emit("change");
    }

    get() {
        return this.menu;
    }

    getMenuState() {
        return this.menuOpen;
    }

    setMenuState(data) {
        this.menuOpen = data;
        this.emit("stateChange");
    }
}

const MenuDataStore = new menuDataStore;

export default MenuDataStore;