/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ShopIcon_0003 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M180.7,407.1c-20.9,0-37.8,16.9-37.8,37.8s16.9,37.8,37.8,37.8c20.9,0,37.8-16.9,37.8-37.8S201.6,407.1,180.7,407.1z
            M371.3,407.1c-20.9,0-37.8,16.9-37.8,37.8s16.9,37.8,37.8,37.8c20.9,0,37.8-16.9,37.8-37.8S392.2,407.1,371.3,407.1z M456.5,71.9
            H123.9l-11-42.5C109.7,12.5,94.7,0,77.3,0H23C10.3,0,0,10.3,0,23c0,12.7,10.3,23,23,23h46.6l62.2,239.5l-24.8,56.9
            c-3.1,7.1-2.4,15.3,1.9,21.8c4.2,6.5,11.5,10.4,19.2,10.4h289.2c12.7,0,23-10.3,23-23c0-12.7-10.3-23-23-23H163.3l13.9-32
            c0.2-0.4,0.1-0.8,0.3-1.2h238.8c12.2,0,22.8-8.4,25.6-20.3L482,104.3C485.9,87.7,473.4,71.9,456.5,71.9z M233.2,217.4
            c0,9.1-7.4,16.4-16.4,16.4c-9.1,0-16.4-7.4-16.4-16.4v-67.5c0-9.1,7.4-16.4,16.4-16.4c9.1,0,16.4,7.4,16.4,16.4V217.4z M312,217.4
            c0,9.1-7.4,16.4-16.4,16.4c-9.1,0-16.4-7.4-16.4-16.4v-67.5c0-9.1,7.4-16.4,16.4-16.4c9.1,0,16.4,7.4,16.4,16.4V217.4z M390.9,217.4
            c0,9.1-7.4,16.4-16.4,16.4c-9.1,0-16.4-7.4-16.4-16.4v-67.5c0-9.1,7.4-16.4,16.4-16.4c9.1,0,16.4,7.4,16.4,16.4V217.4z"/>
        </SvgIcon>
    );
}

export default ShopIcon_0003;