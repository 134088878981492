/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Secure data store component
 * ---------------------------
 * - encrypt data
 * - decrypt data
 * - save data to localStorage
 * - get data from localStorage
 * - clear data form localStorage
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

/**
 * Import other used components
 */
import CryptoJS from "crypto-js";

/**
 * Import used stores
 */
import DeviceInfoStore from "./DeviceInfoStore";

class SecureDataStore extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();
    }

    /**
     * Encrypt data
     * @param data
     * @returns {*|CipherParams|PromiseLike<ArrayBuffer>}
     */
    encryptData(data) {
        // alert("encrypt data " + DeviceInfoStore.getInfo("uuid"));
        return CryptoJS.AES.encrypt(JSON.stringify(data), DeviceInfoStore.getInfo("uuid"));
    }

    /**
     * Decrypt data
     * @param data
     * @returns {any}
     */
    decryptData(data) {
        if (data !== null && data !=="null") {
            let bytes  = CryptoJS.AES.decrypt(data.toString(), DeviceInfoStore.getInfo("uuid"));
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
    }

    /**
     * Save data to localStorage
     * @param name
     * @param value
     */
    setData(name, value) {
        localStorage.setItem(name, this.encryptData(value));
    }

    /**
     * Get data from localStorage
     * @param name
     * @returns {any}
     */
    getData(name) {
        return this.decryptData(localStorage.getItem(name));
    }

    /**
     * Clear data from localStorage
     * @param name
     */
    clearData(name) {
        localStorage.removeItem(name);
    }
}

const secureDataStore = new SecureDataStore;

export default secureDataStore;