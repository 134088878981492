/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Universal Table Header component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Import other used components
 */
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import getHeaderClassNames from "../utils/getHeaderClassNames";

class TheTableHeaderCell extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            settings,
            item,
        } = this.props;

        return (
            <React.Fragment>
                {settings.visible && (
                    <TableCell
                        className={getHeaderClassNames(settings)}
                        style={{cursor:"pointer", userSelect: "none"}}
                    >
                        {settings.key !== "row_controls" && (
                            <Typography color="inherit">{item}</Typography>
                        )}
                    </TableCell>
                )}
            </React.Fragment>
        );
    }
}

TheTableHeaderCell.propTypes = {
    settings: PropTypes.any,
    item: PropTypes.any,
    classes: PropTypes.any,
};

TheTableHeaderCell.defaultProps = {
    header: false,
    classes: {}
};

export default TheTableHeaderCell;