/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Menu settings component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import EditDialog from "./editDialog";
import SpeedDial from "../../common/speedDial";
import OrderStatusStore from "../../../stores/OrderStatusStore";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Sortly, { ContextProvider, useDrag, useDrop } from "react-sortly";
import notificationService from "../../../services/Notification";
import MenuDataStore from "../../../stores/MenuDataStore";
import DeleteDialog from "../../common/deleteDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import {mapOrderStatus} from "../../../utils";


const ItemRenderer = (props) => {
    const {
        openDelete,
        editMenuItem,
        data,
        isDragging,
        isClosestDragging,
        onDragend
    } = props;
    const [, drag] = useDrag();
    const [, drop] = useDrop();

    return (
        <TableBody ref={drop}>
            <TableRow
                onDragEnd={onDragend}
                ref={drag}
                style={{backgroundColor: isDragging || isClosestDragging ? "white": "unset"}}
            >
                <TableCell style={{ padding: 0, paddingLeft: (data.depth+1) * 20 }}>
                    <DragIndicatorIcon/>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap", padding: 0}}>
                    <IconButton
                        onClick={() => {{editMenuItem(data);}}}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        disabled={!data.can_delete}
                        onClick={() => {{openDelete(data);}}}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
                {data.is_group && (
                    <TableCell
                        colSpan={5}
                        style={{ padding: 0 }}
                    >
                        {data.title}
                    </TableCell>
                )}
                {!data.is_group && (
                    <TableCell
                        style={{ padding: 0 }}
                    >
                        {data.title}
                    </TableCell>
                )}
                {/*{!data.is_group && (*/}
                {/*    <TableCell>*/}
                {/*        {data.menu_id}*/}
                {/*    </TableCell>*/}
                {/*)}*/}
                {!data.is_group && (
                    <TableCell
                        style={{ padding: 0 }}
                    >
                        {data.resource}
                    </TableCell>
                )}
                {!data.is_group && (
                    <TableCell
                        style={{ padding: 0 }}
                    >
                        {data.layout}
                    </TableCell>
                )}
                {!data.is_group && (
                    <TableCell
                        style={{ padding: 0 }}
                    >
                        {data.order_status_from === "0" ? "" : OrderStatusStore.get(data.order_status_from, "label")}
                    </TableCell>
                )}
                {!data.is_group && (
                    <TableCell
                        style={{ padding: 0 }}
                    >
                        {data.order_status_to === "0" ? "" : OrderStatusStore.get(data.order_status_to, "label")}
                    </TableCell>
                )}
            </TableRow>
        </TableBody>
    );
};

ItemRenderer.propTypes = {
    data: PropTypes.any,
    editMenuItem: PropTypes.any,
    isDragging: PropTypes.any,
    isClosestDragging: PropTypes.any,
    openDelete: PropTypes.func,
    onDragend: PropTypes.func
};

const MySortableTree = (props) => {
    const {
        data,
        handleChange,
        editMenuItem,
        openDelete,
        onDragend
    } = props;

    return (
        <Sortly items={data} maxDepth={1} onChange={handleChange} threshold={20}>
            {(props) => <ItemRenderer {...props} openDelete={openDelete} editMenuItem={editMenuItem} onDragend={onDragend} />}
        </Sortly>
    );
};

MySortableTree.propTypes = {
    data: PropTypes.any,
    handleChange: PropTypes.any,
    onDragend: PropTypes.any,
    editMenuItem: PropTypes.any,
    openDelete: PropTypes.any,
};

class MenuSettings extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            dialog_open: false,
            delete_item_id: false,
            select_icon_dialog_open: false,
            is_edit: false,
            actual_edit: {},
            data: [],
        };

        this.editMenuItem = this.editMenuItem.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataSuccess = this.getDataSuccess.bind(this);
        this.saveAllItemsSuccess = this.saveAllItemsSuccess.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkIsGroup = this.checkIsGroup.bind(this);
        this.onIconSelected = this.onIconSelected.bind(this);
        this.success = this.success.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.onDeleteOk = this.onDeleteOk.bind(this);
        this.deleteSuccess = this.deleteSuccess.bind(this);
        this.onDragend = this.onDragend.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        this.getData();
    }

    /**
     * Load all data
     */
    getData() {
        LoaderService.getData("menu_tree?is_admin=1", "", this.getDataSuccess);
        LoaderService.getData("menu_tree", "", this.success);
    }

    /**
     * Check menu item is group
     * @param item
     * @returns {boolean}
     */
    checkIsGroup(item) {
        return item.resource === "menu_group";
    }

    onIconSelected(icon) {
        let actual_edit = this.state.actual_edit;
        actual_edit.icon = icon;
        this.setState({actual_edit: actual_edit});
    }

    /**
     * Prepare data for menu list
     * @param result
     */
    getDataSuccess(result) {
        if(result.hasOwnProperty("_embedded")) {
            let self = this,
                data = [];

            result._embedded.forEach(function (main) {
                let items = [];
                main.depth = 0;
                main.is_group = self.checkIsGroup(main);
                main.can_delete = true;

                if (main.hasOwnProperty("items")){
                    items = JSON.parse(JSON.stringify(main.items));
                    main.can_delete = false;
                    delete main.items;
                }

                data.push(main);

                if (items.length) {
                    items.forEach(function (item) {
                        item.depth = 1;
                        item.is_group = self.checkIsGroup(item);
                        item.can_delete = true;
                        data.push(item);
                    });
                }
            });

            data = mapOrderStatus(data);
            this.setState({data});
        }
    }

    /**
     * Clone object
     * @param src
     * @returns {any}
     */
    cloneObject(src) {
        return Object.assign({}, src);
    }

    /**
     * Edit menu item
     * @param item
     */
    editMenuItem(item) {
        if (item !== undefined) {
            this.setState({actual_edit: this.cloneObject(item),  dialog_open: true, is_edit: true});
        }
        else {
            this.setState({
                actual_edit: {
                    menu_id: "",
                    is_group: false,
                    can_delete: false,
                    title: "",
                    layout: "",
                    resource: "",
                    order_status_from: [],
                    order_status_to: [],
                    icon: ""
                },
                dialog_open: true,
                is_edit: false
            });
        }
    }

    /**
     * Close the edit dialog
     */
    onCancel() {
        this.setState({
            actual_edit: {
                menu_id: "",
                is_group: false,
                can_delete: false,
                title: "",
                layout: "",
                resource: "",
                order_status_from: [],
                order_status_to: [],
                icon: ""
            },
            actual_index: 0,
            dialog_open: false,
            is_edit: false
        });
    }

    /**
     * Save menu data
     */
    onSave() {
        let actual_edit = this.state.actual_edit;

        if (actual_edit.is_group) {
            actual_edit.layout = "menu_group";
            actual_edit.resource = "menu_group";
        }

        if (
            actual_edit.layout !== "consolidation" &&
            actual_edit.layout !== "consolidation_item" &&
            actual_edit.layout !== "packaging" &&
            actual_edit.layout !== "control" &&
            actual_edit.layout !== "orders" &&
            actual_edit.layout !== "order"
        ) {
            actual_edit.order_status_from = [];
            actual_edit.order_status_to = [];
        }

        actual_edit.order_status_from = JSON.stringify(actual_edit.order_status_from.filter(id => id !== 0));
        actual_edit.order_status_to = JSON.stringify(actual_edit.order_status_to.filter(id => id !== 0));

        if (this.state.is_edit) {
            LoaderService.putData("menu/" + actual_edit.id, actual_edit, this.saveSuccess);
        }
        else {
            actual_edit.sort_order = this.state.data.length + 1;
            LoaderService.postData("menu", actual_edit, this.saveSuccess);
        }
    }

    /**
     * Save success
     */
    saveSuccess() {
        this.getData();
        this.onCancel();
        notificationService.showNotification("success", "Menu item successfully saved");
    }

    /**
     * Handle success data
     * @param result
     */
    success(result) {
        if (result.hasOwnProperty("_embedded")) {
            MenuDataStore.set(result._embedded);
        }
    }

    /**
     * Save changes to component state
     * @param e
     */
    onChange(e) {
        let item = this.state.actual_edit,
            target = e.target.name,
            val = e.target.value;

        switch (target) {
            case "is_group" :
                val = val === "false" ? true : false;
                break;
            case "menu_id" :
                val =val.toLowerCase().replace(/-/g,"_").replace(/\s/g,"_").replace(/[^\w\s]/gi, "");
                break;
            case "resource" :
                val = encodeURIComponent(val.toLowerCase().replace(/-/g,"_").replace(/\s/g,"_").replace(/[^\w\s]/gi, ""));
                break;
            case "order_status_from" :
            case "order_status_to" :
                val = Array.from(new Set(val.map(Number)));
                break;
        }

        item[target] = val;
        this.forceUpdate();
    }

    /**
     * Menu structure changed
     * @param items
     */
    handleChange(items) {
        this.setState({ data: items });
    }

    onDragend() {
        let data = JSON.parse(JSON.stringify(this.state.data)),
            items_to_save = [];
        let group = 0;

        data.forEach(function (item, key) {
            let depth = item.depth;

            item.sort_order = key + 1;
            delete item.depth;
            delete item.is_group;
            delete item.can_delete;

            if (depth > 0) {
                item.parent_id = group;
            }
            else {
                item.parent_id = 0;
                group = item.id;
            }
            items_to_save.push(item);
        });

        LoaderService.putData("menu", items_to_save, this.saveAllItemsSuccess);
    }

    /**
     * All menu successfully saved
     */
    saveAllItemsSuccess() {
        LoaderService.getData("menu_tree", "", this.success);
        notificationService.showNotification("success", "Menu successfully saved");
    }

    openDelete(data) {
        this.setState({delete_item_id: data.id});
    }

    onDeleteCancel() {
        this.setState({delete_item_id: false});
    }

    onDeleteOk() {
        LoaderService.deleteData("menu/" + this.state.delete_item_id, {}, this.deleteSuccess);
        this.setState({delete_item_id: false});
    }

    deleteSuccess() {
        notificationService.showNotification("success", "Item successfully deleted");
        this.getData();
    }


    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
        } = this.props;

        return (
            <React.Fragment>
                <div className={classes.tableRoot}>
                    <Paper square={true} elevation={1}>
                        <Table
                            className={"responsiveTable"}
                            aria-label="simple table"
                            stickyHeader={false}
                        >
                            <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                <TableRow selected={true}>
                                    <TableCell style={{width: "80px"}} />
                                    <TableCell style={{width: "80px"}} />
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Title</Typography>
                                    </TableCell>
                                    {/*<TableCell*/}
                                    {/*    style={{cursor:"pointer", userSelect: "none"}}*/}
                                    {/*>*/}
                                    {/*    <Typography color="inherit">Menu id</Typography>*/}
                                    {/*</TableCell>*/}
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Resource</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">Layout</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">From</Typography>
                                    </TableCell>
                                    <TableCell
                                        style={{cursor:"pointer", userSelect: "none"}}
                                    >
                                        <Typography color="inherit">To</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <DndProvider backend={HTML5Backend}>
                                <ContextProvider>
                                    <MySortableTree onDragend={this.onDragend} data={this.state.data} openDelete={this.openDelete} editMenuItem={this.editMenuItem} handleChange={this.handleChange} />
                                </ContextProvider>
                            </DndProvider>
                        </Table>
                    </Paper>
                </div>
                <DeleteDialog
                    open={this.state.delete_item_id !== false}
                    onCancel={this.onDeleteCancel}
                    onOk={this.onDeleteOk}
                />
                <SpeedDial onClick={() => {{this.editMenuItem();}}}/>
                <EditDialog
                    classes={classes}
                    open={this.state.dialog_open}
                    edit={this.state.is_edit}
                    data={this.state.actual_edit}
                    onOk={this.onSave}
                    onCancel={this.onCancel}
                    onClose={this.onCancel}
                    onChange={this.onChange}
                    onIconSelected={this.onIconSelected}
                />
            </React.Fragment>
        );
    }
}

MenuSettings.propTypes = {
    acl: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
};

MenuSettings.defaultProps = {
    url: false,
    classes: {}
};

export default MenuSettings;