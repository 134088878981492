/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Barcode reader component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";

class BarcodeReader extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.keys = "";
        this.key_time = 0;
        this.onKeyDetected = this.onKeyDetected.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        window.addEventListener("keypress", this.onKeyDetected);
        console.log("addEventListener keypress");
    }

    /**
     * Set things when component is unmounting
     */
    componentWillUnmount() {
        window.removeEventListener("keypress", this.onKeyDetected);
        console.log("removeEventListener keypress");
    }

    onKeyDetected(e) {
        const
            limit = this.props.timeLimit === void 0 ? 6000 : this.props.timeLimit,
            minLength = this.props.minLength === void 0 ? 4 : this.props.minLength,
            prevent = this.props.preventDefault === void 0 ? false : this.props.preventDefault,
            disabled = this.props.disabled === void 0 ? false : this.props.disabled;

        if (disabled) {
            console.log("barcodeReader", {disabled: disabled, activeElement: document.activeElement});
            return;
        }
        if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 13 || e.keyCode === 246) {
            if (prevent && e.keyCode !== 13) {
                e.preventDefault();
            }
            let charCode = e.which || e.keyCode,
                key = String.fromCharCode(charCode),
                time = Date.now();

            if (e.keyCode === 13) {
                key = "enter";
            }

            if (this.props.onKeyDetected && key !== "enter") {
                this.props.onKeyDetected(key);
            }

            if (time - this.key_time > limit) {
                this.keys = "";
                // if (this.props.onEnter && e.keyCode === 13) {
                //     // console.log("fired manually");
                //     e.preventDefault();
                //     this.props.onEnter();
                // }
            }
            else {
                e.preventDefault();
            }
            // else {
            //     if (e.keyCode === 246) { // convert hungarian keyboard ö to 0
            //         key = "0";
            //     }
            // }

            if (key !== "enter") {
                this.keys+=key;
                console.log("barcodeReader", {keys: this.keys, activeElement: document.activeElement});
            }
            else {
                if (this.keys.length > minLength) {
                    if (this.props.onEnter) {
                        // console.log("fired");
                        e.preventDefault();
                        this.props.onEnter(this.keys);
                    }
                    this.keys = "";
                }
            }
            this.key_time = time;
        }
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        return ([]);
    }
}

BarcodeReader.propTypes = {
    onEnter: PropTypes.func,
    onKeyDetected: PropTypes.func,
    disabled: PropTypes.bool,
    preventDefault: PropTypes.bool,
    timeLimit: PropTypes.any,
    minLength: PropTypes.any,
};

BarcodeReader.defaultProps = {
    disabled: false,
    preventDefault: false,
    timeLimit: 100,
    minLength: 4,
};

export default BarcodeReader;