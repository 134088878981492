/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Control Packages component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "@services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";

import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "../../common/customSelect";
// import {InFocusGuard} from "react-focus-lock";
// import isEqual from "react-fast-compare";

const isEqual = require("react-fast-compare");
// var equal = require("fast-deep-equal/react");

class Packages extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.rootRef= React.createRef();

        this.state = {
            show_lines: true,
        };

        this.isSelected = this.isSelected.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!isEqual(this.props, nextState) || !isEqual(this.props, nextState));
    }

    /**
     * Check the package is actually selected
     * @param pack
     * @returns {boolean}
     */
    isSelected(pack) {
        if (this.props.actualFounded !== false) {
            if (pack.package_id === this.props.actualFounded.item.package_id) {
                return true;
            }
        }

        return false;
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            packages,
            units,
            getRowClassname,
            changePackageUnit,
            onPackageSizeChange,
            onPackageWeightChange,
            onFieldBlur,
        } = this.props;
        return (
            <React.Fragment>
                <input type="hidden" id="focusPoint" ref={this.rootRef}/>
                <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                    <TableBody>
                        {packages.map((pack, index) => (
                            <Grow
                                onClick={() => !this.isSelected(pack) &&  this.props.searchItem(pack.ean)}
                                key={pack.package_id} in={this.state.show_lines}
                                {...(this.state.show_lines ? { timeout: 100 * index } : {})}>
                                <TableRow
                                    // hover
                                    // selected={false}
                                    className={getRowClassname(pack) + " ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                                >
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        className={"ut-xxs-col-12 ut-xxs-center ut-xs-col-12 ut-xs-center ut-s-col-12 ut-s-center ut-m-col-7 ut-m-left ut-l-right ut-xl-right ut-xxl-right"}
                                        // scope="row"
                                    >
                                        <FormControl>
                                            <InputLabel
                                                htmlFor={pack.package_id + "package_dimension_height"}
                                                color="secondary"
                                            >
                                                Height
                                            </InputLabel>
                                            <Input
                                                id={pack.package_id + "package_dimension_height"}
                                                type={"text"}
                                                style={{maxWidth: 80, marginRight: "5px"}}
                                                value={pack.dimension.height}
                                                className="input-text-right"
                                                name="height"
                                                color="secondary"
                                                onChange={e => onPackageSizeChange(e, false)}
                                                onBlur={onFieldBlur}
                                                disabled={!this.isSelected(pack)}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                        >cm</Typography>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel
                                                htmlFor={pack.package_id + "package_dimension_width"}
                                                color="secondary"
                                            >
                                                Width
                                            </InputLabel>
                                            <Input
                                                id={pack.package_id + "package_dimension_width"}
                                                type={"text"}
                                                style={{maxWidth: 80, marginRight: "5px"}}
                                                value={pack.dimension.width}
                                                className="input-text-right"
                                                name="width"
                                                color="secondary"
                                                // autoFocus={true}
                                                onChange={e => onPackageSizeChange(e, false)}
                                                onBlur={onFieldBlur}
                                                disabled={!this.isSelected(pack)}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                        >cm</Typography>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel
                                                htmlFor={pack.package_id + "package_dimension_length"}
                                                color="secondary"
                                            >
                                                Length
                                            </InputLabel>
                                            <Input
                                                id={pack.package_id + "package_dimension_length"}
                                                type={"text"}
                                                style={{maxWidth: 80}}
                                                value={pack.dimension.length}
                                                className="input-text-right"
                                                name="length"
                                                color="secondary"
                                                // autoFocus={true}
                                                onChange={e => onPackageSizeChange(e, false)}
                                                onBlur={onFieldBlur}
                                                disabled={!this.isSelected(pack)}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                        >cm</Typography>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        className={"ut-xxs-col-5 ut-xxs-center ut-xs-col-5 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-5 ut-m-right"}
                                    >
                                        <FormControl>
                                            <InputLabel
                                                htmlFor={pack.package_id + "package_weight"}
                                                color="secondary"
                                            >
                                                Weight
                                            </InputLabel>
                                            <Input
                                                id={pack.package_id + "package_weight"}
                                                type={"text"}
                                                style={{maxWidth: 80}}
                                                value={pack.weight}
                                                className="input-text-right"
                                                name="weight"
                                                color="secondary"
                                                // autoFocus={true}
                                                onChange={onPackageWeightChange}
                                                onBlur={onFieldBlur}
                                                disabled={!this.isSelected(pack)}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                        >Kg</Typography>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell
                                        // align={header[item.id].align}
                                        component="td"
                                        scope="row"
                                        className={"ut-xxs-col-7 ut-xxs-center ut-xs-col-7 ut-xs-left ut-s-col-6 ut-s-left ut-m-col-12 ut-m-center"}
                                    >
                                        <FormControl>
                                            <InputLabel
                                                id={"package_unit" + pack.package_id}
                                                color="secondary"
                                            >Package unit</InputLabel>
                                            <CustomSelect
                                                labelId={"package_unit" + pack.package_id}
                                                id={"package_unit" + pack.package_id}
                                                ref={"package_unit" + pack.package_id}
                                                name="unit"
                                                label={'Package unit'}
                                                style={{minWidth: "150px"}}
                                                value={pack.hasOwnProperty("package_unit") ? pack.package_unit : ""}
                                                color="secondary"
                                                onChange={e => changePackageUnit(e)}
                                                disabled={!this.isSelected(pack)}
                                            >
                                                {Object.keys(units).map((key, i) => (
                                                    <MenuItem key={i} id={"unit"+pack.package_id+key} value={key}>{units[key]}</MenuItem>
                                                ))}
                                            </CustomSelect>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </Grow>
                        ))}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }
}

Packages.propTypes = {
    classes: PropTypes.any,
    packages: PropTypes.array,
    units: PropTypes.any,
    getRowClassname: PropTypes.func,
    changePackageUnit: PropTypes.func,
    onPackageSizeChange: PropTypes.func,
    onPackageWeightChange: PropTypes.func,
    onFieldBlur: PropTypes.func,
    actualFounded: PropTypes.any,
    ACL: PropTypes.any,
    searchItem: PropTypes.func
};

Packages.defaultProps = {
    classes: {},
    packages: [],
    units: {}
};

export default Packages;
