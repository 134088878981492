/**
 * Copyright ©2022 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Select component
 * ---------------
 */

import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

class CustomSelect extends React.Component {
    render() {
        return (
            <Select {...this.props} MenuProps={{ className: "full-width-select" }}>
                <MenuItem className={'select-label'} value="-">
                    <InputLabel>{this.props.label}</InputLabel>
                </MenuItem>

                { this.props.children }

                <MenuItem className={'cancel-button'} value="">
                    <Button variant={"contained"} color="secondary">{'Vissza'}</Button>
                </MenuItem>
            </Select>
        );
    }
}

export default CustomSelect;

CustomSelect.propTypes = Select.propTypes;
