/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Role Service component
 * ---------------
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

/**
 * Import used stores
 */
import RoleStore from "../stores/RoleStore";

/**
 * Import used services
 */
import LoaderService from "./Loader";

class roleService extends EventEmitter {

    /**
     * Set the component defaults
     */
    constructor() {
        super();

        this.timer = false;
        this.state = {};

        /**
         * Bind functions to this
         */
        this.success = this.success.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Set things when component is unmounting
     */
    componentWillUnmount(){
        this.stop();
    }

    /**
     * Start service
     */
    start() {
        if (this.timer !== false) { return; }

        this.fetchData();
        // this.timer = setInterval(() => {
        //     this.fetchData();
        // }, 3600 * seconds);
    }

    /**
     * Stop the service
     */
    stop() {
        clearInterval(this.timer);
        this.timer = false;
    }

    /**
     * Fetch the data
     */
    fetchData() {
        LoaderService.getData("role", "", this.success);
    }

    /**
     * Handle success data
     * @param result
     */
    success(result) {
        if (result.hasOwnProperty("_embedded") && result._embedded.hasOwnProperty("oauth_role")) {
            let items = {};

            result._embedded.oauth_role.forEach(function (item) {
                items[item.role_id] = item;
            });
            RoleStore.set(items);
        }
    }
}

const RoleService = new roleService;

export default RoleService;