/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order info component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

// import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import {Typography} from "@material-ui/core";
import ViewportStore from "../../../../../stores/ViewportStore";
import Grid from "@material-ui/core/Grid";
import Dialog from "../../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

class ItemInfo extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            zebra: ViewportStore.get(),
            image_modal_open: false
        };

        this.showImageModal = this.showImageModal.bind(this);
        this.hideImageModal = this.hideImageModal.bind(this);
        this.getEAN = this.getEAN.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        ViewportStore.on("change", () => {
            this.setState({zebra: ViewportStore.get()});
        });
    }

    showImageModal() {
        this.setState({image_modal_open: true});
    }

    hideImageModal() {
        this.setState({image_modal_open: false});
    }

    getEAN(eans) {
        if (!eans) {
            return "";
        }
        eans.forEach(function (ean) {
            if (ean.charAt(0) === "2") {
                return ean;
            }
        });

        return eans[0];
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            founded_item,
            layout_type
        } = this.props;
        let comission_note = (founded_item.item.comission_note && founded_item.item.comission_note.length > 100) ? founded_item.item.comission_note.substring(0, 100) + "..." : founded_item.item.comission_note;
        if (!comission_note) {
            comission_note = (founded_item.item.accessory && founded_item.item.accessory.length > 100) ? founded_item.item.accessory.substring(0, 100) + "..." : founded_item.item.accessory;
        }

        return (
            <React.Fragment>
            {layout_type === "card" && (
                <Paper
                    square={true}
                    elevation={1}
                    style={{display: "block"}}
                >
                    <React.Fragment>
                        <Grid style={{display: "inline-grid", width: "50%", textAlign: "center", padding: "10px"}}
                              className={"bold"}
                              item={true}
                              xs={6}>
                            {founded_item.item.sku}
                        </Grid>
                        <Grid style={{display: "inline-grid", width: "50%", textAlign: "center", padding: "10px", borderLeft: "1px dashed silver"}}
                              className={"bold"}
                              item={true}
                              xs={6}>
                            {this.getEAN(founded_item.item.ean)}
                        </Grid>

                        <Grid style={{display: "block", textAlign: "center", padding: "10px", backgroundColor: "rgba(0, 0, 0, 0.07)"}}
                              className={"bold"}
                              item={true}
                              xs={12}>{founded_item.item.name}
                        </Grid>

                        {founded_item.item.image_url ? (
                            <div style={{
                                width: "50%",
                                height: this.state.zebra ? "150px" : "260px",
                                textAlign: "center",
                                paddign: "3px",
                                borderRight: "1px dashed silver",
                                display: "inline-block",
                                float: "left"
                            }}>
                                <img style={{objectFit: "contain", width: "100%", height: "100%"}}
                                     src={founded_item.item.image_url}
                                     onClick={this.showImageModal}
                                     alt={"Product image"}/>
                            </div>
                        ) : (
                            <Skeleton variant="rect" width={"80%"} height={100} className="float-right"/>
                        )}
                        <div style={{width: "50%", padding: "5px", display: "inline-block", float: "left"}}>
                            <div style={{fontSize: "12px", fontWeight: "bold"}}>
                                {comission_note}
                            </div>
                        </div>

                        <div style={{clear: "both", borderTop: "1px dashed silver"}}></div>
                    </React.Fragment>
                </Paper>
                )}

                {layout_type === "default" && (
                    <Paper
                        square={true}
                        elevation={1}
                        style={{display: "flex"}}
                    >
                    <React.Fragment>
                        {founded_item.item.image_url ? (
                            <div style={{width: this.state.zebra ? "150px" : "260px", height: this.state.zebra ? "150px" : "260px",
                                textAlign: "center", paddign: "3px", margin: "2px", border: "1px dashed silver"}}>
                            <img style={{objectFit: "contain", width: "100%", height: "100%"}}
                                 src={founded_item.item.image_url}
                                 alt={"Order product image"}/>
                            </div>
                        ) : (
                            <Skeleton variant="rect" width={"80%"} height={100} className="float-right"/>
                        )}
                        <div style={{width: "60%", margin: "4px"}}>
                            <React.Fragment>
                                <Typography variant="caption" className="bold">{founded_item.item.name}</Typography><br/>
                            </React.Fragment>

                            <Typography variant="caption" className="bold">{founded_item.item.sku}</Typography><br/>
                            <div style={{wordBreak: "break-all", fontSize: "12px", fontWeight: "bold"}}>
                                {comission_note}
                            </div>
                        </div>
                    </React.Fragment>
                    </Paper>
                )}

                <Dialog
                    title='Product image'
                    open={this.state.image_modal_open}
                    onClose={this.hideImageModal}
                    onOk={this.hideImageModal}
                    okText='Close'
                >
                    <DialogContentText>
                        <img style={{objectFit: "contain", width: "100%", height: "100%"}}
                             src={founded_item.item.image_url}
                             alt={"Product image"}/>
                    </DialogContentText>
                </Dialog>

            </React.Fragment>
        );
    }
}

ItemInfo.propTypes = {
    founded_item: PropTypes.any,
    layout_type: PropTypes.any,
    classes: PropTypes.any,
};

ItemInfo.defaultProps = {
    founded_item: {},
    layout_type: "default",
    classes: {}
};

export default ItemInfo;