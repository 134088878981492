/**
 * Copyright © 2022 ITG Commerce. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Order view controls component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

class OrderControls extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            order,
            settings,
            cancelOrder,
            saveOrder,
            print
        } = this.props;

        return (
            <Grid item xs={12} sm={12} style={{textAlign:"center", justifyContent: "center"}}>
                <Divider style={{marginBottom: "10px"}}/>
                <div style={{display: "none"}}>Controls: {order.increment_id}</div>

                {settings.row_save && (
                    <Button
                        variant="contained"
                        disabled={false}
                        color={"primary"}
                        className={""}
                        style={{marginRight: "5px"}}
                        onClick={saveOrder}
                    >
                        Lezár
                    </Button>
                )}

                {settings.row_print && (
                    <Button
                        variant="contained"
                        disabled={false}
                        color={"secondary"}
                        className={""}
                        style={{marginRight: "5px"}}
                        onClick={print}
                    >
                        Nyomtat
                    </Button>
                )}

                <Button
                    variant="contained"
                    disabled={false}
                    color={"secondary"}
                    className={""}
                    onClick={cancelOrder}
                >
                    Vissza
                </Button>

            </Grid>
        );
    }
}

OrderControls.propTypes = {
    order: PropTypes.any,
    settings: PropTypes.any,
    cancelOrder: PropTypes.func,
    saveOrder: PropTypes.func,
    print: PropTypes.func
};

OrderControls.defaultProps = {
    order: {},
    settings: {}
};

export default OrderControls;