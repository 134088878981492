/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Packages component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
// import LoaderService from "../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import GradientIcon from "@material-ui/icons/Gradient";
import BuildIcon from "@material-ui/icons/Build";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Remove from "@material-ui/icons/Delete";

class Packages extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            show_lines: true,
        };

        this.getEAN = this.getEAN.bind(this);
        this.blurElements = this.blurElements.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    getEAN(eans) {
        eans.forEach(function (ean) {
            if (ean.charAt(0) === "2") {
                return ean;
            }
        });

        return eans[0];
    }

    blurElements() {
        setTimeout(function () {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            window.focus();
        }, 100);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            packages,
            getRowClassname,
            selectedPackage,
            selectPackage,
            increasePackageItem,
            decreasePackageItem,
            togglePackageDimensionsDialog,

        } = this.props;
        return (
            <React.Fragment>
                {packages.map((pack, pack_index) => (
                    <ExpansionPanel
                        key={pack_index}
                        defaultExpanded
                        expanded={selectedPackage === pack_index}
                        onChange={this.blurElements}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="package-content"
                            onClick={() => {selectPackage(pack_index);}}
                        >
                            <Badge
                                color="secondary"
                                className="package-name"
                                badgeContent={pack.qty}
                                invisible={false}
                                onClick={() => {togglePackageDimensionsDialog(pack);}}
                                style={{cursor: "pointer", alignSelf: "center"}}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                            >
                                <Badge
                                    color={(pack.dimension.height === "" || pack.dimension.width === "" || pack.dimension.length === "") ? "primary" : "secondary"}
                                    invisible={false}
                                    variant="dot"
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                    >
                                        {pack.accessory && (
                                            <BuildIcon
                                                style={{
                                                    width: "17px",
                                                    height: "17px",
                                                    marginRight: "4px"
                                                }}
                                            />
                                        )}
                                        {"Package " + pack.package_id}
                                    </Typography>
                                </Badge>
                            </Badge>
                            <IconButton
                                style={{alignSelf: "flex-end"}}
                                margin="dense"
                                aria-label="toggle password visibility"
                                onClick={()=>this.props.removePackage(pack.package_id)}
                            >
                                <Remove/>
                            </IconButton>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            className={classes.details}
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                >
                                    <Button
                                        startIcon={<AspectRatioIcon/>}
                                        variant="contained"
                                        color="primary"
                                        style={(pack.dimension.height === "" || pack.dimension.width === "" || pack.dimension.length === "") ? {} : {backgroundColor: green[500], "&:hover": { backgroundColor: green[200]}}}
                                        fullWidth={true}
                                        onClick={() => {togglePackageDimensionsDialog(pack);}}
                                    >
                                        {(pack.dimension.height === "" || pack.dimension.width === "" || pack.dimension.length === "") ? "not set" : pack.dimension.height + "x" + pack.dimension.width + "x" + pack.dimension.length + " cm"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                >
                                    <Button
                                        startIcon={<GradientIcon/>}
                                        variant="contained"
                                        color="default"
                                        fullWidth={true}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                        style={{cursor: "default"}}
                                    >
                                        {pack.qty + " db"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                >
                                    <Button
                                        startIcon={<FitnessCenterIcon/>}
                                        variant="contained"
                                        color="default"
                                        fullWidth={true}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                        style={{cursor: "default"}}
                                    >
                                        {pack.weight.toFixed(2) + " kg"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Table className={"responsiveTable"} aria-label="simple table" stickyHeader={false}>
                                        <TableHead elevation={12} className={"MuiPaper-elevation4"}>
                                            <TableRow selected={true}>
                                                <TableCell
                                                    align={"left"}
                                                >
                                                    <Typography color="inherit" variant="caption">EAN</Typography>
                                                </TableCell>
                                                <TableCell
                                                    align={"left"}
                                                >
                                                    <Typography color="inherit" variant="caption">BH cikkszám</Typography>
                                                </TableCell>
                                                <TableCell
                                                    align={"left"}
                                                >
                                                    <Typography color="inherit" variant="caption">Megnevezés</Typography>
                                                </TableCell>
                                                <TableCell
                                                    align={"right"}
                                                >
                                                    <Typography color="inherit" variant="caption">Súly kg</Typography>
                                                </TableCell>
                                                <TableCell
                                                    align={"right"}
                                                >
                                                    <Typography color="inherit" variant="caption">Csomagban db</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pack.items.map((item, index) => (
                                                <Grow
                                                    key={item.item_id} in={this.state.show_lines}
                                                    {...(this.state.show_lines ? { timeout: 100 * index } : {})}>
                                                    <TableRow
                                                        hover
                                                        selected={false}
                                                        className={getRowClassname(item) + " ut-xxs-row ut-xs-row ut-s-row ut-m-row"}
                                                    >
                                                        <TableCell
                                                            // align={header[item.id].align}
                                                            component="td"
                                                            scope="row"
                                                            className="ut-xxs-col-6 ut-xs-col-6 ut-s-col-6 ut-m-col-4"
                                                        >
                                                            {/*<Typography*/}
                                                            {/*    className={"tdBefore"}*/}
                                                            {/*    color="textSecondary"*/}
                                                            {/*    display="block"*/}
                                                            {/*    variant="caption"*/}
                                                            {/*    style={{textAlign: "left"}}*/}
                                                            {/*>*/}
                                                            {/*    EAN*/}
                                                            {/*</Typography>*/}
                                                            {this.getEAN(item.ean)}
                                                        </TableCell>
                                                        <TableCell
                                                            // align={header[item.id].align}
                                                            component="td"
                                                            scope="row"
                                                            className="ut-xxs-col-6 ut-xxs-right ut-xs-col-6 ut-xs-right ut-s-col-6 ut-s-right ut-m-col-3 ut-m-left"
                                                        >
                                                            {/*<Typography*/}
                                                            {/*    className={"tdBefore"}*/}
                                                            {/*    color="textSecondary"*/}
                                                            {/*    display="block"*/}
                                                            {/*    variant="caption"*/}
                                                            {/*    style={{textAlign: "left"}}*/}
                                                            {/*>*/}
                                                            {/*    BH cikkszám*/}
                                                            {/*</Typography>*/}
                                                            {item.sku}
                                                        </TableCell>
                                                        <TableCell
                                                            // align={header[item.id].align}
                                                            component="td"
                                                            scope="row"
                                                            className="ut-xxs-col-12 ut-xs-col-12 ut-s-col-12 ut-m-col-5"
                                                        >
                                                            {/*<Typography*/}
                                                            {/*    className={"tdBefore"}*/}
                                                            {/*    color="textSecondary"*/}
                                                            {/*    display="block"*/}
                                                            {/*    variant="caption"*/}
                                                            {/*    style={{textAlign: "left"}}*/}
                                                            {/*>*/}
                                                            {/*    Megnevezés*/}
                                                            {/*</Typography>*/}
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell
                                                            // align={header[item.id].align}
                                                            component="td"
                                                            scope="row"
                                                            align={"right"}
                                                            className="ut-xxs-col-4 ut-xxs-left ut-xs-col-4 ut-xs-left ut-s-col-4 ut-s-left ut-m-col-6 ut-m-left"
                                                        >
                                                            <Typography
                                                                className={"tdBefore"}
                                                                color="textSecondary"
                                                                display="block"
                                                                variant="caption"
                                                                style={{textAlign: "left"}}
                                                            >
                                                                Súly (kg)
                                                            </Typography>
                                                            {(Number(item.weight) * Number(item.qty)).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell
                                                            // align={header[item.id].align}
                                                            component="td"
                                                            scope="row"
                                                            align={"right"}
                                                            className="ut-xxs-col-8 ut-xxs-right ut-xs-col-8 ut-xs-right ut-s-col-8 ut-s-right ut-m-col-6 ut-m-right"
                                                        >
                                                            {/*<Typography*/}
                                                            {/*    className={"tdBefore"}*/}
                                                            {/*    color="textSecondary"*/}
                                                            {/*    display="block"*/}
                                                            {/*    variant="caption"*/}
                                                            {/*    style={{textAlign: "left"}}*/}
                                                            {/*>*/}
                                                            {/*    Csomagban (db)*/}
                                                            {/*</Typography>*/}
                                                            <FormControl style={{maxWidth: 150, marginTop: "4px"}}>
                                                                <Input
                                                                    id="founded_nr"
                                                                    type={"text"}
                                                                    value={Number(item.qty)}
                                                                    name="founded_nr"
                                                                    color="secondary"
                                                                    className={"text-center big-input-text"}
                                                                    // onChange={foundedChanged}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <IconButton
                                                                                className="decrease-item"
                                                                                aria-label="Decrease item"
                                                                                onClick={() => {decreasePackageItem(item);}}
                                                                            >
                                                                                <RemoveCircleIcon />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                className="increase-item"
                                                                                aria-label="Increase item"
                                                                                onClick={() => {increasePackageItem(item);}}
                                                                            >
                                                                                <AddCircleIcon />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                    </TableRow>
                                                </Grow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </React.Fragment>
        );
    }
}

Packages.propTypes = {
    selectedPackage: PropTypes.any,
    selectPackage: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    packages: PropTypes.array,
    getRowClassname: PropTypes.func,
    increasePackageItem: PropTypes.func,
    decreasePackageItem: PropTypes.func,
    togglePackageDimensionsDialog: PropTypes.func,
    removePackage: PropTypes.func,
    setScannerActive: PropTypes.func,
    ACL: PropTypes.any
};

Packages.defaultProps = {
    selectedPackage: 0,
    classes: {},
    packages: []
};

export default Packages;