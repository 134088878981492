/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Edit dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "../../../common/dialog";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "../../../common/switch/OnOffSwitch";
import ConfigDialog from "../../../common/settings/config";
import PrinterTypeStore from "../../../../stores/PrinterTypeStore";

class EditMenuDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            printer_type: PrinterTypeStore.getAll(),
            configuration_dialog_open: false,
            actual_edit: {},
        };

        this.configurationDialogOpen = this.configurationDialogOpen.bind(this);
        this.configurationDialogClose = this.configurationDialogClose.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    configurationDialogOpen() {
        this.setState({configuration_dialog_open: true});
    }

    configurationDialogClose() {
        this.setState({configuration_dialog_open: false});
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            classes,
            open,
            onOk,
            onClose,
            onCancel,
            onChange,
            edit,
            data,
            onConfigurationChanged
        } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    title={edit ? "Edit printer" : "Add new printer"}
                    open={open}
                    onOk={onOk}
                    onClose={onClose}
                    onCancel={onCancel}
                    cancelText="Cancel"
                    okText="Save"
                    maxWidth="sm"
                >
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="name_input"
                                    color="secondary"
                                >
                                    Name
                                </InputLabel>
                                <Input
                                    id="code_input"
                                    type={"string"}
                                    value={data.name === null || data.name === undefined ? "" : data.name}
                                    name="name"
                                    color="secondary"
                                    autoFocus={true}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="label_input"
                                    color="secondary"
                                >
                                    Barcode
                                </InputLabel>
                                <Input
                                    id="barcode_input"
                                    type={"string"}
                                    value={data.barcode === null || data.barcode === undefined ? "" : data.barcode}
                                    name="barcode"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    htmlFor="label_input"
                                    color="secondary"
                                >
                                    Host
                                </InputLabel>
                                <Input
                                    id="host_input"
                                    type={"string"}
                                    value={data.host === null || data.host === undefined ? "" : data.host}
                                    name="host"
                                    color="secondary"
                                    autoFocus={false}
                                    onChange={onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel
                                    id={"printer_type_input"}
                                    color="secondary"
                                >Printer type</InputLabel>
                                <Select
                                    labelId={"printer_type_input"}
                                    id={"printer_type_input"}
                                    name="printer_type"
                                    style={{minWidth: "150px", textAlign: "left"}}
                                    value={data.printer_type === null || data.printer_type === undefined ? "" : data.printer_type}
                                    color="secondary"
                                    onChange={onChange}
                                >
                                    {Object.keys(this.state.printer_type).map((key, i) => (
                                        <MenuItem key={i} value={key}>{this.state.printer_type[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{textAlign: "left"}}>
                            <FormControlLabel
                                style={{marginTop: "10px", textAlign: "left"}}
                                control={
                                    <Switch name="enabled" checked={data.enabled === "1"} onChange={onChange} value={data.enabled} />
                                }
                                label="Enabled"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={() => {this.configurationDialogOpen();}}
                            >
                                Show configuration
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <ConfigDialog
                    classes={classes}
                    open={this.state.configuration_dialog_open}
                    edit={this.state.is_edit}
                    data={data.config}
                    onCancel={this.configurationDialogClose}
                    onClose={this.configurationDialogClose}
                    onConfigurationChanged={onConfigurationChanged}
                />
            </React.Fragment>
        );
    }
}

EditMenuDialog.propTypes = {
    classes: PropTypes.any,
    open: PropTypes.any,
    onOk: PropTypes.any,
    onClose: PropTypes.any,
    onCancel: PropTypes.any,
    onChange: PropTypes.any,
    data: PropTypes.any,
    edit: PropTypes.any,
    onConfigurationChanged: PropTypes.any,
};

EditMenuDialog.defaultProps = {
    open: false,
    false: false,
    data: {},
    classes: {}
};

export default EditMenuDialog;