/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Item not need dialog component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import DialogContentText from "@material-ui/core/DialogContentText";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Dialog from "../../../dialog";

class ItemNotNeedDialog extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        const {
            open,
            onClose,
            onOk,
        } = this.props;
        return (
            <Dialog
                title="Too many items!"
                open={open}
                onClose={onClose}
                onOk={onOk}
                primaryAnimation="button-sonar"
                okText="I understand"
                type="error"
            >
                <DialogContentText>
                    <NotInterestedIcon
                        className="blink"
                        style={{fontSize: "60px"}}
                    />
                </DialogContentText>
                <DialogContentText>
                    Please do not put this item in to package
                </DialogContentText>
            </Dialog>
        );
    }
}

ItemNotNeedDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    onOk: PropTypes.any,
};

ItemNotNeedDialog.defaultProps = {
    open: false
};

export default ItemNotNeedDialog;