/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Consolidation component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";

/**
 * Import used services
 */
import LoaderService from "../../../../services/Loader";

/**
 * Import other used components
 */

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "../../dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import SettingsIcon from "@material-ui/icons/Settings";
import ShippingMethodStore from "../../../../stores/ShippingMethodStore";

import Header from "../common/header";
import ItemInfo from "../common/itemInfo";
import ProgressBar from "../common/progressBar";
import Founded from "./Founded";
import CodeInputDialog from "../common/codeInputDialog";
import NotificationService from "../../../../services/Notification";
import SettingsDataStore from "../../../../stores/SettingsDataStore";
import Suppliers from "../common/suppliers";
import OrderSaveDialog from "../common/orderSaveDialog";
import ItemNotNeedDialog from "../common/itemNotNeedDialog";
import OrderListDialog from "../common/orderListDialog";
import OrderInfoDialog from "../common/orderInfoDialog";
import PleaseScan from "../common/pleaseScan";
import SaveIcon from "@material-ui/icons/Save";
import OrderListTable from "../common/orderListTable";
import BarcodeReader from "../common/barcodeReader";
import FocusLock from "react-focus-lock";
import OrderNotEditableDialog from "../common/orderNotEditableDialog";
import Settings from "./settings";
import SpeedDial from "../../speedDial";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {checkDownloadables} from "../../utils/downloadFile";
import RoleStore from "../../../../stores/RoleStore";
import userDataStore from "../../../../stores/UserDataStore";

class Consolidation extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
        this.loading = false;
        this.keys = "";
        this.key_time = 0;
        this.rootRef = React.createRef();
        this.menu_id = "";

        this.state = {
            scannerActive: true,
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            total_count: 0,
            founded_count: 0,
            enter_code_dialogue: false,
            speedDial_open: false,
            orders: [],
            data: false,
            actual_founded: false,
            alert_open: false,
            warning_open: false,
            warning_message: "",
            can_save: false,
            save_dialog: false,
            settings: {
                show_product_on_order_list: true,
                header: {
                    show_code_input: false
                }
            },
            tableSettings: {
                partialSave: false,
                saveType: ""
            },
            editable: true,
            notEditableDialog: false,
            message: "",
            error_message: false,
            show_done: false,
            labelPrintCount: 0,
            shouldPrintLabel: false,
            myRole: userDataStore.getData("user") && userDataStore.getData("user").role_id,
            settingsOpen: false,
            status: "",
            storage: "",
            vendorCount: 0
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleClickOrderCode = this.handleClickOrderCode.bind(this);
        this.ordersSuccess = this.ordersSuccess.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.orderSuccess = this.orderSuccess.bind(this);
        this.getFounded = this.getFounded.bind(this);
        this.getDifference = this.getDifference.bind(this);
        this.searchItem = this.searchItem.bind(this);
        this.foundedChanged = this.foundedChanged.bind(this);
        this.increaseToFounded = this.increaseToFounded.bind(this);
        this.decreaseToFounded = this.decreaseToFounded.bind(this);
        this.getRowClassname = this.getRowClassname.bind(this);
        this.warningClose = this.warningClose.bind(this);
        this.newOrderAlertClose = this.newOrderAlertClose.bind(this);
        this.newOrderAlertOk = this.newOrderAlertOk.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkReadyToSave = this.checkReadyToSave.bind(this);
        this.toggleShowEnterCode = this.toggleShowEnterCode.bind(this);
        this.closeCodeDialogue = this.closeCodeDialogue.bind(this);
        this.preventClose = this.preventClose.bind(this);
        this.saveOrderOk = this.saveOrderOk.bind(this);
        this.saveOrderSuccess = this.saveOrderSuccess.bind(this);
        this.notNeedOk = this.notNeedOk.bind(this);
        this.orderListCancel = this.orderListCancel.bind(this);
        this.closeOrderInfo = this.closeOrderInfo.bind(this);
        this.openOrderInfo = this.openOrderInfo.bind(this);
        this.reset = this.reset.bind(this);
        this.cancelSaveOrder = this.cancelSaveOrder.bind(this);
        this.searchBarcode = this.searchBarcode.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.notEditableDialogOk = this.notEditableDialogOk.bind(this);
        this.setScannerActive = this.setScannerActive.bind(this);
        this.ordersError = this.ordersError.bind(this);
        this.saveTableSettings = this.saveTableSettings.bind(this);
        this.loadTableSettings = this.loadTableSettings.bind(this);
        this.loadTableSettingsSuccess = this.loadTableSettingsSuccess.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
    }

    /**
     * Set things when component is mounting
     */
    componentDidMount() {
        let settings = SettingsDataStore.getData(this.props.pageData.layout);


        this.loadTableSettings();

        if (settings !== undefined) {
            this.setState({settings: settings});
        }

        const labelSettings = JSON.parse(RoleStore.get(this.state.myRole, "settings"));
        this.setState({shouldPrintLabel: labelSettings ? labelSettings.label_printing : false});
        RoleStore.addChangeListener("change", this.onRoleChange);
    }

    componentDidUpdate() {
        this.checkTableSettings(this.props.pageData);
    }

    componentWillUnmount() {
        RoleStore.removeAllListeners();
    }

    onRoleChange() {
        const settings = JSON.parse(RoleStore.get(this.state.myRole, "settings"));
        this.setState({shouldPrintLabel: settings ? settings.label_printing : false});
    }

    loadTableSettings() {
        LoaderService.getData(
            "table_settings/table/" + this.props.pageData.menu_id + "/role_id/" + this.state.myRole,
            "",
            this.loadTableSettingsSuccess,
            this.notFoundTableSettings,
            false
        );
    }

    reset() {
        this.setState({
            order_info_dialog: false,
            order_list_dialog: false,
            is_too_mouch: false,
            total_count: 0,
            founded_count: 0,
            enter_code_dialogue: false,
            speedDial_open: false,
            orders: [],
            data: false,
            actual_founded: false,
            alert_open: false,
            warning_open: false,
            warning_message: "",
            can_save: false,
            save_dialog: false,
            just_item_info: false,
            notEditableDialog: false,
            editable: true,
            message: "",
            status: "",
            lastScanned: "",
        });
    }

    scrollToTop() {
        if (this.state.settings.scroll_to_top_on_scan) {
            this.rootRef.current.scrollTo(0, 0);
        }
    }

    /**
     * Show/Hide enter code dialogue
     */
    toggleShowEnterCode() {
        this.setState({enter_code_dialogue: !this.state.enter_code_dialogue});
    }

    closeCodeDialogue() {
        if (!this.state.enter_code_dialogue) {
            return false;
        }
        this.setState({enter_code_dialogue: false});
    }

    saveTableSettings(settings) {
        console.log(settings);
    }

    loadTableSettingsSuccess(res) {
        if (res.settings) {
            const settings = JSON.parse(res.settings);
            this.setState({tableSettings: settings.settings});
        }
    }

    notFoundTableSettings(res) {
        console.log(res);
    }

    /**
     * Check order is ready to save
     */
    checkReadyToSave() {
        let self = this,
            total_count = 0,
            founded_count = 0;

        self._can_save = true;
        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item) {
                if (item.founded !== Number(item.qty)) {
                    self._can_save = false;
                }
                total_count += Number(item.qty);
                if (item.hasOwnProperty("founded")) {
                    founded_count += Number(item.founded);
                }
            });
        });


        this.setState({
            can_save: this._can_save,
            save_dialog: this._can_save,
            total_count: total_count,
            founded_count: founded_count
        });

        if (this._can_save) {
            this.checkModalAutoFocus();
        }
    }

    /**
     * Handle new order request form the user
     * @returns {boolean}
     */
    newOrder() {
        if (this.state.data !== false) {
            this.setState({alert_open: true, speedDial_open: false});
            return false;
        }

        this.reset();
    }


    /**
     * Return the row classname depend on founded calculation
     * @param item
     * @returns {string}
     */
    getRowClassname(item) {
        if (!item.hasOwnProperty("founded")) {
            return " normal";
        }

        if (Number(item.qty) > item.founded) {
            return " yellow";
        }

        if (item.founded > Number(item.qty)) {
            return " red blink";
        }

        if (item.founded === Number(item.qty)) {
            return " green";
        }
    }

    /**
     * Increase item founded count
     * @param founded_item
     */
    increaseToFounded(founded_item) {
        if (Number(founded_item.item.qty) === Number(founded_item.item.founded)) {
            this.setState({is_too_mouch: true});
            return false;
        }
        founded_item.item.founded++;
        this.setState({actual_founded: founded_item});
        this.checkReadyToSave();
    }

    /**
     * Increase item founded count
     * the 0 is the limit of the minimum
     * @param founded_item
     */
    decreaseToFounded(founded_item) {
        if (founded_item.item.founded === 0) {
            return;
        }

        founded_item.item.founded--;
        this.setState({actual_founded: founded_item});
        this.checkReadyToSave();
    }

    /**
     * Set item founded count by arbitrary value
     * the 0 is the limit of the minimum
     * item.qty is the limit for maximum
     * @param founded_item
     * @param value
     */
    foundedChanged(founded_item, value) {
        if (Number(value) > Number(founded_item.item.qty)) {
            this.setState({is_too_mouch: true});
            return false;
        }

        if (Number(value) < 0) {
            return;
        }

        founded_item.item.founded = Number(value);
        this.setState({actual_founded: founded_item});
        this.checkReadyToSave();
    }

    /**
     * Get founded count or create if not exists
     * @param tableIndex
     * @param index
     * @returns {number|number|*}
     */
    getFounded(tableIndex, index) {
        if (this.state.data.suppliers[tableIndex].items[index].hasOwnProperty("founded")) {
            return this.state.data.suppliers[tableIndex].items[index].founded;
        }
        return 0;
    }

    /**
     * Return the founded and qty difference
     * @param tableIndex
     * @param index
     * @returns {number}
     */
    getDifference(tableIndex, index) {
        let founded = 0;
        if (this.state.data.suppliers[tableIndex].items[index].hasOwnProperty("founded")) {
            founded = this.state.data.suppliers[tableIndex].items[index].founded;
        }

        return Number(founded) - Number(this.state.data.suppliers[tableIndex].items[index].qty);
    }


    handleClickOrderCode(order_code) {
        if (order_code === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({lastScanned: order_code});
        this.closeCodeDialogue();
        this.fetchData("consolidation/" + this.props.pageData.menu_id + "/" + order_code, this.ordersSuccess, (e) => this.ordersError(e));
    }

    ordersError(e) {
        if (!e.message) return;
        alert(e.message);
        // this.setState({error_message: e.message});
    }

    loadOrder(id) {
        if (id === "") {
            NotificationService.showNotification("error", "The order code is empty");
            return false;
        }
        this.setState({order_list_dialog: false});
        // this.closeCodeDialogue();
        this.fetchData("consolidation/" + this.props.pageData.menu_id + "/" + id, this.ordersSuccess);
    }

    searchItem(item_code) {
        let self = this,
            isTooMouch = false,
            isFounded = false;

        if (item_code === "") {
            NotificationService.showNotification("error", "The item code is empty");
            return false;
        }

        if (!this.state.data.hasOwnProperty("suppliers")) {
            NotificationService.showNotification("error", "Please enter the order code first");
            return false;
        }

        this.setState({lastScanned: item_code});
        this.closeCodeDialogue();


        if (!this.state.data.ean.hasOwnProperty(item_code)) {
            this.setState({actual_founded: false});
            // this.setState({error_message: "The item not found in this order"});
            alert("The item not found in this order");
            return false;
        }

        let ean_items = this.state.data.ean[item_code];

        this.state.data.suppliers.forEach(function (supplier) {
            supplier.items.forEach(function (item, item_index) {
                ean_items.forEach(function (ean_item) {
                    if (ean_item === item.item_id) {
                        if (!item.hasOwnProperty("founded")) {
                            item.founded = 0;
                        }
                        if (Number(item.qty) === Number(item.founded)) {
                            isTooMouch = true;
                        } else {
                            item.founded++;
                            isFounded = true;
                            self.setState({actual_founded: {item: item, index: item_index}});
                        }
                    }
                });
            });
        });

        if (isTooMouch && !isFounded) {
            this.setState({is_too_mouch: true});
            return false;
        }
        self.scrollToTop();
        self.checkReadyToSave();
    }

    ordersSuccess(result) {
        this.loading = false;
        let just_item_info = false;

        if (result.hasOwnProperty("_embedded")) {

            if (result._embedded.hasOwnProperty("orders")) {
                if (result._embedded.orders.length === 1) {
                    if (result._embedded.orders[0].hasOwnProperty("increment_id") && this.state.settings.auto_load_order) {
                        this.loadOrder(result._embedded.orders[0].increment_id);
                        this.setState({orders: [], just_item_info: false});
                        return;
                    }
                }
                if (result._embedded.hasOwnProperty("product")) {
                    just_item_info = {item: result._embedded.product};
                }

                this.setState({
                    orders: result._embedded.orders,
                    order_list_dialog: true,
                    just_item_info: just_item_info
                });
            } else {
                if (result._embedded[0].hasOwnProperty("suppliers")) {
                    if (!result._embedded[0].editable) {
                        this.setState({
                            data: false,
                            orders: [],
                            just_item_info: false,
                            notEditableDialog: !result._embedded[0].editable,
                            editable: result._embedded[0].editable,
                            message: result._embedded[0].message,
                            status: result._embedded[0].status,
                            storage: result._embedded[0].storage,
                            vendorCount: result._embedded[0].vendor_count
                        });
                        return;
                    }
                    this.setState({
                        data: result._embedded[0],
                        orders: [],
                        just_item_info: false,
                        notEditableDialog: !result._embedded[0].editable,
                        editable: result._embedded[0].editable,
                        message: result._embedded[0].message,
                        status: result._embedded[0].status,
                        storage: result._embedded[0].storage,
                        vendorCount: result._embedded[0].vendor_count
                    });

                    if (result._embedded[0].other_info &&
                        this.state.tableSettings &&
                        this.state.tableSettings.hasOwnProperty("vendorWarning") &&
                        this.state.tableSettings.vendorWarning
                    ) {
                        this.setState({warning_open: true, warning_message: result._embedded[0].other_info});
                    }

                    this.checkReadyToSave();
                }
            }
        }
    }

    orderSuccess(result) {
        this.loading = false;

        if (result.hasOwnProperty("status")) {
            if (!result.editable) {
                this.setState({
                    data: false,
                    orders: [],
                    just_item_info: false,
                    notEditableDialog: !result.editable,
                    editable: result.editable,
                    message: result.message,
                    status: result.status
                });
                return;
            }
            this.setState({
                data: result,
                just_item_info: false,
                notEditableDialog: !result.editable,
                editable: result.editable,
                message: result.message,
                status: result.status
            });
            this.checkReadyToSave();
        }
    }

    fetchData(what, callback, errorCallback) {
        this.loading = true;
        LoaderService.getData(what, "", callback, errorCallback);
    }

    newOrderAlertClose() {
        this.setState({alert_open: false});
    }

    warningClose() {
        this.setState({warning_open: false, warning_message: ""});
    }

    newOrderAlertOk() {
        this.reset();
    }

    preventClose(e) {
        e.preventDefault();
    }

    saveOrderOk(status) {
        let _data = Object.assign(this.state.data, {status});
        if (this.state.labelPrintCount) {
            _data.print_label = this.state.labelPrintCount;
        }
        if (this.state.storage) {
            _data.storage = this.state.storage;
        }

        if (this.state.tableSettings && this.state.tableSettings.partialSave && !this.state.can_save) {
            _data.partial_save =  this.state.tableSettings.saveType;
            _data.suppliers.forEach(sup => {
                sup.items.forEach(itm => {
                   if (!itm.hasOwnProperty("founded")) {
                       itm.founded = 0;
                   }
                });
            });
        } else {
            _data.partial_save =  false;
        }
        LoaderService.postData("consolidation/" + this.props.pageData.menu_id + "/" + this.state.data.increment_id, _data, this.saveOrderSuccess);
    }

    saveOrderSuccess(result) {
        if (result.hasOwnProperty("_embedded")) {
            result._embedded.forEach(res => {
                checkDownloadables(res);
            });
        }

        if (result.hasOwnProperty("_embedded")) {
            this.reset();
        }

        this.setState({labelPrintCount: 0, storage: "", vendorCount: 0});
    }

    cancelSaveOrder() {
        this.setState({save_dialog: false});
    }

    notNeedOk() {
        this.setState({is_too_mouch: false});
    }

    notEditableDialogOk() {
        this.setState({notEditableDialog: false});
    }

    orderListCancel() {
        this.setState({order_list_dialog: false});
    }

    closeOrderInfo() {
        this.setState({order_info_dialog: false});
    }

    openOrderInfo() {
        this.setState({order_info_dialog: true});
    }

    searchBarcode(keys) {
        if (this.state.data !== false) {
            if (keys !== undefined) {
                this.searchItem(keys);
            }
        } else {
            if (keys !== undefined) {
                this.handleClickOrderCode(keys);
            }
        }
    }


    setScannerActive(val) {
        this.setState({scannerActive: val});
    }

    checkTableSettings(pageData) {
        if (pageData.menu_id !== this.menu_id) {
            this.menu_id = pageData.menu_id;
            this.loadTableSettings();
        }
    }

    checkModalAutoFocus() {
        let _self = this;

        // @TODO: itt feltételes fókusz beállításra volt szükség, biztosan van ennél szebb megoldás is, én nem találtam
        setTimeout(() => {
            let okButton = document.querySelector(".button-sonar");
            if (okButton) {
                okButton.focus();
            }

            if (!_self.state.storage) {
                let storageTextField = document.querySelector(".storage-text-field input");
                if (storageTextField) {
                    storageTextField.focus();
                }
            }
        }, 1000);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render() {
        const {
            pageData,
            classes,
            acl
        } = this.props;

        return (
          <div className={classes.tableRoot} ref={this.rootRef}>
            <FocusLock>
              <BarcodeReader
                onEnter={this.searchBarcode}
                preventDefault={false}
                disabled={
                  !this.state.scannerActive ||
                  this.state.notEditableDialog ||
                  this.state.save_dialog ||
                  this.state.order_info_dialog ||
                  this.state.alert_open ||
                  this.state.order_list_dialog ||
                  this.state.is_too_mouch
                }
              />
              <div style={{ display: 'flex', width: '100%' }}>
                <Header
                  style={{ flex: 1 }}
                  increment_id={this.state.data.increment_id}
                  classes={classes}
                  handleClickOrderCode={this.handleClickOrderCode}
                  searchItem={this.searchItem}
                  status={this.state.data.status}
                  webshopStatus={this.state.data.webshop_status_label}
                  vendors={this.state.data.vendors}
                  created={this.state.data.created_at_date}
                  settings={this.state.settings.header}
                  showItemInfo={this.state.settings.show_product_on_order_list}
                  just_item_info={this.state.just_item_info}
                />
                {this.state.data.hasOwnProperty('suppliers') && (
                  <div
                    style={{ height: '100%', flex: 1 }}
                    onClick={() => this.setState({ show_done: true })}
                  >
                    <ProgressBar
                      lastScanned={this.state.lastScanned}
                      key={'progress_bar'}
                      total={this.state.total_count}
                      actual={this.state.founded_count}
                      percentOne={
                        this.state.founded_count === 0
                          ? 0
                          : (Number(this.state.founded_count) /
                              Number(this.state.total_count)) *
                            100
                      }
                    />
                  </div>
                )}
              </div>
              <Grid item xs={12}>
                {this.state.just_item_info !== false &&
                  this.state.just_item_info !== undefined &&
                  this.state.settings.show_product_on_order_list && (
                    <Slide
                      key={'just_item_info'}
                      direction='down'
                      in={
                        this.state.just_item_info !== false &&
                        this.state.just_item_info !== undefined &&
                        this.state.settings.show_product_on_order_list
                      }
                      mountOnEnter
                      unmountOnExit
                    >
                      <ItemInfo founded_item={this.state.just_item_info} />
                    </Slide>
                  )}
                {this.state.actual_founded && (
                  <Slide
                    key={'founded_item_info'}
                    direction='down'
                    in={this.state.actual_founded !== false}
                    mountOnEnter
                    unmountOnExit
                  >
                    <ItemInfo founded_item={this.state.actual_founded} />
                  </Slide>
                )}
                {this.state.actual_founded && (
                  <Slide
                    key={'founded_setter'}
                    direction='down'
                    in={this.state.actual_founded !== false}
                    mountOnEnter
                    unmountOnExit
                    {...(this.state.actual_founded !== false
                      ? { timeout: 100 }
                      : {})}
                  >
                    <Founded
                      founded_item={this.state.actual_founded}
                      getRowClassname={this.getRowClassname}
                      foundedChanged={this.foundedChanged}
                      increaseToFounded={this.increaseToFounded}
                      decreaseToFounded={this.decreaseToFounded}
                    />
                  </Slide>
                )}
              </Grid>

              {this.state.data.hasOwnProperty('suppliers') && (
                <Suppliers
                  suppliers={this.state.data.suppliers}
                  titleDone={'Done'}
                  show_done={false}
                  getRowClassname={this.getRowClassname}
                  getFounded={this.getFounded}
                  getDifference={this.getDifference}
                />
              )}

              {!this.state.data.hasOwnProperty('suppliers') &&
                !this.state.order_list_dialog && (
                  <PleaseScan title={pageData.title} />
                )}

              {!this.state.settings.show_order_list_dialog &&
                this.state.order_list_dialog && (
                  <Slide
                    direction='up'
                    in={
                      !this.state.settings.show_order_list_dialog &&
                      this.state.order_list_dialog
                    }
                    mountOnEnter
                    unmountOnExit
                  >
                    <OrderListTable
                      onSelect={this.loadOrder}
                      orders={this.state.orders}
                    />
                  </Slide>
                )}

              {(this.state.data ||
                !this.state.settings.header.show_code_input) && (
                <SpeedDial
                  actions={[
                    {
                      icon: <SearchIcon />,
                      label: 'Search',
                      condition: !this.state.settings.header.show_code_input,
                      onClick: () => this.toggleShowEnterCode(),
                      className:
                        !this.state.data && !this.state.enter_code_dialogue
                          ? 'highlight'
                          : '',
                    },
                    {
                      icon: <InfoIcon />,
                      label: 'Details',
                      condition: this.state.data,
                      onClick: () => this.openOrderInfo(),
                    },
                    {
                      icon: <SaveIcon />,
                      label: 'Save',
                      condition:
                        this.state.can_save ||
                        (this.state.data &&
                          this.state.tableSettings &&
                          this.state.tableSettings.partialSave),
                      onClick: () => {
                        this.setState({ save_dialog: true });
                        this.checkModalAutoFocus();
                      },
                      className: this.state.can_save ? 'highlight' : '',
                    },
                    {
                      icon: <AddCircleOutlineIcon />,
                      label: 'New',
                      tooltipTitle: 'New',
                      condition: this.state.data,
                      onClick: () => this.newOrder(),
                    },
                    {
                      icon: <SettingsIcon />,
                      label: 'Settings',
                      tooltipTitle: 'Settings',
                      condition: acl.hasOwnProperty('admin'),
                      onClick: () => this.setState({ settingsOpen: true }),
                    },
                  ]}
                  isHighlit={
                    (!this.state.data && !this.state.enter_code_dialogue) ||
                    this.state.can_save
                  }
                ></SpeedDial>
              )}
              <Dialog
                title='Are you sure to load new order?'
                open={this.state.alert_open}
                onClose={this.newOrderAlertClose}
                onCancel={this.newOrderAlertClose}
                onOk={this.newOrderAlertOk}
                okText='Agree'
                cancelText='Disagree'
              >
                <DialogContentText>
                  All not saved data for this order wil be lost
                </DialogContentText>
              </Dialog>

              <Dialog
                open={this.state.warning_open}
                onClose={this.warningClose}
                onOk={this.warningClose}
                okText='Ok'
                type='success'
              >
                <DialogContentText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.warning_message,
                    }}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '5px',
                      border: '2px solid white',
                      textAlign: 'left',
                      fontSize: '12px',
                      borderRadius: '5px'
                    }}
                  ></div>
                  {this.state.data.shipping_method && (
                    <Typography style={{color: "white"}}>
                      <br/><br/>{ShippingMethodStore.get(
                        this.state.data.shipping_method,
                        'label'
                      )}
                    </Typography>
                  )}
                </DialogContentText>
              </Dialog>

              {this.state.settings.show_order_list_dialog && (
                <OrderListDialog
                  open={this.state.order_list_dialog}
                  onClose={this.orderListCancel}
                  onCancel={this.orderListCancel}
                  onSelect={this.loadOrder}
                  orders={this.state.orders}
                />
              )}

              <OrderInfoDialog
                open={this.state.order_info_dialog}
                onClose={this.closeOrderInfo}
                onCancel={this.closeOrderInfo}
                order={this.state.data}
              />

              <OrderSaveDialog
                allItemsFound={this.state.can_save}
                tableSettings={this.state.tableSettings}
                labelPrintCount={this.state.labelPrintCount}
                storage={this.state.storage}
                showStorage={this.state.vendorCount > 1}
                shouldPrintLabels={this.state.shouldPrintLabel}
                setPrintLabelCount={(labelPrintCount) =>
                  this.setState({ labelPrintCount })
                }
                shippingMethod={this.state.data.shipping_method}
                setStorage={(storage) => this.setState({ storage })}
                statuses={this.props.pageData.order_status_to}
                otherInfo={this.state.data.other_info}
                open={this.state.save_dialog}
                onClose={this.preventClose}
                onOk={this.saveOrderOk}
                onCancel={this.cancelSaveOrder}
              />

              <ItemNotNeedDialog
                open={this.state.is_too_mouch}
                onClose={this.preventClose}
                onOk={this.notNeedOk}
              />

              <OrderNotEditableDialog
                open={this.state.notEditableDialog}
                onClose={this.preventClose}
                onOk={this.notEditableDialogOk}
                message={this.state.message}
                status={this.state.status}
              />

              {!this.state.alert_open && !this.state.error_message && (
                <CodeInputDialog
                  open={this.state.enter_code_dialogue}
                  onClose={this.toggleShowEnterCode}
                  onCancel={this.toggleShowEnterCode}
                  hasOrder={this.state.data !== false}
                  searchOrder={this.handleClickOrderCode}
                  searchItem={this.searchItem}
                />
              )}

              <Dialog
                type={'error'}
                open={!!this.state.error_message}
                okText={'I understand'}
                cancelText={'I understand'}
                onCancel={() => this.setState({ error_message: false })}
                onClose={() => this.setState({ error_message: false })}
              >
                <DialogContentText>
                  <NotInterestedIcon
                    className='blink'
                    style={{ fontSize: '60px' }}
                  />
                </DialogContentText>
                <DialogContentText>
                  {this.state.error_message}
                </DialogContentText>
              </Dialog>
              <Dialog
                open={this.state.show_done}
                onCancel={() => this.setState({ show_done: false })}
                cancelText={'Close'}
                onClose={() => this.setState({ show_done: false })}
              >
                <Suppliers
                  suppliers={this.state.data.suppliers}
                  titleDone={'Done'}
                  show_done={true}
                  getRowClassname={this.getRowClassname}
                  getFounded={this.getFounded}
                  getDifference={this.getDifference}
                />
              </Dialog>
              <Dialog
                open={this.state.settingsOpen}
                title={'Consolidation settings'}
                onClose={() => this.setState({ settingsOpen: false })}
              >
                <Settings
                  settings={this.state.tableSettings}
                  setSettings={(tableSettings) =>
                    this.setState({ tableSettings })
                  }
                  menu_id={this.props.pageData.menu_id}
                  onClose={() => {
                    this.setState({ settingsOpen: false });
                    this.loadTableSettings();
                  }}
                />
              </Dialog>
            </FocusLock>
          </div>
        );
    }
}

Consolidation.propTypes = {
    pageData: PropTypes.any,
    url: PropTypes.any,
    location: PropTypes.any,
    classes: PropTypes.any,
    acl: PropTypes.any
};

Consolidation.defaultProps = {
    url: false,
    classes: {},
    acl: {}
};

export default Consolidation;
