/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * Not found page component
 * ---------------
 */

/**
 * Import used react components
 */
import React from "react";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";

class NotFoundPage extends React.Component {

    /**
     * Set the component defaults
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render dom elements
     * @returns {*}
     */
    render()
    {
        return (
            <div className="not-found-page">
                <Typography
                    variant="h1" style={{fontWeight: "bold", color: "#4f4f4f"}}>
                    404
                </Typography>
                <Typography
                    variant="h6" style={{color: "#4f4f4f"}}>
                    Page not found
                </Typography>
            </div>
        );
    }
}

NotFoundPage.propTypes = {
    location: PropTypes.any,
    classes: PropTypes.any
};

NotFoundPage.defaultProps = {
    classes: {}
};

export default NotFoundPage;