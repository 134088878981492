import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";

const MenuItemButton = (props) => {
    let history = useHistory(),
        id = props.id,
        url = props.url,
        pathname = props.pathname,
        callback = props.callback;

    if (url) {
        if (url === "/") { url = ""; }
        return <ListItem
            button
            href={"/" + url}
            key={id}
            selected={"/" + url === pathname}
            onClick={() => {history.push("/" + url); {callback && (callback());}}}
        >
            {props.children}
        </ListItem>;
    }
    return <ListItem
        button
        href={"/" + url}
        key={id}
        selected={"/" + url === pathname}
        onClick={() => {{callback && (callback());}}}
    >
        {props.children}
    </ListItem>;
};

MenuItemButton.propTypes = {
    id: PropTypes.any,
    url: PropTypes.any,
    pathname: PropTypes.any,
    children: PropTypes.any,
    callback: PropTypes.any
};

MenuItemButton.defaultProps = {
    id: false,
    url: false,
    pathname: false,
    callback: false
};

export default MenuItemButton;