/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function PaymentIcon_0002 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M550.2,47c-6-26.8-29.8-47-58.4-47l0,0L59.6,0.2C26.8,0.2,0,27,0,59.9v263.7c0,32.7,26.7,59.6,59.6,59.6l432.1,0.1
            c33,0,59.8-26.9,59.8-59.8l0.1-263.7C551.5,55.4,551.1,51.2,550.2,47z M521.8,323.6c-0.1,16.6-13.5,30.1-30.1,30.1l-432.1-0.1
            c-16.5,0-29.9-13.5-29.9-29.9V126.1L522,126C521.9,154.8,521.8,323.6,521.8,323.6z M521.9,67.5L29.6,67.6v-7.8
            c0-8.1,3.1-15.5,8.7-21.2c5.7-5.7,13.1-8.8,21.2-8.8l432.2-0.2h0.2c8,0.1,15.4,3.1,21.1,8.8c5.7,5.7,8.8,13.2,8.8,21.2
            C521.9,59.8,521.9,63.8,521.9,67.5z M471,291.4c0,14.8-12,26.6-26.6,26.6s-26.6-12-26.6-26.6c0-14.6,12-26.6,26.6-26.6
            S471,276.6,471,291.4z M362.6,216H47.3v-52.9h315.3V216z"/>
        </SvgIcon>
    );
}

export default PaymentIcon_0002;