/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ShopIcon_0006 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M506.6,99.9L268.8,2.5c-8.2-3.3-17.1-3.3-25.2,0.1L9.8,99.8c-6.2,2.6-9.8,8.7-9.8,15.5v34.9c0,9.2,6.7,16.9,15.9,16.9h12.6
            c2.3,0,4.8,1.7,4.8,4v221.8c0,20.8,16.3,40.5,37.1,40.5h12.9v-208h349.5v208H447c20.8,0,35.6-19.7,35.6-40.5V171.1
            c0-2.3,3.9-4,6.3-4h12.6c9.2,0,14.5-7.7,14.5-16.9v-34.8C515.9,108.6,512.9,102.4,506.6,99.9z M166.4,167.1H83.2v-41.6h83.2V167.1z
            M299.6,167.1h-83.2v-41.6h83.2V167.1z M349.5,167.1v-41.6h83.2v41.6H349.5z M204.1,362.6v52.3c0,8.7-7,15.7-15.7,15.7h-58.9
            c-8.7,0-15.7-7-15.7-15.7v-52.3c0-8.7,7-15.7,15.7-15.7h24v32.9h11.1v-32.9h23.8C197,346.9,204.1,353.9,204.1,362.6z M303.9,362.6
            v52.3c0,8.7-7,15.7-15.7,15.7h-58.9c-8.7,0-15.7-7-15.7-15.7v-52.3c0-8.7,7-15.7,15.7-15.7h24v32.9h11.1v-32.9h23.8
            C296.8,346.9,303.9,353.9,303.9,362.6z M405.5,362.6v52.3c0,8.7-7,15.7-15.7,15.7h-58.9c-8.7,0-15.7-7-15.7-15.7v-52.3
            c0-8.7,7-15.7,15.7-15.7h24v32.9H366v-32.9h23.8C398.5,346.9,405.5,353.9,405.5,362.6z M254.2,268.2v52.3c0,8.7-7,15.7-15.7,15.7
            h-58.9c-8.7,0-15.7-7-15.7-15.7v-52.3c0-8.7,7-15.7,15.7-15.7h24v32.9h11.1v-32.9h23.8C247.1,252.5,254.2,259.5,254.2,268.2z
            M356.4,268.2v52.3c0,8.7-7,15.7-15.7,15.7h-58.9c-8.7,0-15.7-7-15.7-15.7v-52.3c0-8.7,7-15.7,15.7-15.7h24v32.9h11.1v-32.9h23.8
            C349.4,252.5,356.4,259.5,356.4,268.2z"/>
        </SvgIcon>
    );
}

export default ShopIcon_0006;