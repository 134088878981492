/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ScanIcon_0001 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 563 511.8">
            <path d="M29.1,300.2H7.5c-4.1,0-7.5-3.3-7.5-7.5V7.5C0,3.4,3.3,0,7.5,0h21.7c4.1,0,7.5,3.3,7.5,7.5v285.2
            C36.6,296.9,33.3,300.2,29.1,300.2z M119.1,0H82.3c-4.1,0-7.5,3.3-7.5,7.5v285.2c0,4.1,3.3,7.5,7.5,7.5h36.8c4.1,0,7.5-3.3,7.5-7.5
            V7.5C126.5,3.4,123.2,0,119.1,0z M186.3,0h-10.9c-4.1,0-7.5,3.3-7.5,7.5v285.2c0,4.1,3.3,7.5,7.5,7.5h10.9c4.1,0,7.5-3.3,7.5-7.5
            V7.5C193.8,3.4,190.4,0,186.3,0z M277.4,0h-45.5c-4.1,0-7.5,3.4-7.5,7.5v148.3c15.1-21.8,36-39.3,60.5-50.3V7.5
            C284.9,3.4,281.6,0,277.4,0z M330.7,0h-0.2c-4.1,0-7.5,3.4-7.5,7.5v86.7c5-0.7,10-1.3,15.1-1.6V7.5C338.2,3.4,334.9,0,330.7,0z
            M372.9,0H368c-4.1,0-7.5,3.4-7.5,7.5v85.7c6.7,0.7,13.4,1.8,19.9,3.5V7.5C380.4,3.4,377,0,372.9,0z M427.9,0h-24.1
            c-4.1,0-7.5,3.4-7.5,7.5v93.9c14.1,5.2,27.3,12.5,39,21.5V7.5C435.4,3.4,432,0,427.9,0z M518.3,412.9c-5.6,5.6-13,8.4-20.3,8.4
            s-14.7-2.8-20.3-8.4l-61.8-61.8c-20.3,12.9-44.4,20.5-70.2,20.5c-72.5,0-131.4-59-131.4-131.4s59-131.4,131.4-131.4
            c72.5,0,131.4,59,131.4,131.4c0,25.8-7.6,49.9-20.5,70.2l61.8,61.8C529.5,383.5,529.5,401.7,518.3,412.9z M436,240.3
            c0-49.8-40.5-90.4-90.4-90.4c-49.8,0-90.4,40.5-90.4,90.4s40.5,90.4,90.4,90.4C395.5,330.6,436,290.1,436,240.3z"/>
        </SvgIcon>
    );
}

export default ScanIcon_0001;