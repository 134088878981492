/**
 * Copyright ©2019 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 * URL Data store component
 * -----------------------------------------------
 * - store "burned in" url-s
 */

/**
 * Import used react components
 */
import EventEmitter from "events";

class UrlDataStore extends EventEmitter {
    constructor() {
        super();
        this.urldata = {
            defaultURI: "http://logistics.test/",
            defaultLogin: "oauth"
        };
    }

    addChangeListener(type, callback) {
        this.on(type, callback);
    }

    removeChangeListener(type, callback) {
        this.removeListener(type, callback);
    }

    getData(variable) {
        return this.urldata[variable];
    }

    setData(variable, data) {
        this.urldata[variable] = data;
        this.emit("change");
    }
}

const urlDataStore = new UrlDataStore;

export default urlDataStore;