/**
 * Copyright ©2020 Itegration Ltd., Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function NumberIcon_8 (props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333333 333333">
            <path d="M166667 0c46022 0 87689 18655 117851 48816 30161 30161 48816 71828 48816 117851 0 92046-74620 166667-166667 166667-46022 0-87689-18655-117851-48816C18655 254357 0 212690 0 166667c0-46022 18655-87689 48816-117851C78977 18655 120644 0 166667 0zm-32344 161776c-5827-3085-10096-6575-12744-10407-3646-5235-5484-11248-5484-18041 0-11186 5266-20347 15798-27483 8226-5453 19101-8195 32624-8195 17917 0 31128 3396 39698 10220 8569 6793 12838 15393 12838 25707 0 6045-1714 11654-5141 16889-2587 3895-6606 7665-12121 11311 7229 3459 12651 8070 16203 13773 3583 5733 5359 12059 5359 19007 0 6699-1527 12931-4612 18758s-6855 10314-11311 13461c-4487 3178-10065 5484-16702 6980-6668 1496-13772 2243-21344 2243-14178 0-24990-1682-32500-5017-7478-3365-13149-8289-17044-14801-3895-6513-5858-13773-5858-21812 0-7852 1838-14489 5484-19943 3646-5453 9254-9659 16857-12651zm16733-26268c0 4612 1465 8351 4362 11187 2898 2835 6762 4237 11623 4237 4269 0 7790-1402 10532-4206 2773-2804 4144-6419 4144-10844 0-4643-1433-8382-4300-11280-2898-2898-6575-4331-11031-4331-4518 0-8226 1402-11061 4238-2836 2835-4269 6512-4269 10999zm-2057 57583c0 5920 1807 10719 5391 14489 3583 3739 7696 5609 12308 5609 4456 0 8444-1901 11965-5702 3521-3802 5297-8631 5297-14489 0-5920-1776-10750-5328-14520-3583-3770-7696-5671-12370-5671-4643 0-8663 1838-12090 5484-3459 3646-5172 8600-5172 14801zM273295 60037c-27288-27288-64987-44166-106628-44166S87327 32749 60039 60037s-44166 64987-44166 106628 16878 79340 44166 106628 64987 44166 106628 44166c83283 0 150794-67511 150794-150794 0-41641-16878-79340-44166-106628z"/>
        </SvgIcon>
    );
}

export default NumberIcon_8;